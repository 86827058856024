import {
  selectCompaniesId,
  selectNotCompaniesId,
  selectWithTestCp,
} from 'features/charge-point/filters/store/slice';
import { useAppSelector } from 'shared/redux/types';

import { ConsumedEnergyMetricsGraph } from '../consumed-energy';
import { SuccessfulSessionsMetricsGraph } from '../successful-sessions';

import { GraphsContainer } from './styles';

export function MetricsGraphs() {
  const withTestCp = useAppSelector(selectWithTestCp);
  const companiesId = useAppSelector(selectCompaniesId);
  const notCompaniesId = useAppSelector(selectNotCompaniesId);

  const withTestCpParam = withTestCp.length ? true : undefined;
  const companiesIdParam = companiesId.length ? companiesId : undefined;
  const notCompaniesIdParam = notCompaniesId.length
    ? notCompaniesId
    : undefined;

  return (
    <GraphsContainer>
      <ConsumedEnergyMetricsGraph
        isTest={withTestCpParam}
        companyIds={companiesIdParam}
        notСompanyIds={notCompaniesIdParam}
      />
      <SuccessfulSessionsMetricsGraph
        isTest={withTestCpParam}
        companyIds={companiesIdParam}
        notСompanyIds={notCompaniesIdParam}
      />
    </GraphsContainer>
  );
}
