import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1MetricsChargepoints: build.query<
      GetApiChargePointV1MetricsChargepointsApiResponse,
      GetApiChargePointV1MetricsChargepointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Metrics/CHARGEPOINTS`,
        params: {
          companyIds: queryArg.companyIds,
          isTest: queryArg.isTest,
          chargePointStatuses: queryArg.chargePointStatuses,
          notСompanyIds: queryArg.notСompanyIds,
        },
      }),
    }),
    getApiChargePointV1MetricsConnectors: build.query<
      GetApiChargePointV1MetricsConnectorsApiResponse,
      GetApiChargePointV1MetricsConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Metrics/CONNECTORS`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          chargePointStatuses: queryArg.chargePointStatuses,
          connectorStatuses: queryArg.connectorStatuses,
        },
      }),
    }),
    getApiChargePointV1MetricsSessionCharging: build.query<
      GetApiChargePointV1MetricsSessionChargingApiResponse,
      GetApiChargePointV1MetricsSessionChargingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Metrics/SESSION_CHARGING`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
        },
      }),
    }),
    getApiChargePointV1MetricsGraphSuccessfulSessions: build.query<
      GetApiChargePointV1MetricsGraphSuccessfulSessionsApiResponse,
      GetApiChargePointV1MetricsGraphSuccessfulSessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Metrics/Graph/SUCCESSFUL_SESSIONS`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          groupByPeriod: queryArg.groupByPeriod,
          chargePointIds: queryArg.chargePointIds,
        },
      }),
    }),
    getApiChargePointV1MetricsGraphAmountOfConsumedEnergy: build.query<
      GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiResponse,
      GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Metrics/Graph/AMOUNT_OF_CONSUMED_ENERGY`,
        params: {
          companyIds: queryArg.companyIds,
          notСompanyIds: queryArg.notСompanyIds,
          isTest: queryArg.isTest,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          groupByPeriod: queryArg.groupByPeriod,
          chargePointIds: queryArg.chargePointIds,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1MetricsChargepointsApiResponse =
  /** status 200 Success */ GetChargePointsMetricsVmBaseResponse;
export type GetApiChargePointV1MetricsChargepointsApiArg = {
  companyIds?: string[];
  isTest?: boolean;
  chargePointStatuses?: ChargePointStatus[];
  notСompanyIds?: string[];
};
export type GetApiChargePointV1MetricsConnectorsApiResponse =
  /** status 200 Success */ GetConnectorsMetricsVmBaseResponse;
export type GetApiChargePointV1MetricsConnectorsApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  chargePointStatuses?: ChargePointStatus[];
  connectorStatuses?: ConnectorStatus[];
};
export type GetApiChargePointV1MetricsSessionChargingApiResponse =
  /** status 200 Success */ GetSessionChargingMetricsVmBaseResponse;
export type GetApiChargePointV1MetricsSessionChargingApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
};
export type GetApiChargePointV1MetricsGraphSuccessfulSessionsApiResponse =
  /** status 200 Success */ GetSuccessfulSessionMetricsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1MetricsGraphSuccessfulSessionsApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  dateFrom?: string;
  dateTo?: string;
  groupByPeriod?: GroupByPeriod;
  chargePointIds?: string[];
};
export type GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiResponse =
  /** status 200 Success */ GetAmountOfConsumedEnergyMetricsVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiArg = {
  companyIds?: string[];
  notСompanyIds?: string[];
  isTest?: boolean;
  dateFrom?: string;
  dateTo?: string;
  groupByPeriod?: GroupByPeriod;
  chargePointIds?: string[];
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetChargePointsMetricsVm = {
  count?: number;
  of?: number;
};
export type GetChargePointsMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetChargePointsMetricsVm;
};
export type ChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type GetConnectorsMetricsVm = {
  count?: number;
  of?: number;
};
export type GetConnectorsMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetConnectorsMetricsVm;
};
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type GetSessionChargingMetricsVm = {
  count?: number;
};
export type GetSessionChargingMetricsVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionChargingMetricsVm;
};
export type GetSuccessfulSessionMetricsVm = {
  date?: string | null;
  value?: number;
};
export type GetSuccessfulSessionMetricsVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSuccessfulSessionMetricsVm[] | null;
};
export type GroupByPeriod = 'DAY' | 'MONTH';
export type GetAmountOfConsumedEnergyMetricsVm = {
  date?: string | null;
  value?: number;
};
export type GetAmountOfConsumedEnergyMetricsVmIReadOnlyCollectionBaseResponse =
  {
    status?: Status;
    statusMessage?: string | null;
    unavailables?: UnavailableServices[] | null;
    data?: GetAmountOfConsumedEnergyMetricsVm[] | null;
  };
export const {
  useGetApiChargePointV1MetricsChargepointsQuery,
  useGetApiChargePointV1MetricsConnectorsQuery,
  useGetApiChargePointV1MetricsSessionChargingQuery,
  useGetApiChargePointV1MetricsGraphSuccessfulSessionsQuery,
  useGetApiChargePointV1MetricsGraphAmountOfConsumedEnergyQuery,
} = injectedRtkApi;
