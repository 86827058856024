import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BillingListTable } from 'features/billing/table';

const pageParamsSchema = z.object({
  chargePointId: z.string().optional(),
});

export function ChargePointBillingsPage() {
  const { chargePointId } = useTypedParams(pageParamsSchema);

  return (
    <BillingListTable
      chargePointsIds={chargePointId ? [chargePointId] : undefined}
    />
  );
}
