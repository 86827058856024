import { useGetCountries } from 'shared/api/services/information/rtk';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { Form } from './form';

// TODO:

type Props = { closeModal: () => void };

export function FormContainer({ closeModal }: Props) {
  const countriesQuery = useGetCountries();

  const isLoading = countriesQuery.isLoading;

  const apiResponseError = [countriesQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [countriesQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!countriesQuery?.data?.data) {
    return <EmptyData />;
  }

  return <Form closeModal={closeModal} countries={countriesQuery.data.data} />;
}
