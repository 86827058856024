import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';

import {
  StyledCard,
  CardTitle,
  GridWrapper,
  GridRow,
  GridCell,
  ParamKey,
  ParamValue,
} from './styles';
import {
  InfrastructureInfo,
  INFRASTRUCTURE_INFO,
} from 'entities/charge-point/consts';
import { Link } from 'react-router-dom';
import { COMPANY_ROUTES } from 'shared/consts/routes/company';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  chargePoint: GetChargePointVm;
  companies: CompanyDto[];
};

export function StationInfoCard({ chargePoint, companies }: Props) {
  const {
    publicDescription,
    workingTime,
    latitude,
    longitude,
    hub,
    infrastructureInfo,
    companyId,
  } = chargePoint;

  const getInfrastructureInfo = (
    infrastructureInfo: string | null | undefined
  ) => {
    if (!infrastructureInfo) {
      return '';
    }

    try {
      const o = JSON.parse(infrastructureInfo);

      return Object.keys(o)
        .filter((key) => o[key] === 'true')
        .map((el) => INFRASTRUCTURE_INFO[el as InfrastructureInfo])
        .join(', ');
    } catch (e) {
      return false;
    }
  };

  const getCompanyLink = () => {
    if (!companyId) {
      return '';
    }

    const company = companies.find((el) => el.id === companyId);

    if (!company) {
      return '';
    }

    return (
      <Link to={`${COMPANY_ROUTES.COMPANIES}/${company.id}`}>
        {company.name}
      </Link>
    );
  };

  const PARAMS: Array<{
    label: React.ReactNode;
    value: React.ReactNode;
  }> = [
    {
      label: 'Публичное описание',
      value: publicDescription,
    },
    {
      label: 'Тариф',
      value: '',
    },
    {
      label: 'Владелец',
      value: getCompanyLink(),
    },
    { label: 'Макс. % оплаты баллами', value: '' },
    { label: 'Доступны скидки', value: '' },

    {
      label: 'Время работы ЭЗС',
      value: workingTime,
    },
    { label: 'Координаты', value: `${latitude}, ${longitude}` },
    {
      label: 'Хаб',
      value: hub ? hub.name : '',
    },
    {
      label: 'Что рядом',
      value: getInfrastructureInfo(infrastructureInfo),
    },
  ];

  return (
    <StyledCard>
      <CardTitle>О станции</CardTitle>
      <GridWrapper>
        {PARAMS.map(({ label, value }, idx) => {
          return (
            <GridRow key={idx}>
              <GridCell>
                <ParamKey>{label}</ParamKey>
              </GridCell>
              <GridCell>
                <ParamValue>{value}</ParamValue>
              </GridCell>
            </GridRow>
          );
        })}
      </GridWrapper>
    </StyledCard>
  );
}
