import { RESERVES, ROOT, SESSIONS, MANUAL_COMMANDS } from 'shared/consts/names';

import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { AddChargePointLink } from 'entities/charge-point/ui/add-link';
import { OpenCommandButton } from 'features/command/ui/button';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Мониторинг</CustomLink>,
    extraContent: <AddChargePointLink />,
  },
  {
    key: SESSIONS,
    label: <CustomLink to={SESSIONS}>Журнал сессий</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  // {
  //   key: RESERVES,
  //   label: <CustomLink to={RESERVES}>Журнал брони</CustomLink>,
  //   permissions: [
  //     {
  //       controller: 'PrivateMethods',
  //       right: 'Read',
  //     },
  //   ],
  //   disabled: true,
  // },
  {
    key: MANUAL_COMMANDS,
    label: <CustomLink to={MANUAL_COMMANDS}>Управление</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    // extraContent: <OpenCommandButton />,
    disabled: true,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
