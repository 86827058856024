import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
`;

export const CardTitle = styled.h3`
  color: #000;

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 12px;
`;

export const GridWrapper = styled.div`
  display: grid;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 194px auto;
  padding: 20px 0;
  gap: 16px;

  border-bottom: ${DEFAULT_BORDER};
`;

export const GridCell = styled.div``;

export const ParamKey = styled.div`
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

export const ParamValue = styled.div`
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
