import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const ValueContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const ValueCount = styled.span`
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: ${COLOR.black};
  margin-right: 3px;
`;

export const ValueOf = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR.lightGrey};
  margin-top: 6px;
`;
