import { CarDto } from 'shared/api/services/user/rtk/generated';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';

import { CONNECTOR_TYPE } from 'entities/connector';

import {
  CarHeader,
  CarHeaderInfo,
  CarInfoGroup,
  CarInfoSubTitle,
  CarInfoValue,
  CarModel,
  CarTitle,
  StyledCarCard,
} from './styles';
import { Actions } from './actions';

type Props = {
  car: CarDto;
  carModels: GetCarModelVm[];
};

export function CarCard({ car, carModels }: Props) {
  const { connectors, name, carModelId } = car;

  const getBrandAndModelNames = () => {
    const carModel = carModels.find((el) => el.id === carModelId);

    if (!carModel) {
      return '';
    }

    return `${carModel.carBrand.name} ${carModel.name}`;
  };

  const { adapterList, connectorList } = connectors.reduce(
    (acc, el) => {
      const { type, isAdapterNeeded } = el;

      if (isAdapterNeeded) {
        acc.adapterList = [...acc.adapterList, CONNECTOR_TYPE[type]];
      } else {
        acc.connectorList = [...acc.connectorList, CONNECTOR_TYPE[type]];
      }

      return acc;
    },
    { adapterList: [], connectorList: [] } as Record<
      'connectorList' | 'adapterList',
      string[]
    >
  );

  return (
    <StyledCarCard>
      <CarHeader>
        <CarHeaderInfo>
          <CarTitle>{`Электромобиль ${name}`}</CarTitle>
          <CarModel>{getBrandAndModelNames()}</CarModel>
        </CarHeaderInfo>
        <Actions car={car} />
      </CarHeader>
      <CarInfoGroup>
        <CarInfoSubTitle>Коннекторы</CarInfoSubTitle>
        <CarInfoValue>{connectorList.join(', ')}</CarInfoValue>
      </CarInfoGroup>
      <CarInfoGroup>
        <CarInfoSubTitle>Переходники</CarInfoSubTitle>
        <CarInfoValue>{adapterList.join(', ')}</CarInfoValue>
      </CarInfoGroup>
    </StyledCarCard>
  );
}
