import { z } from 'zod';

import {
  useGetRolesRights,
  useGetUserRights,
  useGetUserRoles,
} from 'shared/api/services/identity/rtk/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { SUPERUSER_ROLE } from 'entities/authorization/consts';

import { Form } from './form';

const pageParamsSchema = z.object({
  userName: z.string(),
});

export function FormContainer() {
  const { userName } = useTypedParams(pageParamsSchema);

  // Фетчим
  // 1.Список ролей юзера Array<{id, name}>
  // 2.Список личных прав юзера (без прав ролей) Array<{controllerName, R, W, E>}
  // 3.Список прав для каждой роли (из п.1)

  const userRolesQuery = useGetUserRoles(userName);
  const userRightsQuery = useGetUserRights(userName);

  const isAdmin =
    Boolean(
      userRolesQuery?.data?.roles?.find((role) => role.name === SUPERUSER_ROLE)
    ) ?? false;

  const rolesNamesArr =
    userRolesQuery?.data?.roles?.map((role) => role.name) || [];

  const rolesRightsQuery = useGetRolesRights(rolesNamesArr, {
    skip: Boolean(!rolesNamesArr) || isAdmin,
  });

  const isLoading = userRolesQuery.isLoading || userRightsQuery.isLoading;

  const apiResponseError = [userRolesQuery.data, userRightsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [userRolesQuery.error, userRightsQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <Form
      isAdmin={isAdmin}
      userRights={userRightsQuery.data?.rights ?? []}
      userName={userName}
      roleRightsDict={rolesRightsQuery.data ?? {}}
    />
  );
}
