import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAddCarModel } from 'shared/api/services/information/rtk';
import { GetCarBrandVm } from 'shared/api/services/information/rtk/generated/car-brands';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

type Props = {
  carBrands: GetCarBrandVm[];
};

export function Form({ carBrands }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddCarModel();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields carBrands={carBrands} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  carBrands: GetCarBrandVm[];
};

Form.Fields = function Fields({ carBrands }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="name" label="Наименование" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="carBrandId"
            options={carBrands.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Марка авто"
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
