import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { BillingListTable } from 'features/billing/table';

const pageParamsSchema = z.object({
  userId: z.string(),
});

export function UserBillingsPage() {
  const { userId } = useTypedParams(pageParamsSchema);

  return <BillingListTable userId={userId} />;
}
