import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { ButtonLabel, IconContainer, StyledButton } from './styles';

type Props = {
  onClick: () => void;
};

export function AddStageButton({ onClick }: Props) {
  return (
    <StyledButton onClick={onClick} type="button">
      <ButtonLabel>Добавить стадию</ButtonLabel>
      <IconContainer>
        <PlusInCircleIcon />
      </IconContainer>
    </StyledButton>
  );
}
