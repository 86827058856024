import { useGetCarModels } from 'shared/api/services/information/rtk';
import { useGetCars } from 'shared/api/services/user/rtk/enhanced';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { CarList } from './car-list';

type Props = {
  userId: string;
};

export function CarListContainer({ userId }: Props) {
  const carsQuery = useGetCars(userId);
  const carModelsQuery = useGetCarModels();

  const isLoading = carsQuery.isLoading || carModelsQuery.isLoading;

  const apiResponseError = [carsQuery.data, carModelsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [carsQuery.error, carModelsQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!carsQuery.data?.data || !carModelsQuery.data?.data) {
    return <EmptyData />;
  }

  return (
    <CarList
      cars={carsQuery.data.data}
      userId={userId}
      carModels={carModelsQuery.data.data}
    />
  );
}
