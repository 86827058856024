import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { CommandType } from 'entities/command';

import { CommandForm } from '../form';

type Props = {
  commandType?: CommandType;
  chargePointId?: string;
};

export const CommandModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Добавление действия управления"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <CommandForm {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
