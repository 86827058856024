import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { Form } from './form';

export function FormContainer() {
  const { isFetching, error, data } = useGetCountries(undefined);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.data) {
    return <EmptyData />;
  }

  return <Form countries={data.data} />;
}
