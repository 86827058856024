import { ADD_PREFIX, PUSH_NOTIFICATIONS } from '../names';

export const TEMPLATE = ':templateId';

const ROOT = `/${PUSH_NOTIFICATIONS}`;

export const PUSH_NOTIFICATION_ROUTES = {
  LIST: ROOT,
  TEMPLATE: `${ROOT}/${TEMPLATE}`,
  ADD: `${ROOT}/${ADD_PREFIX}`,
};
