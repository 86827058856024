/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PushNotificationService.WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  BatchDtoIReadOnlyCollectionResponseBase,
  BatchDtoResponseBase,
  CreateBatchRequestDto,
  CreateOrUpdateTokenRequestDto,
  CreateTemplateRequestDto,
  GetApiPushNotificationMessagesStatusesParams,
  LoadMessagesStatisticsDtoResponseBase,
  MessageStatusDtoIReadOnlyCollectionResponseBase,
  PostApiPushNotificationBatchesSendParams,
  PostApiPushNotificationMessagesFromCsvWithDevicesIdsBodyOne,
  PostApiPushNotificationMessagesFromCsvWithDevicesIdsBodyTwo,
  PostApiPushNotificationMessagesFromCsvWithDevicesIdsParams,
  PostApiPushNotificationTestingParams,
  ResponseBase,
  TemplateDtoIReadOnlyCollectionResponseBase,
  TemplateDtoResponseBase,
  TokenDtoResponseBase,
  UpdateTemplateRequestDto
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getPushNotificationServiceWebApi = () => {
const getApiPushNotificationAppInfo = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/Api/PushNotification/AppInfo`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get batch by its Id.
 */
const getApiPushNotificationBatchesBatchId = (
    batchId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BatchDtoResponseBase>(
      {url: `/Api/PushNotification/Batches/${batchId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Delete batch.
 */
const deleteApiPushNotificationBatchesBatchId = (
    batchId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/PushNotification/Batches/${batchId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get all batches.
 */
const getApiPushNotificationBatches = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BatchDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/PushNotification/Batches`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Create batch.
 */
const postApiPushNotificationBatches = (
    createBatchRequestDto: CreateBatchRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BatchDtoResponseBase>(
      {url: `/Api/PushNotification/Batches`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBatchRequestDto
    },
      options);
    }
  
/**
 * @summary Send batch's messages.
 */
const postApiPushNotificationBatchesSend = (
    params?: PostApiPushNotificationBatchesSendParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/PushNotification/Batches/send`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Get messages statuses by filter.
 */
const getApiPushNotificationMessagesStatuses = (
    params?: GetApiPushNotificationMessagesStatusesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MessageStatusDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/PushNotification/Messages/_statuses`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Load messages for sending from CSV-file with user devices ids.
 */
const postApiPushNotificationMessagesFromCsvWithDevicesIds = (
    postApiPushNotificationMessagesFromCsvWithDevicesIdsBody: PostApiPushNotificationMessagesFromCsvWithDevicesIdsBodyOne | PostApiPushNotificationMessagesFromCsvWithDevicesIdsBodyTwo,
    params?: PostApiPushNotificationMessagesFromCsvWithDevicesIdsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<LoadMessagesStatisticsDtoResponseBase>(
      {url: `/Api/PushNotification/Messages/fromCsvWithDevicesIds`, method: 'POST',
      data: postApiPushNotificationMessagesFromCsvWithDevicesIdsBody,
        params
    },
      options);
    }
  
/**
 * @summary Get template by its Id.
 */
const getApiPushNotificationTemplatesTemplateId = (
    templateId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TemplateDtoResponseBase>(
      {url: `/Api/PushNotification/Templates/${templateId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update template.
 */
const patchApiPushNotificationTemplatesTemplateId = (
    templateId: string,
    updateTemplateRequestDto: UpdateTemplateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TemplateDtoResponseBase>(
      {url: `/Api/PushNotification/Templates/${templateId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateTemplateRequestDto
    },
      options);
    }
  
/**
 * @summary Delete template.
 */
const deleteApiPushNotificationTemplatesTemplateId = (
    templateId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/PushNotification/Templates/${templateId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get all templates.
 */
const getApiPushNotificationTemplates = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TemplateDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/PushNotification/Templates`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Create template.
 */
const postApiPushNotificationTemplates = (
    createTemplateRequestDto: CreateTemplateRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TemplateDtoResponseBase>(
      {url: `/Api/PushNotification/Templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTemplateRequestDto
    },
      options);
    }
  
/**
 * @summary Method for testing firebase work.
 */
const postApiPushNotificationTesting = (
    params?: PostApiPushNotificationTestingParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/Api/PushNotification/Testing`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Create or update (if exists) device token.
 */
const putApiPushNotificationTokens = (
    createOrUpdateTokenRequestDto: CreateOrUpdateTokenRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TokenDtoResponseBase>(
      {url: `/Api/PushNotification/Tokens`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateTokenRequestDto
    },
      options);
    }
  
return {getApiPushNotificationAppInfo,getApiPushNotificationBatchesBatchId,deleteApiPushNotificationBatchesBatchId,getApiPushNotificationBatches,postApiPushNotificationBatches,postApiPushNotificationBatchesSend,getApiPushNotificationMessagesStatuses,postApiPushNotificationMessagesFromCsvWithDevicesIds,getApiPushNotificationTemplatesTemplateId,patchApiPushNotificationTemplatesTemplateId,deleteApiPushNotificationTemplatesTemplateId,getApiPushNotificationTemplates,postApiPushNotificationTemplates,postApiPushNotificationTesting,putApiPushNotificationTokens}};
export type GetApiPushNotificationAppInfoResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationAppInfo']>>>
export type GetApiPushNotificationBatchesBatchIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationBatchesBatchId']>>>
export type DeleteApiPushNotificationBatchesBatchIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['deleteApiPushNotificationBatchesBatchId']>>>
export type GetApiPushNotificationBatchesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationBatches']>>>
export type PostApiPushNotificationBatchesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['postApiPushNotificationBatches']>>>
export type PostApiPushNotificationBatchesSendResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['postApiPushNotificationBatchesSend']>>>
export type GetApiPushNotificationMessagesStatusesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationMessagesStatuses']>>>
export type PostApiPushNotificationMessagesFromCsvWithDevicesIdsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['postApiPushNotificationMessagesFromCsvWithDevicesIds']>>>
export type GetApiPushNotificationTemplatesTemplateIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationTemplatesTemplateId']>>>
export type PatchApiPushNotificationTemplatesTemplateIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['patchApiPushNotificationTemplatesTemplateId']>>>
export type DeleteApiPushNotificationTemplatesTemplateIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['deleteApiPushNotificationTemplatesTemplateId']>>>
export type GetApiPushNotificationTemplatesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['getApiPushNotificationTemplates']>>>
export type PostApiPushNotificationTemplatesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['postApiPushNotificationTemplates']>>>
export type PostApiPushNotificationTestingResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['postApiPushNotificationTesting']>>>
export type PutApiPushNotificationTokensResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getPushNotificationServiceWebApi>['putApiPushNotificationTokens']>>>
