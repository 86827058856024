import { BillingDto } from 'shared/api/services/billing/rtk/generated';

import { Divider } from './divider';
import {
  Section,
  SectionContent,
  SectionContentRow,
  SectionTitle,
  StyledCard,
  SummaryFooter,
  SummaryPayment,
} from './styles';

type Props = {
  billing: BillingDto;
};

export function SummaryCard({ billing }: Props) {
  const {
    payment,
    bonusesPayment,
    discount,
    invoiceSum,
    benefitPayment,
    balancePayment,
  } = billing;

  return (
    <StyledCard>
      <Section>
        <SectionTitle>Бонусы и скидки</SectionTitle>
        <SectionContent>
          <SectionContentRow>
            <span>Без скидок и баллов</span>
            <span>{`${payment} Р`}</span>
          </SectionContentRow>
          <SectionContentRow>
            <span>Списано баллов</span>
            <span>{bonusesPayment}</span>
          </SectionContentRow>
          <SectionContentRow>
            <span>Скидка</span>
            <span>{`${discount} Р`}</span>
          </SectionContentRow>
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>Оплата</SectionTitle>
        <SectionContent>
          <SectionContentRow>
            <span>С баланса</span>
            <span>{`${balancePayment} Р`}</span>
          </SectionContentRow>
          <SectionContentRow>
            <span>С карты</span>
            <span>{`${invoiceSum} Р`}</span>
          </SectionContentRow>
        </SectionContent>
      </Section>

      <SummaryFooter>
        <Divider />
        <SummaryPayment>
          <span>Итого</span>
          <span>{`${benefitPayment} Р`}</span>
        </SummaryPayment>
      </SummaryFooter>
    </StyledCard>
  );
}
