import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { Form } from './form';
import { useGetMobileApps } from 'shared/api/services/information/rtk';

export function FormContainer() {
  const {
    data: mobileApps,
    isLoading: isMobileAppsLoading,
    error: mobileAppsError,
  } = useGetMobileApps();

  const error = [mobileAppsError].find((err) => err !== undefined);

  const isLoading = isMobileAppsLoading;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!mobileApps?.data) {
    return <EmptyData />;
  }

  return <Form mobileApps={mobileApps.data} />;
}
