import { AppThunk } from 'shared/redux/types';
import { enhancedApi as api, ServicesListDto } from '../generated';

import { FormOutput as AddServicesFormOutput } from 'features/billing/add/add-services/consts/schema';
import { FormOutput as EditServicesListFormOutput } from 'features/billing/edit/consts/schema';

export const addServicesToServicesList =
  (args: {
    stages: AddServicesFormOutput;
    id: string;
  }): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const {
      id,
      stages: { chargingStages, reservingStages },
    } = args;

    let servicesIds: string[] = [];

    if (chargingStages.length) {
      const addServiceRes = await dispatch(
        api.endpoints.postApiBillingV1Services.initiate({
          name: 'Услуга Заряд',
          type: 'CHARGING',
        })
      );

      if (addServiceRes.error) {
        throw addServiceRes.error;
      }

      const serviceId = addServiceRes.data.data?.id;

      if (!serviceId) {
        return;
      }

      const addServiceToServicesListRes = await dispatch(
        api.endpoints.patchApiBillingV1ServicesListsById.initiate({
          id,
          updateServicesListRequestDto: { servicesIds: [serviceId] },
        })
      );

      if (addServiceToServicesListRes.error) {
        throw addServiceToServicesListRes.error;
      }

      if (addServiceToServicesListRes.data.data?.services) {
        servicesIds = addServiceToServicesListRes.data.data?.services.map(
          (el) => el.id
        );
      }

      const addStagesRes = await Promise.all(
        chargingStages.map((stage) =>
          dispatch(
            api.endpoints.postApiBillingV1Stages.initiate({
              ...stage,
              serviceId,
            })
          )
        )
      );

      const addStagesError = addStagesRes.find((el) => el.error);

      if (addStagesError) {
        throw addStagesError;
      }
    }

    if (reservingStages.length) {
      const addServiceRes = await dispatch(
        api.endpoints.postApiBillingV1Services.initiate({
          name: 'Услуга Бронирование',
          type: 'RESERVING',
        })
      );

      if (addServiceRes.error) {
        throw addServiceRes.error;
      }

      const serviceId = addServiceRes.data.data?.id;

      if (!serviceId) {
        return;
      }

      const addServiceToServicesListRes = await dispatch(
        api.endpoints.patchApiBillingV1ServicesListsById.initiate({
          id,
          updateServicesListRequestDto: {
            servicesIds: [...servicesIds, serviceId],
          },
        })
      );

      if (addServiceToServicesListRes.error) {
        throw addServiceToServicesListRes.error;
      }

      const addStagesRes = await Promise.all(
        chargingStages.map((stage) =>
          dispatch(
            api.endpoints.postApiBillingV1Stages.initiate({
              ...stage,
              serviceId,
            })
          )
        )
      );

      const addStagesError = addStagesRes.find((el) => el.error);

      if (addStagesError) {
        throw addStagesError;
      }
    }

    await dispatch(
      api.util.invalidateTags([{ type: 'ServicesLists', id: 'LIST' }])
    );
  };

export const updateServicesList =
  (args: {
    formOutput: EditServicesListFormOutput;
    servicesList: ServicesListDto;
  }): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const {
      servicesList,
      formOutput: { chargingStages, reservingStages, name },
    } = args;

    const { id, services } = servicesList;

    let servicesIds: string[] = services?.map((el) => el.id) ?? [];

    if (name !== servicesList.name) {
      const updateNameRes = await dispatch(
        api.endpoints.patchApiBillingV1ServicesListsById.initiate({
          id,
          updateServicesListRequestDto: {
            name,
          },
        })
      );

      if (updateNameRes.error) {
        throw updateNameRes.error;
      }
    }

    if (chargingStages.length) {
      let chargingServiceId: string;

      const service = services?.find((el) => el.type === 'CHARGING');

      if (service) {
        chargingServiceId = service.id;
      } else {
        // Если сервиса не было - создаем

        const addServiceRes = await dispatch(
          api.endpoints.postApiBillingV1Services.initiate({
            name: 'Услуга Заряд',
            type: 'CHARGING',
          })
        );

        if (addServiceRes.error) {
          throw addServiceRes.error;
        }

        if (!addServiceRes.data.data?.id) {
          return;
        }

        chargingServiceId = addServiceRes.data.data.id;

        //
        // Обновляем список услуг, добавляя созданный сервис
        //

        const addServiceToServicesListRes = await dispatch(
          api.endpoints.patchApiBillingV1ServicesListsById.initiate({
            id,
            updateServicesListRequestDto: {
              servicesIds: [...servicesIds, chargingServiceId],
            },
          })
        );

        if (addServiceToServicesListRes.error) {
          throw addServiceToServicesListRes.error;
        }

        if (addServiceToServicesListRes.data.data?.services) {
          servicesIds = addServiceToServicesListRes.data.data?.services.map(
            (el) => el.id
          );
        }
      }

      const addStagesRes = await Promise.all(
        chargingStages.map((stage) =>
          dispatch(
            api.endpoints.postApiBillingV1Stages.initiate({
              ...stage,
              serviceId: chargingServiceId,
            })
          )
        )
      );

      const addStagesError = addStagesRes.find((el) => el.error);

      if (addStagesError) {
        throw addStagesError;
      }
    }

    if (reservingStages.length) {
      let reservingServiceId: string;

      const service = services?.find((el) => el.type === 'RESERVING');

      if (service) {
        reservingServiceId = service.id;
      } else {
        // Если сервиса не было - создаем

        const addServiceRes = await dispatch(
          api.endpoints.postApiBillingV1Services.initiate({
            name: 'Услуга Бронирование',
            type: 'RESERVING',
          })
        );

        if (addServiceRes.error) {
          throw addServiceRes.error;
        }

        if (!addServiceRes.data.data?.id) {
          return;
        }

        reservingServiceId = addServiceRes.data.data.id;

        //
        // Обновляем список услуг, добавляя созданный сервис
        //

        const addServiceToServicesListRes = await dispatch(
          api.endpoints.patchApiBillingV1ServicesListsById.initiate({
            id,
            updateServicesListRequestDto: {
              servicesIds: [...servicesIds, reservingServiceId],
            },
          })
        );

        if (addServiceToServicesListRes.error) {
          throw addServiceToServicesListRes.error;
        }

        if (addServiceToServicesListRes.data.data?.services) {
          servicesIds = addServiceToServicesListRes.data.data?.services.map(
            (el) => el.id
          );
        }
      }

      const addStagesRes = await Promise.all(
        reservingStages.map((stage) =>
          dispatch(
            api.endpoints.postApiBillingV1Stages.initiate({
              ...stage,
              serviceId: reservingServiceId,
            })
          )
        )
      );

      const addStagesError = addStagesRes.find((el) => el.error);

      if (addStagesError) {
        throw addStagesError;
      }
    }

    await dispatch(
      api.util.invalidateTags([
        { type: 'ServicesLists', id: 'LIST' },
        {
          type: 'ServicesLists',
          id: id,
        },
      ])
    );
  };
