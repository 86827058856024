import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledTitle = styled.h4`
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
`;

export const StagesContainer = styled.div`
  font-size: 14px;
  font-weight: 500;

  & > * {
    margin-bottom: 20px;
  }
`;

export const StyledLabel = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;

  margin-bottom: 5px;
`;

export const StyledValue = styled.div``;
