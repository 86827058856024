/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * ChargePointService.WebApi
 * OpenAPI spec version: 1.0
 */

export type ConnectorType = typeof ConnectorType[keyof typeof ConnectorType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectorType = {
  SCHUKO: 'SCHUKO',
  TYPE2: 'TYPE2',
  CCS2: 'CCS2',
  CHADEMO: 'CHADEMO',
  GBTDC: 'GBTDC',
  GBTAC: 'GBTAC',
  CCS1: 'CCS1',
  TYPE1: 'TYPE1',
  TESLA: 'TESLA',
} as const;
