import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { ROLE_ROUTES } from 'shared/consts/routes/identity-role';

import { useGetIdentityRoles } from 'shared/api/services/identity/rtk/enhanced';
import { IDENTITY_ROLES } from 'shared/consts/names';

export function MenuContainer() {
  const { data, isFetching, error } = useGetIdentityRoles();

  if (data && data.status === 'Error') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.roles || []).map(({ id, name }) => ({
    id: encodeURIComponent(name),
    name,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={IDENTITY_ROLES}
      absolutePath={ROLE_ROUTES.ROLES}
      label="Роли"
    />
  );
}
