import { useAppSelector } from 'shared/redux/types';

import { ChargePointListTable } from 'features/charge-point/table';
import {
  selectCompaniesId,
  selectNotCompaniesId,
  selectStatuses,
  selectWithTestCp,
} from 'features/charge-point/filters/store/slice';

export function ChargePointListWidget() {
  const statuses = useAppSelector(selectStatuses);
  const withTestCp = useAppSelector(selectWithTestCp);
  const companiesId = useAppSelector(selectCompaniesId);
  const notCompaniesId = useAppSelector(selectNotCompaniesId);

  // TODO: типизировать status и ownerTypes потом

  return (
    <ChargePointListTable
    // ownerTypes={ownerTypes.length ? ownerTypes : undefined}
    // ownersId={ownerIds.length ? ownerIds : undefined}
    // status={statuses.length ? statuses : undefined}
    // withTestCp={withTestCp}
    />
  );
}
