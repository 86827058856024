import styled from 'styled-components';
import { Result } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledResult = styled(Result)`
  background-color: ${COLOR.white};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 2%;
  overflow: hidden;

  & .ant-result-icon {
    margin-bottom: 1%;
  }

  & .ant-result-icon > .anticon {
    font-size: 56px;
  }
`;
