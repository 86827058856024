import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationV1MobileAppsById: build.query<
      GetApiInformationV1MobileAppsByIdApiResponse,
      GetApiInformationV1MobileAppsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/MobileApps/${queryArg}`,
      }),
    }),
    patchApiInformationV1MobileAppsById: build.mutation<
      PatchApiInformationV1MobileAppsByIdApiResponse,
      PatchApiInformationV1MobileAppsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/MobileApps/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateMobileAppRequest,
      }),
    }),
    deleteApiInformationV1MobileAppsById: build.mutation<
      DeleteApiInformationV1MobileAppsByIdApiResponse,
      DeleteApiInformationV1MobileAppsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/MobileApps/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationV1MobileApps: build.query<
      GetApiInformationV1MobileAppsApiResponse,
      GetApiInformationV1MobileAppsApiArg
    >({
      query: () => ({ url: `/Api/Information/v1/MobileApps` }),
    }),
    postApiInformationV1MobileApps: build.mutation<
      PostApiInformationV1MobileAppsApiResponse,
      PostApiInformationV1MobileAppsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/MobileApps`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationV1MobileAppsByIdApiResponse =
  /** status 200 Success */ MobileAppVmBaseResponse;
export type GetApiInformationV1MobileAppsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiInformationV1MobileAppsByIdApiResponse =
  /** status 200 Success */ UpdateMobileAppVmBaseResponse;
export type PatchApiInformationV1MobileAppsByIdApiArg = {
  /** Идентификатор */
  id: string;
  /** Модель обновления */
  updateMobileAppRequest: UpdateMobileAppRequest;
};
export type DeleteApiInformationV1MobileAppsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1MobileAppsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiInformationV1MobileAppsApiResponse =
  /** status 200 Success */ MobileAppVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationV1MobileAppsApiArg = void;
export type PostApiInformationV1MobileAppsApiResponse =
  /** status 200 Success */ AddMobileAppVmBaseResponse;
export type PostApiInformationV1MobileAppsApiArg =
  /** Модель мобильного приложения */ AddMobileAppRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type MobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type MobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: MobileAppVm;
};
export type UpdateMobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type UpdateMobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateMobileAppVm;
};
export type UpdateMobileAppRequest = {
  name?: string | null;
  description?: string | null;
  isDefault?: boolean | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type MobileAppVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: MobileAppVm[] | null;
};
export type AddMobileAppVm = {
  id: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
};
export type AddMobileAppVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddMobileAppVm;
};
export type AddMobileAppRequest = {
  name: string;
  description: string;
  isDefault: boolean;
};
export const {
  useGetApiInformationV1MobileAppsByIdQuery,
  usePatchApiInformationV1MobileAppsByIdMutation,
  useDeleteApiInformationV1MobileAppsByIdMutation,
  useGetApiInformationV1MobileAppsQuery,
  usePostApiInformationV1MobileAppsMutation,
} = injectedRtkApi;
