import { COLOR } from 'shared/consts';
import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;

  & thead {
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgb(217, 217, 217);

    & tr th {
      padding: 10px;
    }
  }

  & tbody {
    & tr td {
      padding: 10px;
    }
  }

  & tbody tr td div {
    & label {
      background-color: transparent !important;
    }

    & div {
      display: none;
    }
  }

  & tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const BrandLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BrandLogo = styled.img`
  height: 50px;
`;

export const NoBrandLogoText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.black};
  padding: 5px;
`;
