import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledContainer = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  gap: 12px;
`;

export const AvatarMock = styled.span`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${COLOR.lightGreenMenu};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const UserGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const UserFullName = styled.p`
  color: ${COLOR.title};
  font-weight: 500;
  white-space: nowrap;
`;

export const ProfileLink = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.placeholder};
  height: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
