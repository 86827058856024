import { COLOR } from 'shared/consts';
import styled from 'styled-components';

export const StyledLayout = styled.div``;

export const MenuGroup = styled.div`
  &:not(:last-child) {
    border-bottom: 2px solid ${COLOR.greenMenu};
  }

  & button {
    display: block;
    width: 100%;
    padding: 7px 10px;
    line-height: 22px;
  }

  & button:last-child {
    border-bottom: none;
  }
`;
