import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useDeleteConnector } from 'shared/api/services/chargepoint/rtk';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  connectorId: string;
  chargePointId: string;
};

export const Modal = NiceModal.create(
  ({ chargePointId, connectorId }: Props) => {
    const modal = useModal();

    const [trigger, { isLoading }] = useDeleteConnector();

    const handleDelete = async () => {
      try {
        const res = await trigger({
          chargePointsId: chargePointId,
          connectorId,
        }).unwrap();

        openSuccessNotification(DELETE_SUCCESS);
      } catch (err) {
        const hasErrorMessage = isErrorWithMessage(err);

        const errorText = hasErrorMessage
          ? err.data.statusMessage
          : DELETE_ERROR;

        openErrorNotification(errorText);
      } finally {
        modal.hide();
      }
    };

    return (
      <UiModal
        title={`Удалить коннектор?`}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
      >
        <ConfirmDialog
          onCancel={modal.hide}
          onConfirm={() => handleDelete()}
          loading={isLoading}
        />
      </UiModal>
    );
  }
);
