import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPushNotificationV1AppInfo: build.query<
      GetApiPushNotificationV1AppInfoApiResponse,
      GetApiPushNotificationV1AppInfoApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/V1/AppInfo` }),
    }),
    getApiPushNotificationV1BatchesByBatchId: build.query<
      GetApiPushNotificationV1BatchesByBatchIdApiResponse,
      GetApiPushNotificationV1BatchesByBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Batches/${queryArg}`,
      }),
    }),
    deleteApiPushNotificationV1BatchesByBatchId: build.mutation<
      DeleteApiPushNotificationV1BatchesByBatchIdApiResponse,
      DeleteApiPushNotificationV1BatchesByBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Batches/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPushNotificationV1Batches: build.query<
      GetApiPushNotificationV1BatchesApiResponse,
      GetApiPushNotificationV1BatchesApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/V1/Batches` }),
    }),
    postApiPushNotificationV1Batches: build.mutation<
      PostApiPushNotificationV1BatchesApiResponse,
      PostApiPushNotificationV1BatchesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Batches`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPushNotificationV1BatchesSend: build.mutation<
      PostApiPushNotificationV1BatchesSendApiResponse,
      PostApiPushNotificationV1BatchesSendApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Batches/send`,
        method: 'POST',
        params: {
          batchId: queryArg,
        },
      }),
    }),
    getApiPushNotificationV1MessagesStatuses: build.query<
      GetApiPushNotificationV1MessagesStatusesApiResponse,
      GetApiPushNotificationV1MessagesStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Messages/_statuses`,
        params: {
          BatchId: queryArg.batchId,
          Status: queryArg.status,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    postApiPushNotificationV1MessagesFromCsvWithDevicesIds: build.mutation<
      PostApiPushNotificationV1MessagesFromCsvWithDevicesIdsApiResponse,
      PostApiPushNotificationV1MessagesFromCsvWithDevicesIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Messages/fromCsvWithDevicesIds`,
        method: 'POST',
        body: queryArg.body,
        params: {
          batchId: queryArg.batchId,
        },
      }),
    }),
    getApiPushNotificationV1TemplatesByTemplateId: build.query<
      GetApiPushNotificationV1TemplatesByTemplateIdApiResponse,
      GetApiPushNotificationV1TemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Templates/${queryArg}`,
      }),
    }),
    patchApiPushNotificationV1TemplatesByTemplateId: build.mutation<
      PatchApiPushNotificationV1TemplatesByTemplateIdApiResponse,
      PatchApiPushNotificationV1TemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Templates/${queryArg.templateId}`,
        method: 'PATCH',
        body: queryArg.updateTemplateRequestDto,
      }),
    }),
    deleteApiPushNotificationV1TemplatesByTemplateId: build.mutation<
      DeleteApiPushNotificationV1TemplatesByTemplateIdApiResponse,
      DeleteApiPushNotificationV1TemplatesByTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Templates/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiPushNotificationV1Templates: build.query<
      GetApiPushNotificationV1TemplatesApiResponse,
      GetApiPushNotificationV1TemplatesApiArg
    >({
      query: () => ({ url: `/Api/PushNotification/V1/Templates` }),
    }),
    postApiPushNotificationV1Templates: build.mutation<
      PostApiPushNotificationV1TemplatesApiResponse,
      PostApiPushNotificationV1TemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Templates`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiPushNotificationV1Testing: build.mutation<
      PostApiPushNotificationV1TestingApiResponse,
      PostApiPushNotificationV1TestingApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Testing`,
        method: 'POST',
        params: {
          deviceId: queryArg,
        },
      }),
    }),
    putApiPushNotificationV1Tokens: build.mutation<
      PutApiPushNotificationV1TokensApiResponse,
      PutApiPushNotificationV1TokensApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/PushNotification/V1/Tokens`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiPushNotificationV1AppInfoApiResponse =
  /** status 200 Success */ string;
export type GetApiPushNotificationV1AppInfoApiArg = void;
export type GetApiPushNotificationV1BatchesByBatchIdApiResponse =
  /** status 200 Success */ BatchDtoResponseBase;
export type GetApiPushNotificationV1BatchesByBatchIdApiArg = string;
export type DeleteApiPushNotificationV1BatchesByBatchIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPushNotificationV1BatchesByBatchIdApiArg = string;
export type GetApiPushNotificationV1BatchesApiResponse =
  /** status 200 Success */ BatchDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationV1BatchesApiArg = void;
export type PostApiPushNotificationV1BatchesApiResponse =
  /** status 200 Success */ BatchDtoResponseBase;
export type PostApiPushNotificationV1BatchesApiArg = CreateBatchRequestDto;
export type PostApiPushNotificationV1BatchesSendApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiPushNotificationV1BatchesSendApiArg = string | undefined;
export type GetApiPushNotificationV1MessagesStatusesApiResponse =
  /** status 200 Success */ MessageStatusDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationV1MessagesStatusesApiArg = {
  batchId?: string;
  status?: MessageStatus;
  limit?: number;
  offset?: number;
};
export type PostApiPushNotificationV1MessagesFromCsvWithDevicesIdsApiResponse =
  /** status 200 Success */ LoadMessagesStatisticsDtoResponseBase;
export type PostApiPushNotificationV1MessagesFromCsvWithDevicesIdsApiArg = {
  batchId?: string;
  body: {
    CsvFile: Blob;
  };
};
export type GetApiPushNotificationV1TemplatesByTemplateIdApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type GetApiPushNotificationV1TemplatesByTemplateIdApiArg = string;
export type PatchApiPushNotificationV1TemplatesByTemplateIdApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type PatchApiPushNotificationV1TemplatesByTemplateIdApiArg = {
  templateId: string;
  updateTemplateRequestDto: UpdateTemplateRequestDto;
};
export type DeleteApiPushNotificationV1TemplatesByTemplateIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiPushNotificationV1TemplatesByTemplateIdApiArg = string;
export type GetApiPushNotificationV1TemplatesApiResponse =
  /** status 200 Success */ TemplateDtoIReadOnlyCollectionResponseBase;
export type GetApiPushNotificationV1TemplatesApiArg = void;
export type PostApiPushNotificationV1TemplatesApiResponse =
  /** status 200 Success */ TemplateDtoResponseBase;
export type PostApiPushNotificationV1TemplatesApiArg = CreateTemplateRequestDto;
export type PostApiPushNotificationV1TestingApiResponse = unknown;
export type PostApiPushNotificationV1TestingApiArg = string | undefined;
export type PutApiPushNotificationV1TokensApiResponse =
  /** status 200 Success */ TokenDtoResponseBase;
export type PutApiPushNotificationV1TokensApiArg =
  CreateOrUpdateTokenRequestDto;
export type BatchStatus = 'NEW' | 'SENT' | 'SUCCESS' | 'ERROR';
export type BatchDto = {
  id: string;
  status: BatchStatus;
  createdDate: string;
  sentDate?: string | null;
  totalCount: number;
  sentCount: number;
  deliveredCount: number;
  templateId: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type BatchDtoResponseBase = {
  data?: BatchDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type BatchDtoIReadOnlyCollectionResponseBase = {
  data?: BatchDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateBatchRequestDto = {
  templateId?: string;
};
export type MessageStatus = 'LOADED' | 'SENT' | 'DELIVERED' | 'ERROR';
export type MessageStatusDto = {
  templateId?: string;
  status?: MessageStatus;
  deviceId?: string;
};
export type MessageStatusDtoIReadOnlyCollectionResponseBase = {
  data?: MessageStatusDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type LoadMessagesStatisticsDto = {
  receivedDevicesIdsCount: number;
  successfullyCreatedCount: number;
};
export type LoadMessagesStatisticsDtoResponseBase = {
  data?: LoadMessagesStatisticsDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type TemplateDto = {
  id: string;
  name: string;
  title: string;
  text: string;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl: string;
  createdDate: string;
};
export type TemplateDtoResponseBase = {
  data?: TemplateDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateTemplateRequestDto = {
  name?: string | null;
  title?: string | null;
  text?: string | null;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl?: string | null;
};
export type TemplateDtoIReadOnlyCollectionResponseBase = {
  data?: TemplateDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateTemplateRequestDto = {
  name: string;
  title: string;
  text: string;
  imgUrl?: string | null;
  deepLink?: string | null;
  browserUrl: string;
};
export type TokenDto = {
  id: string;
  deviceId: string;
  value: string;
  lastUpdate: string;
};
export type TokenDtoResponseBase = {
  data?: TokenDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateOrUpdateTokenRequestDto = {
  deviceId: string;
  value: string;
};
export const {
  useGetApiPushNotificationV1AppInfoQuery,
  useGetApiPushNotificationV1BatchesByBatchIdQuery,
  useDeleteApiPushNotificationV1BatchesByBatchIdMutation,
  useGetApiPushNotificationV1BatchesQuery,
  usePostApiPushNotificationV1BatchesMutation,
  usePostApiPushNotificationV1BatchesSendMutation,
  useGetApiPushNotificationV1MessagesStatusesQuery,
  usePostApiPushNotificationV1MessagesFromCsvWithDevicesIdsMutation,
  useGetApiPushNotificationV1TemplatesByTemplateIdQuery,
  usePatchApiPushNotificationV1TemplatesByTemplateIdMutation,
  useDeleteApiPushNotificationV1TemplatesByTemplateIdMutation,
  useGetApiPushNotificationV1TemplatesQuery,
  usePostApiPushNotificationV1TemplatesMutation,
  usePostApiPushNotificationV1TestingMutation,
  usePutApiPushNotificationV1TokensMutation,
} = injectedRtkApi;
