import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
  UiDeleteFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useAddIdentityRole } from 'shared/api/services/identity/rtk/enhanced';
import {
  ApplicationDtoUserDto,
  WebApiDtoGroupDto,
} from 'shared/api/services/identity/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAppSelector } from 'shared/redux/types';
import { selectTokenName } from 'entities/authorization';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { useAbility } from 'shared/lib/ability/context';

import { DeleteIdentityUserModal } from 'features/identity-user/delete';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  groups: WebApiDtoGroupDto[];
  user: ApplicationDtoUserDto;
};

export function Form({ groups, user }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...user },
  });

  // Кастомный хук нужен
  const [trigger, { isLoading }] = useAddIdentityRole();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{user.userName}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields groups={groups} isDisabled={isDisabled} />
              <Form.Buttons
                isDisabled={isDisabled}
                enableForm={enableForm}
                userName={user.userName}
              />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  groups: WebApiDtoGroupDto[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ groups, isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="userName"
            label="Логин"
            required
            disabled
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="lastName"
            label="Фамилия"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="firstName"
            label="Имя"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="middleName"
            label="Отчество"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="password"
            label="Сменить пароль"
            type="password"
            autoComplete="new-password"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="groupName"
            options={groups.map(({ id, name }) => ({
              value: name,
              label: name,
            }))}
            label="Группа"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
  userName: string;
};

Form.Buttons = function Buttons({
  enableForm,
  isDisabled,
  userName,
}: ButtonsProps) {
  const currentUserName = useAppSelector(selectTokenName);

  const ability = useAbility();

  if (!ability.can('Write', 'Identity')) {
    return null;
  }

  const showDeleteModal = () => {
    NiceModal.show(DeleteIdentityUserModal, { userName });
  };

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
        {currentUserName !== userName ? (
          <UiDeleteFormButton onClick={showDeleteModal} />
        ) : null}
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
