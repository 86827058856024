/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UserService.WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  CarConnectorDtoIReadOnlyCollectionResponseBase,
  CarConnectorDtoResponseBase,
  CarDtoIReadOnlyCollectionResponseBase,
  CarDtoResponseBase,
  CreateCarConnectorRequestDto,
  CreateCarRequestDto,
  CreateFavoriteChargePointRequestDto,
  CreateGroupRequestDto,
  CreateUserRequestDto,
  DeleteFavoriteChargePointRequestDto,
  FavoriteChargePointDtoResponseBase,
  GetApiUserV1CarConnectorsParams,
  GetApiUserV1CarsParams,
  GetApiUserV1FavoriteChargePointsParams,
  GetApiUserV1GroupsParams,
  GetApiUserV1UsersCountParams,
  GetApiUserV1UsersParams,
  GroupDtoIReadOnlyCollectionResponseBase,
  GroupDtoResponseBase,
  GuidIReadOnlyCollectionResponseBase,
  IssueAuthTokensRequestDto,
  JwtTokensDtoResponseBase,
  LockUserRequestDto,
  PostApiUserV1OldActionGetsessionParams,
  ResponseBase,
  SendVerificationCodeRequestDto,
  SessionAuthTokenDtoResponseBase,
  SessionUserDtoResponseBase,
  UInt32ResponseBase,
  UpdateCarConnectorRequestDto,
  UpdateCarRequestDto,
  UpdateGroupRequestDto,
  UpdateUserRequestDto,
  UserDtoIReadOnlyCollectionResponseBase,
  UserDtoResponseBase
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getUserServiceWebApi = () => {
/**
 * @summary Get assembly version.
 */
const getApiUserV1AppInfo = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/Api/User/V1/AppInfo`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get car connector by Id.
 */
const getApiUserV1CarConnectorsCarConnectorId = (
    carConnectorId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarConnectorDtoResponseBase>(
      {url: `/Api/User/V1/CarConnectors/${carConnectorId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update car connector.
 */
const patchApiUserV1CarConnectorsCarConnectorId = (
    carConnectorId: string,
    updateCarConnectorRequestDto: UpdateCarConnectorRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarConnectorDtoResponseBase>(
      {url: `/Api/User/V1/CarConnectors/${carConnectorId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarConnectorRequestDto
    },
      options);
    }
  
/**
 * @summary Remove car connector.
 */
const deleteApiUserV1CarConnectorsCarConnectorId = (
    carConnectorId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/CarConnectors/${carConnectorId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get car connectors by filter.
 */
const getApiUserV1CarConnectors = (
    params: GetApiUserV1CarConnectorsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarConnectorDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/User/V1/CarConnectors`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Add car connector.
 */
const postApiUserV1CarConnectors = (
    createCarConnectorRequestDto: CreateCarConnectorRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarConnectorDtoResponseBase>(
      {url: `/Api/User/V1/CarConnectors`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCarConnectorRequestDto
    },
      options);
    }
  
/**
 * @summary Get car by Id.
 */
const getApiUserV1CarsCarId = (
    carId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarDtoResponseBase>(
      {url: `/Api/User/V1/Cars/${carId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update car.
 */
const patchApiUserV1CarsCarId = (
    carId: string,
    updateCarRequestDto: UpdateCarRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarDtoResponseBase>(
      {url: `/Api/User/V1/Cars/${carId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarRequestDto
    },
      options);
    }
  
/**
 * @summary Delete car.
 */
const deleteApiUserV1CarsCarId = (
    carId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/Cars/${carId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get cars by filter.
 */
const getApiUserV1Cars = (
    params: GetApiUserV1CarsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/User/V1/Cars`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Create car.
 */
const postApiUserV1Cars = (
    createCarRequestDto: CreateCarRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CarDtoResponseBase>(
      {url: `/Api/User/V1/Cars`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCarRequestDto
    },
      options);
    }
  
/**
 * @summary Get favorite charge points ids by filter.
 */
const getApiUserV1FavoriteChargePoints = (
    params: GetApiUserV1FavoriteChargePointsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GuidIReadOnlyCollectionResponseBase>(
      {url: `/Api/User/V1/FavoriteChargePoints`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Add favorite charge point.
 */
const postApiUserV1FavoriteChargePoints = (
    createFavoriteChargePointRequestDto: CreateFavoriteChargePointRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FavoriteChargePointDtoResponseBase>(
      {url: `/Api/User/V1/FavoriteChargePoints`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createFavoriteChargePointRequestDto
    },
      options);
    }
  
/**
 * @summary Remove favorite charge point.
 */
const deleteApiUserV1FavoriteChargePoints = (
    deleteFavoriteChargePointRequestDto: DeleteFavoriteChargePointRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/FavoriteChargePoints`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteFavoriteChargePointRequestDto
    },
      options);
    }
  
/**
 * @summary Get group by Id.
 */
const getApiUserV1GroupsGroupId = (
    groupId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GroupDtoResponseBase>(
      {url: `/Api/User/V1/Groups/${groupId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update group.
 */
const patchApiUserV1GroupsGroupId = (
    groupId: string,
    updateGroupRequestDto: UpdateGroupRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GroupDtoResponseBase>(
      {url: `/Api/User/V1/Groups/${groupId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateGroupRequestDto
    },
      options);
    }
  
/**
 * @summary Delete group.
 */
const deleteApiUserV1GroupsGroupId = (
    groupId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/Groups/${groupId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get groups by filter.
 */
const getApiUserV1Groups = (
    params?: GetApiUserV1GroupsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GroupDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/User/V1/Groups`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Create group.
 */
const postApiUserV1Groups = (
    createGroupRequestDto: CreateGroupRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GroupDtoResponseBase>(
      {url: `/Api/User/V1/Groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createGroupRequestDto
    },
      options);
    }
  
/**
 * @summary Send verification code to user.
 */
const postApiUserV1LoginCode = (
    sendVerificationCodeRequestDto: SendVerificationCodeRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/Login/code`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendVerificationCodeRequestDto
    },
      options);
    }
  
/**
 * @summary Get authorization tokens.
 */
const postApiUserV1Login = (
    issueAuthTokensRequestDto: IssueAuthTokensRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JwtTokensDtoResponseBase>(
      {url: `/Api/User/V1/Login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: issueAuthTokensRequestDto
    },
      options);
    }
  
/**
 * @summary Refresh authorization tokens.
 */
const postApiUserV1LoginRefreshTokens = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JwtTokensDtoResponseBase>(
      {url: `/Api/User/V1/Login/refreshTokens`, method: 'POST'
    },
      options);
    }
  
/**
 * @summary Lock or unlock user.
If need to unlock - set LockDateTo to now.
 */
const postApiUserV1LoginLock = (
    lockUserRequestDto: LockUserRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/Login/lock`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: lockUserRequestDto
    },
      options);
    }
  
/**
 * @deprecated
 * @summary Send verification code to user.
 */
const postApiUserV1OldActionLoginCode = (
    sendVerificationCodeRequestDto: SendVerificationCodeRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/OldAction/login/code`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendVerificationCodeRequestDto
    },
      options);
    }
  
/**
 * @deprecated
 * @summary Get session token.
 */
const postApiUserV1OldActionLogin = (
    issueAuthTokensRequestDto: IssueAuthTokensRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SessionAuthTokenDtoResponseBase>(
      {url: `/Api/User/V1/OldAction/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: issueAuthTokensRequestDto
    },
      options);
    }
  
/**
 * @deprecated
 * @summary Get session user by token.
 */
const postApiUserV1OldActionGetsession = (
    params?: PostApiUserV1OldActionGetsessionParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SessionUserDtoResponseBase>(
      {url: `/Api/User/V1/OldAction/getsession`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Get user by Id.
 */
const getApiUserV1UsersUserId = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserDtoResponseBase>(
      {url: `/Api/User/V1/Users/${userId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update user.
 */
const patchApiUserV1UsersUserId = (
    userId: string,
    updateUserRequestDto: UpdateUserRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserDtoResponseBase>(
      {url: `/Api/User/V1/Users/${userId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRequestDto
    },
      options);
    }
  
/**
 * @summary Delete user.
 */
const deleteApiUserV1UsersUserId = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResponseBase>(
      {url: `/Api/User/V1/Users/${userId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get users by filter.
 */
const getApiUserV1Users = (
    params?: GetApiUserV1UsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserDtoIReadOnlyCollectionResponseBase>(
      {url: `/Api/User/V1/Users`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Create user.
 */
const postApiUserV1Users = (
    createUserRequestDto: CreateUserRequestDto,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserDtoResponseBase>(
      {url: `/Api/User/V1/Users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUserRequestDto
    },
      options);
    }
  
/**
 * @summary Get users count by filter.
 */
const getApiUserV1UsersCount = (
    params?: GetApiUserV1UsersCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UInt32ResponseBase>(
      {url: `/Api/User/V1/Users/count`, method: 'GET',
        params
    },
      options);
    }
  
return {getApiUserV1AppInfo,getApiUserV1CarConnectorsCarConnectorId,patchApiUserV1CarConnectorsCarConnectorId,deleteApiUserV1CarConnectorsCarConnectorId,getApiUserV1CarConnectors,postApiUserV1CarConnectors,getApiUserV1CarsCarId,patchApiUserV1CarsCarId,deleteApiUserV1CarsCarId,getApiUserV1Cars,postApiUserV1Cars,getApiUserV1FavoriteChargePoints,postApiUserV1FavoriteChargePoints,deleteApiUserV1FavoriteChargePoints,getApiUserV1GroupsGroupId,patchApiUserV1GroupsGroupId,deleteApiUserV1GroupsGroupId,getApiUserV1Groups,postApiUserV1Groups,postApiUserV1LoginCode,postApiUserV1Login,postApiUserV1LoginRefreshTokens,postApiUserV1LoginLock,postApiUserV1OldActionLoginCode,postApiUserV1OldActionLogin,postApiUserV1OldActionGetsession,getApiUserV1UsersUserId,patchApiUserV1UsersUserId,deleteApiUserV1UsersUserId,getApiUserV1Users,postApiUserV1Users,getApiUserV1UsersCount}};
export type GetApiUserV1AppInfoResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1AppInfo']>>>
export type GetApiUserV1CarConnectorsCarConnectorIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1CarConnectorsCarConnectorId']>>>
export type PatchApiUserV1CarConnectorsCarConnectorIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['patchApiUserV1CarConnectorsCarConnectorId']>>>
export type DeleteApiUserV1CarConnectorsCarConnectorIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['deleteApiUserV1CarConnectorsCarConnectorId']>>>
export type GetApiUserV1CarConnectorsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1CarConnectors']>>>
export type PostApiUserV1CarConnectorsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1CarConnectors']>>>
export type GetApiUserV1CarsCarIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1CarsCarId']>>>
export type PatchApiUserV1CarsCarIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['patchApiUserV1CarsCarId']>>>
export type DeleteApiUserV1CarsCarIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['deleteApiUserV1CarsCarId']>>>
export type GetApiUserV1CarsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1Cars']>>>
export type PostApiUserV1CarsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1Cars']>>>
export type GetApiUserV1FavoriteChargePointsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1FavoriteChargePoints']>>>
export type PostApiUserV1FavoriteChargePointsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1FavoriteChargePoints']>>>
export type DeleteApiUserV1FavoriteChargePointsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['deleteApiUserV1FavoriteChargePoints']>>>
export type GetApiUserV1GroupsGroupIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1GroupsGroupId']>>>
export type PatchApiUserV1GroupsGroupIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['patchApiUserV1GroupsGroupId']>>>
export type DeleteApiUserV1GroupsGroupIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['deleteApiUserV1GroupsGroupId']>>>
export type GetApiUserV1GroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1Groups']>>>
export type PostApiUserV1GroupsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1Groups']>>>
export type PostApiUserV1LoginCodeResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1LoginCode']>>>
export type PostApiUserV1LoginResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1Login']>>>
export type PostApiUserV1LoginRefreshTokensResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1LoginRefreshTokens']>>>
export type PostApiUserV1LoginLockResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1LoginLock']>>>
export type PostApiUserV1OldActionLoginCodeResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1OldActionLoginCode']>>>
export type PostApiUserV1OldActionLoginResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1OldActionLogin']>>>
export type PostApiUserV1OldActionGetsessionResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1OldActionGetsession']>>>
export type GetApiUserV1UsersUserIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1UsersUserId']>>>
export type PatchApiUserV1UsersUserIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['patchApiUserV1UsersUserId']>>>
export type DeleteApiUserV1UsersUserIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['deleteApiUserV1UsersUserId']>>>
export type GetApiUserV1UsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1Users']>>>
export type PostApiUserV1UsersResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['postApiUserV1Users']>>>
export type GetApiUserV1UsersCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getUserServiceWebApi>['getApiUserV1UsersCount']>>>
