import { Route } from 'react-router-dom';

import { MOBILE_APP } from 'shared/consts/routes/mobile-app';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { MobileAppLayout } from 'entities/mobile-app/mobile-app-layout';

import { MobileAppsMenu } from 'features/mobile-app/menu';

import { AddMobileAppPage } from 'pages/mobile-app/add';
import { EditMobileAppPage } from 'pages/mobile-app/edit';
import { ADD_PREFIX, MOBILE_APPS } from 'shared/consts/names';

export const MobileAppRoutes = (
  <Route element={<RequireAuth controllerName="MobileApp" rightType="Write" />}>
    <Route
      path={MOBILE_APPS}
      element={<SectionLayout menu={<MobileAppsMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="MobileApp" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddMobileAppPage />} />}
        />
      </Route>
      <Route path={MOBILE_APP} element={<MobileAppLayout />}>
        <Route index element={<EditMobileAppPage />} />
      </Route>
    </Route>
  </Route>
);
