import { AppThunk } from 'shared/redux/types';

import { FormOutput as AddBatchFormOutput } from 'features/push-notification/add-batch/consts/schema';

import { enhancedApi as api } from '../generated';

export const addBatch =
  (args: AddBatchFormOutput): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const { templateId } = args;

    if (templateId === 0) {
      const { templateId, ...rest } = args;

      const addTemplateRes = await dispatch(
        api.endpoints.postApiPushNotificationV1Templates.initiate(rest)
      );

      if (addTemplateRes.error) {
        throw addTemplateRes.error;
      }

      if (addTemplateRes.data.data?.id) {
        const addBatchRes = await dispatch(
          api.endpoints.postApiPushNotificationV1Batches.initiate({
            templateId: addTemplateRes.data.data?.id,
          })
        );

        if (addBatchRes.error) {
          throw addBatchRes.error;
        }
      }
    } else {
      const addBatchRes = await dispatch(
        api.endpoints.postApiPushNotificationV1Batches.initiate({
          templateId,
        })
      );

      if (addBatchRes.error) {
        throw addBatchRes.error;
      }
    }

    // Templates ?
    await dispatch(api.util.invalidateTags([{ type: 'Batches', id: 'LIST' }]));
  };
