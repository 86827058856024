import { Col } from 'antd';
import { BATCH_STATUS } from 'entities/push-notification/consts';
import { Link } from 'react-router-dom';

import {
  BatchDto,
  TemplateDto,
} from 'shared/api/services/pushnotification/rtk/generated';
import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { FormRow } from 'shared/ui/form';
import { HighlightedText } from 'shared/ui/styles';
import { UiCard } from 'shared/ui/ui-card';
import { StyledDivider } from 'shared/ui/ui-kit/divider/styles';

import { RowWrapper, StyledContainer, StyledKey, StyledValue } from './styles';

type Props = {
  batch: BatchDto;
  templatesDict: Record<string, TemplateDto>;
};

export function BatchInfo({ batch, templatesDict }: Props) {
  const {
    deliveredCount,
    sentCount,
    totalCount,
    createdDate,
    sentDate,
    status,
    templateId,
  } = batch;

  const template = templatesDict[templateId];

  const { deepLink, browserUrl, text, title, name } = template;

  return (
    <UiCard>
      <UiCard.Header>{`Рассылка от ${
        sentDate && showTimeString(sentDate, FORMAT_TO_SECONDS)
      }`}</UiCard.Header>
      <UiCard.Body>
        <StyledContainer>
          <RowWrapper>
            <FormRow gutter={16}>
              <Col span={12}>
                <StyledKey>Название шаблона</StyledKey>
              </Col>
              <Col span={12}>
                <StyledValue>{name}</StyledValue>
              </Col>
            </FormRow>
            <StyledDivider />
          </RowWrapper>

          <RowWrapper>
            <FormRow gutter={16}>
              <Col span={12}>
                <StyledKey>Заголовок</StyledKey>
              </Col>
              <Col span={12}>
                <StyledValue>{title}</StyledValue>
              </Col>
            </FormRow>
            <StyledDivider />
          </RowWrapper>

          <RowWrapper>
            <FormRow gutter={16}>
              <Col span={12}>
                <StyledKey>Текст</StyledKey>
              </Col>
              <Col span={12}>
                <StyledValue>{text}</StyledValue>
              </Col>
            </FormRow>
            <StyledDivider />
          </RowWrapper>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>URL для браузера</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{browserUrl}</StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Deeplink для приложения</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{deepLink}</StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Ссылка на шаблон сообщения</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>
                <Link to={`${PUSH_NOTIFICATION_ROUTES.LIST}/${templateId}`}>
                  <HighlightedText>{`${PUSH_NOTIFICATION_ROUTES.LIST}/${templateId}`}</HighlightedText>
                </Link>
              </StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Дата создания</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>
                {showTimeString(createdDate, FORMAT_TO_SECONDS)}
              </StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Дата отправки рассылки</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>
                {sentDate && showTimeString(sentDate, FORMAT_TO_SECONDS)}
              </StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Общее кол-во сообщений рассылки</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{totalCount}</StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Кол-во отправленных сообщений</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{sentCount}</StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Кол-во доставленных сообщений</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{deliveredCount}</StyledValue>
            </Col>
          </FormRow>

          <FormRow gutter={16}>
            <Col span={12}>
              <StyledKey>Статус состояния рассылки</StyledKey>
            </Col>
            <Col span={12}>
              <StyledValue>{BATCH_STATUS[status]}</StyledValue>
            </Col>
          </FormRow>
        </StyledContainer>
      </UiCard.Body>
    </UiCard>
  );
}
