import { ADD_PREFIX, COUNTRIES, SETTINGS } from '../names';

export const COUNTRY = ':countryId';

const ROOT = `/${SETTINGS}/${COUNTRIES}`;

export const COUNTRY_ROUTES = {
  COUNTRIES: ROOT,
  COUNTRY: `${ROOT}/${COUNTRY}`,
  ADD_COUNTRY: `${ROOT}/${ADD_PREFIX}`,
};
