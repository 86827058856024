import dayjs from 'dayjs';
import { useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import { ErrorMessage, RangePickerProps } from 'shared/ui';
import { DATE_RANGE_OPTION } from 'shared/lib/metrics';
import { useGetGraphComsumedEnergy } from 'shared/api/services/chargepoint/rtk/enhanced/metrics';
import { getServerErrorText } from 'shared/lib';

import { ChartLayout } from './layout';

const DEFAULT_DATE_RANGE = 'threeMonths';

type Props = {
  chargePointIds?: string[];
};

export function ChartContainer({ chargePointIds }: Props) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(
    dayjs().subtract(...DATE_RANGE_OPTION[DEFAULT_DATE_RANGE].subtractParams)
  );
  const [dateTo, setDateTo] = useState<dayjs.Dayjs>(dayjs());

  const [isOpen, setIsOpen] = useState(false);

  const onDateFromChange = (val: dayjs.Dayjs) => setDateFrom(val);
  const onDateToChange = (val: dayjs.Dayjs) => setDateTo(val);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const onChange = (values: RangePickerProps['value']) => {
    if (values) {
      setDateFrom(dayjs(values[0]));
      setDateTo(dayjs(values[1]));
    }
  };

  const { isFetching, data, error } = useGetGraphComsumedEnergy(
    {
      chargePointIds,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      groupByPeriod: 'DAY',
    },
    {
      skip: isOpen,
    }
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  return (
    <ChartLayout
      dateFrom={dateFrom}
      dateTo={dateTo}
      loading={isFetching}
      onOpenChange={onOpenChange}
      onChange={onChange}
      data={data?.data ?? []}
      onDateFromChange={onDateFromChange}
      onDateToChange={onDateToChange}
    />
  );
}
