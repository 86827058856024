import { useState } from 'react';

import { ProvidedStageDto } from 'shared/api/services/billing/rtk/generated';

import { PopoverContent } from './content';
import { StyledPopover } from './styles';

type Props = {
  stage: ProvidedStageDto;
  children: JSX.Element;
};

export function StagePopover({ stage, children }: Props) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <StyledPopover
      content={<PopoverContent stage={stage} />}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayInnerStyle={{
        borderRadius: '6px',
        background: 'rgba(0, 0, 0, 0.70)',
        backdropFilter: 'blur(6px)',
      }}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      {children}
    </StyledPopover>
  );
}
