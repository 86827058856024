import { z } from 'zod';

import { EmptyData, NavMenuError } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import {
  useGetChargePoints,
  useGetHubs,
} from 'shared/api/services/chargepoint/rtk';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';

import { MenuInstance } from './menu';

const pageParamsSchema = z.object({
  hubId: z.string().optional(),
});

export function MenuContainer() {
  const { hubId } = useTypedParams(pageParamsSchema);

  const queryParams = useTypedQueryParamsCustom(pageParamsSchema);

  const {
    data: hubs,
    isFetching: isHubsFetching,
    error: hubsError,
  } = useGetHubs({});

  const {
    data: chargePoints,
    isFetching: isChargePointsFetching,
    error: chargePointsError,
  } = useGetChargePoints(queryParams, {
    skip: hubId === undefined,
  });

  const apiResponseError = [hubs, chargePoints].find(
    (el) => el && el.status === 'ERROR'
  );

  if (apiResponseError) {
    return <NavMenuError text={apiResponseError.statusMessage} />;
  }

  const error = [hubsError, chargePointsError].find((err) => err !== undefined);

  if (error) {
    return <NavMenuError text={getServerErrorText(error)} />;
  }

  if (!isHubsFetching && !isChargePointsFetching && !hubs) {
    return <EmptyData />;
  }

  return (
    <MenuInstance
      isHubsLoading={isHubsFetching}
      items={hubs?.data || []}
      isChargePointsLoading={isChargePointsFetching}
      chargePoints={chargePoints?.data || []}
    />
  );
}
