import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { ButtonLabel, IconContainer, StyledButton } from './styles';

type Props = {
  onClick: () => void;
  label: string;
};

export function AddServiceButton({ onClick, label }: Props) {
  return (
    <StyledButton onClick={onClick} type="button">
      <ButtonLabel>{label}</ButtonLabel>
      <IconContainer>
        <PlusInCircleIcon />
      </IconContainer>
    </StyledButton>
  );
}
