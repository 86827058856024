import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompanyV1AppInfo: build.query<
      GetApiCompanyV1AppInfoApiResponse,
      GetApiCompanyV1AppInfoApiArg
    >({
      query: () => ({ url: `/Api/Company/V1/AppInfo` }),
    }),
    getApiCompanyV1CompaniesByCompanyId: build.query<
      GetApiCompanyV1CompaniesByCompanyIdApiResponse,
      GetApiCompanyV1CompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Company/V1/Companies/${queryArg}` }),
    }),
    patchApiCompanyV1CompaniesByCompanyId: build.mutation<
      PatchApiCompanyV1CompaniesByCompanyIdApiResponse,
      PatchApiCompanyV1CompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Companies/${queryArg.companyId}`,
        method: 'PATCH',
        body: queryArg.updateCompanyRequestDto,
      }),
    }),
    deleteApiCompanyV1CompaniesByCompanyId: build.mutation<
      DeleteApiCompanyV1CompaniesByCompanyIdApiResponse,
      DeleteApiCompanyV1CompaniesByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Companies/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiCompanyV1Companies: build.query<
      GetApiCompanyV1CompaniesApiResponse,
      GetApiCompanyV1CompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Companies`,
        params: {
          IsDefault: queryArg,
        },
      }),
    }),
    postApiCompanyV1Companies: build.mutation<
      PostApiCompanyV1CompaniesApiResponse,
      PostApiCompanyV1CompaniesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Companies`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiCompanyV1EmployeesByEmployeeId: build.query<
      GetApiCompanyV1EmployeesByEmployeeIdApiResponse,
      GetApiCompanyV1EmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Company/V1/Employees/${queryArg}` }),
    }),
    patchApiCompanyV1EmployeesByEmployeeId: build.mutation<
      PatchApiCompanyV1EmployeesByEmployeeIdApiResponse,
      PatchApiCompanyV1EmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Employees/${queryArg.employeeId}`,
        method: 'PATCH',
        body: queryArg.updateEmployeeRequestDto,
      }),
    }),
    deleteApiCompanyV1EmployeesByEmployeeId: build.mutation<
      DeleteApiCompanyV1EmployeesByEmployeeIdApiResponse,
      DeleteApiCompanyV1EmployeesByEmployeeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Employees/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiCompanyV1Employees: build.query<
      GetApiCompanyV1EmployeesApiResponse,
      GetApiCompanyV1EmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Employees`,
        params: {
          UserId: queryArg.userId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiCompanyV1Employees: build.mutation<
      PostApiCompanyV1EmployeesApiResponse,
      PostApiCompanyV1EmployeesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Employees`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiCompanyV1EmployeesCount: build.query<
      GetApiCompanyV1EmployeesCountApiResponse,
      GetApiCompanyV1EmployeesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Company/V1/Employees/count`,
        params: {
          UserId: queryArg,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiCompanyV1AppInfoApiResponse =
  /** status 200 Success */ string;
export type GetApiCompanyV1AppInfoApiArg = void;
export type GetApiCompanyV1CompaniesByCompanyIdApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type GetApiCompanyV1CompaniesByCompanyIdApiArg = string;
export type PatchApiCompanyV1CompaniesByCompanyIdApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type PatchApiCompanyV1CompaniesByCompanyIdApiArg = {
  companyId: string;
  updateCompanyRequestDto: UpdateCompanyRequestDto;
};
export type DeleteApiCompanyV1CompaniesByCompanyIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiCompanyV1CompaniesByCompanyIdApiArg = string;
export type GetApiCompanyV1CompaniesApiResponse =
  /** status 200 Success */ CompanyDtoIReadOnlyCollectionResponseBase;
export type GetApiCompanyV1CompaniesApiArg = boolean | undefined;
export type PostApiCompanyV1CompaniesApiResponse =
  /** status 200 Success */ CompanyDtoResponseBase;
export type PostApiCompanyV1CompaniesApiArg = CreateCompanyRequestDto;
export type GetApiCompanyV1EmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type GetApiCompanyV1EmployeesByEmployeeIdApiArg = string;
export type PatchApiCompanyV1EmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type PatchApiCompanyV1EmployeesByEmployeeIdApiArg = {
  employeeId: string;
  updateEmployeeRequestDto: UpdateEmployeeRequestDto;
};
export type DeleteApiCompanyV1EmployeesByEmployeeIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiCompanyV1EmployeesByEmployeeIdApiArg = string;
export type GetApiCompanyV1EmployeesApiResponse =
  /** status 200 Success */ EmployeeDtoIReadOnlyCollectionResponseBase;
export type GetApiCompanyV1EmployeesApiArg = {
  userId?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiCompanyV1EmployeesApiResponse =
  /** status 200 Success */ EmployeeDtoResponseBase;
export type PostApiCompanyV1EmployeesApiArg = CreateEmployeeRequestDto;
export type GetApiCompanyV1EmployeesCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiCompanyV1EmployeesCountApiArg = string | undefined;
export type EmployeesBalancePeriod = 'FIRST_DAY_MONTH';
export type CompanyDto = {
  id: string;
  name: string;
  mobileName: string;
  legalName: string;
  email?: string | null;
  contractData?: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  logoUrl?: string | null;
  inn: string;
  kpp?: string | null;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked: boolean;
  creationDate: string;
  lastUpdate: string;
  isDefault: boolean;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CompanyDtoResponseBase = {
  data?: CompanyDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCompanyRequestDto = {
  name?: string | null;
  legalName?: string | null;
  email?: string | null;
  contractData?: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  logoUrl?: string | null;
  inn?: string | null;
  kpp?: string | null;
  mobileName?: string | null;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked?: boolean | null;
  isDefault?: boolean | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CompanyDtoIReadOnlyCollectionResponseBase = {
  data?: CompanyDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCompanyRequestDto = {
  name: string;
  legalName: string;
  email: string;
  contractData: string;
  address: string;
  phoneNumber: string;
  logoUrl: string;
  inn: string;
  kpp: string;
  mobileName: string;
  directorName?: string | null;
  telegram?: string | null;
  companyBalanceForPeriod?: number | null;
  employeeBalanceForPeriod?: number | null;
  balanceRefreshPeriod?: EmployeesBalancePeriod;
  isBalanceRefreshLocked: boolean;
  isDefault: boolean;
};
export type EmployeeDto = {
  id: string;
  userId: string;
  name?: string | null;
  balanceId: string;
  companyId: string;
  creationDate: string;
  lastUpdate: string;
};
export type EmployeeDtoResponseBase = {
  data?: EmployeeDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateEmployeeRequestDto = {
  userId?: string | null;
  balanceId?: string | null;
  companyId?: string | null;
  name?: string | null;
};
export type EmployeeDtoIReadOnlyCollectionResponseBase = {
  data?: EmployeeDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type CreateEmployeeRequestDto = {
  userId: string;
  balanceId: string;
  companyId: string;
  name?: string | null;
};
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiCompanyV1AppInfoQuery,
  useGetApiCompanyV1CompaniesByCompanyIdQuery,
  usePatchApiCompanyV1CompaniesByCompanyIdMutation,
  useDeleteApiCompanyV1CompaniesByCompanyIdMutation,
  useGetApiCompanyV1CompaniesQuery,
  usePostApiCompanyV1CompaniesMutation,
  useGetApiCompanyV1EmployeesByEmployeeIdQuery,
  usePatchApiCompanyV1EmployeesByEmployeeIdMutation,
  useDeleteApiCompanyV1EmployeesByEmployeeIdMutation,
  useGetApiCompanyV1EmployeesQuery,
  usePostApiCompanyV1EmployeesMutation,
  useGetApiCompanyV1EmployeesCountQuery,
} = injectedRtkApi;
