import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { ServicesListInfo } from './serivces-list-info';

type Props = React.ComponentProps<typeof ServicesListInfo> & {
  title: JSX.Element;
};

export const ServicesListModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title={props.title}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ServicesListInfo {...props} />
    </UiModal>
  );
});
