import { Col, Spin, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { MobileAppVm } from 'shared/api/services/information/rtk/generated/mobile-apps';
import { useAddCountry } from 'shared/api/services/information/rtk';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

import { LabelWithHint } from '../../../../common-styles';

type Props = {
  mobileApps: MobileAppVm[];
};

export function Form({ mobileApps }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [trigger, { isLoading }] = useAddCountry();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields mobileApps={mobileApps} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  mobileApps: MobileAppVm[];
};

Form.Fields = function Fields({ mobileApps }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="id" label="ID" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="name" label="Название" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="code"
            label={
              <LabelWithHint>
                Код страны
                <Tooltip title="Двухбуквенный код страны ISO">
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="currencySymbol"
            label="Символ валюты"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="currencyText"
            label="Название валюты"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="phonePrefix"
            label={
              <LabelWithHint>
                Телефонный префикс
                <Tooltip title="Префикс страны, например +7">
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="phoneMask"
            label={
              <LabelWithHint>
                Телефонная маска
                <Tooltip
                  title={
                    <>
                      <p>
                        Формат заполнения:
                        *префикс:количество_цифр_после_префикса*
                      </p>
                      <p>Например 7:10 - формат российских номеров телефона.</p>
                      <p>
                        Могут быть указать несколько масок через запятую,
                        например 7:10,7:9
                      </p>
                    </>
                  }
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            }
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="urlFlag"
            label="URL иконки флага"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="description"
            label="Описание"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="language" label="Язык" required />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="privacyPolicyUrl"
            label="URL политики конфиденциальности"
            required
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="displayName"
            label="Отображаемое название"
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomCheckbox<FormInput> name="isDefault" label="isDefault" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="mobileAppIds"
            options={mobileApps.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Мобильные приложения"
            allowClear
            mode="multiple"
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
