import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiReserveV1ActionReserveByReserveId: build.mutation<
      PostApiReserveV1ActionReserveByReserveIdApiResponse,
      PostApiReserveV1ActionReserveByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Action/Reserve/${queryArg.reserveId}`,
        method: 'POST',
        body: queryArg.addReserveRequest,
      }),
    }),
    postApiReserveV1ActionResetByReserveId: build.mutation<
      PostApiReserveV1ActionResetByReserveIdApiResponse,
      PostApiReserveV1ActionResetByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Action/Reset/${queryArg.reserveId}`,
        method: 'POST',
        params: {
          userId: queryArg.userId,
        },
      }),
    }),
    postApiReserveV1ActionExtendByReserveId: build.mutation<
      PostApiReserveV1ActionExtendByReserveIdApiResponse,
      PostApiReserveV1ActionExtendByReserveIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Action/Extend/${queryArg.reserveId}`,
        method: 'POST',
        params: {
          TimeSpan: queryArg.timeSpan,
          UserId: queryArg.userId,
        },
      }),
    }),
    getApiReserveV1Locks: build.query<
      GetApiReserveV1LocksApiResponse,
      GetApiReserveV1LocksApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Locks`,
        params: {
          UserId: queryArg.userId,
          ReserveIds: queryArg.reserveIds,
          ReserveName: queryArg.reserveName,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveV1LocksById: build.query<
      GetApiReserveV1LocksByIdApiResponse,
      GetApiReserveV1LocksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/v1/Locks/${queryArg}` }),
    }),
    getApiReserveV1LocksCount: build.query<
      GetApiReserveV1LocksCountApiResponse,
      GetApiReserveV1LocksCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Locks/Count`,
        params: {
          UserId: queryArg.userId,
          ReservesIds: queryArg.reservesIds,
          IsActive: queryArg.isActive,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
        },
      }),
    }),
    getApiReserveV1SessionsById: build.query<
      GetApiReserveV1SessionsByIdApiResponse,
      GetApiReserveV1SessionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Reserve/v1/Sessions/${queryArg}` }),
    }),
    getApiReserveV1Sessions: build.query<
      GetApiReserveV1SessionsApiResponse,
      GetApiReserveV1SessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Sessions`,
        params: {
          UserId: queryArg.userId,
          ReserveId: queryArg.reserveId,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiReserveV1SessionsCount: build.query<
      GetApiReserveV1SessionsCountApiResponse,
      GetApiReserveV1SessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Reserve/v1/Sessions/Count`,
        params: {
          UserId: queryArg.userId,
          ReserveId: queryArg.reserveId,
          BeginDateFrom: queryArg.beginDateFrom,
          BeginDateTo: queryArg.beginDateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          LastUpdateFrom: queryArg.lastUpdateFrom,
          LastUpdateTo: queryArg.lastUpdateTo,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiReserveV1ActionReserveByReserveIdApiResponse =
  /** status 200 Success */ AddReserveResponseBaseResponse;
export type PostApiReserveV1ActionReserveByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  /** Параметры */
  addReserveRequest: AddReserveRequest;
};
export type PostApiReserveV1ActionResetByReserveIdApiResponse =
  /** status 200 Success */ ResetReserveResponseBaseResponse;
export type PostApiReserveV1ActionResetByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  /** Фильтр, разрешающий снять резерв если юзер совпадает с текущем юзером брони */
  userId?: string;
};
export type PostApiReserveV1ActionExtendByReserveIdApiResponse =
  /** status 200 Success */ ExtendReserveResponseBaseResponse;
export type PostApiReserveV1ActionExtendByReserveIdApiArg = {
  /** Идентификатор обьекта резерва */
  reserveId: string;
  timeSpan: TimeSpan;
  userId?: string;
};
export type GetApiReserveV1LocksApiResponse =
  /** status 200 Success */ GetLocksResponseArrayBaseResponse;
export type GetApiReserveV1LocksApiArg = {
  userId?: string;
  reserveIds?: string[];
  reserveName?: string;
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveV1LocksByIdApiResponse =
  /** status 200 Success */ GetLocksResponseBaseResponse;
export type GetApiReserveV1LocksByIdApiArg =
  /** Идентификатор резерва */ string;
export type GetApiReserveV1LocksCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiReserveV1LocksCountApiArg = {
  userId?: string;
  reservesIds?: string[];
  isActive?: boolean;
  limit?: number;
  offset?: number;
};
export type GetApiReserveV1SessionsByIdApiResponse =
  /** status 200 Success */ GetSessionResponseBaseResponse;
export type GetApiReserveV1SessionsByIdApiArg =
  /** Идентификатор обьекта резерва */ string;
export type GetApiReserveV1SessionsApiResponse =
  /** status 200 Success */ GetSessionResponseArrayBaseResponse;
export type GetApiReserveV1SessionsApiArg = {
  userId?: string;
  reserveId?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiReserveV1SessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiReserveV1SessionsCountApiArg = {
  userId?: string;
  reserveId?: string;
  beginDateFrom?: string;
  beginDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  lastUpdateFrom?: string;
  lastUpdateTo?: string;
};
export type Status = 0 | 1 | 2;
export type UnavailableServices =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21;
export type AddReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type AddReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddReserveResponse;
};
export type TimeSpan = {
  ticks?: number;
};
export type TimeSpanRead = {
  ticks?: number;
  days?: number;
  hours?: number;
  milliseconds?: number;
  microseconds?: number;
  nanoseconds?: number;
  minutes?: number;
  seconds?: number;
  totalDays?: number;
  totalHours?: number;
  totalMilliseconds?: number;
  totalMicroseconds?: number;
  totalNanoseconds?: number;
  totalMinutes?: number;
  totalSeconds?: number;
};
export type AddReserveRequest = {
  userId: string;
  timeSpan: TimeSpan;
  reserveName: string;
};
export type AddReserveRequestRead = {
  userId: string;
  timeSpan: TimeSpanRead;
  reserveName: string;
};
export type ResetReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type ResetReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ResetReserveResponse;
};
export type ExtendReserveResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type ExtendReserveResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: ExtendReserveResponse;
};
export type GetLocksResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
};
export type GetLocksResponseArrayBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetLocksResponse[] | null;
};
export type GetLocksResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetLocksResponse;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetSessionResponse = {
  id: string;
  reserveId: string;
  reserveName: string;
  userId: string;
  dateFromReserve: string;
  dateToReserve: string;
  lastUpdate: string;
};
export type GetSessionResponseBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse;
};
export type GetSessionResponseArrayBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionResponse[] | null;
};
export type OrderBy = 'ASC' | 'DESC';
export const {
  usePostApiReserveV1ActionReserveByReserveIdMutation,
  usePostApiReserveV1ActionResetByReserveIdMutation,
  usePostApiReserveV1ActionExtendByReserveIdMutation,
  useGetApiReserveV1LocksQuery,
  useGetApiReserveV1LocksByIdQuery,
  useGetApiReserveV1LocksCountQuery,
  useGetApiReserveV1SessionsByIdQuery,
  useGetApiReserveV1SessionsQuery,
  useGetApiReserveV1SessionsCountQuery,
} = injectedRtkApi;
