import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogin } from 'shared/api/services/identity/rtk/enhanced';
import { useAppDispatch } from 'shared/redux/types';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { UiSubmitButton, FormControlsContainer } from 'shared/ui';
import { setCredentials } from 'shared/redux/slices/auth/authSlice';
import { setSessionCredentials } from 'shared/redux/slices/auth/authSessionSlice';
import { isErrorWithMessage, openErrorNotification } from 'shared/lib';
import { FormRow } from 'shared/ui/form';

import { LoginErrorMessage } from './styles';
import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../consts/schema';
import { UserIcon } from '../icons/user';
import { LockIcon } from '../icons/lock';
import { LOGIN_ERROR } from '../consts';
import { CustomFloatInput } from './float-input';

export function Form() {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [trigger, { isLoading }] = useLogin();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { rememberMe, ...rest } = data;
    try {
      const res = await trigger(rest).unwrap();

      const payload = {
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
      };

      if (rememberMe) {
        dispatch(setCredentials(payload));
      } else {
        dispatch(setSessionCredentials(payload));
      }

      navigate(from);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : LOGIN_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields />
          <Form.Buttons />
        </form>
      </Spin>
    </FormProvider>
  );
}

Form.Fields = function Fields() {
  const {
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const login = watch('login');

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomFloatInput<FormInput>
            hasFocus
            name="login"
            type="text"
            label="Логин"
            placeholder="Логин"
            suffix={<UserIcon />}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CustomFloatInput<FormInput>
            name="password"
            label="Пароль"
            type="password"
            placeholder="Пароль"
            suffix={<LockIcon />}
            disabled={Boolean(!login.length)}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomCheckbox<FormInput> name="rememberMe" label="Запомнить меня" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <UiSubmitButton text="Войти" style={{ width: '100%' }} />
    </FormControlsContainer>
  );
};
