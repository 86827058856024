import { z } from 'zod';

import { postApiBillingTariffsBody } from 'shared/api/services/billing/orval/zod/schemas';
import { requiredNumberInString } from 'shared/lib/schema';

export const type = postApiBillingTariffsBody.shape.type
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  });

export const FormSchema = postApiBillingTariffsBody
  .omit({
    type: true,
    price: true,
  })
  .extend({
    type: type,
    price: requiredNumberInString.pipe(z.number().gte(0)),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  countryId: '',
};
