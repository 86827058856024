import { GetSessionChargingMetricsVm } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { MetricsCardLayout } from '../card-layout';
import { ValueContainer, ValueCount } from './styles';

import Gradient from '../../assets/blue.svg';

type Props = {
  loading: boolean;
  metricsData: GetSessionChargingMetricsVm;
};

export function MetricsCard({ loading, metricsData }: Props) {
  const { count } = metricsData;

  const value = (
    <ValueContainer>
      <ValueCount>{count}</ValueCount>
    </ValueContainer>
  );

  return (
    <MetricsCardLayout
      loading={loading}
      suffix="заряжается"
      title="Электромобили"
      gradient={Gradient}
      value={value}
    />
  );
}
