import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { useDeleteChargePointGroup } from 'shared/api/services/chargepoint/rtk';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';
import { GetGroupVm } from 'shared/api/services/chargepoint/rtk/generated/groups';

type Props = {
  group: GetGroupVm;
};

export const DeleteChargePointGroupModal = NiceModal.create(
  ({ group }: Props) => {
    const modal = useModal();

    const [trigger, { isLoading }] = useDeleteChargePointGroup();

    const handleDelete = async () => {
      try {
        const res = await trigger(group.id!).unwrap();

        res.status === 'SUCCESS'
          ? openSuccessNotification(DELETE_SUCCESS)
          : openErrorNotification(res.statusMessage);
      } catch (e) {
        openErrorNotification(DELETE_ERROR);
      } finally {
        modal.hide();
      }
    };

    return (
      <UiModal
        title={`Удалить группу ЭЗС "${group.name}"?`}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
      >
        <ConfirmDialog
          onCancel={modal.hide}
          onConfirm={() => handleDelete()}
          loading={isLoading}
        />
      </UiModal>
    );
  }
);
