import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { DevTool } from '@hookform/devtools';
import { useEffect, useMemo, useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { BurgerIcon } from 'shared/icons/burger';
import { DeleteIcon } from 'shared/icons/delete';
import {
  ServicesListDto,
  ServiceType,
  StageWithTariffDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import { useAppDispatch } from 'shared/redux/types';
import { updateServicesList } from 'shared/api/services/billing/rtk/enhanced/thunk';

import { STAGE_END_TYPE } from 'entities/billing/consts';
import {
  getTariffOptionLabel,
  getTariffOptionsList,
} from 'entities/billing/lib/get-tariff-options-list';

import { AddStageButton } from 'features/billing/add/add-services/ui/add-stage-button';
import { DeleteStageModal } from 'features/billing/delete-stage';
import { AddServiceButton } from 'features/billing/add/add-services/ui/add-service-button';
import { AddTariffButton } from 'features/billing/add-tariff';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  ServiceTitle,
} from './styles';

type Props = {
  tariffs: TariffDto[];
  servicesList: ServicesListDto;
};

export function Form({ tariffs, servicesList }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: useMemo(() => {
      return { name: servicesList.name };
    }, [servicesList.name]),
  });

  useEffect(() => {
    form.reset({ name: servicesList.name });
  }, [servicesList]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const stages = servicesList.services?.reduce(
    (acc, el) => {
      if (el.stages) {
        if (el.type === 'CHARGING') {
          acc.CHARGING = [...acc.CHARGING, ...el.stages];
        } else if (el.type === 'RESERVING') {
          acc.RESERVING = [...acc.RESERVING, ...el.stages];
        }
      }

      return acc;
    },
    { CHARGING: [], RESERVING: [], PARKING: [] } as Record<
      ServiceType,
      StageWithTariffDto[]
    >
  );

  const handleSubmit = form.handleSubmit(async (data) => {
    //
    // Не инвалидируем кэш
    //
    if (
      data.name === servicesList.name &&
      !data.chargingStages.length &&
      !data.reservingStages.length
    ) {
      return;
    }

    try {
      setIsLoading(true);

      await dispatch(updateServicesList({ formOutput: data, servicesList }));

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{servicesList.name}</UiCard.Header>
      <UiCard.Body>
        <DevTool control={form.control} />
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                tariffs={tariffs}
                chargingStages={stages?.CHARGING}
                reservingStages={stages?.RESERVING}
                servicesListId={servicesList.id}
              />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  tariffs: TariffDto[];
  chargingStages?: StageWithTariffDto[];
  reservingStages?: StageWithTariffDto[];
  servicesListId: string;
};

Form.Fields = function Fields({
  tariffs,
  chargingStages,
  reservingStages,
  servicesListId,
}: FieldsProps) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  const showDeleteModal = (stageId: string) => {
    NiceModal.show(DeleteStageModal, { stageId, servicesListId });
  };

  const getStagesMaxSerialNumber = (
    stages: StageWithTariffDto[] | undefined
  ) => {
    if (!stages) {
      return 0;
    }

    const maxNumber = stages.reduce((acc, el) => {
      if (el.serialNumber > acc) {
        acc = el.serialNumber;
        return acc;
      }

      return acc;
    }, 0);

    return maxNumber + 1;
  };

  const renderReservingService = () => {
    if (!reservingStages?.length && !reservingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Бронирование"
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber: reservingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Бронирование</ServiceTitle>
        {reservingStages?.map((el) => {
          const { name, tariff, endType, endConditionValue, id } = el;

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <UiInput value={name} disabled />
                  </Col>
                  <Col span={6}>
                    <UiSelect value={getTariffOptionLabel(tariff)} disabled />
                  </Col>
                  <Col span={6}>
                    <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                  </Col>
                  <Col span={6}>
                    <UiInput value={endConditionValue} disabled />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space size={20}>
                  <ControlButton
                    onClick={() => showDeleteModal(id)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}

        {reservingFieldArray.fields.map((field, index) => {
          const endType = watch(`reservingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => reservingFieldArray.remove(index)}
                    type="button"
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber:
                getStagesMaxSerialNumber(reservingStages) +
                reservingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      </>
    );
  };

  const renderChargingService = () => {
    if (!chargingStages?.length && !chargingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Заряд"
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: chargingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Заряд</ServiceTitle>
        {chargingStages?.map((el) => {
          const { name, tariff, endType, endConditionValue, id } = el;

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <UiInput value={name} disabled />
                  </Col>
                  <Col span={6}>
                    <UiSelect value={getTariffOptionLabel(tariff)} disabled />
                  </Col>
                  <Col span={6}>
                    <UiSelect value={STAGE_END_TYPE[endType]} disabled />
                  </Col>
                  <Col span={6}>
                    <UiInput value={endConditionValue} disabled />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space size={20}>
                  <ControlButton onClick={() => showDeleteModal(id)}>
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}

        {chargingFieldArray.fields.map((field, index) => {
          const endType = watch(`chargingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space size={20}>
                  <ControlButton
                    onClick={() => chargingFieldArray.remove(index)}
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber:
                getStagesMaxSerialNumber(chargingStages) +
                chargingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      </>
    );
  };

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="name"
              label="Название списка услуг"
              required
            />
          </Col>
        </FormRow>
      </FormFieldsGroup>
      <FormFieldsGroup>{renderReservingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargingService()}</FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
