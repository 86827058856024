import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledLayout = styled.article`
  border-radius: 8px;

  border: 1px solid #f0f0f0;
  background-color: #fff;
`;

export const StyledHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;

  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR.title};
`;

export const StyledBody = styled.div`
  padding: 20px;
`;
