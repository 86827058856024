import { enhancedApi as api } from '../generated/hubs';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointV1Hubs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    getApiChargePointV1HubsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Hubs', id: arg },
      ];
    },
    postApiChargePointV1Hubs: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Hubs', id: 'LIST' }];
    },
    patchApiChargePointV1HubsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Hubs', id: 'LIST' },
        {
          type: 'Hubs',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiChargePointV1HubsQuery: useGetHubs,
  useGetApiChargePointV1HubsByIdQuery: useGetHub,
  usePostApiChargePointV1HubsMutation: useAddHub,
  usePatchApiChargePointV1HubsByIdMutation: useUpdateHub,
} = enhancedApi;
