import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';

import { useGetIdentityUsers } from 'shared/api/services/identity/rtk/enhanced';
import { IDENTITY_USERS } from 'shared/consts/names';
import { IDENTITY_USER_ROUTES } from 'shared/consts/routes/identity-user';

export function MenuContainer() {
  const { data, isFetching, error } = useGetIdentityUsers();

  if (data && data.status === 'Error') {
    return <NavMenuError text={data.statusMessage} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.users || []).map(({ userName }) => ({
    id: encodeURIComponent(userName),
    name: userName,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={IDENTITY_USERS}
      absolutePath={IDENTITY_USER_ROUTES.USERS}
      label="Пользователи"
    />
  );
}
