import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const FormWrapper = styled.div`
  /* width: 550px; */
`;

export const InputContainer = styled.div`
  margin-bottom: 10px;
`;

export const InputLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
  margin-bottom: 5px;
`;
