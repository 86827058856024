import { z } from 'zod';

import { useGetRoleRights } from 'shared/api/services/identity/rtk/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { Form } from './form';

const pageParamsSchema = z.object({
  roleName: z.string(),
});

export function FormContainer() {
  const { roleName } = useTypedParams(pageParamsSchema);

  const roleRightsQuery = useGetRoleRights(roleName);

  const isLoading = roleRightsQuery.isLoading;

  const apiResponseError = [roleRightsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [roleRightsQuery.error].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <Form roleRights={roleRightsQuery.data?.rights ?? []} roleName={roleName} />
  );
}
