import dayjs from 'dayjs';
import { z } from 'zod';

import { FULL_DATE_PATTERN } from 'shared/consts/regex';
import { postApiUserUsersBody } from 'shared/api/services/user/orval/zod/schemas';

export const FormSchema = postApiUserUsersBody
  .omit({
    lockoutEndDate: true,
  })
  .extend({});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  login: '',
  countryId: '',
  userAgreementAccepted: false,
  isDebug: false,
  sex: undefined,
};
