import styled from 'styled-components';

export const ChargePointInfoGridLayout = styled.div`
  display: grid;
  gap: 20px;
  grid-template-rows: minmax(333px, auto) 1fr;
  grid-template-columns: 267px minmax(auto, 896px) 367px;
  width: 100%;

  & .grid-col-span-2 {
    grid-column: span 2;
  }
`;

export const GridColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ChargedSummaryContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const CardsContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`;
