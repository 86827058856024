export function FiltersIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 4.375H3.75M3.75 4.375C3.75 5.41053 4.58947 6.25 5.625 6.25C6.66053 6.25 7.5 5.41053 7.5 4.375C7.5 3.33947 6.66053 2.5 5.625 2.5C4.58947 2.5 3.75 3.33947 3.75 4.375ZM1.875 10.625H5.625M11.25 10.625H13.125M11.25 10.625C11.25 11.6605 10.4105 12.5 9.375 12.5C8.33947 12.5 7.5 11.6605 7.5 10.625C7.5 9.58947 8.33947 8.75 9.375 8.75C10.4105 8.75 11.25 9.58947 11.25 10.625ZM9.375 4.375H13.125"
        stroke="#28303F"
        strokeLinecap="round"
      />
    </svg>
  );
}
