import styled from 'styled-components';

// import { DayjsDatePicker } from 'shared/ui';

// const { RangePicker } = DayjsDatePicker;

// export const StyledRangePicker = styled(RangePicker)`
//   border: none;
//   width: 208px;
//   padding: 0;
//   background-color: transparent;

//   & .ant-picker-input > input {
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 15px;
//     color: #414141;
//   }

//   & .ant-picker-active-bar {
//     display: none;
//   }

//   & .ant-picker-clear {
//     right: 0;
//   }
// `;

export const StyledContainer = styled.main`
  grid-column-start: menu;
  grid-column-end: main;
  display: flex;
  /* height: calc(100vh - 70px); */
  width: 100%;
  /* margin-top: 70px; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const FiltersWrapper = styled.div`
  margin-bottom: 20px;
`;

export const GridLayout = styled.div`
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 70px - 40px - 49px);
  display: grid;
  gap: 20px;
  grid-template-columns: minmax(min-content, 1fr) minmax(500px, 1fr);
  // grid-template-columns:  repeat(2, minmax(500px, 1fr));
  grid-template-rows: 149px minmax(500px, 1fr);
`;

export const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(163px, 260px)) max-content;
  /* grid-template-columns: repeat(3, minmax(163px, 260px)) auto; */
  grid-template-rows: 149px;
  gap: 10px;
`;
