import { useState } from 'react';

import { AppThunk, useAppDispatch } from 'shared/redux/types';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { TargetsServicesListsDto } from 'shared/api/services/billing/rtk/generated';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { enhancedApi as cpApi } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import { enhancedApi as billingApi } from 'shared/api/services/billing/rtk/enhanced';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';
import { getServicesListId } from 'entities/charge-point';

import { FormOutput } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

export const updateChargePointThunk =
  ({
    chargePoint,
    data,
    targetsServicesLists,
  }: {
    chargePoint: GetChargePointVm;
    data: FormOutput;
    targetsServicesLists: TargetsServicesListsDto[];
  }): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const {
      coordinates,
      ocppConfig,
      infrastructureInfo,
      servicesListId,
      ...rest
    } = data;

    const [latitude, longitude] = coordinates.split(',');

    const updateBody = {
      ...rest,
      ocppConfig: buildOcppConfigObject(ocppConfig),
      latitude: Number(latitude.trim()),
      longitude: Number(longitude.trim()),
      infrastructureInfo: infrastructureInfo.length
        ? JSON.stringify(
            infrastructureInfo.reduce((acc, el) => {
              acc[el] = 'true';

              return acc;
            }, {} as Record<string, string>)
          )
        : null,
    };

    const updateRes = await dispatch(
      cpApi.endpoints.patchApiChargePointV1ChargePointsById.initiate({
        id: chargePoint.id,
        updateChargePointRequest: updateBody,
      })
    );

    if (updateRes.error) {
      throw updateRes.error;
    }

    const oldServicesListId = getServicesListId(targetsServicesLists);

    if (!oldServicesListId && servicesListId) {
      const addServicesListLinkRes = await dispatch(
        billingApi.endpoints.postApiBillingV1ServicesListLinks.initiate({
          targetName: 'CHARGE_POINT',
          servicesListId,
          targetId: chargePoint.id,
        })
      );

      if (addServicesListLinkRes.error) {
        throw addServicesListLinkRes.error;
      }
    } else if (oldServicesListId && !servicesListId) {
      const removeServicesListLinkRes = await dispatch(
        billingApi.endpoints.deleteApiBillingV1ServicesListLinksById.initiate(
          oldServicesListId
        )
      );

      if (removeServicesListLinkRes.error) {
        throw removeServicesListLinkRes.error;
      }
    } else if (
      oldServicesListId &&
      servicesListId &&
      oldServicesListId !== servicesListId
    ) {
      const removeServicesListLinkRes = await dispatch(
        billingApi.endpoints.deleteApiBillingV1ServicesListLinksById.initiate(
          oldServicesListId
        )
      );

      if (removeServicesListLinkRes.error) {
        throw removeServicesListLinkRes.error;
      }

      const addServicesListLinkRes = await dispatch(
        billingApi.endpoints.postApiBillingV1ServicesListLinks.initiate({
          targetName: 'CHARGE_POINT',
          servicesListId,
          targetId: chargePoint.id,
        })
      );

      if (addServicesListLinkRes.error) {
        throw addServicesListLinkRes.error;
      }
    }

    await dispatch(
      cpApi.util.invalidateTags([
        { type: 'ChargePoints', id: 'LIST' },
        { type: 'ChargePoints', id: chargePoint.id },
      ])
    );
  };

export function useUpdateChargePoint() {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleUpdate = async (args: {
    chargePoint: GetChargePointVm;
    data: FormOutput;
    targetsServicesLists: TargetsServicesListsDto[];
  }) => {
    try {
      setLoading(true);

      await dispatch(updateChargePointThunk(args));

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleUpdate,
    isMutationLoading: loading,
  };
}
