import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { Spin } from 'antd';

import { RangePickerProps } from 'shared/ui';
import { DATE_RANGE_OPTION } from 'shared/lib/metrics';
import { ArrowDownIcon } from 'shared/icons/arrow-down';
import { addSpacesToNumber } from 'shared/lib';
import { LineChart } from 'shared/ui/charts/line';
import { GetAmountOfConsumedEnergyMetricsVm } from 'shared/api/services/chargepoint/orval/axios/schemas';

import { getMetricsLineChartData } from 'entities/metrics';

import { customFormat } from 'features/metrics/graphs/consts';

import {
  CardHeader,
  CardTitle,
  ChargedInfoContainer,
  LineChartContainer,
  LoaderContainer,
  StyleDateRangeGroup,
  StyledCard,
  StyledRadioButton,
  StyledRangePicker,
  ValueSummary,
} from './styles';
import { colorParams, scalesOptions } from './constants';

const DEFAULT_DATE_RANGE = 'threeMonths';

type Props = {
  loading: boolean;
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  onChange: (values: RangePickerProps['value']) => void;
  onOpenChange: (open: boolean) => void;
  data: GetAmountOfConsumedEnergyMetricsVm[];
  onDateFromChange: (val: dayjs.Dayjs) => void;
  onDateToChange: (val: dayjs.Dayjs) => void;
};

export function ChartLayout({
  dateFrom,
  dateTo,
  loading,
  onChange,
  onOpenChange,
  data,
}: Props) {
  const [dateRangeOption, setDateRangeOption] = useState(DEFAULT_DATE_RANGE);

  const onRadioGroupChange = (e: RadioChangeEvent) => {
    const option = e.target.value;

    const [value, type] = DATE_RANGE_OPTION[option].subtractParams;

    onChange([dayjs().subtract(value, type), dayjs()]);

    setDateRangeOption(option);
  };

  const renderDateRangeOptions = () =>
    Object.keys(DATE_RANGE_OPTION).map((el) => {
      return (
        <StyledRadioButton key={el} value={el}>
          {DATE_RANGE_OPTION[el].label}
        </StyledRadioButton>
      );
    });

  let content;

  if (loading) {
    content = (
      <LoaderContainer>
        <Spin spinning>Загрузка данных...</Spin>
      </LoaderContainer>
    );
  } else {
    const summary = addSpacesToNumber(
      Math.round(data.reduce((acc, el) => acc + el.value, 0))
    );
    const chartData = getMetricsLineChartData(data, colorParams);

    content = (
      <>
        <CardHeader>
          <CardTitle>Заряжено в кВт*ч</CardTitle>
          <StyleDateRangeGroup
            value={dateRangeOption}
            onChange={onRadioGroupChange}
          >
            {renderDateRangeOptions()}
          </StyleDateRangeGroup>
        </CardHeader>

        <ChargedInfoContainer>
          <ValueSummary>{summary}</ValueSummary>
          <StyledRangePicker
            locale={locale}
            separator={'-'}
            allowClear={false}
            suffixIcon={<ArrowDownIcon />}
            value={[dateFrom, dateTo]}
            format={customFormat}
            onChange={onChange}
            onOpenChange={onOpenChange}
          />
        </ChargedInfoContainer>
        <LineChartContainer>
          <LineChart data={chartData} scaleOptions={scalesOptions} />
        </LineChartContainer>
      </>
    );
  }

  return <StyledCard>{content}</StyledCard>;
}
