import { useState } from 'react';

import { HighlightedText } from 'shared/ui/styles';

import { PopoverContent } from './content';
import { StyledPopover } from './styles';

type Props = {
  userId: string;
};

export function UserPopover({ userId }: Props) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <StyledPopover
      content={<PopoverContent visible={visible} userId={userId} />}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayInnerStyle={{
        borderRadius: '6px',
        background: 'rgba(0, 0, 0, 0.70)',
        backdropFilter: 'blur(6px)',
      }}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <HighlightedText>{userId}</HighlightedText>
    </StyledPopover>
  );
}
