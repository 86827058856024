export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 4L4.43051 15.1661C4.51689 16.2027 5.38342 17 6.4236 17H13.5764C14.6166 17 15.4831 16.2027 15.5695 15.1661L16.5 4"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M4.75 2C4.75 1.30964 5.30964 0.75 6 0.75H14C14.6904 0.75 15.25 1.30964 15.25 2V4.25H4.75V2Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M1 4.25L19 4.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
