import { useMemo, useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type {
  CheckboxOptionType,
  CheckboxValueType,
} from 'antd/es/checkbox/Group';

import { UiModal } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/redux/types';

import {
  selectOwnerTypes,
  selectStatuses,
  selectWithTestCp,
  selectCompaniesId,
  selectNotCompaniesId,
  setOwnerTypes,
  setCompaniesId,
} from '../../store/slice';
import {
  FilterCheckbox,
  FiltersButton,
  FiltersCheckboxGroup,
  FiltersContainer,
  IconContainer,
  RedPoint,
} from './styles';
import { FiltersIcon } from '../../icons/filter';
import { OWNER_TYPE } from '../../model';
import { FullFilters } from '../full-filters';
import { useCompaniesList } from '../../hooks/use-companies-list';
import { AGENT_KEY, OWN_KEY } from '../../consts';

const plainOptions: CheckboxOptionType[] = (
  Object.keys(OWNER_TYPE) as unknown as Array<keyof typeof OWNER_TYPE>
).map((el) => ({ value: el, label: OWNER_TYPE[el] }));

export function ShortFilters() {
  const { ownCompanies, agentCompanies, error, isLoading } = useCompaniesList();

  const ownCompaniesIds = useMemo(() => {
    return ownCompanies.map((el) => el.id);
  }, [ownCompanies]);

  const agentCompaniesIds = useMemo(() => {
    return agentCompanies.map((el) => el.id);
  }, [agentCompanies]);

  const companies = useMemo(() => {
    if (ownCompanies && agentCompanies) {
      return [...ownCompanies, ...agentCompanies];
    }

    return [];
  }, [ownCompanies, agentCompanies]);

  const ownerTypes = useAppSelector(selectOwnerTypes);
  const statuses = useAppSelector(selectStatuses);
  const companiesIds = useAppSelector(selectCompaniesId);

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const checkAll = plainOptions.length === ownerTypes.length;

  const indeterminate =
    ownerTypes.length > 0 && ownerTypes.length < plainOptions.length;

  const onChange = (list: CheckboxValueType[]) => {
    dispatch(setOwnerTypes(list as string[]));

    if (list.includes(OWN_KEY) && list.includes(AGENT_KEY)) {
      dispatch(setCompaniesId([...ownCompaniesIds, ...agentCompaniesIds]));
    } else if (list.includes(OWN_KEY) && !list.includes(AGENT_KEY)) {
      dispatch(setCompaniesId([...ownCompaniesIds]));
    } else if (!list.includes(OWN_KEY) && list.includes(AGENT_KEY)) {
      dispatch(setCompaniesId([...agentCompaniesIds]));
    } else if (!list.includes(OWN_KEY) && !list.includes(AGENT_KEY)) {
      dispatch(setCompaniesId([]));
    }
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    dispatch(
      setOwnerTypes(
        e.target.checked ? plainOptions.map((el) => el.value as string) : []
      )
    );
    dispatch(setCompaniesId([...ownCompaniesIds, ...agentCompaniesIds]));
  };

  const hasFilters =
    ownerTypes.length || statuses.length || companiesIds.length; // || owners.length;

  const closeFiltersModal = () => setIsFiltersModalVisible(false);
  const openFiltersModal = () => setIsFiltersModalVisible(true);

  return (
    <>
      <UiModal visible={isFiltersModalVisible} onCancel={closeFiltersModal}>
        <FullFilters closeModal={closeFiltersModal} />
      </UiModal>
      <FiltersContainer>
        <FilterCheckbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Все
        </FilterCheckbox>
        <FiltersCheckboxGroup
          options={plainOptions}
          value={ownerTypes}
          onChange={onChange}
        />
        <FiltersButton onClick={openFiltersModal}>
          <IconContainer>
            <FiltersIcon />
          </IconContainer>

          {hasFilters ? <RedPoint /> : null}
        </FiltersButton>
      </FiltersContainer>
    </>
  );
}
