import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';

import { StyledButton } from './styles';

type Props = DraggerProps & {
  innerContent?: JSX.Element;
};

export function UiUploadDragger(props: Props) {
  const { innerContent } = props;

  return (
    <Upload.Dragger {...props}>
      {innerContent || (
        <StyledButton
          htmlType="button"
          type="primary"
          icon={<UploadOutlined />}
          style={{ margin: '0 auto' }}
        >
          Выбрать файл
        </StyledButton>
      )}
    </Upload.Dragger>
  );
}
