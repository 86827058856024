import { Link } from 'react-router-dom';

import { USER_ROUTES } from 'shared/consts/routes/user';
import { NavigationTabButton } from 'shared/ui';

export function AddUserGroupLink() {
  const path = USER_ROUTES.ADD_PERSONS_GROUP;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
