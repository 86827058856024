import { Spin } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { RangePickerProps } from 'shared/ui';
import { ArrowDownIcon } from 'shared/icons/arrow-down';
import { LineChart } from 'shared/ui/charts/line';
import { LineChartDataType } from 'shared/ui/charts/line/types';

import { scalesOptions, customFormat } from '../../consts';
import {
  CardHeader,
  CardContent,
  RangePickerContainer,
  StyledRangePicker,
  MonitoringMetricsCardContainer,
  LoaderContainer,
  StyledContainer,
} from './styles';

type Props = {
  loading: boolean;
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  onChange: (values: RangePickerProps['value']) => void;
  onOpenChange: (open: boolean) => void;
  summary: React.ReactNode;
  chartData: LineChartDataType;
};

export function MetricsLayout({
  dateFrom,
  dateTo,
  loading,
  onChange,
  onOpenChange,
  summary,
  chartData,
}: Props) {
  let content;

  if (loading) {
    content = (
      <LoaderContainer>
        <Spin spinning>Загрузка данных...</Spin>
      </LoaderContainer>
    );
  } else {
    content = (
      <>
        <RangePickerContainer>
          <StyledRangePicker
            locale={locale}
            separator={'-'}
            allowClear={false}
            suffixIcon={<ArrowDownIcon />}
            value={[dateFrom, dateTo]}
            format={customFormat}
            onChange={onChange}
            onOpenChange={onOpenChange}
          />
        </RangePickerContainer>
        <MonitoringMetricsCardContainer>
          <CardHeader>{summary}</CardHeader>
          <CardContent>
            <LineChart data={chartData} scaleOptions={scalesOptions} />
          </CardContent>
        </MonitoringMetricsCardContainer>
      </>
    );
  }

  return <StyledContainer>{content}</StyledContainer>;
}
