import { z } from 'zod';

import { postApiInformationV1CarModelsBody } from 'shared/api/services/information/orval/zod/schemas';

export const FormSchema = postApiInformationV1CarModelsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  carBrandId: '',
};
