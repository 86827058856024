import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const NoCarsText = styled.h3``;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
`;

export const StyledTitle = styled.h4`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
