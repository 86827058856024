import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { PopoverContentLayout } from 'shared/ui/ui-popover/content-layout';

import { CommandModal } from 'features/command';

import { AVAILABLE_COMMAND_TYPE } from '../consts';
import { AvailableCommandType } from '../model';

type Props = {
  chargePointId: string;
};

export function Actions({ chargePointId }: Props) {
  const ability = useAbility();

  const navigate = useNavigate();

  const showCommandModal = (type: AvailableCommandType) => {
    NiceModal.show(CommandModal, {
      commandType: type,
      chargePointId,
    });
  };

  const commands = ability.can('Execute', 'PrivateMethods') ? (
    <>
      {(
        Object.keys(AVAILABLE_COMMAND_TYPE) as unknown as Array<
          keyof typeof AVAILABLE_COMMAND_TYPE
        >
      ).map((type) => {
        return (
          <button
            key={AVAILABLE_COMMAND_TYPE[type]}
            onClick={() => {
              showCommandModal(type);
            }}
          >
            {AVAILABLE_COMMAND_TYPE[type]}
          </button>
        );
      })}
    </>
  ) : null;

  const chargePointActions = ability.can('Write', 'ChargePoint') ? (
    <button
      onClick={() => {
        navigate('edit');
      }}
    >
      Редактировать
    </button>
  ) : null;

  const content = (
    <PopoverContentLayout contentGroups={[commands, chargePointActions]} />
  );

  if (
    !ability.can('Execute', 'PrivateMethods') &&
    !ability.can('Write', 'ChargePoint')
  ) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
