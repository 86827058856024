import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { FormContainer } from '../form/container';

type Props = {
  userId: string;
};

export const Modal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Добавить автомобиль"
    >
      <FormContainer {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
