import { isAxiosError } from 'axios';

import { FormOutput } from 'features/car/add/consts/schema';
import { ConnectorType } from 'shared/api/services/chargepoint/orval/axios/schemas';
import { BaseResponse } from 'shared/api/services/chargepoint/rtk/generated/user-group-cp-rights';

import { getUserServiceWebApi } from '../../orval/axios/client';
import { CreateCarConnectorRequestDto } from '../../orval/axios/schemas';
import { enhancedApi as api } from '../generated';

type AddCarArg = FormOutput & {
  userId: string;
};

export const enhancedApi = api
  .enhanceEndpoints({
    endpoints: {
      getApiUserV1Users: (endpoint) => {
        endpoint.providesTags = [{ type: 'Users', id: 'LIST' }];
      },
      getApiUserV1UsersByUserId: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Users', id: arg },
        ];
      },
      postApiUserV1Users: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'Users', id: 'LIST' }];
      },
      patchApiUserV1UsersByUserId: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'Users', id: 'LIST' },
          {
            type: 'Users',
            id: arg.userId,
          },
        ];
      },
      deleteApiUserV1UsersByUserId: () => {},
      // Группы юзеров
      getApiUserV1Groups: (endpoint) => {
        endpoint.providesTags = [{ type: 'UserGroups', id: 'LIST' }];
      },
      getApiUserV1GroupsByGroupId: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'UserGroups', id: arg },
        ];
      },
      postApiUserV1Groups: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'UserGroups', id: 'LIST' }];
      },
      patchApiUserV1GroupsByGroupId: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'UserGroups', id: 'LIST' },
          {
            type: 'UserGroups',
            id: arg.groupId,
          },
        ];
      },
      deleteApiUserV1GroupsByGroupId: (endpoint) => {
        endpoint.invalidatesTags = [{ type: 'UserGroups', id: 'LIST' }];
      },
      // Машины
      getApiUserV1Cars: (endpoint) => {
        endpoint.providesTags = (result, error, arg) => [
          { type: 'Cars', id: arg },
        ];
      },
      postApiUserV1Cars: (endpoint) => {
        endpoint.invalidatesTags = (result, error, arg) => [
          { type: 'Cars', id: arg.userId },
        ];
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      deleteCar: builder.mutation<
        BaseResponse,
        { carId: string; userId: string }
      >({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { carId, userId } = args;

          const { deleteApiUserV1CarsCarId } = getUserServiceWebApi();

          try {
            const res = await deleteApiUserV1CarsCarId(carId);

            if (res.status === 'ERROR') {
              return {
                error: {
                  error: res.statusMessage ?? 'Неизвестная ошибка',
                  status: 'CUSTOM_ERROR',
                },
              };
            }

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {
            if (isAxiosError(err)) {
              if (err.response) {
                return {
                  status: err.response.status,
                  data: err.response.data,
                };
              } else if (err.request) {
                return {
                  error: err.request,
                };
              }
            }

            if (err instanceof Error) {
              return {
                error: err.message,
              };
            }

            return {
              error: {
                error: 'Неизвестная ошибка',
                status: 'CUSTOM_ERROR',
              },
            };
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Cars', id: arg.userId },
        ],
      }),
      addCar: builder.mutation<BaseResponse, AddCarArg>({
        queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
          const { carModelId, name, userId, adapters, connectors } = args;

          const { postApiUserV1Cars, postApiUserV1CarConnectors } =
            getUserServiceWebApi();

          try {
            //
            // 1. Создаем машину
            //
            const car = await postApiUserV1Cars({ carModelId, name, userId });

            if (car.status === 'ERROR') {
              return {
                error: {
                  error: car.statusMessage ?? 'Неизвестная ошибка',
                  status: 'CUSTOM_ERROR',
                },
              };
            }

            // TODO: может ли быть машина без коннекторов и адаптеров?

            //
            // 2. Добавляем коннекторы к машине
            //
            const connectorsArr = (
              Object.keys(ConnectorType) as unknown as Array<
                keyof typeof ConnectorType
              >
            ).reduce((acc, el) => {
              if (!connectors[el] && !adapters[el]) {
                return acc;
              }

              if (connectors[el]) {
                return [...acc, { type: el, isAdapterNeeded: false }];
              }

              if (adapters[el]) {
                return [...acc, { type: el, isAdapterNeeded: true }];
              }

              return acc;
            }, [] as Omit<CreateCarConnectorRequestDto, 'carId'>[]);

            // Promise.allSettled?
            const connectorsRes = await Promise.all(
              connectorsArr.map(
                (el) =>
                  postApiUserV1CarConnectors({ ...el, carId: car.data?.id! }) //
              )
            );

            const resErr = connectorsRes.find((el) => el.status === 'ERROR');

            if (resErr) {
              return {
                error: {
                  error: resErr.statusMessage ?? 'Неизвестная ошибка',
                  status: 'CUSTOM_ERROR',
                },
              };
            }

            return { data: { status: 'SUCCESS', statusMessage: '' } };
          } catch (err) {
            if (isAxiosError(err)) {
              if (err.response) {
                return {
                  status: err.response.status,
                  data: err.response.data,
                };
              } else if (err.request) {
                return {
                  error: err.request,
                };
              }
            }

            if (err instanceof Error) {
              return {
                error: err.message,
              };
            }

            return {
              error: {
                error: 'Неизвестная ошибка',
                status: 'CUSTOM_ERROR',
              },
            };
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Cars', id: arg.userId },
        ],
      }),
    }),
  });

export const {
  // Юзеры
  useGetApiUserV1UsersQuery: useGetUsers,
  useLazyGetApiUserV1UsersQuery: useLazyGetUsers,
  useGetApiUserV1UsersByUserIdQuery: useGetUser,
  usePostApiUserV1UsersMutation: useAddUser,
  usePatchApiUserV1UsersByUserIdMutation: useUpdateUser,
  useDeleteApiUserV1UsersByUserIdMutation: useDeleteUser,
  useGetApiUserV1UsersCountQuery: useGetUsersCount,
  useLazyGetApiUserV1UsersCountQuery: useLazyGetUsersCount,
  // Группы юзеров
  useGetApiUserV1GroupsQuery: useGetUserGroups,
  useGetApiUserV1GroupsByGroupIdQuery: useGetUserGroup,
  usePostApiUserV1GroupsMutation: useAddUserGroup,
  usePatchApiUserV1GroupsByGroupIdMutation: useUpdateUserGroup,
  useDeleteApiUserV1GroupsByGroupIdMutation: useDeleteUserGroup,
  //
  useGetApiUserV1CarsQuery: useGetCars,
  useAddCarMutation: useAddCar,
  useDeleteCarMutation: useDeleteCar,
} = enhancedApi;
