import { ProvidedStageDto } from 'shared/api/services/billing/rtk/generated';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';

import { STAGE_END_TYPE } from 'entities/billing/consts';

import {
  ContentHeader,
  StyledContainer,
  StyledText,
  InfoContainer,
} from './styles';

type Props = {
  stage: ProvidedStageDto;
};

export function PopoverContent({ stage }: Props) {
  const { stageName, beginDate, endDate, stageEndType } = stage;

  return (
    <StyledContainer>
      <ContentHeader>{`Стадия ${stageName}`}</ContentHeader>
      <InfoContainer>
        <StyledText>{`Дата старта: ${
          beginDate ? showTimeString(beginDate, FORMAT_TO_SECONDS) : ''
        }`}</StyledText>
        <StyledText>{`Дата окончания: ${
          endDate ? showTimeString(endDate, FORMAT_TO_SECONDS) : ''
        }`}</StyledText>
        <StyledText>{`Причина окончания: ${STAGE_END_TYPE[stageEndType]} (${stageEndType})`}</StyledText>
      </InfoContainer>
    </StyledContainer>
  );
}
