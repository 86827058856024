import { enhancedApi as api } from '../generated/events';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointV1Events: (endpoint) => {
      endpoint.providesTags = [{ type: 'Events', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointV1EventsQuery: useGetEvents,
  useGetApiChargePointV1EventsCountQuery: useGetEventsCount,
} = enhancedApi;
