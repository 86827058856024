import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 20px;
`;

export const CardTitle = styled.h2`
  color: #000;

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
`;

export const ContentLayout = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 707px 337px;
  grid-template-rows: 142px auto;
  grid-template-areas:
    'operation operation'
    'services summary';
`;
