import { MenuGroup, StyledLayout } from './styles';

type Props = {
  contentGroups: Array<JSX.Element | null>;
};

export function PopoverContentLayout({ contentGroups }: Props) {
  return (
    <StyledLayout>
      {contentGroups.map((group) => (
        <MenuGroup>{group}</MenuGroup>
      ))}
    </StyledLayout>
  );
}
