import { ADD_PREFIX, MOBILE_APPS, SETTINGS } from '../names';

export const MOBILE_APP = ':mobileAppId';

const ROOT = `/${SETTINGS}/${MOBILE_APPS}`;

export const MOBILE_APP_ROUTES = {
  MOBILE_APPS: ROOT,
  MOBILE_APP: `${ROOT}/${MOBILE_APP}`,
  ADD_MOBILE_APP: `${ROOT}/${ADD_PREFIX}`,
};
