import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import {
  CAR_BRAND,
  CAR_BRAND_ROUTES,
  CAR_MODEL,
} from 'shared/consts/routes/car-brands';
import { CAR_BRANDS, CAR_MODELS } from 'shared/consts/names';

import { Controller, RequireAuth, Right } from 'entities/authorization';
import { CarModelsLayout } from 'entities/car-model';
import { CarBrandLayout } from 'entities/car-brand/ui/layout';

import { CarModelsMenu } from 'features/car-model/menu';

import { AddCarModelPage } from 'pages/car-model/add';
import { EditCarModelPage } from 'pages/car-model/edit';
import { CarBrandCarModelListPage } from 'pages/car-brand/car-model-list';
import { CarModelListPage } from 'pages/car-model/list';
import { AddCarBrandPage } from 'pages/car-brand/add';
import { EditCarBrandPage } from 'pages/car-brand/edit';

const withRequireAuth = (
  route: JSX.Element,
  controller: Controller,
  right: Right
) => {
  return (
    <Route
      element={<RequireAuth controllerName={controller} rightType={right} />}
    >
      {route}
    </Route>
  );
};

export const CarModelRoutes = (
  <Route element={<RequireAuth controllerName="Car" rightType="Read" />}>
    <Route
      path={CAR_BRANDS}
      element={<SectionLayout menu={<CarModelsMenu />} />}
    >
      <Route element={<CarModelsLayout />}>
        <Route index element={<CarModelListPage />} />
      </Route>

      {withRequireAuth(
        <Route
          path={CAR_BRAND_ROUTES.ADD_CAR_BRAND}
          element={<AddCarBrandPage />}
        />,
        'Car',
        'Write'
      )}

      {withRequireAuth(
        <Route
          path={CAR_BRAND_ROUTES.ADD_CAR_MODEL}
          element={<AddCarModelPage />}
        />,
        'Car',
        'Write'
      )}

      <Route path={CAR_BRAND} element={<CarBrandLayout />}>
        <Route index element={<EditCarBrandPage />} />
        <Route path={CAR_MODELS} element={<CarBrandCarModelListPage />} />
      </Route>

      <Route
        path={`${CAR_BRAND}/${CAR_MODEL}`}
        element={<EditCarModelPage />}
      />
    </Route>
  </Route>
);
