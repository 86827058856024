import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1GroupsById: build.query<
      GetApiChargePointV1GroupsByIdApiResponse,
      GetApiChargePointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/V1/Groups/${queryArg}` }),
    }),
    patchApiChargePointV1GroupsById: build.mutation<
      PatchApiChargePointV1GroupsByIdApiResponse,
      PatchApiChargePointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Groups/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequest,
      }),
    }),
    deleteApiChargePointV1GroupsById: build.mutation<
      DeleteApiChargePointV1GroupsByIdApiResponse,
      DeleteApiChargePointV1GroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointV1Groups: build.query<
      GetApiChargePointV1GroupsApiResponse,
      GetApiChargePointV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Groups`,
        params: {
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    postApiChargePointV1Groups: build.mutation<
      PostApiChargePointV1GroupsApiResponse,
      PostApiChargePointV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiChargePointV1GroupsCount: build.query<
      GetApiChargePointV1GroupsCountApiResponse,
      GetApiChargePointV1GroupsCountApiArg
    >({
      query: () => ({ url: `/Api/ChargePoint/V1/Groups/Count` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1GroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type GetApiChargePointV1GroupsByIdApiArg = string;
export type PatchApiChargePointV1GroupsByIdApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PatchApiChargePointV1GroupsByIdApiArg = {
  /** Идентификатор сущности */
  id: string;
  /** Параметры */
  updateGroupRequest: UpdateGroupRequest;
};
export type DeleteApiChargePointV1GroupsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointV1GroupsByIdApiArg =
  /** Идентификатор сущности */ string;
export type GetApiChargePointV1GroupsApiResponse =
  /** status 200 Success */ GetGroupVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1GroupsApiArg = {
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type PostApiChargePointV1GroupsApiResponse =
  /** status 200 Success */ GetGroupVmBaseResponse;
export type PostApiChargePointV1GroupsApiArg = AddGroupRequest;
export type GetApiChargePointV1GroupsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointV1GroupsCountApiArg = void;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetGroupVm = {
  id: string;
  name: string;
  comment?: string | null;
  children?: any[] | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
  createdDate: string;
  lastUpdate: string;
};
export type GetGroupVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm;
};
export type UpdateGroupRequest = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
  mobileAppIds?: string[] | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetGroupVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGroupVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type AddGroupRequest = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  mobileAppIds?: string[] | null;
};
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export const {
  useGetApiChargePointV1GroupsByIdQuery,
  usePatchApiChargePointV1GroupsByIdMutation,
  useDeleteApiChargePointV1GroupsByIdMutation,
  useGetApiChargePointV1GroupsQuery,
  usePostApiChargePointV1GroupsMutation,
  useGetApiChargePointV1GroupsCountQuery,
} = injectedRtkApi;
