import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { CustomLink } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
  Controller,
  READ_FOR_ALL_CONTROLLERS,
} from 'entities/authorization';

import { HeaderNavMenuItem, StyledHeaderNavMenu } from './styles';

import { MonitoringIcon } from '../../../icons/monitoring';
import { SecurityIcon } from '../../../icons/security';
import { SettingsIcon } from '../../../icons/settings';

interface HeaderMenuItem {
  key: string;
  path: string;
  icon: JSX.Element;
  controllers: Controller[];
  checkIsActive?: (path: string) => boolean;
}

const MENU_ITEMS: HeaderMenuItem[] = [
  {
    key: 'monitoring',
    path: '/',
    icon: (
      <Tooltip title="Мониторинг сети ЭЗС">
        <span>
          <MonitoringIcon />
        </span>
      </Tooltip>
    ),
    checkIsActive: (path: string) => {
      return !path.startsWith('/settings') && !path.startsWith('/security');
    },
    controllers: [
      'ChargeTariff',
      'Discount',
      'ChargePoint',
      'Person',
      'Report',
    ],
  },
  {
    key: 'settings',
    path: '/settings',
    icon: (
      <Tooltip title="Системные справочники">
        <span>
          <SettingsIcon />
        </span>
      </Tooltip>
    ),
    controllers: [
      'Country',
      'Car',
      'Bank',
      'Connector',
      'Settings',
      'Person',
      'Hub',
      'Firmware',
      'OcpiHubs',
      'Cpo',
      'MobileApp',
      'Instruction',
    ],
  },
  {
    key: 'security',
    path: '/security',
    icon: (
      <Tooltip title="Пользователи и администраторы">
        <span>
          <SecurityIcon />
        </span>
      </Tooltip>
    ),

    controllers: ['Identity'],
  },
];

export function NavMenu() {
  // const roles = useSelector(selectTokenRoles);
  // const claims = useSelector(selectTokenClaims);

  // const menuItems = MENU_ITEMS.filter((item) =>
  //   item.controllers.some((controller) => {
  //     if (READ_FOR_ALL_CONTROLLERS.includes(controller)) {
  //       return checkControllerRight(controller, 'Write', roles, claims);
  //     }

  //     return checkControllerRight(controller, 'Read', roles, claims);
  //   })
  // ).map(({ icon, key, path, checkIsActive }) => (
  //   <HeaderNavMenuItem key={key}>
  //     <CustomLink to={path} checkIsActive={checkIsActive}>
  //       {icon}
  //     </CustomLink>
  //   </HeaderNavMenuItem>
  // ));

  const menuItems = MENU_ITEMS.map(({ icon, key, path, checkIsActive }) => (
    <HeaderNavMenuItem key={key}>
      <CustomLink to={path} checkIsActive={checkIsActive}>
        {icon}
      </CustomLink>
    </HeaderNavMenuItem>
  ));

  return <StyledHeaderNavMenu>{menuItems}</StyledHeaderNavMenu>;
}
