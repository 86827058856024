import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationV1SupportById: build.query<
      GetApiInformationV1SupportByIdApiResponse,
      GetApiInformationV1SupportByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Information/v1/Support/${queryArg}` }),
    }),
    patchApiInformationV1SupportById: build.mutation<
      PatchApiInformationV1SupportByIdApiResponse,
      PatchApiInformationV1SupportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/Support/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateSupportRequest,
      }),
    }),
    deleteApiInformationV1SupportById: build.mutation<
      DeleteApiInformationV1SupportByIdApiResponse,
      DeleteApiInformationV1SupportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/Support/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationV1Support: build.query<
      GetApiInformationV1SupportApiResponse,
      GetApiInformationV1SupportApiArg
    >({
      query: () => ({ url: `/Api/Information/v1/Support` }),
    }),
    postApiInformationV1Support: build.mutation<
      PostApiInformationV1SupportApiResponse,
      PostApiInformationV1SupportApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/Support`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationV1SupportByIdApiResponse =
  /** status 200 Success */ GetSupportVmBaseResponse;
export type GetApiInformationV1SupportByIdApiArg = /** Идентификатор */ string;
export type PatchApiInformationV1SupportByIdApiResponse =
  /** status 200 Success */ UpdateSupportVmBaseResponse;
export type PatchApiInformationV1SupportByIdApiArg = {
  /** Идентификатор саппорта */
  id: string;
  /** Параметры */
  updateSupportRequest: UpdateSupportRequest;
};
export type DeleteApiInformationV1SupportByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1SupportByIdApiArg =
  /** Идентификатор */ string;
export type GetApiInformationV1SupportApiResponse =
  /** status 200 Success */ GetSupportVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationV1SupportApiArg = void;
export type PostApiInformationV1SupportApiResponse =
  /** status 200 Success */ AddSupportVmBaseResponse;
export type PostApiInformationV1SupportApiArg = AddSupportRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export type GetSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSupportVm;
};
export type UpdateSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot: string;
  countryId: string;
};
export type UpdateSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateSupportVm;
};
export type UpdateSupportRequest = {
  userAgreementUrl?: string | null;
  urlFaq?: string | null;
  supportPhone?: string | null;
  telegrammBot?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetSupportVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSupportVm[] | null;
};
export type AddSupportVm = {
  id: string;
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export type AddSupportVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddSupportVm;
};
export type AddSupportRequest = {
  userAgreementUrl: string;
  urlFaq: string;
  supportPhone: string;
  telegrammBot?: string | null;
  countryId: string;
};
export const {
  useGetApiInformationV1SupportByIdQuery,
  usePatchApiInformationV1SupportByIdMutation,
  useDeleteApiInformationV1SupportByIdMutation,
  useGetApiInformationV1SupportQuery,
  usePostApiInformationV1SupportMutation,
} = injectedRtkApi;
