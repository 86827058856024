import { enhancedApi as api } from '../generated/groups';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointV1Groups: (endpoint) => {
      endpoint.providesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    getApiChargePointV1GroupsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: arg },
      ];
    },
    postApiChargePointV1Groups: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
    patchApiChargePointV1GroupsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'ChargePointGroups', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: arg.id,
        },
      ];
    },
    deleteApiChargePointV1GroupsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ChargePointGroups', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiChargePointV1GroupsQuery: useGetChargePointGroups,
  useGetApiChargePointV1GroupsCountQuery: useGetChargePointGroupsCount,
  useGetApiChargePointV1GroupsByIdQuery: useGetChargePointGroup,
  usePostApiChargePointV1GroupsMutation: useAddChargePointGroup,
  usePatchApiChargePointV1GroupsByIdMutation: useUpdateChargePointGroup,
  useDeleteApiChargePointV1GroupsByIdMutation: useDeleteChargePointGroup,
} = enhancedApi;
