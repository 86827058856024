import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  value: React.ReactNode;
  width: number;
};

export function CustomText({ value, width }: Props) {
  return (
    <Text
      style={{ width: `${width}px` }}
      ellipsis={{
        tooltip: value,
      }}
    >
      {value}
    </Text>
  );
}
