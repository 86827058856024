import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const Summary = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
`;
