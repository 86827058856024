import {
  useGetCarBrands,
  useGetCarModels,
} from 'shared/api/services/information/rtk';
import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { Form } from './form';

type Props = {
  userId: string;
  closeModal: () => void;
};

export function FormContainer(props: Props) {
  const carModelsQuery = useGetCarModels();
  const carBrandsQuery = useGetCarBrands();

  const isLoading = carModelsQuery.isLoading || carBrandsQuery.isLoading;

  const error = [carModelsQuery.error, carBrandsQuery.error].find(
    (err) => err !== undefined
  );

  const apiResponseError = [carModelsQuery.data, carBrandsQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (!carBrandsQuery?.data?.data || !carModelsQuery?.data?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      {...props}
      carBrands={carBrandsQuery.data?.data}
      carModels={carModelsQuery.data?.data}
    />
  );
}
