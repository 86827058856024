import { Col, Spin } from 'antd';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';

import { GetCarBrandVm } from 'shared/api/services/information/rtk/generated/car-brands';
import { FormRow } from 'shared/ui/form';
import { CustomInput } from 'shared/ui/form/custom-input';
import { CustomSelect } from 'shared/ui/form/custom-select';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useAddCar } from 'shared/api/services/user/rtk/enhanced';
import { ConnectorType } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';

import { CONNECTOR_TYPE } from 'entities/connector';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../../consts/schema';

import { ADD_ERROR, ADD_SUCCESS } from '../../consts';
import {
  BrandLogo,
  BrandLogoContainer,
  NoBrandLogoText,
  StyledTable,
} from './styles';

type Props = {
  userId: string;
  closeModal: () => void;
  carBrands: GetCarBrandVm[];
  carModels: GetCarModelVm[];
};

export function Form({ closeModal, userId, carBrands, carModels }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const { setValue, getValues } = form;

  const [trigger, { isLoading }] = useAddCar();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({ ...data, userId }).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form
          onSubmit={handleSubmit}
          onChange={(e: React.FormEvent<HTMLFormElement>) => {
            const { name, value } = e.target as HTMLInputElement;

            if (!name.includes('connectors') && !name.includes('adapters')) {
              return;
            }

            const [connection, type] = name.split('.');

            if (value === 'false') {
              const anotherConnection =
                connection === 'connectors' ? 'adapters' : 'connectors';

              const anotherConnectionValue = getValues([
                `${anotherConnection}.${type as ConnectorType}`,
              ]);

              if (anotherConnectionValue[0] === true) {
                setValue(
                  `${anotherConnection}.${type as ConnectorType}`,
                  false
                );
              }
            }
          }}
        >
          <Form.Fields carBrands={carBrands} carModels={carModels} />
          <Form.Buttons />
        </form>
      </Spin>
    </FormProvider>
  );
}

type FieldsProps = {
  carBrands: GetCarBrandVm[];
  carModels: GetCarModelVm[];
};

Form.Fields = function Fields({ carBrands, carModels }: FieldsProps) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  const carBrandId = watch('carBrandId');

  const carModelsOptions = useMemo(() => {
    if (!carBrandId) {
      return [];
    }

    return carBrands.find((el) => el.id === carBrandId)?.carModels ?? [];

    // return carModels.filter((carModel) => carModel.carBrand.id === carBrandId);
  }, [carBrandId]);

  const renderCarBrandLogo = () => {
    let logoUrl;

    if (!carBrandId) {
      logoUrl = null;
    } else {
      logoUrl = carBrands.find((el) => el.id === carBrandId)?.logoUrl;
    }

    return (
      <BrandLogoContainer>
        {logoUrl ? (
          <BrandLogo src={logoUrl} />
        ) : (
          <NoBrandLogoText>Нет логотипа</NoBrandLogoText>
        )}
      </BrandLogoContainer>
    );
  };

  const renderConnectorsTable = () => {
    return (
      <StyledTable>
        <thead>
          <tr>
            <th>Название</th>
            <th>Коннектор</th>
            <th>Переходник</th>
          </tr>
        </thead>
        <tbody>
          {(
            Object.keys(CONNECTOR_TYPE) as unknown as Array<
              keyof typeof CONNECTOR_TYPE
            >
          ).map((el) => {
            return (
              <tr key={el}>
                <td>{CONNECTOR_TYPE[el]}</td>
                <td>
                  <CustomCheckbox<FormInput> name={`connectors.${el}`} />
                </td>
                <td>
                  <CustomCheckbox<FormInput> name={`adapters.${el}`} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    );
  };

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="carBrandId"
            options={carBrands.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Марка автомобиля"
          />
        </Col>
        <Col span={12}>{renderCarBrandLogo()}</Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="carModelId"
            label="Модель автомобиля"
            options={carModelsOptions.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput> name="name" label="Название" required />
        </Col>
      </FormRow>

      {renderConnectorsTable()}
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
