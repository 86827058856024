import { Spin } from 'antd';
import { Link } from 'react-router-dom';

import {
  BillingDto,
  ServicesListDto,
} from 'shared/api/services/billing/rtk/generated';
import { UserDto } from 'shared/api/services/user/rtk/generated';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { HighlightedText } from 'shared/ui/styles';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import { PAYMENT_STATUS } from 'entities/billing/consts';

import { CustomText } from '../custom-text';
import {
  CardTable,
  CardTitle,
  StyledCard,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from './styles';
import { BILLING_ROUTES } from 'shared/consts/routes/billing';

type Props = {
  billing: BillingDto;
  user: UserDto | undefined;
  chargePoint: GetChargePointVm | undefined;
  connectorId: string | undefined;
  serviceList: ServicesListDto | undefined;
  isLoading: boolean;
  closeModal: () => void;
};

export function Card({
  billing,
  chargePoint,
  user,
  isLoading,
  connectorId,
  serviceList,
  closeModal,
}: Props) {
  const { paymentStatus } = billing;

  const renderUserCell = () => {
    if (!user) {
      return '';
    }

    const { groupId, id, login } = user;

    return (
      <Link to={`${USER_ROUTES.PERSONS_LIST}/${groupId}/${id}/profile`}>
        <HighlightedText onClick={closeModal}>{login}</HighlightedText>
      </Link>
    );
  };

  const renderChargePointCell = () => {
    if (!chargePoint) {
      return '';
    }

    const { address, name, group, id } = chargePoint;

    return (
      <Link
        to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group?.id ?? null}/${id}`}
      >
        <HighlightedText
          onClick={closeModal}
        >{`${name} ${address}`}</HighlightedText>
      </Link>
    );
  };

  const renderServiceListCell = () => {
    if (!serviceList) {
      return '';
    }

    const { id, name } = serviceList;

    return (
      <Link to={`${BILLING_ROUTES.LIST}/${id}`}>
        <HighlightedText onClick={closeModal}>{name}</HighlightedText>
      </Link>
    );
  };

  const renderConnectorCell = () => {
    if (!connectorId || !chargePoint) {
      return '';
    }

    if (connectorId === chargePoint.id) {
      return '';
    }

    const connectorType = chargePoint.connectors?.find(
      (el) => el.id === connectorId
    )?.type;

    return connectorType ?? '';
  };

  return (
    <StyledCard>
      <CardTitle>Об операции</CardTitle>
      <Spin spinning={isLoading}>
        <CardTable>
          <TableHead>
            <TableHeadCell>Статус оплаты</TableHeadCell>
            <TableHeadCell>{`Тариф (биллинг)`}</TableHeadCell>
            <TableHeadCell>Клиент</TableHeadCell>
            <TableHeadCell>ЭЗС</TableHeadCell>
            <TableHeadCell>Коннектор </TableHeadCell>
          </TableHead>
          <TableRow>
            <TableCell>
              <CustomText width={106} value={PAYMENT_STATUS[paymentStatus]} />
            </TableCell>
            <TableCell>
              <CustomText width={172} value={renderServiceListCell()} />
            </TableCell>
            <TableCell>
              <CustomText width={134} value={renderUserCell()} />
            </TableCell>
            <TableCell>
              <CustomText width={372} value={renderChargePointCell()} />
            </TableCell>
            <TableCell>
              <CustomText width={98} value={renderConnectorCell()} />
            </TableCell>
          </TableRow>
        </CardTable>
      </Spin>
    </StyledCard>
  );
}
