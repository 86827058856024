import { HTMLInputTypeAttribute, useState } from 'react';
import { Input } from 'antd';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import {
  FieldErrorContainer,
  FloatLabelWrapper,
  IconContainer,
} from './styles';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  hasFocus?: boolean;
} & React.ComponentProps<typeof Input>;

export function CustomFloatInput<T extends FieldValues>({
  name,
  disabled,
  hasFocus,
  label,
  placeholder,
  type,
  suffix,
}: Props<T>) {
  const { control } = useFormContext();

  const [focus, setFocus] = useState(Boolean(hasFocus));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { value } = field;
        const { error } = fieldState;

        const getWrapperClass = () => {
          if (error?.message) {
            return 'error';
          }

          if (value && value.length > 0) {
            return 'success';
          }

          return 'undefined';
        };

        const isOccupied = focus || (value && value.length !== 0);

        const labelClass = isOccupied
          ? 'label as-label'
          : 'label as-placeholder';

        return (
          <FloatLabelWrapper
            className={getWrapperClass()}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
          >
            <Input
              {...field}
              type={type}
              suffix={
                <IconContainer status={getWrapperClass()}>
                  {suffix}
                </IconContainer>
              }
              autoFocus={hasFocus}
              disabled={disabled}
            />
            <label className={labelClass}>
              {isOccupied ? label : placeholder}
            </label>
            <FieldErrorContainer>{error?.message}</FieldErrorContainer>
          </FloatLabelWrapper>
        );
      }}
    />
  );
}
