import styled from 'styled-components';

export const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormFieldsGroup = styled.div``;

export const ControlsContainer = styled.div`
  margin-left: 12px;
  display: flex;
  gap: 20px;
`;

export const ControlButton = styled.button`
  cursor: pointer;
`;

export const ServiceTitle = styled.h4`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
`;
