import { Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  HeaderControls,
  HeaderControlsInner,
  HeaderInput,
  StyledHeader,
  Wrapper,
  HeaderSearchWrapper,
  StyledButton,
  LanguageRadioGroupWrapper,
  StyledCheckbox,
  CheckboxWrapper,
  CheckboxLabel,
} from './styles';

import { SearchIcon } from '../../icons/search';
import { ToggleLanguage } from './toggle-language';
import { NavMenu } from './nav-menu';
import { UserInfo } from '../../user-info/ui';

type Props = {
  term: string;
  searchByChargePointName: boolean;
  loading: boolean;
  onSearch: () => Promise<void>;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Header({
  term,
  searchByChargePointName,
  loading,
  onSearch,
  onCheckboxChange,
  onInputChange,
}: Props) {
  return (
    <StyledHeader>
      <Wrapper>
        <HeaderControls>
          <HeaderControlsInner>
            <HeaderSearchWrapper>
              <HeaderInput
                placeholder="Поиск"
                allowClear
                value={term}
                onChange={onInputChange}
                onPressEnter={onSearch}
                prefix={<SearchIcon />}
              />
              <CheckboxWrapper>
                <StyledCheckbox
                  checked={searchByChargePointName}
                  onChange={onCheckboxChange}
                >
                  <CheckboxLabel>Поиск по номеру ЭЗС</CheckboxLabel>
                </StyledCheckbox>
              </CheckboxWrapper>
              {!!term && (
                <Spin spinning={loading}>
                  <StyledButton onClick={onSearch}>Найти</StyledButton>
                </Spin>
              )}
            </HeaderSearchWrapper>
            <NavMenu />
          </HeaderControlsInner>
        </HeaderControls>
        <UserInfo />
        <LanguageRadioGroupWrapper>
          <ToggleLanguage />
        </LanguageRadioGroupWrapper>
      </Wrapper>
    </StyledHeader>
  );
}
