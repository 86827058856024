export function StatusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <rect x="1.75" y="1.25" width="12.5" height="10" rx="3" stroke="white" />
      <path
        d="M6.125 13.75H8M9.875 13.75H8M8 13.75V11.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.375 9.375H8.625" stroke="white" stroke-linecap="round" />
    </svg>
  );
}
