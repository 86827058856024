import { z } from 'zod';

import { postApiInformationV1CountriesBody } from 'shared/api/services/information/orval/zod/schemas';

export const FormSchema = postApiInformationV1CountriesBody.extend({
  id: z.string().min(1),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  id: '',
  name: '',
  code: '',
  currencySymbol: '',
  currencyText: '',
  phonePrefix: '',
  phoneMask: '',
  urlFlag: '',
  description: '',
  language: '',
  privacyPolicyUrl: '',
  displayName: '',
  isDefault: false,
};
