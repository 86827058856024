import {
  LineChartColorParams,
  LineChartDataType,
} from 'shared/ui/charts/line/types';

import { GraphMetric } from '../model/metrics';

export const getMetricsLineChartData = (
  data: GraphMetric[],
  colorParams: LineChartColorParams
): LineChartDataType => {
  return {
    datasets: [
      {
        label: '',
        data: data.map(({ date, value }) => ({
          x: date,
          y: value,
        })),
        tension: 0.5,
        fill: true,
        ...colorParams,
      },
    ],
  };
};
