import { Route } from 'react-router-dom';

import { ChapterLayout } from 'widgets/layout/chapter';
import { MonitoringSidebar } from 'features/sidebar';

import NotFound from '../../../pages/NotFound';

import { PushNotificationRoutes } from './push-notification';
import { HubRoutes } from './hub';
import { ChargePointRoutes } from './charge-point';
import { UserRoutes } from './user';
import { routes as BillingRoutes } from './billing';

import { MonitoringPage } from 'pages/monitoring';

export const MonitoringRoutes = (
  <Route path="/" element={<ChapterLayout siderbar={<MonitoringSidebar />} />}>
    <Route index element={<MonitoringPage />} />
    {ChargePointRoutes}
    {HubRoutes}
    {UserRoutes}
    {BillingRoutes}
    {PushNotificationRoutes}
    <Route path="*" element={<NotFound />} />
  </Route>
);
