import { createColumnHelper } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useAppDispatch } from 'shared/redux/types';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/identity-users/slice';
import { ApplicationDtoUserDto } from 'shared/api/services/identity/rtk/generated';

const columnHelper = createColumnHelper<ApplicationDtoUserDto>();

const DATA_COLUMNS = [
  columnHelper.accessor('userName', {
    id: 'userName',
    header: 'Логин',
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'E-mail',
  }),
  columnHelper.accessor('lastName', {
    id: 'lastName',
    header: 'Фамилия',
  }),
  columnHelper.accessor('firstName', {
    id: 'firstName',
    header: 'Имя',
  }),
  columnHelper.accessor('groups', {
    id: 'groups',
    header: 'Группы',
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      return val.join(', ');
    },
  }),
  columnHelper.accessor('roles', {
    id: 'roles',
    header: 'Роли',
    cell: (props) => {
      const val = props.getValue();

      if (!val) {
        return '';
      }

      return val.join(', ');
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataColumns = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataColumns, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
