import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppThunk, useAppDispatch } from 'shared/redux/types';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { enhancedApi as cpApi } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import { enhancedApi as billingApi } from 'shared/api/services/billing/rtk/enhanced';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import { buildOcppConfigObject } from 'entities/charge-point/lib/build-ocpp-config-object';

import { FormOutput } from '../consts/schema';
import { ADD_SUCCESS, ADD_ERROR } from '../consts';

export const addChargePointThunk =
  (data: FormOutput): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const {
      coordinates,
      ocppConfig,
      infrastructureInfo,
      servicesListId,
      ...rest
    } = data;

    const [latitude, longitude] = coordinates.split(',');

    const addBody = {
      ...rest,
      ocppConfig: buildOcppConfigObject(ocppConfig),
      latitude: Number(latitude.trim()),
      longitude: Number(longitude.trim()),
      infrastructureInfo: infrastructureInfo.length
        ? JSON.stringify(
            infrastructureInfo.reduce((acc, el) => {
              acc[el] = 'true';

              return acc;
            }, {} as Record<string, string>)
          )
        : null,
    };

    const addRes = await dispatch(
      cpApi.endpoints.postApiChargePointV1ChargePoints.initiate(addBody)
    );

    if (addRes.error) {
      throw addRes.error;
    }

    if (servicesListId) {
      const addServicesListLinkRes = await dispatch(
        billingApi.endpoints.postApiBillingV1ServicesListLinks.initiate({
          targetName: 'CHARGE_POINT',
          servicesListId,
          targetId: addRes.data.data?.id!,
        })
      );

      if (addServicesListLinkRes.error) {
        throw addServicesListLinkRes.error;
      }
    }

    await dispatch(
      cpApi.util.invalidateTags([
        { type: 'ChargePoints', id: 'LIST' },
        {
          type: 'ChargePointGroups',
          id: 'LIST',
        },
      ])
    );
  };

export function useAddChargePoint() {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleCreate = async (args: FormOutput) => {
    try {
      setLoading(true);

      await dispatch(addChargePointThunk(args));

      openSuccessNotification(ADD_SUCCESS);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleCreate,
    isMutationLoading: loading,
  };
}
