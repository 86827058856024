import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetTemplates } from 'shared/api/services/pushnotification/rtk/enhanced';

import { Form } from './form';

export function FormContainer() {
  const templatesQuery = useGetTemplates();

  if (templatesQuery.isFetching) {
    return <div>Loading...</div>;
  }

  if (templatesQuery.error) {
    return <ErrorMessage text={getServerErrorText(templatesQuery.error)} />;
  }

  if (!templatesQuery.data?.data) {
    return <EmptyData />;
  }

  return <Form templates={templatesQuery.data.data} />;
}
