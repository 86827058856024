import { z } from 'zod';

import { LATITUDE, LONGITUDE, requiredNumberInString } from 'shared/lib/schema';
import { patchApiChargePointV1ChargePointsIdBody } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { LATITUDE_PATTERN, LONGITUDE_PATTERN } from 'shared/consts/regex';

import {
  ADDRESS_MAX_LENGTH,
  chargePointProtocol,
  CITY_MIN_LENGTH,
  countryCode,
  speedType,
  status,
} from 'entities/charge-point';

const bodySchema = patchApiChargePointV1ChargePointsIdBody;

export const FormSchema = bodySchema
  .omit({
    speedType: true,
    chargePointProtocol: true,
    status: true,
    countryCode: true,
    city: true,
    address: true,
    latitude: true,
    longitude: true,
    maxPower: true,
  })
  .extend({
    speedType: speedType.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    chargePointProtocol: chargePointProtocol.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    status: status.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    countryCode: countryCode.nullish().transform((val, ctx) => {
      if (val === undefined || val === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required field',
        });

        return z.NEVER;
      }

      return val;
    }),
    city: z.string().min(CITY_MIN_LENGTH),
    address: z.string().max(ADDRESS_MAX_LENGTH),
    coordinates: z.string().transform((val, ctx) => {
      if (!val.includes(', ')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Формат: широта, долгота',
        });

        return z.NEVER;
      }

      const [latitude, longitude] = val.split(', ');

      if (!LATITUDE_PATTERN.test(latitude)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Широта от -90 до 90',
        });

        return z.NEVER;
      }

      if (!LONGITUDE_PATTERN.test(longitude)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Долгота от -180 до 180',
        });

        return z.NEVER;
      }

      return val;
    }),
    maxPower: requiredNumberInString.pipe(z.number().gte(0)),
    infrastructureInfo: z.array(z.string()),
    servicesListId: z.string().nullish(),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
