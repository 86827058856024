import {
  IDENTITY_USERS,
  ADD_PREFIX,
  SECURITY,
  IDENTITY_GROUPS,
} from '../names';

export const GROUP = ':groupName';

const ROOT = `/${SECURITY}/${IDENTITY_GROUPS}`;

export const GROUP_ROUTES = {
  GROUPS: ROOT,
  GROUP: `${ROOT}/${GROUP}`,
  USERS_LIST: `${ROOT}/${GROUP}/${IDENTITY_USERS}`,
  ADD_GROUP: `${ROOT}/${ADD_PREFIX}`,
};
