import dayjs from 'dayjs';
import { z } from 'zod';

import { FULL_DATE_PATTERN } from 'shared/consts/regex';
import { patchApiUserUsersUserIdBody } from 'shared/api/services/user/orval/zod/schemas';

export const FormSchema = patchApiUserUsersUserIdBody.omit({
  lockoutEndDate: true,
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;
