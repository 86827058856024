import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { ADD_PREFIX, SUPPORTS } from 'shared/consts/names';
import { SUPPORT } from 'shared/consts/routes/support';
import { RequireAuth } from 'entities/authorization';

import { SupportsMenu } from 'features/support/menu';

import { AddSupportPage } from 'pages/support/add';
import { EditSupportPage } from 'pages/support/edit';

export const SupportRoutes = (
  <Route element={<RequireAuth controllerName="Settings" rightType="Read" />}>
    <Route path={SUPPORTS} element={<SectionLayout menu={<SupportsMenu />} />}>
      <Route
        element={<RequireAuth controllerName="Settings" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddSupportPage />} />
      </Route>
      <Route path={SUPPORT} element={<EditSupportPage />} />
    </Route>
  </Route>
);
