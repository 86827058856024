import {
  Row,
  SortingState,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { GetEventVm } from 'shared/api/services/chargepoint/rtk/generated/events';
import { JsonDataViewer } from 'shared/ui';
import {
  ServerTableLayout,
  useQueryParamsPagination,
  useQueryParamsSorting,
} from 'shared/ui/table-new';

import { useColumns } from './use-columns';

type Props = {
  data: GetEventVm[];
  loading: boolean;
  totalCount: number;
};

export function TableInstance({ data, loading, totalCount }: Props) {
  const [rowToShowJSON, setRowToShowJSON] = useState<null | GetEventVm>(null);

  const columns = useColumns();

  const { limit, page } = useQueryParamsPagination();
  const { orderingField, orderingType } = useQueryParamsSorting();

  const pagination: PaginationState = {
    pageIndex: page,
    pageSize: limit,
  };

  const sorting: SortingState = [
    {
      id: orderingField,
      desc: orderingType === 'DESC',
    },
  ];

  // TODO:
  // add filters

  const table = useReactTable({
    data,
    columns,
    state: { pagination, sorting },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalCount / limit),
  });

  const handleRowDoubleClick = (row: Row<GetEventVm>) => {
    setRowToShowJSON(row.original);
  };

  if (rowToShowJSON) {
    // TODO: мб нужен парсинг data-поля

    return (
      <JsonDataViewer
        data={rowToShowJSON}
        onClick={() => setRowToShowJSON(null)}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      totalCount={totalCount}
      handleRowDoubleClick={handleRowDoubleClick}
    />
  );
}
