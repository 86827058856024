import {
  RESERVES,
  ROOT,
  SESSIONS,
  EVENTS,
  STATUSES,
  MANUAL_COMMANDS,
  BILLINGS,
} from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { OpenManualCommandButton } from 'features/command/ui/button';

import { AddChargePointLink } from '../add-link';
import { AddChargePointGroupLink } from '../add-group-link';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Информация об ЭЗС</CustomLink>,
    extraContent: <AddChargePointLink />,
  },
  {
    key: SESSIONS,
    label: <CustomLink to={SESSIONS}>Журнал сессий</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  // {
  //   key: RESERVES,
  //   label: <CustomLink to={RESERVES}>Журнал брони</CustomLink>,
  //   permissions: [
  //     {
  //       controller: 'PrivateMethods',
  //       right: 'Read',
  //     },
  //   ],
  //   disabled: true,
  // },
  {
    key: BILLINGS,
    label: <CustomLink to={BILLINGS}>Журнал оплат</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: EVENTS,
    label: <CustomLink to={EVENTS}>События</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: MANUAL_COMMANDS,
    label: <CustomLink to={MANUAL_COMMANDS}>Управление</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    // extraContent: <OpenManualCommandButton />,
    disabled: true,
  },
  {
    key: STATUSES,
    label: <CustomLink to={STATUSES}>Статусы</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
    disabled: true,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
