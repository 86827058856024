import styled from 'styled-components';

import { COLOR } from 'shared/consts';
import { DayjsDatePicker } from 'shared/ui';

const { RangePicker } = DayjsDatePicker;

export const StyledContainer = styled.article`
  display: flex;
  flex-direction: column;
`;

export const MonitoringMetricsCardContainer = styled.div`
  flex: 1;
  background: ${COLOR.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const RangePickerContainer = styled.div`
  margin-bottom: 10px;
`;

export const StyledMonitoringMetricsCard = styled.div`
  background: ${COLOR.white};
  border-radius: 8px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLOR.black};
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CardContent = styled.div`
  padding: 15px;
  flex-grow: 1;
`;

export const StyledRangePicker = styled(RangePicker)`
  border: none;
  width: 220px;
  padding: 0;
  background-color: transparent;

  &.ant-picker-focused {
    box-shadow: none;
  }

  & .ant-picker-input > input {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #414141;
  }

  & .ant-picker-active-bar {
    display: none;
  }

  & .ant-picker-clear {
    right: 0;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
