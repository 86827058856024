import { z } from 'zod';

import { nonEmptyString } from 'shared/lib/schema';
import { EmployeesBalancePeriod } from 'shared/api/services/company/rtk/generated';

const balanceRefreshPeriod: z.ZodType<EmployeesBalancePeriod> = z.enum([
  'FIRST_DAY_MONTH',
]);

export const FormSchema = z.object({
  name: nonEmptyString,
  legalName: nonEmptyString,
  email: nonEmptyString,
  contractData: nonEmptyString,
  address: nonEmptyString,
  phoneNumber: nonEmptyString,
  logoUrl: nonEmptyString,
  inn: nonEmptyString,
  kpp: nonEmptyString,
  // coerce
  companyBalanceForPeriod: z.number().optional(),
  employeeBalanceForPeriod: z.number().optional(),
  balanceRefreshPeriod: balanceRefreshPeriod.optional(),
  isBalanceRefreshLocked: z.boolean(),
  isDefault: z.boolean(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;

export const DEFAULT_VALUES: FormSchemaType = {
  name: '',
  legalName: '',
  email: '',
  contractData: '',
  address: '',
  phoneNumber: '',
  logoUrl: '',
  inn: '',
  kpp: '',
  balanceRefreshPeriod: undefined,
  isDefault: false,
  isBalanceRefreshLocked: false,
};
