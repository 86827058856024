import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
  UiCancelFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import {
  Code,
  GetCountryVm,
} from 'shared/api/services/information/rtk/generated/countries';
import { useAddTariff } from 'shared/api/services/billing/rtk/enhanced';
import { FormRow } from 'shared/ui/form';

import { COUNTRY_CURRENCY, TARIFF_TYPE } from 'entities/billing/consts';

import {
  DEFAULT_VALUES,
  FormInput,
  FormOutput,
  FormSchema,
} from '../../../../billing/add-tariff/consts/schema';
import { ADD_ERROR, ADD_SUCCESS } from '../../../../billing/add-tariff/consts';

type Props = {
  countries: GetCountryVm[];
  closeModal: () => void;
};

export function Form({ countries, closeModal }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const [trigger, { isLoading }] = useAddTariff();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <Form.Fields countries={countries} />
          <Form.Buttons />
        </form>
      </Spin>
    </FormProvider>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
};

Form.Fields = function Fields({ countries }: FieldsProps) {
  const {
    control,
    formState: { errors },

    setValue,
    watch,
  } = useFormContext<FormInput, void, FormOutput>();

  const type = watch('type');
  const price = watch('price');
  const countryId = watch('countryId');

  useEffect(() => {
    const name = `${price || ''} ${COUNTRY_CURRENCY[countryId as Code] || ''}/${
      type ? TARIFF_TYPE[type] : ''
    }`;

    setValue('name', name);
  }, [type, price, countryId]);

  return (
    <>
      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput>
            name="name"
            label="Название стоимости"
            required
            disabled
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <CustomInput<FormInput> name="price" label="Цена" required />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="type"
            options={Object.entries(TARIFF_TYPE).map((entry) => {
              const [key, label] = entry;

              return {
                value: key,
                label,
              };
            })}
            label="Единица измерения"
            required
          />
        </Col>
      </FormRow>

      <FormRow gutter={20}>
        <Col span={24}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
