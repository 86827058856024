import { VersionBadge } from 'shared/ui';
import { useAppDispatch } from 'shared/redux/types';
import { clearCredentials } from 'shared/redux/slices/auth/authSlice';
import { clearSessionCredentials } from 'shared/redux/slices/auth/authSessionSlice';

import { IconContainer, LogoutButtonContainer, StyledButton } from './styles';

import { LogoutIcon } from './icons/logout';

export function LogoutButton() {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(clearCredentials());
    dispatch(clearSessionCredentials());
  };

  return (
    <LogoutButtonContainer>
      <VersionBadge />
      <StyledButton type="default" onClick={handleClick}>
        <IconContainer>
          <LogoutIcon />
        </IconContainer>
        <p>выход</p>
      </StyledButton>
    </LogoutButtonContainer>
  );
}
