import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;

  box-shadow: 0px 0px 14px 0px rgba(24, 30, 28, 0.15);
  padding: 20px;

  grid-area: operation;
`;

export const CardTitle = styled.h3`
  color: #000;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  margin-bottom: 8px;
`;

export const CardTable = styled.table`
  color: #000;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  table-layout: fixed;

  width: 100%;

  & th,
  td {
    padding-right: 20px;
  }
`;

export const TableHead = styled.thead`
  color: rgba(0, 0, 0, 0.5);

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  height: 44px;
`;

export const TableHeadCell = styled.th`
  padding-block: 8px;
  vertical-align: middle;
`;

export const TableRow = styled.tr`
  height: 40px;

  &:nth-child(even) {
    background-color: #fafafa;
  }
`;

export const TableCell = styled.td`
  vertical-align: middle;
`;
