import { Map, YMaps, withYMaps } from '@pbe/react-yandex-maps';

import { GetStatusesVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import './styles.css';

import { MapContainer } from './styles';

import { Pin } from './pin';

export type MapProps = React.ComponentProps<typeof Map> & {
  // Undocumented feature
  onBoundsChange?: (e: any) => void;
};

type Props = {
  chargePoints: GetStatusesVm[];
  loading?: boolean;
  mapProps: MapProps;
  onPinClick?: (chargePoint: GetStatusesVm) => void;
};

export function YandexMap({
  chargePoints,
  loading = false,
  mapProps,
  onPinClick,
}: Props) {
  const CreatedMap = ({ ymaps }) => {
    const factory = ymaps.templateLayoutFactory;

    return (
      <Map
        {...mapProps}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {chargePoints.map((chargePoint) => (
          <Pin
            chargePoint={chargePoint}
            factory={factory}
            key={chargePoint.chargePointId}
            onPinClick={onPinClick ? () => onPinClick(chargePoint) : undefined}
          />
        ))}
      </Map>
    );
  };

  const ConnectedCreatedMap = withYMaps(CreatedMap, true, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <MapContainer>
      <YMaps
        query={{
          lang: 'ru_RU',
          load: 'templateLayoutFactory,control.ZoomControl,control.GeolocationControl,overlay.storage,GeoObject',
        }}
      >
        <ConnectedCreatedMap />
      </YMaps>
    </MapContainer>
  );
}
