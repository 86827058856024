import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { GROUP_ROUTES } from 'shared/consts/routes/identity-group';
import { useAbility } from 'shared/lib/ability/context';

export function AddIdentityUserGroupLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'Identity')) {
    return null;
  }

  return (
    <Link to={GROUP_ROUTES.ADD_GROUP}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
