import { z } from 'zod';

import { postApiUserCarsBody } from 'shared/api/services/user/orval/zod/schemas';
import { ConnectorType } from 'shared/api/services/chargepoint/orval/axios/schemas';

const connectorsSchema = z.object(
  Object.fromEntries(
    Object.entries(ConnectorType).map(([key, value]) => [key, z.boolean()])
  ) as Record<keyof typeof ConnectorType, z.ZodBoolean>
);

export const FormSchema = postApiUserCarsBody
  .omit({
    userId: true,
  })
  .extend({
    carBrandId: z.string().optional(),
    connectors: connectorsSchema,
    adapters: connectorsSchema,
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  carBrandId: '',
  carModelId: '',
  name: '',
  adapters: {
    CCS1: false,
    CCS2: false,
    CHADEMO: false,
    GBTAC: false,
    GBTDC: false,
    SCHUKO: false,
    TESLA: false,
    TYPE1: false,
    TYPE2: false,
  },
  connectors: {
    CCS1: false,
    CCS2: false,
    CHADEMO: false,
    GBTAC: false,
    GBTDC: false,
    SCHUKO: false,
    TESLA: false,
    TYPE1: false,
    TYPE2: false,
  },
};
