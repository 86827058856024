import { z } from 'zod';

import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetHub } from 'shared/api/services/chargepoint/rtk';

import { Form } from './form';

const pageParamsSchema = z.object({
  hubId: z.string(),
});

export function FormContainer() {
  const { hubId } = useTypedParams(pageParamsSchema);

  const { isFetching, error, data } = useGetHub(hubId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.data) {
    return <EmptyData />;
  }

  return <Form hub={data.data} />;
}
