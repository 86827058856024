import styled from 'styled-components';

export const StyledTitle = styled.h3`
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 20px;
`;
