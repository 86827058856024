import { Col, Divider, Space, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import {
  ServicesListDto,
  TariffDto,
} from 'shared/api/services/billing/rtk/generated';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { useAppDispatch } from 'shared/redux/types';
import { addServicesToServicesList } from 'shared/api/services/billing/rtk/enhanced/thunk';
import { DeleteIcon } from 'shared/icons/delete';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { UiInput } from 'shared/ui/ui-kit';
import { BILLING_ROUTES } from 'shared/consts/routes/billing';

import { getTariffOptionsList } from 'entities/billing/lib/get-tariff-options-list';
import { STAGE_END_TYPE } from 'entities/billing/consts';

import { AddTariffButton } from 'features/billing/add-tariff';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';
import {
  ControlButton,
  FormFieldsContainer,
  FormFieldsGroup,
  ServiceTitle,
} from './styles';
import { AddServiceButton } from './add-service-button';
import { AddStageButton } from './add-stage-button';

type Props = {
  serviceList: ServicesListDto;
  tariffs: TariffDto[];
};

export function Form({ tariffs, serviceList }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { id } = serviceList;

    try {
      setIsLoading(true);

      await dispatch(addServicesToServicesList({ stages: data, id }));

      openSuccessNotification(ADD_SUCCESS);

      navigate(BILLING_ROUTES.LIST);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields tariffs={tariffs} name={serviceList.name} />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  tariffs: TariffDto[];
  name: string;
};

Form.Fields = function Fields({ tariffs, name }: FieldsProps) {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<FormInput, void, FormOutput>();

  const reservingFieldArray = useFieldArray({
    name: 'reservingStages',
    control,
  });

  const chargingFieldArray = useFieldArray({
    name: 'chargingStages',
    control,
  });

  const renderReservingService = () => {
    if (!reservingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Бронирование"
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber: reservingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Бронирование</ServiceTitle>

        {reservingFieldArray.fields.map((field, index) => {
          const endType = watch(`reservingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`reservingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`reservingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => reservingFieldArray.remove(index)}
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            reservingFieldArray.append({
              name: '',
              serialNumber: reservingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      </>
    );
  };

  const renderChargingService = () => {
    if (!chargingFieldArray.fields.length) {
      return (
        <AddServiceButton
          label="Добавить услугу Заряд"
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: chargingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      );
    }

    return (
      <>
        <ServiceTitle>Услуга Заряд</ServiceTitle>

        {chargingFieldArray.fields.map((field, index) => {
          const endType = watch(`chargingStages.${index}.endType`);

          return (
            <FormRow gutter={12} wrap={false}>
              <Col flex="auto">
                <FormRow gutter={20}>
                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.name`}
                      label="Название стадии услуги"
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.tariffId`}
                      label="Стоимость"
                      options={getTariffOptionsList(tariffs)}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddTariffButton />
                          </>
                        );
                      }}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomSelect<FormInput>
                      name={`chargingStages.${index}.endType`}
                      label="Триггер окончания"
                      options={Object.entries(STAGE_END_TYPE).map((entry) => {
                        const [key, label] = entry;

                        return { value: key, label };
                      })}
                      required
                    />
                  </Col>

                  <Col span={6}>
                    <CustomInput<FormInput>
                      name={`chargingStages.${index}.endConditionValue`}
                      label="Значение % батареи / мин"
                      disabled={
                        !(
                          endType === 'ENERGY_PERCENT' ||
                          endType === 'TIME_MINUTE'
                        )
                      }
                      required
                    />
                  </Col>
                </FormRow>
              </Col>
              <Col flex="60px">
                <Space
                  size={20}
                  style={{ alignSelf: 'flex-start', paddingTop: '28px' }}
                >
                  <ControlButton
                    onClick={() => chargingFieldArray.remove(index)}
                  >
                    <DeleteIcon />
                  </ControlButton>
                </Space>
              </Col>
            </FormRow>
          );
        })}
        <AddStageButton
          onClick={() =>
            chargingFieldArray.append({
              name: '',
              serialNumber: chargingFieldArray.fields.length,
              tariffId: '',
              endConditionValue: 0,
            })
          }
        />
      </>
    );
  };

  return (
    <FormFieldsContainer>
      <FormFieldsGroup>
        <FormRow gutter={20}>
          <Col span={6}>
            <UiInput value={name} disabled />
            {/* <CustomInput value={name} label="Название биллинга" disabled /> */}
          </Col>
        </FormRow>
      </FormFieldsGroup>

      <FormFieldsGroup>{renderReservingService()}</FormFieldsGroup>
      <FormFieldsGroup>{renderChargingService()}</FormFieldsGroup>
    </FormFieldsContainer>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
