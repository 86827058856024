/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  AddCarBrandRequest,
  AddCarBrandVmBaseResponse,
  AddCarModelRequest,
  AddCarModelVmBaseResponse,
  AddCountryRequest,
  AddCountryVmBaseResponse,
  AddMobileAppRequest,
  AddMobileAppVmBaseResponse,
  AddOrUpdateLogoCarBrandVmBaseResponse,
  AddSupportRequest,
  AddSupportVmBaseResponse,
  BaseResponse,
  GetApiInformationV1CarModelsParams,
  GetApiInformationV1CountriesParams,
  GetCarBrandVmBaseResponse,
  GetCarBrandVmIReadOnlyCollectionBaseResponse,
  GetCarModelVmBaseResponse,
  GetCarModelVmIReadOnlyCollectionBaseResponse,
  GetCountryVmBaseResponse,
  GetCountryVmIReadOnlyCollectionBaseResponse,
  GetSupportVmBaseResponse,
  GetSupportVmIReadOnlyCollectionBaseResponse,
  MobileAppVmBaseResponse,
  MobileAppVmIReadOnlyCollectionBaseResponse,
  PutApiInformationV1CarBrandsLogoCarBrandIdBody,
  UpdateCarBrandRequest,
  UpdateCarBrandVmBaseResponse,
  UpdateCarModelRequest,
  UpdateCarModelVmBaseResponse,
  UpdateCountryRequest,
  UpdateCountryVmBaseResponse,
  UpdateMobileAppRequest,
  UpdateMobileAppVmBaseResponse,
  UpdateSupportRequest,
  UpdateSupportVmBaseResponse
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getWebApi = () => {
/**
 * @summary Получить бренд
 */
const getApiInformationV1CarBrandsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarBrandVmBaseResponse>(
      {url: `/Api/Information/v1/CarBrands/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить бренд
 */
const patchApiInformationV1CarBrandsId = (
    id: string,
    updateCarBrandRequest: UpdateCarBrandRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCarBrandVmBaseResponse>(
      {url: `/Api/Information/v1/CarBrands/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarBrandRequest
    },
      options);
    }
  
/**
 * @summary Удалить бренд
 */
const deleteApiInformationV1CarBrandsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/CarBrands/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список брендов
 */
const getApiInformationV1CarBrands = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarBrandVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/v1/CarBrands`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить бренд
 */
const postApiInformationV1CarBrands = (
    addCarBrandRequest: AddCarBrandRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCarBrandVmBaseResponse>(
      {url: `/Api/Information/v1/CarBrands`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCarBrandRequest
    },
      options);
    }
  
/**
 * @summary Добавить или обновить логотип бренда
 */
const putApiInformationV1CarBrandsLogoCarBrandId = (
    carBrandId: string,
    putApiInformationV1CarBrandsLogoCarBrandIdBody: PutApiInformationV1CarBrandsLogoCarBrandIdBody,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
if(putApiInformationV1CarBrandsLogoCarBrandIdBody.file !== undefined) {
 formData.append('file', putApiInformationV1CarBrandsLogoCarBrandIdBody.file)
 }

      return customInstance<AddOrUpdateLogoCarBrandVmBaseResponse>(
      {url: `/Api/Information/v1/CarBrands/Logo/${carBrandId}`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  
/**
 * @summary Удалить файл логотипа
 */
const deleteApiInformationV1CarBrandsLogoCarBrandId = (
    carBrandId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/CarBrands/Logo/${carBrandId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить модель
 */
const getApiInformationV1CarModelsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarModelVmBaseResponse>(
      {url: `/Api/Information/v1/CarModels/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить модель
 */
const patchApiInformationV1CarModelsId = (
    id: string,
    updateCarModelRequest: UpdateCarModelRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCarModelVmBaseResponse>(
      {url: `/Api/Information/v1/CarModels/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCarModelRequest
    },
      options);
    }
  
/**
 * @summary Удалить модель
 */
const deleteApiInformationV1CarModelsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/CarModels/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить модели
 */
const getApiInformationV1CarModels = (
    params?: GetApiInformationV1CarModelsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCarModelVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/v1/CarModels`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Добавить модель
 */
const postApiInformationV1CarModels = (
    addCarModelRequest: AddCarModelRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCarModelVmBaseResponse>(
      {url: `/Api/Information/v1/CarModels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCarModelRequest
    },
      options);
    }
  
/**
 * @summary Получить страну
 */
const getApiInformationV1CountriesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCountryVmBaseResponse>(
      {url: `/Api/Information/v1/Countries/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить страну
 */
const patchApiInformationV1CountriesId = (
    id: string,
    updateCountryRequest: UpdateCountryRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateCountryVmBaseResponse>(
      {url: `/Api/Information/v1/Countries/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCountryRequest
    },
      options);
    }
  
/**
 * @summary Удалить страну
 */
const deleteApiInformationV1CountriesId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/Countries/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список стран
 */
const getApiInformationV1Countries = (
    params?: GetApiInformationV1CountriesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetCountryVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/v1/Countries`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Добавить страну
 */
const postApiInformationV1Countries = (
    addCountryRequest: AddCountryRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddCountryVmBaseResponse>(
      {url: `/Api/Information/v1/Countries`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addCountryRequest
    },
      options);
    }
  
/**
 * @summary Получить мобильное приложение
 */
const getApiInformationV1MobileAppsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MobileAppVmBaseResponse>(
      {url: `/Api/Information/v1/MobileApps/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить существующее мобильное приложение
 */
const patchApiInformationV1MobileAppsId = (
    id: string,
    updateMobileAppRequest: UpdateMobileAppRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateMobileAppVmBaseResponse>(
      {url: `/Api/Information/v1/MobileApps/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateMobileAppRequest
    },
      options);
    }
  
/**
 * @summary Удалить мобильное приложение
 */
const deleteApiInformationV1MobileAppsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/MobileApps/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список мобильных приложений
 */
const getApiInformationV1MobileApps = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MobileAppVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/v1/MobileApps`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить мобильное приложение
 */
const postApiInformationV1MobileApps = (
    addMobileAppRequest: AddMobileAppRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddMobileAppVmBaseResponse>(
      {url: `/Api/Information/v1/MobileApps`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addMobileAppRequest
    },
      options);
    }
  
/**
 * @summary Получить саппорт
 */
const getApiInformationV1SupportId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSupportVmBaseResponse>(
      {url: `/Api/Information/v1/Support/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Обновить саппорта
 */
const patchApiInformationV1SupportId = (
    id: string,
    updateSupportRequest: UpdateSupportRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateSupportVmBaseResponse>(
      {url: `/Api/Information/v1/Support/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateSupportRequest
    },
      options);
    }
  
/**
 * @summary Удалить саппорт
 */
const deleteApiInformationV1SupportId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BaseResponse>(
      {url: `/Api/Information/v1/Support/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Получить список саппорт
 */
const getApiInformationV1Support = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSupportVmIReadOnlyCollectionBaseResponse>(
      {url: `/Api/Information/v1/Support`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Добавить саппорта
 */
const postApiInformationV1Support = (
    addSupportRequest: AddSupportRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddSupportVmBaseResponse>(
      {url: `/Api/Information/v1/Support`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addSupportRequest
    },
      options);
    }
  
return {getApiInformationV1CarBrandsId,patchApiInformationV1CarBrandsId,deleteApiInformationV1CarBrandsId,getApiInformationV1CarBrands,postApiInformationV1CarBrands,putApiInformationV1CarBrandsLogoCarBrandId,deleteApiInformationV1CarBrandsLogoCarBrandId,getApiInformationV1CarModelsId,patchApiInformationV1CarModelsId,deleteApiInformationV1CarModelsId,getApiInformationV1CarModels,postApiInformationV1CarModels,getApiInformationV1CountriesId,patchApiInformationV1CountriesId,deleteApiInformationV1CountriesId,getApiInformationV1Countries,postApiInformationV1Countries,getApiInformationV1MobileAppsId,patchApiInformationV1MobileAppsId,deleteApiInformationV1MobileAppsId,getApiInformationV1MobileApps,postApiInformationV1MobileApps,getApiInformationV1SupportId,patchApiInformationV1SupportId,deleteApiInformationV1SupportId,getApiInformationV1Support,postApiInformationV1Support}};
export type GetApiInformationV1CarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1CarBrandsId']>>>
export type PatchApiInformationV1CarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationV1CarBrandsId']>>>
export type DeleteApiInformationV1CarBrandsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1CarBrandsId']>>>
export type GetApiInformationV1CarBrandsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1CarBrands']>>>
export type PostApiInformationV1CarBrandsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationV1CarBrands']>>>
export type PutApiInformationV1CarBrandsLogoCarBrandIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['putApiInformationV1CarBrandsLogoCarBrandId']>>>
export type DeleteApiInformationV1CarBrandsLogoCarBrandIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1CarBrandsLogoCarBrandId']>>>
export type GetApiInformationV1CarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1CarModelsId']>>>
export type PatchApiInformationV1CarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationV1CarModelsId']>>>
export type DeleteApiInformationV1CarModelsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1CarModelsId']>>>
export type GetApiInformationV1CarModelsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1CarModels']>>>
export type PostApiInformationV1CarModelsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationV1CarModels']>>>
export type GetApiInformationV1CountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1CountriesId']>>>
export type PatchApiInformationV1CountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationV1CountriesId']>>>
export type DeleteApiInformationV1CountriesIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1CountriesId']>>>
export type GetApiInformationV1CountriesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1Countries']>>>
export type PostApiInformationV1CountriesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationV1Countries']>>>
export type GetApiInformationV1MobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1MobileAppsId']>>>
export type PatchApiInformationV1MobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationV1MobileAppsId']>>>
export type DeleteApiInformationV1MobileAppsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1MobileAppsId']>>>
export type GetApiInformationV1MobileAppsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1MobileApps']>>>
export type PostApiInformationV1MobileAppsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationV1MobileApps']>>>
export type GetApiInformationV1SupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1SupportId']>>>
export type PatchApiInformationV1SupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['patchApiInformationV1SupportId']>>>
export type DeleteApiInformationV1SupportIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['deleteApiInformationV1SupportId']>>>
export type GetApiInformationV1SupportResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiInformationV1Support']>>>
export type PostApiInformationV1SupportResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiInformationV1Support']>>>
