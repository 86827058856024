import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthState, initialState } from 'shared/redux/consts';
import { AppState } from 'shared/redux/types';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      return { ...state, ...payload };
    },
    clearCredentials: (_state) => {
      return initialState;
    },
  },
});

export const { setCredentials, clearCredentials } = slice.actions;

export const selectAccessToken = (state: AppState) => state.auth.accessToken;

export default slice.reducer;
