import { z } from 'zod';

import { postApiBillingServicesListsBody } from 'shared/api/services/billing/orval/zod/schemas';

export const FormSchema = postApiBillingServicesListsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
};
