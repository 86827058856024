import { useParams } from 'react-router-dom';
import { Menu, Spin } from 'antd';

import { CustomLink } from 'shared/ui';
import { StyledNavMenu } from 'shared/ui/section-menu/menu/styles';
import { HUB_ROUTES } from 'shared/consts/routes/hub';

import { AddButton } from './add-button';
import { GetHubVm } from 'shared/api/services/chargepoint/rtk/generated/hubs';
import { GetChargePointVm } from 'shared/api/services/chargepoint/orval/axios/schemas';
import { CHARGE_POINTS } from 'shared/consts/names';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

type Props = {
  isHubsLoading: boolean;
  isChargePointsLoading: boolean;
  items: GetHubVm[];
  chargePoints: GetChargePointVm[];
};

export function MenuInstance({
  items,
  isHubsLoading,
  isChargePointsLoading,
  chargePoints,
}: Props) {
  const { hubId } = useParams() as {
    hubId: string;
  };

  const renderChargePointMenuItem = (chargePoint: GetChargePointVm) => {
    const { id, name, group } = chargePoint;
    const { id: groupId } = group || {};

    const path = `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
      groupId || null
    }/${id}`;

    return (
      <CustomLink to={path}>
        <Menu.Item key={path} eventKey={path}>
          {name}
        </Menu.Item>
      </CustomLink>
    );
  };

  const hubsMenuItems = items.map((hub) => {
    const { id, name } = hub;

    const innerMenuContent = chargePoints.map((cp) =>
      renderChargePointMenuItem(cp)
    );

    const path = String(id) === hubId ? HUB_ROUTES.HUBS : String(id);

    return (
      <CustomLink to={path} key={path}>
        <Spin spinning={isChargePointsLoading && String(id) === hubId}>
          <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
            {innerMenuContent}
          </Menu.SubMenu>
        </Spin>
      </CustomLink>
    );
  });

  return (
    <>
      <AddButton />
      <StyledNavMenu
        openKeys={
          hubId && Boolean(!isChargePointsLoading) ? ['root', hubId] : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={isHubsLoading}>
          <CustomLink
            to={HUB_ROUTES.HUBS}
            checkIsActive={(path) => path === HUB_ROUTES.HUBS}
          >
            <Menu.SubMenu
              key="root"
              title="Зарядные Хабы"
              eventKey="root"
              disabled={isHubsLoading}
            >
              {hubsMenuItems}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledNavMenu>
    </>
  );
}
