import {
  ProvidedServiceDto,
  TariffType,
} from 'shared/api/services/billing/rtk/generated';

import { SERVICE_TYPE } from 'entities/billing/consts';

import { CustomText } from '../custom-text';
import {
  CardTable,
  ServiceContainer,
  ServiceName,
  StyledCard,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from './styles';
import { StagePopover } from '../../stage-popover';

const getTariffSuffix = (type: TariffType) => {
  switch (type) {
    case 'ENERGY_KWH':
      return ' руб/квт*ч';
    case 'FLAT_FEE':
      return '';
    case 'TIME_MINUTE':
      return ' руб/мин';
  }
};

const getValueSuffix = (type: TariffType) => {
  switch (type) {
    case 'ENERGY_KWH':
      return ' квтч';
    case 'FLAT_FEE':
      return '';
    case 'TIME_MINUTE':
      return ' мин';
  }
};

type Props = {
  providedServices: ProvidedServiceDto[];
};

export function ServicesCard({ providedServices }: Props) {
  return (
    <StyledCard>
      {providedServices.map((service) => {
        const { providedStages, serviceType, serviceName, id } = service;

        return (
          <ServiceContainer key={id}>
            <ServiceName>{`${SERVICE_TYPE[serviceType]}: ${serviceName}`}</ServiceName>
            <CardTable>
              <TableHead>
                <TableHeadCell>Стадия</TableHeadCell>
                <TableHeadCell>Цена</TableHeadCell>
                <TableHeadCell>Объем услуги</TableHeadCell>
                <TableHeadCell>Стоимость</TableHeadCell>
                <TableHeadCell>Скидка</TableHeadCell>
                <TableHeadCell>Итого</TableHeadCell>
              </TableHead>
              {providedStages.map((stage) => {
                const {
                  stageName,
                  id,
                  payment,
                  value,
                  discount,
                  benefitPayment,
                  tariffPrice,
                  tariffType,
                } = stage;

                return (
                  <StagePopover stage={stage}>
                    <TableRow key={id}>
                      <TableCell>
                        <CustomText width={160} value={stageName} />
                      </TableCell>
                      <TableCell>
                        <CustomText
                          width={92}
                          value={`${tariffPrice}${getTariffSuffix(tariffType)}`}
                        />
                      </TableCell>
                      <TableCell>
                        <CustomText
                          width={96}
                          value={`${value}${getValueSuffix(tariffType)}`}
                        />
                      </TableCell>
                      <TableCell>
                        <CustomText width={79} value={payment} />
                      </TableCell>
                      <TableCell>
                        <CustomText width={54} value={discount} />
                      </TableCell>
                      <TableCell>
                        <CustomText width={67} value={benefitPayment} />
                      </TableCell>
                    </TableRow>
                  </StagePopover>
                );
              })}
            </CardTable>
          </ServiceContainer>
        );
      })}
    </StyledCard>
  );
}
