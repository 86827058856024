import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 16px;
  color: #fff;
`;

export const ContentHeader = styled.div`
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  margin-bottom: 12px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

export const StyledText = styled(Typography.Text)`
  margin: 0;
  color: inherit;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLink = styled.span`
  color: #0dffac;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
`;
