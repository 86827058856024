import {
  CHARGE_POINTS,
  MANUAL_COMMANDS,
  RESERVES,
  SESSIONS,
  ADD_PREFIX,
  GROUPS,
} from '../names';

const ROOT = `/${CHARGE_POINTS}`;

export const CHARGE_POINT = ':chargePointName';

export const CHARGE_POINT_ROUTES = {
  CHARGE_POINTS: ROOT,
  CHARGE_POINTS_GROUPS_LIST: `${ROOT}/${GROUPS}`,
  CHARGE_POINTS_SESSION_LOG: `${ROOT}/${SESSIONS}`,
  CHARGE_POINTS_RESERVES: `/${CHARGE_POINTS}/${RESERVES}`,
  CHARGE_POINTS_MANUAL_COMMANDS: `/${CHARGE_POINTS}/${MANUAL_COMMANDS}`,
  // Группа ЭЗС
  ADD_CHARGE_POINT: `/${CHARGE_POINTS}/${ADD_PREFIX}`,
  ADD_CHARGE_POINT_GROUP: `/${CHARGE_POINTS}/${GROUPS}/${ADD_PREFIX}`,
} as const;
