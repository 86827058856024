import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import {
  selectTableColumns,
  setTableColumns,
} from 'shared/redux/slices/billings/slice';
import { useAppDispatch } from 'shared/redux/types';
import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { HighlightedText } from 'shared/ui/styles';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';

import { PAYMENT_STATUS } from 'entities/billing/consts';

import { UserPopover } from 'features/user/popover';

import { ChargePointPopover } from './ui/charge-point-popover';
import { FiscalCheckLink } from './ui/fiscal-check-link';
import { BillingDetailsModal } from './ui/details/modal';

const columnHelper = createColumnHelper<BillingDto>();

const DATA_COLUMNS = [
  columnHelper.accessor('providedServices', {
    id: 'providedServices',
    header: 'ЭЗС',
    cell: (props) => {
      const val = props.getValue();

      const sessionId = val.find((el) => el.sessionId)?.sessionId;

      return <ChargePointPopover sessionId={sessionId} />;
    },
    meta: {
      filterElement: <InputFilter paramName="benefitPayment" disabled />,
    },
  }),
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Дата счета',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter dateFromName="" dateToName="" disabled />
      ),
    },
  }),
  columnHelper.accessor('closedDate', {
    id: 'closedDate',
    header: 'Дата оплаты',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter dateFromName="" dateToName="" disabled />
      ),
    },
  }),
  columnHelper.accessor('paymentStatus', {
    id: 'paymentStatus',
    header: 'Статус оплаты',
    cell: (props) => {
      const val = props.getValue();

      return PAYMENT_STATUS[val];
    },
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(PAYMENT_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="status"
          disabled
        />
      ),
    },
  }),

  columnHelper.accessor('userId', {
    id: 'userId',
    header: 'Пользователь',
    cell: (props) => {
      return <UserPopover userId={props.getValue()} />;
    },
    size: 360,
    meta: {
      filterElement: <InputFilter paramName="userId" disabled />,
    },
  }),
  columnHelper.accessor('benefitPayment', {
    id: 'benefitPayment',
    header: 'Стоимость услуги',
    cell: (props) => {
      const val = props.getValue();

      const billing = props.row.original;

      const handleClick = () => {
        NiceModal.show(BillingDetailsModal, { billing });
      };

      return <HighlightedText onClick={handleClick}>{val}</HighlightedText>;
    },

    meta: {
      filterElement: <InputFilter paramName="benefitPayment" disabled />,
    },
  }),
  columnHelper.accessor('payment', {
    id: 'payment',
    header: 'Цена без скидок и бонусов',
    meta: {
      filterElement: <InputFilter paramName="payment" disabled />,
    },
  }),
  columnHelper.accessor('discount', {
    id: 'discount',
    header: 'Скидка',
    meta: {
      filterElement: <InputFilter paramName="discount" disabled />,
    },
  }),
  columnHelper.accessor('bonusesPayment', {
    id: 'bonusesPayment',
    header: 'Списано баллов',
    meta: {
      filterElement: <InputFilter paramName="bonusesPayment" disabled />,
    },
  }),
  // columnHelper.accessor('', {
  //   id: '',
  //   header: 'Списано со счета',
  //   size: 120,
  //   minSize: 120,
  //   maxSize: 120,
  //   meta: {
  //     filterElement: <InputFilter paramName="" disabled />,
  //   },
  // }),
  columnHelper.accessor('invoiceSum', {
    id: 'invoiceSum',
    header: 'Оплачено картой',
    meta: {
      filterElement: <InputFilter paramName="invoiceSum" disabled />,
    },
  }),
  columnHelper.accessor('id', {
    id: 'id',
    header: 'Чек',
    size: 120,
    minSize: 120,
    maxSize: 120,
    cell: (props) => {
      return <FiscalCheckLink billingId={props.getValue()} />;
    },
    meta: {
      filterElement: <InputFilter paramName="id" disabled />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
