import { enhancedApi as api } from '../generated/mobile-apps';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationV1MobileApps: (endpoint) => {
      endpoint.providesTags = [{ type: 'MobileApps', id: 'LIST' }];
    },
    getApiInformationV1MobileAppsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'MobileApps', id: arg },
      ];
    },
    postApiInformationV1MobileApps: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'MobileApps', id: 'LIST' }];
    },
    patchApiInformationV1MobileAppsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'MobileApps', id: 'LIST' },
        {
          type: 'MobileApps',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationV1MobileAppsQuery: useGetMobileApps,
  useGetApiInformationV1MobileAppsByIdQuery: useGetMobileApp,
  usePostApiInformationV1MobileAppsMutation: useAddMobileApp,
  usePatchApiInformationV1MobileAppsByIdMutation: useUpdateMobileApp,
} = enhancedApi;
