import { ADD_PREFIX, SETTINGS, SUPPORTS } from '../names';

export const SUPPORT = ':supportId';

const ROOT = `/${SETTINGS}/${SUPPORTS}`;

export const SUPPORT_ROUTES = {
  SUPPORTS: ROOT,
  SUPPORT: `${ROOT}/${SUPPORT}`,
  ADD_SUPPORT: `${ROOT}/${ADD_PREFIX}`,
};
