import { AppThunk } from 'shared/redux/types';

import { enhancedApi as api, WebApiRequestAddUserRequest } from '../generated';

export const updateUserRoles =
  (args: {
    userName: string;
    deleteRoles: string[];
    addRoles: string[];
  }): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const { addRoles, deleteRoles, userName } = args;

    for (const role of deleteRoles) {
      const res = await dispatch(
        api.endpoints.deleteApiIdentityV1UserRoles.initiate({
          roleName: role,
          userName,
        })
      );

      if (res.error) {
        throw res.error;
      }
    }

    if (addRoles.length) {
      const res = await dispatch(
        api.endpoints.postApiIdentityV1UserRoles.initiate({
          roles: addRoles,
          userName,
        })
      );

      if (res.error) {
        throw res.error;
      }
    }

    await dispatch(
      api.util.invalidateTags([{ type: 'IdentityRoles', id: 'LIST' }])
    );
  };

export const addIdentityUser =
  (
    args: WebApiRequestAddUserRequest & { groupName: string }
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const { groupName, ...rest } = args;

    const res = await dispatch(
      api.endpoints.postApiIdentityV1Users.initiate(rest)
    );

    if (res.error) {
      throw res.error;
    }

    if (groupName) {
      const res = await dispatch(
        api.endpoints.postApiIdentityV1UserGroups.initiate({
          groupName,
          userName: rest.userName,
        })
      );

      if (res.error) {
        throw res.error;
      }
    }

    await dispatch(
      api.util.invalidateTags([{ type: 'IdentityUsers', id: 'LIST' }])
    );
  };
