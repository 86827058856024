import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { getServerErrorText } from 'shared/lib';
import {
  useGetBillings,
  useGetBillingsCount,
} from 'shared/api/services/billing/rtk/enhanced';

import { TableInstance } from './table';

const billingsQueryParamsSchema = z.object({
  balanceId: z.string().uuid().optional(),
  sessionId: z.string().uuid().optional(),
  // userId: z.string().uuid().optional(),
  status: z.enum(['ERROR', 'IN_PROCESS', 'COMPLETED']).optional(),
  dateFrom: z.string().datetime().optional(),
  dateTo: z.string().datetime().optional(),
  orderingField: z.string().optional(),
  orderingType: z.enum(['ASC', 'DESC']).optional(),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
});

const billingsCountQueryParamsSchema = billingsQueryParamsSchema.omit({
  orderingField: true,
  orderingType: true,
  page: true,
  limit: true,
});

type Props = {
  userId?: string;
  chargePointsIds?: string[];
};

export function TableContainer({ userId, chargePointsIds }: Props) {
  const billingsQueryParams = useTypedQueryParamsCustom(
    billingsQueryParamsSchema
  );
  const billingsCountQueryParams = useTypedQueryParamsCustom(
    billingsCountQueryParamsSchema
  );

  const billingsQuery = useGetBillings({
    ...billingsQueryParams,
    userId,
    chargePointsIds,
    offset:
      Math.max(billingsQueryParams.page - 1, 0) * billingsQueryParams.limit,
  });
  const billingsCountQuery = useGetBillingsCount({
    userId,
    chargePointsIds,
    ...billingsCountQueryParams,
  });

  const isLoading = billingsQuery.isLoading || billingsCountQuery.isLoading;
  const isFetching = billingsQuery.isFetching || billingsCountQuery.isFetching;

  const apiResponseError = [billingsQuery.data, billingsCountQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [billingsQuery.error, billingsCountQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (
    !billingsQuery?.data?.data ||
    !billingsCountQuery.data ||
    billingsCountQuery.data.data === undefined
  ) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={billingsQuery.data.data}
      totalCount={billingsCountQuery.data.data}
    />
  );
}
