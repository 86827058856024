import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
`;

export const ButtonLabel = styled.p``;

export const IconContainer = styled.div`
  width: 21px;
  aspect-ratio: 1;
`;
