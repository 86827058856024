import { z } from 'zod';

import { postApiChargePointV1GroupsBody } from 'shared/api/services/chargepoint/orval/zod/schemas';

export const FormSchema = postApiChargePointV1GroupsBody;

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  name: '',
  isDefault: false,
  comment: '',
  parentId: '',
};
