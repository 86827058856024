import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useState } from 'react';

import { UiDefaultButton, UiPrimaryButton } from 'shared/ui/buttons';
import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';

import { FormContentWrapper, StyledButtonsContainer } from './styles';

type Props = {
  onCancel: () => void;
  onConfirm: (val: boolean) => void;
  showCharts: boolean;
};

export function PageSettingsForm({ onCancel, onConfirm, showCharts }: Props) {
  const [show, setShow] = useState(showCharts);

  const handleShowChange = (val: boolean) => setShow(val);

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    handleShowChange(e.target.checked);
  };

  return (
    <>
      <FormContentWrapper>
        <UiCheckbox checked={show} onChange={onCheckboxChange}>
          Показывать графики
        </UiCheckbox>
      </FormContentWrapper>
      <StyledButtonsContainer>
        <UiPrimaryButton onClick={onCancel}>Отмена</UiPrimaryButton>
        <UiDefaultButton
          onClick={() => {
            onConfirm(show);
            onCancel();
          }}
        >
          Да
        </UiDefaultButton>
      </StyledButtonsContainer>
    </>
  );
}
