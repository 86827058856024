import styled from 'styled-components';

export const OptionsGroupLabel = styled.span`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;
