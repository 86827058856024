import {
  CHARGE_POINT_RIGHTS,
  CONTROLLER_CLAIMS,
  IDENTITY_ROLES,
  ROOT,
} from 'shared/consts/names';

import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Профиль</CustomLink>,
  },
  {
    key: CONTROLLER_CLAIMS,
    label: <CustomLink to={CONTROLLER_CLAIMS}>Права</CustomLink>,
  },
  {
    key: CHARGE_POINT_RIGHTS,
    label: <CustomLink to={CHARGE_POINT_RIGHTS}>ЭЗС</CustomLink>,
    disabled: true,
  },
  {
    key: IDENTITY_ROLES,
    label: <CustomLink to={IDENTITY_ROLES}>Роли</CustomLink>,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
