const PARAM_DELIMETER = '\n';
const KEY_VALUE_DELIMETER = '=';

export const getOcppConfigFromString = (jsonOcppConfig: string) => {
  try {
    const parsed = JSON.parse(jsonOcppConfig);

    return Object.entries(parsed)
      .map(([key, value]) => `${key}${KEY_VALUE_DELIMETER}${value}`)
      .join(PARAM_DELIMETER);
  } catch (e) {
    return null;
  }
};
