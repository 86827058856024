import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChargePointStatus } from 'shared/api/services/chargepoint/rtk/generated/metrics';

type FiltersState = {
  ownerTypes: string[];
  statuses: ChargePointStatus[];
  companiesId: string[];
  notCompaniesId: string[];
  withTestCp: string[];
};

const initialState: FiltersState = {
  ownerTypes: [],
  statuses: [],
  withTestCp: [],
  companiesId: [],
  notCompaniesId: [],
};

const slice = createSlice({
  name: 'chargePointFilters',
  initialState,
  reducers: {
    setOwnerTypes: (state, action: PayloadAction<string[]>) => {
      state.ownerTypes = action.payload;
    },
    setStatuses: (state, action: PayloadAction<ChargePointStatus[]>) => {
      state.statuses = action.payload;
    },
    setCompaniesId: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;

      state.companiesId = payload;
    },
    setNotCompaniesId: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;

      state.notCompaniesId = payload;
    },
    setWithTestCp: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;

      state.withTestCp = payload;
    },
  },
});

export const {
  setOwnerTypes,
  setStatuses,
  setCompaniesId,
  setNotCompaniesId,
  setWithTestCp,
} = slice.actions;

export const selectOwnerTypes = (state: RootState) =>
  state.chargePointFilters.ownerTypes;

export const selectStatuses = (state: RootState) =>
  state.chargePointFilters.statuses;
export const selectCompaniesId = (state: RootState) =>
  state.chargePointFilters.companiesId;
export const selectNotCompaniesId = (state: RootState) =>
  state.chargePointFilters.notCompaniesId;
export const selectWithTestCp = (state: RootState) =>
  state.chargePointFilters.withTestCp;

export default slice.reducer;
