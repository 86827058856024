import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiUserV1AppInfo: build.query<
      GetApiUserV1AppInfoApiResponse,
      GetApiUserV1AppInfoApiArg
    >({
      query: () => ({ url: `/Api/User/V1/AppInfo` }),
    }),
    getApiUserV1CarConnectorsByCarConnectorId: build.query<
      GetApiUserV1CarConnectorsByCarConnectorIdApiResponse,
      GetApiUserV1CarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/V1/CarConnectors/${queryArg}` }),
    }),
    patchApiUserV1CarConnectorsByCarConnectorId: build.mutation<
      PatchApiUserV1CarConnectorsByCarConnectorIdApiResponse,
      PatchApiUserV1CarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/CarConnectors/${queryArg.carConnectorId}`,
        method: 'PATCH',
        body: queryArg.updateCarConnectorRequestDto,
      }),
    }),
    deleteApiUserV1CarConnectorsByCarConnectorId: build.mutation<
      DeleteApiUserV1CarConnectorsByCarConnectorIdApiResponse,
      DeleteApiUserV1CarConnectorsByCarConnectorIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/CarConnectors/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserV1CarConnectors: build.query<
      GetApiUserV1CarConnectorsApiResponse,
      GetApiUserV1CarConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/CarConnectors`,
        params: {
          CarId: queryArg,
        },
      }),
    }),
    postApiUserV1CarConnectors: build.mutation<
      PostApiUserV1CarConnectorsApiResponse,
      PostApiUserV1CarConnectorsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/CarConnectors`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserV1CarsByCarId: build.query<
      GetApiUserV1CarsByCarIdApiResponse,
      GetApiUserV1CarsByCarIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/V1/Cars/${queryArg}` }),
    }),
    patchApiUserV1CarsByCarId: build.mutation<
      PatchApiUserV1CarsByCarIdApiResponse,
      PatchApiUserV1CarsByCarIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Cars/${queryArg.carId}`,
        method: 'PATCH',
        body: queryArg.updateCarRequestDto,
      }),
    }),
    deleteApiUserV1CarsByCarId: build.mutation<
      DeleteApiUserV1CarsByCarIdApiResponse,
      DeleteApiUserV1CarsByCarIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Cars/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserV1Cars: build.query<
      GetApiUserV1CarsApiResponse,
      GetApiUserV1CarsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Cars`,
        params: {
          UserId: queryArg,
        },
      }),
    }),
    postApiUserV1Cars: build.mutation<
      PostApiUserV1CarsApiResponse,
      PostApiUserV1CarsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Cars`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserV1FavoriteChargePoints: build.query<
      GetApiUserV1FavoriteChargePointsApiResponse,
      GetApiUserV1FavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/FavoriteChargePoints`,
        params: {
          UserId: queryArg,
        },
      }),
    }),
    postApiUserV1FavoriteChargePoints: build.mutation<
      PostApiUserV1FavoriteChargePointsApiResponse,
      PostApiUserV1FavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/FavoriteChargePoints`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiUserV1FavoriteChargePoints: build.mutation<
      DeleteApiUserV1FavoriteChargePointsApiResponse,
      DeleteApiUserV1FavoriteChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/FavoriteChargePoints`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiUserV1GroupsByGroupId: build.query<
      GetApiUserV1GroupsByGroupIdApiResponse,
      GetApiUserV1GroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/V1/Groups/${queryArg}` }),
    }),
    patchApiUserV1GroupsByGroupId: build.mutation<
      PatchApiUserV1GroupsByGroupIdApiResponse,
      PatchApiUserV1GroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Groups/${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.updateGroupRequestDto,
      }),
    }),
    deleteApiUserV1GroupsByGroupId: build.mutation<
      DeleteApiUserV1GroupsByGroupIdApiResponse,
      DeleteApiUserV1GroupsByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Groups/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserV1Groups: build.query<
      GetApiUserV1GroupsApiResponse,
      GetApiUserV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Groups`,
        params: {
          UserId: queryArg,
        },
      }),
    }),
    postApiUserV1Groups: build.mutation<
      PostApiUserV1GroupsApiResponse,
      PostApiUserV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1LoginCode: build.mutation<
      PostApiUserV1LoginCodeApiResponse,
      PostApiUserV1LoginCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Login/code`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1Login: build.mutation<
      PostApiUserV1LoginApiResponse,
      PostApiUserV1LoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1LoginRefreshTokens: build.mutation<
      PostApiUserV1LoginRefreshTokensApiResponse,
      PostApiUserV1LoginRefreshTokensApiArg
    >({
      query: () => ({
        url: `/Api/User/V1/Login/refreshTokens`,
        method: 'POST',
      }),
    }),
    postApiUserV1LoginLock: build.mutation<
      PostApiUserV1LoginLockApiResponse,
      PostApiUserV1LoginLockApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Login/lock`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1OldActionLoginCode: build.mutation<
      PostApiUserV1OldActionLoginCodeApiResponse,
      PostApiUserV1OldActionLoginCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/OldAction/login/code`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1OldActionLogin: build.mutation<
      PostApiUserV1OldActionLoginApiResponse,
      PostApiUserV1OldActionLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/OldAction/login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiUserV1OldActionGetsession: build.mutation<
      PostApiUserV1OldActionGetsessionApiResponse,
      PostApiUserV1OldActionGetsessionApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/OldAction/getsession`,
        method: 'POST',
        params: {
          sessionToken: queryArg,
        },
      }),
    }),
    getApiUserV1UsersByUserId: build.query<
      GetApiUserV1UsersByUserIdApiResponse,
      GetApiUserV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/User/V1/Users/${queryArg}` }),
    }),
    patchApiUserV1UsersByUserId: build.mutation<
      PatchApiUserV1UsersByUserIdApiResponse,
      PatchApiUserV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.updateUserRequestDto,
      }),
    }),
    deleteApiUserV1UsersByUserId: build.mutation<
      DeleteApiUserV1UsersByUserIdApiResponse,
      DeleteApiUserV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Users/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiUserV1Users: build.query<
      GetApiUserV1UsersApiResponse,
      GetApiUserV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Users`,
        params: {
          Login: queryArg.login,
          RegistrationDateFrom: queryArg.registrationDateFrom,
          RegistrationDateTo: queryArg.registrationDateTo,
          GroupId: queryArg.groupId,
          Limit: queryArg.limit,
          Offset: queryArg.offset,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
        },
      }),
    }),
    postApiUserV1Users: build.mutation<
      PostApiUserV1UsersApiResponse,
      PostApiUserV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Users`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUserV1UsersCount: build.query<
      GetApiUserV1UsersCountApiResponse,
      GetApiUserV1UsersCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/User/V1/Users/count`,
        params: {
          Login: queryArg.login,
          RegistrationDateFrom: queryArg.registrationDateFrom,
          RegistrationDateTo: queryArg.registrationDateTo,
          GroupId: queryArg.groupId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiUserV1AppInfoApiResponse = /** status 200 Success */ string;
export type GetApiUserV1AppInfoApiArg = void;
export type GetApiUserV1CarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type GetApiUserV1CarConnectorsByCarConnectorIdApiArg = string;
export type PatchApiUserV1CarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type PatchApiUserV1CarConnectorsByCarConnectorIdApiArg = {
  carConnectorId: string;
  updateCarConnectorRequestDto: UpdateCarConnectorRequestDto;
};
export type DeleteApiUserV1CarConnectorsByCarConnectorIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserV1CarConnectorsByCarConnectorIdApiArg = string;
export type GetApiUserV1CarConnectorsApiResponse =
  /** status 200 Success */ CarConnectorDtoIReadOnlyCollectionResponseBase;
export type GetApiUserV1CarConnectorsApiArg = string;
export type PostApiUserV1CarConnectorsApiResponse =
  /** status 200 Success */ CarConnectorDtoResponseBase;
export type PostApiUserV1CarConnectorsApiArg = CreateCarConnectorRequestDto;
export type GetApiUserV1CarsByCarIdApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type GetApiUserV1CarsByCarIdApiArg = string;
export type PatchApiUserV1CarsByCarIdApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type PatchApiUserV1CarsByCarIdApiArg = {
  carId: string;
  updateCarRequestDto: UpdateCarRequestDto;
};
export type DeleteApiUserV1CarsByCarIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserV1CarsByCarIdApiArg = string;
export type GetApiUserV1CarsApiResponse =
  /** status 200 Success */ CarDtoIReadOnlyCollectionResponseBase;
export type GetApiUserV1CarsApiArg = string;
export type PostApiUserV1CarsApiResponse =
  /** status 200 Success */ CarDtoResponseBase;
export type PostApiUserV1CarsApiArg = CreateCarRequestDto;
export type GetApiUserV1FavoriteChargePointsApiResponse =
  /** status 200 Success */ GuidIReadOnlyCollectionResponseBase;
export type GetApiUserV1FavoriteChargePointsApiArg = string;
export type PostApiUserV1FavoriteChargePointsApiResponse =
  /** status 200 Success */ FavoriteChargePointDtoResponseBase;
export type PostApiUserV1FavoriteChargePointsApiArg =
  CreateFavoriteChargePointRequestDto;
export type DeleteApiUserV1FavoriteChargePointsApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserV1FavoriteChargePointsApiArg =
  DeleteFavoriteChargePointRequestDto;
export type GetApiUserV1GroupsByGroupIdApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type GetApiUserV1GroupsByGroupIdApiArg = string;
export type PatchApiUserV1GroupsByGroupIdApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type PatchApiUserV1GroupsByGroupIdApiArg = {
  groupId: string;
  updateGroupRequestDto: UpdateGroupRequestDto;
};
export type DeleteApiUserV1GroupsByGroupIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserV1GroupsByGroupIdApiArg = string;
export type GetApiUserV1GroupsApiResponse =
  /** status 200 Success */ GroupDtoIReadOnlyCollectionResponseBase;
export type GetApiUserV1GroupsApiArg = string | undefined;
export type PostApiUserV1GroupsApiResponse =
  /** status 200 Success */ GroupDtoResponseBase;
export type PostApiUserV1GroupsApiArg = CreateGroupRequestDto;
export type PostApiUserV1LoginCodeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserV1LoginCodeApiArg = SendVerificationCodeRequestDto;
export type PostApiUserV1LoginApiResponse =
  /** status 200 Success */ JwtTokensDtoResponseBase;
export type PostApiUserV1LoginApiArg = IssueAuthTokensRequestDto;
export type PostApiUserV1LoginRefreshTokensApiResponse =
  /** status 200 Success */ JwtTokensDtoResponseBase;
export type PostApiUserV1LoginRefreshTokensApiArg = void;
export type PostApiUserV1LoginLockApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserV1LoginLockApiArg = LockUserRequestDto;
export type PostApiUserV1OldActionLoginCodeApiResponse =
  /** status 200 Success */ ResponseBase;
export type PostApiUserV1OldActionLoginCodeApiArg =
  SendVerificationCodeRequestDto;
export type PostApiUserV1OldActionLoginApiResponse =
  /** status 200 Success */ SessionAuthTokenDtoResponseBase;
export type PostApiUserV1OldActionLoginApiArg = IssueAuthTokensRequestDto;
export type PostApiUserV1OldActionGetsessionApiResponse =
  /** status 200 Success */ SessionUserDtoResponseBase;
export type PostApiUserV1OldActionGetsessionApiArg = string | undefined;
export type GetApiUserV1UsersByUserIdApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type GetApiUserV1UsersByUserIdApiArg = string;
export type PatchApiUserV1UsersByUserIdApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type PatchApiUserV1UsersByUserIdApiArg = {
  userId: string;
  updateUserRequestDto: UpdateUserRequestDto;
};
export type DeleteApiUserV1UsersByUserIdApiResponse =
  /** status 200 Success */ ResponseBase;
export type DeleteApiUserV1UsersByUserIdApiArg = string;
export type GetApiUserV1UsersApiResponse =
  /** status 200 Success */ UserDtoIReadOnlyCollectionResponseBase;
export type GetApiUserV1UsersApiArg = {
  login?: string;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  groupId?: string;
  limit?: number;
  offset?: number;
  orderingField?: string;
  orderingType?: OrderingType;
};
export type PostApiUserV1UsersApiResponse =
  /** status 200 Success */ UserDtoResponseBase;
export type PostApiUserV1UsersApiArg = CreateUserRequestDto;
export type GetApiUserV1UsersCountApiResponse =
  /** status 200 Success */ UInt32ResponseBase;
export type GetApiUserV1UsersCountApiArg = {
  login?: string;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  groupId?: string;
};
export type CarConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type CarConnectorDto = {
  id: string;
  carId: string;
  type: CarConnectorType;
  isAdapterNeeded: boolean;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CarConnectorDtoResponseBase = {
  data?: CarConnectorDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCarConnectorRequestDto = {
  carId?: string | null;
  type?: CarConnectorType;
  isAdapterNeeded?: boolean | null;
};
export type ResponseBase = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CarConnectorDtoIReadOnlyCollectionResponseBase = {
  data?: CarConnectorDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCarConnectorRequestDto = {
  carId: string;
  type: CarConnectorType;
  isAdapterNeeded: boolean;
};
export type CarConnectorInCarDto = {
  id: string;
  type: CarConnectorType;
  isAdapterNeeded: boolean;
};
export type CarDto = {
  id: string;
  name: string;
  userId: string;
  carModelId: string;
  connectors: CarConnectorInCarDto[];
};
export type CarDtoResponseBase = {
  data?: CarDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateCarRequestDto = {
  name?: string | null;
  userId?: string | null;
  carModelId?: string | null;
};
export type CarDtoIReadOnlyCollectionResponseBase = {
  data?: CarDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateCarRequestDto = {
  name: string;
  userId: string;
  carModelId: string;
};
export type GuidIReadOnlyCollectionResponseBase = {
  data?: string[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type FavoriteChargePointDto = {
  chargePointId: string;
  userId: string;
};
export type FavoriteChargePointDtoResponseBase = {
  data?: FavoriteChargePointDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateFavoriteChargePointRequestDto = {
  chargePointId: string;
  userId: string;
};
export type DeleteFavoriteChargePointRequestDto = {
  chargePointId: string;
  userId: string;
};
export type GroupDto = {
  id: string;
  name: string;
  createdDate: string;
  lastUpdate: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
  children: GroupDto[];
};
export type GroupDtoResponseBase = {
  data?: GroupDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateGroupRequestDto = {
  name?: string | null;
  comment?: string | null;
  parentId?: string | null;
  isDefault?: boolean | null;
};
export type GroupDtoIReadOnlyCollectionResponseBase = {
  data?: GroupDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type CreateGroupRequestDto = {
  name: string;
  comment?: string | null;
  parentId?: string | null;
  isDefault: boolean;
};
export type SendVerificationCodeRequestDto = {
  login: string;
};
export type JwtTokensDto = {
  accessToken: string;
  refreshToken: string;
};
export type JwtTokensDtoResponseBase = {
  data?: JwtTokensDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type IssueAuthTokensRequestDto = {
  login: string;
  countryId: string;
  mobileAppId: string;
  code: string;
};
export type LockUserRequestDto = {
  userId: string;
  lockDateTo: string;
};
export type SessionAuthTokenDto = {
  sessionToken: string;
};
export type SessionAuthTokenDtoResponseBase = {
  data?: SessionAuthTokenDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type SessionUserDto = {
  userId: string;
};
export type SessionUserDtoResponseBase = {
  data?: SessionUserDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type Sex = 'UNKNOWN' | 'MALE' | 'FEMALE';
export type Language = 'RU' | 'BY' | 'AM' | 'KZ' | 'KG';
export type UserDto = {
  id: string;
  login: string;
  lockoutEndDate: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  registrationDate: string;
  countryId: string;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted: boolean;
  interfaceLanguage: Language;
  isDebug: boolean;
};
export type UserDtoResponseBase = {
  data?: UserDto;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type UpdateUserRequestDto = {
  login?: string | null;
  lockoutEndDate?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  countryId?: string | null;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted?: boolean | null;
  interfaceLanguage?: Language;
  isDebug?: boolean | null;
};
export type UserDtoIReadOnlyCollectionResponseBase = {
  data?: UserDto[] | null;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type OrderingType = 'ASC' | 'DESC';
export type CreateUserRequestDto = {
  login: string;
  lockoutEndDate?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: string | null;
  sex?: Sex;
  countryId: string;
  iconUrl?: string | null;
  groupId?: string | null;
  notesRelatedWithUser?: string | null;
  userAgreementAccepted: boolean;
  interfaceLanguage?: Language;
  isDebug?: boolean | null;
};
export type UInt32ResponseBase = {
  data?: number;
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export const {
  useGetApiUserV1AppInfoQuery,
  useGetApiUserV1CarConnectorsByCarConnectorIdQuery,
  usePatchApiUserV1CarConnectorsByCarConnectorIdMutation,
  useDeleteApiUserV1CarConnectorsByCarConnectorIdMutation,
  useGetApiUserV1CarConnectorsQuery,
  usePostApiUserV1CarConnectorsMutation,
  useGetApiUserV1CarsByCarIdQuery,
  usePatchApiUserV1CarsByCarIdMutation,
  useDeleteApiUserV1CarsByCarIdMutation,
  useGetApiUserV1CarsQuery,
  usePostApiUserV1CarsMutation,
  useGetApiUserV1FavoriteChargePointsQuery,
  usePostApiUserV1FavoriteChargePointsMutation,
  useDeleteApiUserV1FavoriteChargePointsMutation,
  useGetApiUserV1GroupsByGroupIdQuery,
  usePatchApiUserV1GroupsByGroupIdMutation,
  useDeleteApiUserV1GroupsByGroupIdMutation,
  useGetApiUserV1GroupsQuery,
  usePostApiUserV1GroupsMutation,
  usePostApiUserV1LoginCodeMutation,
  usePostApiUserV1LoginMutation,
  usePostApiUserV1LoginRefreshTokensMutation,
  usePostApiUserV1LoginLockMutation,
  usePostApiUserV1OldActionLoginCodeMutation,
  usePostApiUserV1OldActionLoginMutation,
  usePostApiUserV1OldActionGetsessionMutation,
  useGetApiUserV1UsersByUserIdQuery,
  usePatchApiUserV1UsersByUserIdMutation,
  useDeleteApiUserV1UsersByUserIdMutation,
  useGetApiUserV1UsersQuery,
  usePostApiUserV1UsersMutation,
  useGetApiUserV1UsersCountQuery,
} = injectedRtkApi;
