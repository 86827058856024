import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import { RangePickerProps } from 'shared/ui';
import { getQueryParams } from 'shared/lib/router/get-query-params';

import { StyledDateTimeRangePicker } from './styles';

type Props = {
  dateFromName: string;
  dateToName: string;
  disabled?: boolean;
};

export function DateTimeRangePickerFilter({
  dateFromName,
  dateToName,
  disabled = false,
}: Props) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get(dateFromName);
  const dateToParam = searchParams.get(dateToName);

  useEffect(() => {
    if (dateFromParam && dateToParam) {
      setDateFrom(dayjs(dateFromParam));
      setDateTo(dayjs(dateToParam));
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }, [dateFromParam, dateToParam]);

  const onChange = (
    values: RangePickerProps['value'],
    _dateString: [string, string] | string
  ) => {
    const queryParams = getQueryParams();

    if (!values) {
      delete queryParams[dateFromName];
      delete queryParams[dateToName];

      setSearchParams({ ...queryParams });
    } else {
      const [from, to] = values as [dayjs.Dayjs, dayjs.Dayjs];

      setSearchParams({
        ...queryParams,
        [dateFromName]: from.toISOString(),
        [dateToName]: to.toISOString(),
      });
    }
  };

  return (
    <StyledDateTimeRangePicker
      locale={locale}
      separator={null}
      placeholder={['от', 'до']}
      showTime={{ format: 'HH:mm' }}
      format="DD-MM-YYYY HH:mm"
      onChange={onChange}
      value={[dateFrom, dateTo]}
      disabled={disabled}
    />
  );
}
