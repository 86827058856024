import { useAppSelector } from 'shared/redux/types';

import {
  selectCompaniesId,
  selectNotCompaniesId,
  selectStatuses,
  selectWithTestCp,
} from 'features/charge-point/filters/store/slice';

import { ChargePointsMetricsCard } from '../charge-points';
import { ChargingSessionsMetricsCard } from '../charging-sessions';
import { ConnectorsMetricsCard } from '../connectors';

export function MetricsCards() {
  const statuses = useAppSelector(selectStatuses);
  const withTestCp = useAppSelector(selectWithTestCp);
  const companiesId = useAppSelector(selectCompaniesId);
  const notCompaniesId = useAppSelector(selectNotCompaniesId);

  const chargePointStatusesParam = statuses.length ? statuses : undefined;
  const withTestCpParam = withTestCp.length ? true : undefined;
  const companiesIdParam = companiesId.length ? companiesId : undefined;
  const notCompaniesIdParam = notCompaniesId.length
    ? notCompaniesId
    : undefined;

  return (
    <>
      <ChargePointsMetricsCard
        chargePointStatuses={chargePointStatusesParam}
        isTest={withTestCpParam}
        companyIds={companiesIdParam}
        notСompanyIds={notCompaniesIdParam}
      />
      <ConnectorsMetricsCard
        chargePointStatuses={chargePointStatusesParam}
        isTest={withTestCpParam}
        companyIds={companiesIdParam}
        notСompanyIds={notCompaniesIdParam}
      />
      <ChargingSessionsMetricsCard
        isTest={withTestCpParam}
        companyIds={companiesIdParam}
        notСompanyIds={notCompaniesIdParam}
      />
    </>
  );
}
