import { ButtonProps } from 'antd/lib/button';

import { UiButton } from '../ui-button';

type Props = {
  disabled?: boolean;
  text?: React.ReactNode;
} & ButtonProps;

export function UiSubmitButton(props: Props) {
  const disabled = Boolean(props.disabled);

  const text = props.text || 'Сохранить';

  return (
    <UiButton {...props} htmlType="submit" type="primary" disabled={disabled}>
      {text}
    </UiButton>
  );
}
