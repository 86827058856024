/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import type {
  AddReserveRequest,
  AddReserveResponseBaseResponse,
  ExtendReserveResponseBaseResponse,
  GetApiReserveV1LocksCountParams,
  GetApiReserveV1LocksParams,
  GetApiReserveV1SessionsCountParams,
  GetApiReserveV1SessionsParams,
  GetLocksResponseArrayBaseResponse,
  GetLocksResponseBaseResponse,
  GetSessionResponseArrayBaseResponse,
  GetSessionResponseBaseResponse,
  Int64BaseResponse,
  PostApiReserveV1ActionExtendReserveIdParams,
  PostApiReserveV1ActionResetReserveIdParams,
  ResetReserveResponseBaseResponse
} from './schemas'
import { customInstance } from '../../../../../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getWebApi = () => {
/**
 * @summary Зарезервировать объект
 */
const postApiReserveV1ActionReserveReserveId = (
    reserveId: string,
    addReserveRequest: AddReserveRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AddReserveResponseBaseResponse>(
      {url: `/Api/Reserve/v1/Action/Reserve/${reserveId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addReserveRequest
    },
      options);
    }
  
/**
 * @summary Сбросить(отменить) резерв обьекта
 */
const postApiReserveV1ActionResetReserveId = (
    reserveId: string,
    params?: PostApiReserveV1ActionResetReserveIdParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ResetReserveResponseBaseResponse>(
      {url: `/Api/Reserve/v1/Action/Reset/${reserveId}`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Увеличить время резерва на временой интервал
 */
const postApiReserveV1ActionExtendReserveId = (
    reserveId: string,
    params: PostApiReserveV1ActionExtendReserveIdParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ExtendReserveResponseBaseResponse>(
      {url: `/Api/Reserve/v1/Action/Extend/${reserveId}`, method: 'POST',
        params
    },
      options);
    }
  
/**
 * @summary Получить резервы(объекты блокировки) резерва
 */
const getApiReserveV1Locks = (
    params?: GetApiReserveV1LocksParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetLocksResponseArrayBaseResponse>(
      {url: `/Api/Reserve/v1/Locks`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить резерв(обьект блокировки) резерва
 */
const getApiReserveV1LocksId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetLocksResponseBaseResponse>(
      {url: `/Api/Reserve/v1/Locks/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Количество зарезервированных объектов
 */
const getApiReserveV1LocksCount = (
    params?: GetApiReserveV1LocksCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Int64BaseResponse>(
      {url: `/Api/Reserve/v1/Locks/Count`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить сессию резерва
 */
const getApiReserveV1SessionsId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSessionResponseBaseResponse>(
      {url: `/Api/Reserve/v1/Sessions/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Получить сессии
 */
const getApiReserveV1Sessions = (
    params?: GetApiReserveV1SessionsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetSessionResponseArrayBaseResponse>(
      {url: `/Api/Reserve/v1/Sessions`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Получить кол-во записей
 */
const getApiReserveV1SessionsCount = (
    params?: GetApiReserveV1SessionsCountParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Int64BaseResponse>(
      {url: `/Api/Reserve/v1/Sessions/Count`, method: 'GET',
        params
    },
      options);
    }
  
return {postApiReserveV1ActionReserveReserveId,postApiReserveV1ActionResetReserveId,postApiReserveV1ActionExtendReserveId,getApiReserveV1Locks,getApiReserveV1LocksId,getApiReserveV1LocksCount,getApiReserveV1SessionsId,getApiReserveV1Sessions,getApiReserveV1SessionsCount}};
export type PostApiReserveV1ActionReserveReserveIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveV1ActionReserveReserveId']>>>
export type PostApiReserveV1ActionResetReserveIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveV1ActionResetReserveId']>>>
export type PostApiReserveV1ActionExtendReserveIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['postApiReserveV1ActionExtendReserveId']>>>
export type GetApiReserveV1LocksResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1Locks']>>>
export type GetApiReserveV1LocksIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1LocksId']>>>
export type GetApiReserveV1LocksCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1LocksCount']>>>
export type GetApiReserveV1SessionsIdResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1SessionsId']>>>
export type GetApiReserveV1SessionsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1Sessions']>>>
export type GetApiReserveV1SessionsCountResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getWebApi>['getApiReserveV1SessionsCount']>>>
