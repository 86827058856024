import { ADD_PREFIX, COMPANIES, SETTINGS } from '../names';

export const COMPANY = ':companyId';

const ROOT = `/${SETTINGS}/${COMPANIES}`;

export const COMPANY_ROUTES = {
  COMPANIES: ROOT,
  COMPANY: `${ROOT}/${COMPANY}`,
  ADD_COMPANY: `${ROOT}/${ADD_PREFIX}`,
};
