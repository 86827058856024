import styled from 'styled-components';

export const StyledKey = styled.p`
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

export const StyledValue = styled.p`
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const StyledContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RowWrapper = styled.div``;
