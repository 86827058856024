import { enhancedApi as api } from '../generated/support';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationV1Support: (endpoint) => {
      endpoint.providesTags = [{ type: 'Supports', id: 'LIST' }];
    },
    getApiInformationV1SupportById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Supports', id: arg },
      ];
    },
    postApiInformationV1Support: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Supports', id: 'LIST' }];
    },
    patchApiInformationV1SupportById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Supports', id: 'LIST' },
        {
          type: 'Supports',
          id: arg.id,
        },
      ];
    },
  },
});

export const {
  useGetApiInformationV1SupportQuery: useGetSupports,
  useGetApiInformationV1SupportByIdQuery: useGetSupport,
  usePostApiInformationV1SupportMutation: useAddSupport,
  usePatchApiInformationV1SupportByIdMutation: useUpdateSupport,
} = enhancedApi;
