import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useGetTariffs } from 'shared/api/services/billing/rtk/enhanced';

import { Form } from './form';
import { ServicesListDto } from 'shared/api/services/billing/rtk/generated';

type Props = {
  serviceList: ServicesListDto;
};

export function FormContainer({ serviceList }: Props) {
  const tariffsQuery = useGetTariffs({});

  if (tariffsQuery.isFetching) {
    return <div>Loading...</div>;
  }

  if (tariffsQuery.error) {
    return <ErrorMessage text={getServerErrorText(tariffsQuery.error)} />;
  }

  if (!tariffsQuery?.data?.data) {
    return <EmptyData />;
  }

  return <Form tariffs={tariffsQuery.data.data} serviceList={serviceList} />;
}
