import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1EventsById: build.query<
      GetApiChargePointV1EventsByIdApiResponse,
      GetApiChargePointV1EventsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/Api/ChargePoint/V1/Events/${queryArg}` }),
    }),
    deleteApiChargePointV1EventsById: build.mutation<
      DeleteApiChargePointV1EventsByIdApiResponse,
      DeleteApiChargePointV1EventsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiChargePointV1Events: build.query<
      GetApiChargePointV1EventsApiResponse,
      GetApiChargePointV1EventsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiChargePointV1EventsCount: build.query<
      GetApiChargePointV1EventsCountApiResponse,
      GetApiChargePointV1EventsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/Count`,
        params: {
          Name: queryArg.name,
          Type: queryArg['type'],
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          TransactionId: queryArg.transactionId,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    postApiChargePointV1EventsDisconnectEvent: build.mutation<
      PostApiChargePointV1EventsDisconnectEventApiResponse,
      PostApiChargePointV1EventsDisconnectEventApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/DisconnectEvent`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiChargePointV1EventsBootNotificationRequest: build.mutation<
      PostApiChargePointV1EventsBootNotificationRequestApiResponse,
      PostApiChargePointV1EventsBootNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/BOOT_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.bootNotificationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsBootNotificationConfirmation: build.mutation<
      PostApiChargePointV1EventsBootNotificationConfirmationApiResponse,
      PostApiChargePointV1EventsBootNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/BOOT_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.bootNotificationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsRemoteStartTransactionRequest: build.mutation<
      PostApiChargePointV1EventsRemoteStartTransactionRequestApiResponse,
      PostApiChargePointV1EventsRemoteStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/REMOTE_START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStartTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsRemoteStartTransactionConfirmation:
      build.mutation<
        PostApiChargePointV1EventsRemoteStartTransactionConfirmationApiResponse,
        PostApiChargePointV1EventsRemoteStartTransactionConfirmationApiArg
      >({
        query: (queryArg) => ({
          url: `/Api/ChargePoint/V1/Events/REMOTE_START_TRANSACTION/CONFIRMATION`,
          method: 'POST',
          body: queryArg.remoteStartTransactionConfirmationRequest,
          headers: {
            IdempotencyKey: queryArg.idempotencyKey,
          },
        }),
      }),
    postApiChargePointV1EventsStartTransactionRequest: build.mutation<
      PostApiChargePointV1EventsStartTransactionRequestApiResponse,
      PostApiChargePointV1EventsStartTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/START_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.startTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsStartTransactionConfirmation: build.mutation<
      PostApiChargePointV1EventsStartTransactionConfirmationApiResponse,
      PostApiChargePointV1EventsStartTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/START_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.startTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsRemoteStopTransactionRequest: build.mutation<
      PostApiChargePointV1EventsRemoteStopTransactionRequestApiResponse,
      PostApiChargePointV1EventsRemoteStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/REMOTE_STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.remoteStopTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsRemoteStopTransactionConfirmation: build.mutation<
      PostApiChargePointV1EventsRemoteStopTransactionConfirmationApiResponse,
      PostApiChargePointV1EventsRemoteStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/REMOTE_STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.remoteStopTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsStopTransactionRequest: build.mutation<
      PostApiChargePointV1EventsStopTransactionRequestApiResponse,
      PostApiChargePointV1EventsStopTransactionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/STOP_TRANSACTION/REQUEST`,
        method: 'POST',
        body: queryArg.stopTransactionRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsStopTransactionConfirmation: build.mutation<
      PostApiChargePointV1EventsStopTransactionConfirmationApiResponse,
      PostApiChargePointV1EventsStopTransactionConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/STOP_TRANSACTION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.stopTransactionConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsStatusNotificationRequest: build.mutation<
      PostApiChargePointV1EventsStatusNotificationRequestApiResponse,
      PostApiChargePointV1EventsStatusNotificationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/STATUS_NOTIFICATION/REQUEST`,
        method: 'POST',
        body: queryArg.statusNotificationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsStatusNotificationConfirmation: build.mutation<
      PostApiChargePointV1EventsStatusNotificationConfirmationApiResponse,
      PostApiChargePointV1EventsStatusNotificationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/STATUS_NOTIFICATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.statusNotificationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsMeterValuesRequest: build.mutation<
      PostApiChargePointV1EventsMeterValuesRequestApiResponse,
      PostApiChargePointV1EventsMeterValuesRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/METER_VALUES/REQUEST`,
        method: 'POST',
        body: queryArg.meterValuesRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsMeterValuesConfirmation: build.mutation<
      PostApiChargePointV1EventsMeterValuesConfirmationApiResponse,
      PostApiChargePointV1EventsMeterValuesConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/METER_VALUES/CONFIRMATION`,
        method: 'POST',
        body: queryArg.meterValuesConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsChangeConfigurationRequest: build.mutation<
      PostApiChargePointV1EventsChangeConfigurationRequestApiResponse,
      PostApiChargePointV1EventsChangeConfigurationRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/CHANGE_CONFIGURATION/REQUEST`,
        method: 'POST',
        body: queryArg.changeConfigurationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsChangeConfigurationConfirmation: build.mutation<
      PostApiChargePointV1EventsChangeConfigurationConfirmationApiResponse,
      PostApiChargePointV1EventsChangeConfigurationConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/CHANGE_CONFIGURATION/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeConfigurationConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsUnlockConnectorRequest: build.mutation<
      PostApiChargePointV1EventsUnlockConnectorRequestApiResponse,
      PostApiChargePointV1EventsUnlockConnectorRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/UNLOCK_CONNECTOR/REQUEST`,
        method: 'POST',
        body: queryArg.unlockConnectorRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsUnlockConnectorConfirmation: build.mutation<
      PostApiChargePointV1EventsUnlockConnectorConfirmationApiResponse,
      PostApiChargePointV1EventsUnlockConnectorConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/UNLOCK_CONNECTOR/CONFIRMATION`,
        method: 'POST',
        body: queryArg.unlockConnectorConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsChangeAvailabilityRequest: build.mutation<
      PostApiChargePointV1EventsChangeAvailabilityRequestApiResponse,
      PostApiChargePointV1EventsChangeAvailabilityRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/CHANGE_AVAILABILITY/REQUEST`,
        method: 'POST',
        body: queryArg.changeAvailablilityRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsChangeAvailabilityConfirmation: build.mutation<
      PostApiChargePointV1EventsChangeAvailabilityConfirmationApiResponse,
      PostApiChargePointV1EventsChangeAvailabilityConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/CHANGE_AVAILABILITY/CONFIRMATION`,
        method: 'POST',
        body: queryArg.changeAvailablilityConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsDataTransferRequest: build.mutation<
      PostApiChargePointV1EventsDataTransferRequestApiResponse,
      PostApiChargePointV1EventsDataTransferRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/DATA_TRANSFER/REQUEST`,
        method: 'POST',
        body: queryArg.dataTransferRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsDataTransferConfirmation: build.mutation<
      PostApiChargePointV1EventsDataTransferConfirmationApiResponse,
      PostApiChargePointV1EventsDataTransferConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/DATA_TRANSFER/CONFIRMATION`,
        method: 'POST',
        body: queryArg.dataTransferConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsResetRequest: build.mutation<
      PostApiChargePointV1EventsResetRequestApiResponse,
      PostApiChargePointV1EventsResetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/RESET/REQUEST`,
        method: 'POST',
        body: queryArg.resetRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
    postApiChargePointV1EventsResetConfirmation: build.mutation<
      PostApiChargePointV1EventsResetConfirmationApiResponse,
      PostApiChargePointV1EventsResetConfirmationApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Events/RESET/CONFIRMATION`,
        method: 'POST',
        body: queryArg.resetConfirmationRequest,
        headers: {
          IdempotencyKey: queryArg.idempotencyKey,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1EventsByIdApiResponse =
  /** status 200 Success */ GetEventVmBaseResponse;
export type GetApiChargePointV1EventsByIdApiArg = /** Идентификтаор */ string;
export type DeleteApiChargePointV1EventsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiChargePointV1EventsByIdApiArg =
  /** Идентификатор */ string;
export type GetApiChargePointV1EventsApiResponse =
  /** status 200 Success */ GetEventVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1EventsApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
};
export type GetApiChargePointV1EventsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointV1EventsCountApiArg = {
  name?: EventName;
  type?: EventType;
  chargePointId?: string;
  connectorId?: string;
  transactionId?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type PostApiChargePointV1EventsDisconnectEventApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsDisconnectEventApiArg =
  DisconnectEventRequest;
export type PostApiChargePointV1EventsBootNotificationRequestApiResponse =
  /** status 200 Success */ BootNotificationEventVmBaseResponse;
export type PostApiChargePointV1EventsBootNotificationRequestApiArg = {
  idempotencyKey?: string;
  bootNotificationRequest: BootNotificationRequest;
};
export type PostApiChargePointV1EventsBootNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsBootNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  bootNotificationConfirmationRequest: BootNotificationConfirmationRequest;
};
export type PostApiChargePointV1EventsRemoteStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsRemoteStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStartTransactionRequest: RemoteStartTransactionRequest;
};
export type PostApiChargePointV1EventsRemoteStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsRemoteStartTransactionConfirmationApiArg =
  {
    idempotencyKey?: string;
    remoteStartTransactionConfirmationRequest: RemoteStartTransactionConfirmationRequest;
  };
export type PostApiChargePointV1EventsStartTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStartTransactionRequestApiArg = {
  idempotencyKey?: string;
  startTransactionRequest: StartTransactionRequest;
};
export type PostApiChargePointV1EventsStartTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStartTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  startTransactionConfirmationRequest: StartTransactionConfirmationRequest;
};
export type PostApiChargePointV1EventsRemoteStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsRemoteStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  remoteStopTransactionRequest: RemoteStopTransactionRequest;
};
export type PostApiChargePointV1EventsRemoteStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsRemoteStopTransactionConfirmationApiArg =
  {
    idempotencyKey?: string;
    remoteStopTransactionConfirmationRequest: RemoteStopTransactionConfirmationRequest;
  };
export type PostApiChargePointV1EventsStopTransactionRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStopTransactionRequestApiArg = {
  idempotencyKey?: string;
  stopTransactionRequest: StopTransactionRequest;
};
export type PostApiChargePointV1EventsStopTransactionConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStopTransactionConfirmationApiArg = {
  idempotencyKey?: string;
  stopTransactionConfirmationRequest: StopTransactionConfirmationRequest;
};
export type PostApiChargePointV1EventsStatusNotificationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStatusNotificationRequestApiArg = {
  idempotencyKey?: string;
  statusNotificationRequest: StatusNotificationRequest;
};
export type PostApiChargePointV1EventsStatusNotificationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsStatusNotificationConfirmationApiArg = {
  idempotencyKey?: string;
  statusNotificationConfirmationRequest: StatusNotificationConfirmationRequest;
};
export type PostApiChargePointV1EventsMeterValuesRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsMeterValuesRequestApiArg = {
  idempotencyKey?: string;
  meterValuesRequest: MeterValuesRequest;
};
export type PostApiChargePointV1EventsMeterValuesConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsMeterValuesConfirmationApiArg = {
  idempotencyKey?: string;
  meterValuesConfirmationRequest: MeterValuesConfirmationRequest;
};
export type PostApiChargePointV1EventsChangeConfigurationRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsChangeConfigurationRequestApiArg = {
  idempotencyKey?: string;
  changeConfigurationRequest: ChangeConfigurationRequest;
};
export type PostApiChargePointV1EventsChangeConfigurationConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsChangeConfigurationConfirmationApiArg = {
  idempotencyKey?: string;
  changeConfigurationConfirmationRequest: ChangeConfigurationConfirmationRequest;
};
export type PostApiChargePointV1EventsUnlockConnectorRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsUnlockConnectorRequestApiArg = {
  idempotencyKey?: string;
  unlockConnectorRequest: UnlockConnectorRequest;
};
export type PostApiChargePointV1EventsUnlockConnectorConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsUnlockConnectorConfirmationApiArg = {
  idempotencyKey?: string;
  unlockConnectorConfirmationRequest: UnlockConnectorConfirmationRequest;
};
export type PostApiChargePointV1EventsChangeAvailabilityRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsChangeAvailabilityRequestApiArg = {
  idempotencyKey?: string;
  changeAvailablilityRequest: ChangeAvailablilityRequest;
};
export type PostApiChargePointV1EventsChangeAvailabilityConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsChangeAvailabilityConfirmationApiArg = {
  idempotencyKey?: string;
  changeAvailablilityConfirmationRequest: ChangeAvailablilityConfirmationRequest;
};
export type PostApiChargePointV1EventsDataTransferRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsDataTransferRequestApiArg = {
  idempotencyKey?: string;
  dataTransferRequest: DataTransferRequest;
};
export type PostApiChargePointV1EventsDataTransferConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsDataTransferConfirmationApiArg = {
  idempotencyKey?: string;
  dataTransferConfirmationRequest: DataTransferConfirmationRequest;
};
export type PostApiChargePointV1EventsResetRequestApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsResetRequestApiArg = {
  idempotencyKey?: string;
  resetRequest: ResetRequest;
};
export type PostApiChargePointV1EventsResetConfirmationApiResponse =
  /** status 200 Success */ BaseResponse;
export type PostApiChargePointV1EventsResetConfirmationApiArg = {
  idempotencyKey?: string;
  resetConfirmationRequest: ResetConfirmationRequest;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type ChargePointProtocol = 'OCPP16' | 'OCPP16J' | 'OCPP20';
export type EventName =
  | 'BOOT_NOTIFICATION'
  | 'REMOTE_START_TRANSACTION'
  | 'START_TRANSACTION'
  | 'REMOTE_STOP_TRANSACTION'
  | 'STOP_TRANSACTION'
  | 'STATUS_NOTIFICATION'
  | 'METER_VALUES'
  | 'RESET'
  | 'CHANGE_CONFIGURATION'
  | 'UNLOCK_CONNECTOR'
  | 'CHANGE_AVAILABILITY'
  | 'DATA_TRANSFER';
export type EventType = 'REQUEST' | 'CONFIRMATION';
export type ConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type RemoteStartStopStatus = 'ACCEPTED' | 'REJECTED';
export type GetEventVm = {
  id: string;
  chargePointProtocol: ChargePointProtocol;
  name: EventName;
  type: EventType;
  chargePointId: string;
  createdDate: string;
  connectorId?: string | null;
  connectorStatus?: ConnectorStatus;
  remoteStartStopStatus?: RemoteStartStopStatus;
  transactionId?: string | null;
  idempotencyKey: string;
  sessionId?: string | null;
  instantPower?: number | null;
  instantCurrent?: number | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
  meterStop?: number | null;
  meterStart?: number | null;
  data?: string | null;
};
export type GetEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetEventVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetEventVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type DisconnectEventRequest = {
  reason: string;
  chargePointId: string;
};
export type BootNotificationEventVm = {
  date: string;
  isBlocked: boolean;
};
export type BootNotificationEventVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: BootNotificationEventVm;
};
export type BootNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type BootNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type RemoteStartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StartTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  innerConnectorId: number;
  meterStart: number;
  data: string;
};
export type StartTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  transactionId: string;
};
export type RemoteStopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  remoteStartStopStatus: RemoteStartStopStatus;
};
export type StopTransactionRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  meterStop: number;
  reason: string;
  transactionId: string;
  instantPower?: number | null;
  chargingPercentage?: number | null;
  countTotalEnergy?: number | null;
};
export type StopTransactionConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type StatusNotificationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  status: ConnectorStatus;
  data: string;
  innerConnectorId: number;
};
export type StatusNotificationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type MeterValuesRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
  innerConnectorId: number;
  transactionId: string;
  instantPower: number;
  instantCurrent: number;
  chargingPercentage: number;
  countTotalEnergy: number;
};
export type MeterValuesConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeConfigurationConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type UnlockConnectorConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ChangeAvailablilityConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type DataTransferConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export type ResetConfirmationRequest = {
  chargePointProtocol: ChargePointProtocol;
  chargePointId: string;
  data: string;
};
export const {
  useGetApiChargePointV1EventsByIdQuery,
  useDeleteApiChargePointV1EventsByIdMutation,
  useGetApiChargePointV1EventsQuery,
  useGetApiChargePointV1EventsCountQuery,
  usePostApiChargePointV1EventsDisconnectEventMutation,
  usePostApiChargePointV1EventsBootNotificationRequestMutation,
  usePostApiChargePointV1EventsBootNotificationConfirmationMutation,
  usePostApiChargePointV1EventsRemoteStartTransactionRequestMutation,
  usePostApiChargePointV1EventsRemoteStartTransactionConfirmationMutation,
  usePostApiChargePointV1EventsStartTransactionRequestMutation,
  usePostApiChargePointV1EventsStartTransactionConfirmationMutation,
  usePostApiChargePointV1EventsRemoteStopTransactionRequestMutation,
  usePostApiChargePointV1EventsRemoteStopTransactionConfirmationMutation,
  usePostApiChargePointV1EventsStopTransactionRequestMutation,
  usePostApiChargePointV1EventsStopTransactionConfirmationMutation,
  usePostApiChargePointV1EventsStatusNotificationRequestMutation,
  usePostApiChargePointV1EventsStatusNotificationConfirmationMutation,
  usePostApiChargePointV1EventsMeterValuesRequestMutation,
  usePostApiChargePointV1EventsMeterValuesConfirmationMutation,
  usePostApiChargePointV1EventsChangeConfigurationRequestMutation,
  usePostApiChargePointV1EventsChangeConfigurationConfirmationMutation,
  usePostApiChargePointV1EventsUnlockConnectorRequestMutation,
  usePostApiChargePointV1EventsUnlockConnectorConfirmationMutation,
  usePostApiChargePointV1EventsChangeAvailabilityRequestMutation,
  usePostApiChargePointV1EventsChangeAvailabilityConfirmationMutation,
  usePostApiChargePointV1EventsDataTransferRequestMutation,
  usePostApiChargePointV1EventsDataTransferConfirmationMutation,
  usePostApiChargePointV1EventsResetRequestMutation,
  usePostApiChargePointV1EventsResetConfirmationMutation,
} = injectedRtkApi;
