import { FiltersContainer, MetricsContainer } from './styles';

import { ShortFilters } from 'features/charge-point/filters';
import { MetricsCards } from 'features/metrics/cards';

import { ChargePointListWidget } from 'widgets/charge-point/list';
import { ConsumedEnergyChart } from 'widgets/charge-point/consumed-energy-chart';

export function ChargePointListPage() {
  return (
    <>
      <FiltersContainer>
        <ShortFilters />
      </FiltersContainer>
      <MetricsContainer>
        <MetricsCards />
        <ConsumedEnergyChart />
      </MetricsContainer>
      <ChargePointListWidget />
    </>
  );
}
