import { GROUPS, RESERVES, ROOT, SESSIONS } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

import { AddUserGroupLink } from '../add-user-group-link';
import { AddUserLink } from '../add-user-link';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Список клиентов</CustomLink>,
    extraContent: <AddUserLink />,
  },
  {
    key: GROUPS,
    label: <CustomLink to={GROUPS}>Список групп</CustomLink>,
    permissions: [{ controller: 'Group', right: 'Write' }],
    extraContent: <AddUserGroupLink />,
  },
  {
    key: SESSIONS,
    label: <CustomLink to={SESSIONS}>Журнал сессий</CustomLink>,
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  },
  // {
  //   key: RESERVES,
  //   label: <CustomLink to={RESERVES}>Журнал брони</CustomLink>,
  //   permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  //   disabled: true,
  // },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
