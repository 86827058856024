import NiceModal from '@ebay/nice-modal-react';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';
import { useAbility } from 'shared/lib/ability/context';

import { Modal } from '../modal';
import { StyledButton } from './styles';

type Props = {
  userId: string;
};

export function AddButton({ userId }: Props) {
  const ability = useAbility();

  // if (!ability.can('Write', 'Car')) {
  //   return null;
  // }

  const onClick = () => {
    NiceModal.show(Modal, { userId });
  };

  return (
    <StyledButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledButton>
  );
}
