import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { GROUP } from 'shared/consts/routes/identity-group';

import { RequireAuth } from 'entities/authorization';

import { IdentityGroupLayout } from 'entities/identity-group/ui/group-layout';
import { IdentityGroupsLayout } from 'entities/identity-group/ui/groups-layout';

import { IdentityGroupsMenu } from 'features/identity-group/menu';
import { IdentityGroupUserListTable } from 'features/identity-group/users-table';
import { IdentityGroupListTable } from 'features/identity-group/table';

import { AddIdentityGroupPage } from 'pages/identity-group/add';
import { EditIdentityGroupPage } from 'pages/identity-group/edit';
import {
  ADD_PREFIX,
  IDENTITY_GROUPS,
  IDENTITY_USERS,
} from 'shared/consts/names';

export const UsersGroupRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={IDENTITY_GROUPS}
      element={<SectionLayout menu={<IdentityGroupsMenu />} />}
    >
      <Route element={<IdentityGroupsLayout />}>
        <Route index element={<IdentityGroupListTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityGroupPage />} />}
        />
      </Route>
      <Route path={GROUP} element={<IdentityGroupLayout />}>
        <Route index element={<EditIdentityGroupPage />} />
        <Route path={IDENTITY_USERS} element={<IdentityGroupUserListTable />} />
      </Route>
    </Route>
  </Route>
);
