import { z } from 'zod';

import {
  useGetUsers,
  useGetUsersCount,
} from 'shared/api/services/user/rtk/enhanced';
import { useTypedQueryParamsCustom } from 'shared/lib/router/use-typed-query-params';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { TableInstance } from './table';
import { getServerErrorText } from 'shared/lib';

const pageQueryParamsSchema = z.object({
  login: z.string().optional().catch(undefined),
  registrationDateFrom: z.string().optional().catch(undefined),
  registrationDateTo: z.string().optional().catch(undefined),
  page: z.string().default('1').pipe(z.coerce.number().min(1)).catch(1),
  limit: z.string().default('20').pipe(z.coerce.number().min(1)).catch(1),
  orderingField: z.string().optional().catch(undefined),
  orderingType: z.enum(['ASC', 'DESC']).optional(), // catch?
});

const usersCountQueryParamsSchema = pageQueryParamsSchema.pick({
  login: true,
  registrationDateFrom: true,
  registrationDateTo: true,
});

type Props = {
  groupId?: string;
};

export function TableContainer({ groupId }: Props) {
  const usersqueryParams = useTypedQueryParamsCustom(pageQueryParamsSchema);
  const usersCountQueryParams = useTypedQueryParamsCustom(
    usersCountQueryParamsSchema
  );

  const {
    data: users,
    error: usersError,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetUsers({
    ...usersqueryParams,
    offset: Math.max(usersqueryParams.page - 1, 0) * usersqueryParams.limit,
    groupId,
  });

  const {
    data: usersCount,
    error: usersCountError,
    isFetching: isUsersCountFetching,
    isLoading: isUsersCountLoading,
  } = useGetUsersCount({ ...usersCountQueryParams, groupId });

  const isLoading = isUsersLoading || isUsersCountLoading;
  const isFetching = isUsersFetching || isUsersCountFetching;

  const apiResponseError = [users, usersCount].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [usersError, usersCountError].find((err) => err !== undefined);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!users?.data || !usersCount || usersCount.data === undefined) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      loading={isFetching}
      data={users?.data || []}
      totalCount={usersCount.data}
    />
  );
}
