import { BillingDto } from 'shared/api/services/billing/rtk/generated';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';

import { OperationCard } from './operation';
import { ServicesCard } from './services';
import { SummaryCard } from './summary';

import { CardTitle, ContentLayout, StyledCard } from './styles';

type Props = {
  billing: BillingDto;
  closeModal: () => void;
};

export function BillingDetails({ billing, closeModal }: Props) {
  const { closedDate, providedServices } = billing;

  return (
    <StyledCard>
      <CardTitle>{`Детализация оплаты от: ${
        closedDate ? showTimeString(closedDate, FORMAT_TO_SECONDS) : ''
      }`}</CardTitle>
      <ContentLayout>
        <OperationCard billing={billing} closeModal={closeModal} />
        <ServicesCard providedServices={providedServices} />
        <SummaryCard billing={billing} />
      </ContentLayout>
    </StyledCard>
  );
}
