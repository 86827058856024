import { GetChargePointsMetricsVm } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { MetricsCardLayout } from '../card-layout';
import { ValueContainer, ValueCount, ValueOf } from './styles';
import Gradient from '../../assets/green.svg';

type Props = {
  loading: boolean;
  metricsData: GetChargePointsMetricsVm;
};

export function MetricsCard({ loading, metricsData }: Props) {
  const { count, of } = metricsData;

  const value = (
    <ValueContainer>
      <ValueCount>{count}</ValueCount>
      <ValueOf>{`/${of}`}</ValueOf>
    </ValueContainer>
  );

  return (
    <MetricsCardLayout
      loading={loading}
      suffix="станций в сети"
      title="Станции"
      gradient={Gradient}
      value={value}
    />
  );
}
