import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiIdentityV1UsersLogin: build.mutation<
      PostApiIdentityV1UsersLoginApiResponse,
      PostApiIdentityV1UsersLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/Login`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiIdentityV1UsersRefreshTokens: build.mutation<
      PostApiIdentityV1UsersRefreshTokensApiResponse,
      PostApiIdentityV1UsersRefreshTokensApiArg
    >({
      query: () => ({
        url: `/Api/Identity/V1/Users/RefreshTokens`,
        method: 'POST',
      }),
    }),
    postApiIdentityV1UsersByLoginLockoutUser: build.mutation<
      PostApiIdentityV1UsersByLoginLockoutUserApiResponse,
      PostApiIdentityV1UsersByLoginLockoutUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}/LockoutUser`,
        method: 'POST',
      }),
    }),
    postApiIdentityV1UsersByLoginUnlockUser: build.mutation<
      PostApiIdentityV1UsersByLoginUnlockUserApiResponse,
      PostApiIdentityV1UsersByLoginUnlockUserApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}/UnlockUser`,
        method: 'POST',
      }),
    }),
    getApiIdentityV1UsersByUserName: build.query<
      GetApiIdentityV1UsersByUserNameApiResponse,
      GetApiIdentityV1UsersByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Users/${queryArg}` }),
    }),
    deleteApiIdentityV1UsersByUserName: build.mutation<
      DeleteApiIdentityV1UsersByUserNameApiResponse,
      DeleteApiIdentityV1UsersByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityV1Users: build.query<
      GetApiIdentityV1UsersApiResponse,
      GetApiIdentityV1UsersApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Users` }),
    }),
    postApiIdentityV1Users: build.mutation<
      PostApiIdentityV1UsersApiResponse,
      PostApiIdentityV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Users: build.mutation<
      PatchApiIdentityV1UsersApiResponse,
      PatchApiIdentityV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Users`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    postApiIdentityV1UserRoles: build.mutation<
      PostApiIdentityV1UserRolesApiResponse,
      PostApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    putApiIdentityV1UserRoles: build.mutation<
      PutApiIdentityV1UserRolesApiResponse,
      PutApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'PUT',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserRoles: build.mutation<
      DeleteApiIdentityV1UserRolesApiResponse,
      DeleteApiIdentityV1UserRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserRoles`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserRolesByUserName: build.query<
      GetApiIdentityV1UserRolesByUserNameApiResponse,
      GetApiIdentityV1UserRolesByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserRoles/${queryArg}` }),
    }),
    postApiIdentityV1UserGroups: build.mutation<
      PostApiIdentityV1UserGroupsApiResponse,
      PostApiIdentityV1UserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserGroups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserGroups: build.mutation<
      DeleteApiIdentityV1UserGroupsApiResponse,
      DeleteApiIdentityV1UserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserGroups`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserClaimsByUserName: build.query<
      GetApiIdentityV1UserClaimsByUserNameApiResponse,
      GetApiIdentityV1UserClaimsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserClaims/${queryArg}` }),
    }),
    postApiIdentityV1UserClaims: build.mutation<
      PostApiIdentityV1UserClaimsApiResponse,
      PostApiIdentityV1UserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1UserClaims: build.mutation<
      DeleteApiIdentityV1UserClaimsApiResponse,
      DeleteApiIdentityV1UserClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/UserClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1RolesByRoleName: build.query<
      GetApiIdentityV1RolesByRoleNameApiResponse,
      GetApiIdentityV1RolesByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Roles/${queryArg}` }),
    }),
    deleteApiIdentityV1RolesByRoleName: build.mutation<
      DeleteApiIdentityV1RolesByRoleNameApiResponse,
      DeleteApiIdentityV1RolesByRoleNameApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiIdentityV1Roles: build.query<
      GetApiIdentityV1RolesApiResponse,
      GetApiIdentityV1RolesApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Roles` }),
    }),
    postApiIdentityV1Roles: build.mutation<
      PostApiIdentityV1RolesApiResponse,
      PostApiIdentityV1RolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Roles: build.mutation<
      PatchApiIdentityV1RolesApiResponse,
      PatchApiIdentityV1RolesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Roles`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getApiIdentityV1RoleClaimsByRoleName: build.query<
      GetApiIdentityV1RoleClaimsByRoleNameApiResponse,
      GetApiIdentityV1RoleClaimsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/RoleClaims/${queryArg}` }),
    }),
    postApiIdentityV1RoleClaims: build.mutation<
      PostApiIdentityV1RoleClaimsApiResponse,
      PostApiIdentityV1RoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1RoleClaims: build.mutation<
      DeleteApiIdentityV1RoleClaimsApiResponse,
      DeleteApiIdentityV1RoleClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/RoleClaims`,
        method: 'DELETE',
        body: queryArg,
      }),
    }),
    getApiIdentityV1UserRightsByUserName: build.query<
      GetApiIdentityV1UserRightsByUserNameApiResponse,
      GetApiIdentityV1UserRightsByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/UserRights/${queryArg}` }),
    }),
    getApiIdentityV1RoleRightsByRoleName: build.query<
      GetApiIdentityV1RoleRightsByRoleNameApiResponse,
      GetApiIdentityV1RoleRightsByRoleNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/RoleRights/${queryArg}` }),
    }),
    postApiIdentityV1SetUserRights: build.mutation<
      PostApiIdentityV1SetUserRightsApiResponse,
      PostApiIdentityV1SetUserRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/SetUserRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    postApiIdentityV1SetRoleRights: build.mutation<
      PostApiIdentityV1SetRoleRightsApiResponse,
      PostApiIdentityV1SetRoleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/SetRoleRights`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiIdentityV1GroupsByGroupName: build.query<
      GetApiIdentityV1GroupsByGroupNameApiResponse,
      GetApiIdentityV1GroupsByGroupNameApiArg
    >({
      query: (queryArg) => ({ url: `/Api/Identity/V1/Groups/${queryArg}` }),
    }),
    getApiIdentityV1Groups: build.query<
      GetApiIdentityV1GroupsApiResponse,
      GetApiIdentityV1GroupsApiArg
    >({
      query: () => ({ url: `/Api/Identity/V1/Groups` }),
    }),
    postApiIdentityV1Groups: build.mutation<
      PostApiIdentityV1GroupsApiResponse,
      PostApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    patchApiIdentityV1Groups: build.mutation<
      PatchApiIdentityV1GroupsApiResponse,
      PatchApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    deleteApiIdentityV1Groups: build.mutation<
      DeleteApiIdentityV1GroupsApiResponse,
      DeleteApiIdentityV1GroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/Groups`,
        method: 'DELETE',
        params: {
          GroupId: queryArg.groupId,
          GroupName: queryArg.groupName,
        },
      }),
    }),
    headApiIdentityV1SystemActionsLogOutAllUsers: build.mutation<
      HeadApiIdentityV1SystemActionsLogOutAllUsersApiResponse,
      HeadApiIdentityV1SystemActionsLogOutAllUsersApiArg
    >({
      query: () => ({
        url: `/Api/Identity/V1/System/Actions/LogOutAllUsers`,
        method: 'HEAD',
      }),
    }),
    postApiIdentityV1SystemActionsGetSicretKey: build.mutation<
      PostApiIdentityV1SystemActionsGetSicretKeyApiResponse,
      PostApiIdentityV1SystemActionsGetSicretKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Identity/V1/System/Actions/GetSicretKey`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiIdentityV1UsersLoginApiResponse =
  /** status 200 OK */ WebApiResponseLoginResponse;
export type PostApiIdentityV1UsersLoginApiArg = WebApiRequestLoginRequest;
export type PostApiIdentityV1UsersRefreshTokensApiResponse =
  /** status 200 OK */ WebApiResponseRefreshTokensResponse;
export type PostApiIdentityV1UsersRefreshTokensApiArg = void;
export type PostApiIdentityV1UsersByLoginLockoutUserApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UsersByLoginLockoutUserApiArg = string;
export type PostApiIdentityV1UsersByLoginUnlockUserApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UsersByLoginUnlockUserApiArg = string;
export type GetApiIdentityV1UsersByUserNameApiResponse =
  /** status 200 OK */ WebApiResponseGetUserResponse;
export type GetApiIdentityV1UsersByUserNameApiArg = string;
export type DeleteApiIdentityV1UsersByUserNameApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1UsersByUserNameApiArg = string;
export type GetApiIdentityV1UsersApiResponse =
  /** status 200 OK */ WebApiResponseGetUsersResponse;
export type GetApiIdentityV1UsersApiArg = void;
export type PostApiIdentityV1UsersApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UsersApiArg = WebApiRequestAddUserRequest;
export type PatchApiIdentityV1UsersApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PatchApiIdentityV1UsersApiArg = WebApiRequestUpdateUserRequest;
export type PostApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UserRolesApiArg = WebApiRequestAddUserRoleRequest;
export type PutApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PutApiIdentityV1UserRolesApiArg =
  WebApiRequestUpdateUserRoleRequest;
export type DeleteApiIdentityV1UserRolesApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1UserRolesApiArg =
  WebApiRequestDeleteUserRoleRequest;
export type GetApiIdentityV1UserRolesByUserNameApiResponse =
  /** status 200 OK */ WebApiResponseGetUserRolesResponse;
export type GetApiIdentityV1UserRolesByUserNameApiArg = string;
export type PostApiIdentityV1UserGroupsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UserGroupsApiArg =
  WebApiRequestAddUserGroupRequest;
export type DeleteApiIdentityV1UserGroupsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1UserGroupsApiArg =
  WebApiRequestDeleteUserGroupRequest;
export type GetApiIdentityV1UserClaimsByUserNameApiResponse =
  /** status 200 OK */ WebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityV1UserClaimsByUserNameApiArg = string;
export type PostApiIdentityV1UserClaimsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1UserClaimsApiArg =
  WebApiRequestAddUserClaimRequest;
export type DeleteApiIdentityV1UserClaimsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1UserClaimsApiArg =
  WebApiRequestDeleteUserClaimRequest;
export type GetApiIdentityV1RolesByRoleNameApiResponse =
  /** status 200 OK */ WebApiResponseGetRoleResponse;
export type GetApiIdentityV1RolesByRoleNameApiArg = string;
export type DeleteApiIdentityV1RolesByRoleNameApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1RolesByRoleNameApiArg = string;
export type GetApiIdentityV1RolesApiResponse =
  /** status 200 OK */ WebApiResponseGetRolesResponse;
export type GetApiIdentityV1RolesApiArg = void;
export type PostApiIdentityV1RolesApiResponse =
  /** status 200 OK */ WebApiResponseAddRoleResponse;
export type PostApiIdentityV1RolesApiArg = WebApiRequestAddRoleRequest;
export type PatchApiIdentityV1RolesApiResponse =
  /** status 200 OK */ WebApiResponseUpdateRoleResponse;
export type PatchApiIdentityV1RolesApiArg = WebApiRequestUpdateRoleRequest;
export type GetApiIdentityV1RoleClaimsByRoleNameApiResponse =
  /** status 200 OK */ WebApiResponseGetRoleClaimsResponse;
export type GetApiIdentityV1RoleClaimsByRoleNameApiArg = string;
export type PostApiIdentityV1RoleClaimsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1RoleClaimsApiArg =
  WebApiRequestAddRoleClaimRequest;
export type DeleteApiIdentityV1RoleClaimsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1RoleClaimsApiArg =
  WebApiRequestDeleteRoleClaimRequest;
export type GetApiIdentityV1UserRightsByUserNameApiResponse =
  /** status 200 OK */ WebApiResponseGetRightsResponse;
export type GetApiIdentityV1UserRightsByUserNameApiArg = string;
export type GetApiIdentityV1RoleRightsByRoleNameApiResponse =
  /** status 200 OK */ WebApiResponseGetRightsResponse;
export type GetApiIdentityV1RoleRightsByRoleNameApiArg = string;
export type PostApiIdentityV1SetUserRightsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1SetUserRightsApiArg =
  WebApiRequestSetUserRightsRequest;
export type PostApiIdentityV1SetRoleRightsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1SetRoleRightsApiArg =
  WebApiRequestSetRoleRightsRequest;
export type GetApiIdentityV1GroupsByGroupNameApiResponse =
  /** status 200 OK */ WebApiResponseGetGroupResponse;
export type GetApiIdentityV1GroupsByGroupNameApiArg = string;
export type GetApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ WebApiResponseGetGroupsResponse;
export type GetApiIdentityV1GroupsApiArg = void;
export type PostApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type PostApiIdentityV1GroupsApiArg = WebApiRequestAddGroupRequest;
export type PatchApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ WebApiResponseUpdateGroupResponse;
export type PatchApiIdentityV1GroupsApiArg = WebApiRequestUpdateGroupRequest;
export type DeleteApiIdentityV1GroupsApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type DeleteApiIdentityV1GroupsApiArg = {
  groupId?: string;
  groupName?: string;
};
export type HeadApiIdentityV1SystemActionsLogOutAllUsersApiResponse =
  /** status 200 OK */ WebApiMiddlewareResponseBase;
export type HeadApiIdentityV1SystemActionsLogOutAllUsersApiArg = void;
export type PostApiIdentityV1SystemActionsGetSicretKeyApiResponse =
  /** status 200 OK */ string;
export type PostApiIdentityV1SystemActionsGetSicretKeyApiArg =
  WebApiRequestLoginRequest;
export type WebApiMiddlewareStatus = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type WebApiMiddlewareUnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type WebApiResponseLoginResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
};
export type WebApiRequestLoginRequest = {
  login: string;
  password: string;
};
export type WebApiResponseRefreshTokensResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
};
export type WebApiMiddlewareResponseBase = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
};
export type ApplicationDtoClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type ApplicationDtoUserDto = {
  id?: string;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  email?: string | null;
  groups?: string[] | null;
  roles?: string[] | null;
  claims?: ApplicationDtoClaimDto[] | null;
  lockoutEnd?: string | null;
  userLockoutEnd?: string | null;
  isSystem?: boolean;
};
export type WebApiResponseGetUserResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  user?: ApplicationDtoUserDto;
};
export type WebApiResponseGetUsersResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  users?: ApplicationDtoUserDto[] | null;
};
export type WebApiRequestAddUserRequest = {
  userName?: string | null;
  email: string;
  password: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type WebApiRequestUpdateUserRequest = {
  id: string;
  userName?: string | null;
  email?: string | null;
  newPassword?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
};
export type WebApiRequestAddUserRoleRequest = {
  userName: string;
  roles: string[];
};
export type WebApiRequestUpdateUserRoleRequest = {
  userName?: string | null;
  roles?: string[] | null;
};
export type WebApiRequestDeleteUserRoleRequest = {
  userName: string;
  roleName: string;
};
export type WebApiDtoRoleDto = {
  id?: string;
  name?: string | null;
};
export type WebApiResponseGetUserRolesResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  roles?: WebApiDtoRoleDto[] | null;
};
export type WebApiRequestAddUserGroupRequest = {
  userName: string;
  groupName: string;
};
export type WebApiRequestDeleteUserGroupRequest = {
  userName: string;
  groupId?: string | null;
  groupName?: string | null;
};
export type WebApiDtoClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type WebApiResponseGetRoleClaimsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  claims?: WebApiDtoClaimDto[] | null;
};
export type WebApiRequestAddUserClaimRequest = {
  userName: string;
  claimType: string;
  claimValue: string;
};
export type WebApiRequestDeleteUserClaimRequest = {
  userName: string;
  claimType: string;
  claimValue: string;
};
export type ApplicationDtoUserReduceInfo = {
  id?: string;
  userName?: string | null;
};
export type ApplicationDtoRoleWithUsers = {
  id?: string;
  name?: string | null;
  users?: ApplicationDtoUserReduceInfo[] | null;
};
export type WebApiResponseGetRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  role?: ApplicationDtoRoleWithUsers;
};
export type WebApiDtoRoleWithUsersDto = {
  id?: string;
  name?: string | null;
  users?: ApplicationDtoUserReduceInfo[] | null;
};
export type WebApiResponseGetRolesResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  roles?: WebApiDtoRoleWithUsersDto[] | null;
};
export type WebApiResponseAddRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
};
export type WebApiRequestAddRoleRequest = {
  name: string;
};
export type ApplicationIdentityEntityRole = {
  id?: string;
  name?: string | null;
  normalizedName?: string | null;
  concurrencyStamp?: string | null;
};
export type WebApiResponseUpdateRoleResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  role?: ApplicationIdentityEntityRole;
};
export type WebApiRequestUpdateRoleRequest = {
  id: string;
  name: string;
};
export type WebApiRequestAddRoleClaimRequest = {
  roleName: string;
  claimType: string;
  claimValue: string;
};
export type WebApiRequestDeleteRoleClaimRequest = {
  roleName: string;
  claimType: string;
  claimValue: string;
};
export type ApplicationModelsControllerRweRight = {
  controllerName?: string | null;
  read?: boolean;
  write?: boolean;
  execute?: boolean;
};
export type WebApiResponseGetRightsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  rights?: ApplicationModelsControllerRweRight[] | null;
};
export type WebApiRequestSetUserRightsRequest = {
  userName: string;
  controllerRights: ApplicationModelsControllerRweRight[];
};
export type WebApiRequestSetRoleRightsRequest = {
  roleName: string;
  controllerRights: ApplicationModelsControllerRweRight[];
};
export type WebApiDtoUserReduceInfoDto = {
  id?: string;
  userName?: string | null;
};
export type WebApiDtoGroupDto = {
  id?: string;
  name?: string | null;
  users?: WebApiDtoUserReduceInfoDto[] | null;
};
export type WebApiResponseGetGroupResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  group?: WebApiDtoGroupDto;
};
export type WebApiResponseGetGroupsResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  groups?: WebApiDtoGroupDto[] | null;
};
export type WebApiRequestAddGroupRequest = {
  name: string;
};
export type ApplicationIdentityEntityUser = {
  id?: string;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  groups?: ApplicationIdentityEntityGroup[] | null;
  userLockoutEnd?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  isSystem?: boolean;
};
export type ApplicationIdentityEntityGroup = {
  id?: string;
  name?: string | null;
  users?: ApplicationIdentityEntityUser[] | null;
};
export type WebApiResponseUpdateGroupResponse = {
  status?: WebApiMiddlewareStatus;
  statusMessage?: string | null;
  unavailables?: WebApiMiddlewareUnavailableServices[] | null;
  group?: ApplicationIdentityEntityGroup;
};
export type WebApiRequestUpdateGroupRequest = {
  id: string;
  name: string;
};
export const {
  usePostApiIdentityV1UsersLoginMutation,
  usePostApiIdentityV1UsersRefreshTokensMutation,
  usePostApiIdentityV1UsersByLoginLockoutUserMutation,
  usePostApiIdentityV1UsersByLoginUnlockUserMutation,
  useGetApiIdentityV1UsersByUserNameQuery,
  useDeleteApiIdentityV1UsersByUserNameMutation,
  useGetApiIdentityV1UsersQuery,
  usePostApiIdentityV1UsersMutation,
  usePatchApiIdentityV1UsersMutation,
  usePostApiIdentityV1UserRolesMutation,
  usePutApiIdentityV1UserRolesMutation,
  useDeleteApiIdentityV1UserRolesMutation,
  useGetApiIdentityV1UserRolesByUserNameQuery,
  usePostApiIdentityV1UserGroupsMutation,
  useDeleteApiIdentityV1UserGroupsMutation,
  useGetApiIdentityV1UserClaimsByUserNameQuery,
  usePostApiIdentityV1UserClaimsMutation,
  useDeleteApiIdentityV1UserClaimsMutation,
  useGetApiIdentityV1RolesByRoleNameQuery,
  useDeleteApiIdentityV1RolesByRoleNameMutation,
  useGetApiIdentityV1RolesQuery,
  usePostApiIdentityV1RolesMutation,
  usePatchApiIdentityV1RolesMutation,
  useGetApiIdentityV1RoleClaimsByRoleNameQuery,
  usePostApiIdentityV1RoleClaimsMutation,
  useDeleteApiIdentityV1RoleClaimsMutation,
  useGetApiIdentityV1UserRightsByUserNameQuery,
  useGetApiIdentityV1RoleRightsByRoleNameQuery,
  usePostApiIdentityV1SetUserRightsMutation,
  usePostApiIdentityV1SetRoleRightsMutation,
  useGetApiIdentityV1GroupsByGroupNameQuery,
  useGetApiIdentityV1GroupsQuery,
  usePostApiIdentityV1GroupsMutation,
  usePatchApiIdentityV1GroupsMutation,
  useDeleteApiIdentityV1GroupsMutation,
  useHeadApiIdentityV1SystemActionsLogOutAllUsersMutation,
  usePostApiIdentityV1SystemActionsGetSicretKeyMutation,
} = injectedRtkApi;
