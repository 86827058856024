import { StyledBody, StyledHeader, StyledLayout } from './styles';

type Props = {
  children: React.ReactNode;
};

export function UiCard({ children }: Props) {
  return <StyledLayout>{children}</StyledLayout>;
}

type HeaderProps = {
  children: React.ReactNode;
};

UiCard.Header = ({ children }: HeaderProps) => {
  return <StyledHeader>{children}</StyledHeader>;
};

type BodyProps = {
  children: React.ReactNode;
};

UiCard.Body = ({ children }: BodyProps) => {
  return <StyledBody>{children}</StyledBody>;
};
