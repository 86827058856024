import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import {
  selectChargePointsTableColumns,
  setTableColumns,
} from 'shared/redux/slices/charge-points/slice';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useAppDispatch } from 'shared/redux/types';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { CHARGE_POINT_STATUS } from 'entities/charge-point';

import { ChargePointTableActions } from 'features/charge-point/table/ui/table-actions';

import { CellContainer, ChargePointStatusBar } from '../hooks/styles';
import { ExclamationMarkIcon } from '../icons/exclamation-mark';
import { CHARGE_POINT_TYPE_ICON } from '../consts';

const columnHelper = createColumnHelper<GetChargePointVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('lastUpdate', {
    id: 'lastUpdate',
    header: 'Дата обновления',
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('group', {
    id: 'groupId',
    header: 'Расположение',
    size: 200,
    minSize: 200,
    maxSize: 200,
    cell: (props) => {
      const { group, status } = props.row.original;

      return (
        <CellContainer>
          {status && (
            <Tooltip title={CHARGE_POINT_STATUS[status]}>
              <ChargePointStatusBar status={status} />
            </Tooltip>
          )}
          {group && (
            <Link to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${group.id}`}>
              {group.name}
            </Link>
          )}
        </CellContainer>
      );
    },
    enableSorting: false,
    meta: {
      filterElement: <InputFilter paramName="groupId" disabled />,
    },
  }),
  columnHelper.accessor('warningText', {
    id: 'warningText',
    header: 'Alert для МП',
    size: 70,
    minSize: 70,
    maxSize: 70,
    cell: (props) => {
      const warningText = props.getValue();

      if (!warningText) {
        return '';
      }

      return (
        <Tooltip title={warningText}>
          <span>
            <ExclamationMarkIcon />
          </span>
        </Tooltip>
      );
    },
    enableSorting: false,
    meta: {
      filterElement: <InputFilter paramName="warningText" disabled />,
    },
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Наименование',
    cell: (props) => {
      const { group, name, id } = props.row.original;

      const groupId = group?.id || null;

      return (
        <Link to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${groupId}/${id}`}>
          {name}
        </Link>
      );
    },
    meta: {
      filterElement: <InputFilter paramName="name" disabled />,
    },
  }),
  columnHelper.accessor('speedType', {
    id: 'speedType',
    header: 'Тип',
    size: 50,
    minSize: 50,
    maxSize: 50,
    cell: (props) => {
      const speedType = props.getValue();

      if (!speedType || speedType === 'UNDEFINED') {
        return '';
      }

      const tooltipText =
        speedType === 'HIGH_SPEED_DC'
          ? 'Быстрая станция (DC)'
          : 'Медленная станция (АС)';

      return (
        <Tooltip title={tooltipText}>
          <span>{CHARGE_POINT_TYPE_ICON[speedType]}</span>
        </Tooltip>
      );
    },
    meta: {
      filterElement: <InputFilter paramName="speedType" disabled />,
    },
  }),
  columnHelper.accessor('manufacturer', {
    id: 'manufacturer',
    header: 'Производитель',
    size: 200,
    minSize: 200,
    maxSize: 200,
    meta: {
      filterElement: <InputFilter paramName="manufacturer" disabled />,
    },
  }),
  columnHelper.accessor('softwareRevision', {
    id: 'softwareRevision',
    header: 'Версия ПО',
    meta: {
      filterElement: <InputFilter paramName="softwareRevision" disabled />,
    },
  }),
  columnHelper.accessor('address', {
    id: 'address',
    header: 'Адрес',
    size: 350,
    minSize: 350,
    maxSize: 350,
    meta: {
      filterElement: <InputFilter paramName="address" disabled />,
    },
  }),
  columnHelper.accessor('owner', {
    id: 'ownerName',
    header: 'Владелец',
    cell: (props) => {
      const owner = props.getValue();

      if (!owner) {
        return '';
      }

      return owner.name;
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
    enableSorting: false,
    meta: {
      filterElement: (
        <InputFilter
          paramName="ownerName" //
          disabled
        />
      ),
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectChargePointsTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      const { name } = props.row.original;

      return <ChargePointTableActions chargePointName={name} />;
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
