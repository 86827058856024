import { z } from 'zod';

import {
  useGetUserRoles,
  useGetIdentityRoles,
} from 'shared/api/services/identity/rtk/enhanced';
import { getServerErrorText } from 'shared/lib';
import { ErrorMessage } from 'shared/ui';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { Form } from './form';

const pageParamsSchema = z.object({
  userName: z.string(),
});

export function FormContainer() {
  const { userName } = useTypedParams(pageParamsSchema);

  const userRolesQuery = useGetUserRoles(userName);
  const rolesQuery = useGetIdentityRoles();

  const isLoading = rolesQuery.isLoading || userRolesQuery.isLoading;

  const apiResponseError = [rolesQuery.data, userRolesQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [rolesQuery.error, userRolesQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage || ''} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <Form
      userName={userName}
      roles={rolesQuery.data?.roles ?? []}
      userRoles={userRolesQuery.data?.roles ?? []}
    />
  );
}
