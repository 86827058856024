import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { useTypedParams } from 'shared/lib/router/use-typed-params';
import { useGetSupport } from 'shared/api/services/information/rtk/enhanced/support';
import { useGetCountries } from 'shared/api/services/information/rtk';

import { Form } from './form';

const pageParamsSchema = z.object({
  supportId: z.string(),
});

export function FormContainer() {
  const { supportId } = useTypedParams(pageParamsSchema);

  const countriesQuery = useGetCountries(undefined);
  const supportQuery = useGetSupport(supportId);

  const isLoading = countriesQuery.isFetching || supportQuery.isFetching;

  const apiResponseError = [countriesQuery.data, supportQuery.data].find(
    (el) => el && el.status === 'ERROR'
  );

  const error = [countriesQuery.error, supportQuery.error].find(
    (err) => err !== undefined
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!countriesQuery?.data?.data || !supportQuery?.data?.data) {
    return <EmptyData />;
  }

  return (
    <Form
      countries={countriesQuery.data.data}
      support={supportQuery.data.data}
    />
  );
}
