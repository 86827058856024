import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useDeleteIdentityRole } from 'shared/api/services/identity/rtk/enhanced';
import { ROLE_ROUTES } from 'shared/consts/routes/identity-role';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  roleName: string;
};

export const DeleteModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteIdentityRole();

  const navigate = useNavigate();

  const { roleName } = props;

  const handleDelete = async () => {
    try {
      const res = await trigger(roleName).unwrap();

      openSuccessNotification(DELETE_SUCCESS);

      navigate(ROLE_ROUTES.ROLES);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить роль?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={handleDelete}
        loading={isLoading}
      />
    </UiModal>
  );
});
