import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiCompanyV1Companies: (endpoint) => {
      endpoint.providesTags = [{ type: 'Companies', id: 'LIST' }];
    },
    getApiCompanyV1CompaniesByCompanyId: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'Companies', id: arg },
      ];
    },
    postApiCompanyV1Companies: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Companies', id: 'LIST' }];
    },
    patchApiCompanyV1CompaniesByCompanyId: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'Companies', id: 'LIST' },
        {
          type: 'Companies',
          id: arg.companyId,
        },
      ];
    },
  },
});

export const {
  useGetApiCompanyV1CompaniesQuery: useGetCompanies,
  useGetApiCompanyV1CompaniesByCompanyIdQuery: useGetCompany,
  usePostApiCompanyV1CompaniesMutation: useAddCompany,
  usePatchApiCompanyV1CompaniesByCompanyIdMutation: useUpdateCompany,
} = enhancedApi;
