import {
  ADD_PREFIX,
  IDENTITY_ROLES,
  CHARGE_POINT_RIGHTS,
  CONTROLLER_CLAIMS,
  SECURITY,
  IDENTITY_USERS,
} from '../names';

export const USER = ':userName';

const ROOT = `/${SECURITY}/${IDENTITY_USERS}`;

export const IDENTITY_USER_ROUTES = {
  USERS: ROOT,
  USER: `${ROOT}/${USER}`,
  CONTROLLER_CLAIMS: `${ROOT}/${USER}/${CONTROLLER_CLAIMS}`,
  CHARGE_POINTS_RIGHTS: `${ROOT}/${USER}/${CHARGE_POINT_RIGHTS}`,
  ROLES_LIST: `${ROOT}/${USER}/${IDENTITY_ROLES}`,
  ADD_USER: `${ROOT}/${ADD_PREFIX}`,
};
