import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { useAbility } from 'shared/lib/ability/context';

import AddPersonIcon from './icon.svg';

export function AddUserLink() {
  const ability = useAbility();

  if (!ability.can('Write', 'Person')) {
    return null;
  }

  return (
    <Link to={USER_ROUTES.ADD_PERSON}>
      <NavigationTabButton title="Добавить клиента" icon={AddPersonIcon} />
    </Link>
  );
}
