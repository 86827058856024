import {
  enhancedApi as api,
  GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiResponse,
  GetApiChargePointV1MetricsGraphSuccessfulSessionsApiResponse,
} from '../generated/metrics';

import { padMetricsData } from 'entities/metrics/lib/pad-metrics-data';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiChargePointV1MetricsGraphAmountOfConsumedEnergy: (endpoint) => {
      endpoint.transformResponse = (
        res: GetApiChargePointV1MetricsGraphAmountOfConsumedEnergyApiResponse,
        meta,
        arg
      ) => {
        const { data } = res;
        const { dateFrom, dateTo } = arg;

        const paddedMetrics = padMetricsData(dateFrom, dateTo, data);

        return {
          ...res,
          data: paddedMetrics,
        };
      };
    },
    getApiChargePointV1MetricsGraphSuccessfulSessions: (endpoint) => {
      endpoint.transformResponse = (
        res: GetApiChargePointV1MetricsGraphSuccessfulSessionsApiResponse,
        meta,
        arg
      ) => {
        const { data } = res;
        const { dateFrom, dateTo } = arg;

        const paddedMetrics = padMetricsData(dateFrom, dateTo, data);

        return {
          ...res,
          data: paddedMetrics,
        };
      };
    },
  },
});

export const {
  useGetApiChargePointV1MetricsChargepointsQuery: useGetChargePointsMetrics,
  useGetApiChargePointV1MetricsConnectorsQuery: useGetConnectorsMetrics,
  useGetApiChargePointV1MetricsSessionChargingQuery:
    useGetChargingSessionsMetrics,
  useGetApiChargePointV1MetricsGraphAmountOfConsumedEnergyQuery:
    useGetGraphComsumedEnergy,
  useGetApiChargePointV1MetricsGraphSuccessfulSessionsQuery:
    useGetGraphSuccessfulSessions,
} = enhancedApi;
