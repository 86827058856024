import { z } from 'zod';

import { postApiPushNotificationTemplatesBody } from 'shared/api/services/pushnotification/orval/zod/schemas';

// export const FormSchema = z.union([
//   z.object({
//     templateId: z.undefined().transform((val, ctx) => {
//       if (val === undefined) {
//         ctx.addIssue({
//           code: z.ZodIssueCode.custom,
//           message: 'Required field',
//         });

//         return z.NEVER;
//       }

//       return val;
//     }),
//   }),
//   z.object({ templateId: z.literal('') }),
//   z
//     .object({ templateId: z.string().min(1) })
//     .merge(postApiPushNotificationTemplatesBody),
// ]);

export const FormSchema = z.union([
  z.object({
    templateId: z
      .string()
      .nullish()
      .transform((val, ctx) => {
        if (val === undefined || val === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required field',
          });

          return z.NEVER;
        }

        return val;
      }),
  }),
  z
    .object({
      templateId: z
        .literal(0)
        .nullish()
        .transform((val, ctx) => {
          if (val === undefined || val === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Required field',
            });

            return z.NEVER;
          }

          return val;
        }),
    })
    .merge(postApiPushNotificationTemplatesBody),
]);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  templateId: undefined,
};
