import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { ApplicationDtoUserDto } from 'shared/api/services/identity/rtk/generated';
import { useUpdateIdentityUser } from 'shared/api/services/identity/rtk';

import { FormInput, FormOutput, FormSchema } from '../../consts/schema';

import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';
import { SectionTitle } from './styles';

type Props = {
  user: ApplicationDtoUserDto;
  closeModal: () => void;
};

export function Form({ user, closeModal }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...user,
    },
  });

  const [trigger, { isLoading }] = useUpdateIdentityUser();

  const handleSubmit = form.handleSubmit(async (data) => {
    const { newPassword, ...rest } = data;

    const req = {
      ...rest,
      id: user.id,
      userName: user.userName,
      ...(newPassword !== '' ? { newPassword } : {}),
    };

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <SectionTitle>Личные данные</SectionTitle>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="firstName" label="Имя" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="lastName" label="Фамилия" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="middleName" label="Отчество" />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput> name="email" label="E-mail" required />
        </Col>
      </FormRow>
      <SectionTitle>Смена пароля</SectionTitle>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormInput>
            name="newPassword"
            label="Новый пароль"
            type="password"
            autoComplete="new-password"
          />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
