import { useAppSelector } from 'shared/redux/types';

import { MetricsCards } from 'features/metrics/cards';
import { MetricsGraphs } from 'features/metrics/graphs';
import { ShortFilters } from 'features/charge-point/filters/ui/short-filters';

// import { ShorthandedFilters } from 'widgets/charge-point/filters';

import { selectShowCharts } from 'pages/monitoring/store';

import {
  StyledContainer,
  FiltersWrapper,
  GridLayout,
  MetricsContainer,
} from './styles';
import { MapLayout } from '../map/layout';

export function PageLayout() {
  const showCharts = useAppSelector(selectShowCharts);

  return (
    <StyledContainer>
      <FiltersWrapper>
        <ShortFilters />
      </FiltersWrapper>
      <GridLayout>
        <MetricsContainer>
          <MetricsCards />
        </MetricsContainer>
        {showCharts ? <MetricsGraphs /> : null}
        <MapLayout isFullWidth={!showCharts} />
      </GridLayout>
    </StyledContainer>
  );
}
