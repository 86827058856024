import { GetConnectorsMetricsVm } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { MetricsCardLayout } from '../card-layout';
import { ValueContainer, ValueCount, ValueOf } from './styles';

import Gradient from '../../assets/red.svg';

type Props = {
  loading: boolean;
  metricsData: GetConnectorsMetricsVm;
};

export function MetricsCard({ loading, metricsData }: Props) {
  const { count, of } = metricsData;

  const value = (
    <ValueContainer>
      <ValueCount>{of && count ? of - count : 0}</ValueCount>
      <ValueOf>{`/${of}`}</ValueOf>
    </ValueContainer>
  );

  return (
    <MetricsCardLayout
      loading={loading}
      suffix="портов недоступно"
      title="Порты"
      gradient={Gradient}
      value={value}
    />
  );
}
