import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { LoginResponse } from 'shared/model';
import {
  clearSessionCredentials,
  setSessionCredentials,
} from 'shared/redux/slices/auth/authSessionSlice';
import {
  clearCredentials,
  setCredentials,
} from 'shared/redux/slices/auth/authSlice';

import { baseQuery } from './base-query';

const mutex = new Mutex();

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const refreshToken =
    (api.getState() as RootState).auth.refreshToken ||
    (api.getState() as RootState).authSession.refreshToken;

  const rememberUser = (api.getState() as RootState).auth.accessToken;

  // console.log('result', result);
  // Мб надо смотреть в result.meta.response.status ?

  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: '/platform/Identity/RefreshTokens',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const { accessToken } = refreshResult.data as LoginResponse;

          const credentials = {
            accessToken,
          };

          rememberUser
            ? api.dispatch(setCredentials(credentials))
            : api.dispatch(setSessionCredentials(credentials));

          result = await baseQuery(args, api, extraOptions);
        } else {
          rememberUser
            ? api.dispatch(clearCredentials())
            : api.dispatch(clearSessionCredentials());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
