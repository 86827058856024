/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Получить бренд
 */
export const getApiInformationV1CarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationV1CarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
      carModels: zod.array(
        zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
      ),
    })
    .optional(),
});

/**
 * @summary Обновить бренд
 */
export const patchApiInformationV1CarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationV1CarBrandsIdBody = zod.object({
  name: zod.string().min(1),
});

export const patchApiInformationV1CarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
      carModels: zod.array(
        zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
      ),
    })
    .optional(),
});

/**
 * @summary Удалить бренд
 */
export const deleteApiInformationV1CarBrandsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationV1CarBrandsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список брендов
 */
export const getApiInformationV1CarBrandsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        logoUrl: zod.string().nullish(),
        objectStorageName: zod.string().nullish(),
        carModels: zod.array(
          zod.object({
            id: zod.string().uuid(),
            name: zod.string().min(1),
          })
        ),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить бренд
 */
export const postApiInformationV1CarBrandsBody = zod.object({
  name: zod.string().min(1),
});

export const postApiInformationV1CarBrandsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().nullish(),
      objectStorageName: zod.string().nullish(),
      carModels: zod.array(
        zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
        })
      ),
    })
    .optional(),
});

/**
 * @summary Добавить или обновить логотип бренда
 */
export const putApiInformationV1CarBrandsLogoCarBrandIdParams = zod.object({
  carBrandId: zod.string().uuid(),
});

export const putApiInformationV1CarBrandsLogoCarBrandIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      logoUrl: zod.string().min(1),
      objectStorageName: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Удалить файл логотипа
 */
export const deleteApiInformationV1CarBrandsLogoCarBrandIdParams = zod.object({
  carBrandId: zod.string().uuid(),
});

export const deleteApiInformationV1CarBrandsLogoCarBrandIdResponse = zod.object(
  {
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  }
);

/**
 * @summary Получить модель
 */
export const getApiInformationV1CarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationV1CarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrand: zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        logoUrl: zod.string().nullish(),
        objectStorageName: zod.string().nullish(),
      }),
    })
    .optional(),
});

/**
 * @summary Обновить модель
 */
export const patchApiInformationV1CarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationV1CarModelsIdBody = zod.object({
  name: zod.string().nullish(),
  carBrandId: zod.string().uuid().nullish(),
});

export const patchApiInformationV1CarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrand: zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        logoUrl: zod.string().nullish(),
        objectStorageName: zod.string().nullish(),
      }),
    })
    .optional(),
});

/**
 * @summary Удалить модель
 */
export const deleteApiInformationV1CarModelsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationV1CarModelsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить модели
 */
export const getApiInformationV1CarModelsQueryParams = zod.object({
  carModels: zod.array(zod.string().uuid()).optional(),
});

export const getApiInformationV1CarModelsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        carBrand: zod.object({
          id: zod.string().uuid(),
          name: zod.string().min(1),
          logoUrl: zod.string().nullish(),
          objectStorageName: zod.string().nullish(),
        }),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить модель
 */
export const postApiInformationV1CarModelsBody = zod.object({
  carBrandId: zod.string().uuid(),
  name: zod.string().min(1),
});

export const postApiInformationV1CarModelsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      carBrand: zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        logoUrl: zod.string().nullish(),
        objectStorageName: zod.string().nullish(),
      }),
    })
    .optional(),
});

/**
 * @summary Получить страну
 */
export const getApiInformationV1CountriesIdParams = zod.object({
  id: zod.string(),
});

export const getApiInformationV1CountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()),
    })
    .optional(),
});

/**
 * @summary Обновить страну
 */
export const patchApiInformationV1CountriesIdParams = zod.object({
  id: zod.string(),
});

export const patchApiInformationV1CountriesIdBody = zod.object({
  name: zod.string().nullish(),
  displayName: zod.string().nullish(),
  currencySymbol: zod.string().nullish(),
  currencyText: zod.string().nullish(),
  description: zod.string().nullish(),
  code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  phonePrefix: zod.string().nullish(),
  urlFlag: zod.string().nullish(),
  language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  phoneMask: zod.string().nullish(),
  privacyPolicyUrl: zod.string().nullish(),
  isDefault: zod.boolean().nullish(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const patchApiInformationV1CountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()),
    })
    .optional(),
});

/**
 * @summary Удалить страну
 */
export const deleteApiInformationV1CountriesIdParams = zod.object({
  id: zod.string(),
});

export const deleteApiInformationV1CountriesIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список стран
 */
export const getApiInformationV1CountriesQueryParams = zod.object({
  mobileAppIds: zod.array(zod.string().uuid()).optional(),
});

export const getApiInformationV1CountriesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().min(1),
        name: zod.string().min(1),
        displayName: zod.string().min(1),
        currencySymbol: zod.string().min(1),
        currencyText: zod.string().min(1),
        description: zod.string().nullish(),
        code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        phonePrefix: zod.string().min(1),
        urlFlag: zod.string().min(1),
        language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        phoneMask: zod.string().min(1),
        privacyPolicyUrl: zod.string().min(1),
        isDefault: zod.boolean(),
        mobileAppIds: zod.array(zod.string().uuid()),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить страну
 */
export const postApiInformationV1CountriesBody = zod.object({
  id: zod.string().min(1),
  name: zod.string().min(1),
  displayName: zod.string().min(1),
  currencySymbol: zod.string().min(1),
  currencyText: zod.string().min(1),
  description: zod.string().min(1),
  code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  phonePrefix: zod.string().min(1),
  urlFlag: zod.string().min(1),
  language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
  phoneMask: zod.string().min(1),
  privacyPolicyUrl: zod.string().min(1),
  isDefault: zod.boolean(),
  mobileAppIds: zod.array(zod.string().uuid()).nullish(),
});

export const postApiInformationV1CountriesResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().min(1),
      name: zod.string().min(1),
      displayName: zod.string().min(1),
      currencySymbol: zod.string().min(1),
      currencyText: zod.string().min(1),
      description: zod.string().nullish(),
      code: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phonePrefix: zod.string().min(1),
      urlFlag: zod.string().min(1),
      language: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      phoneMask: zod.string().min(1),
      privacyPolicyUrl: zod.string().min(1),
      isDefault: zod.boolean(),
      mobileAppIds: zod.array(zod.string().uuid()).nullish(),
    })
    .optional(),
});

/**
 * @summary Получить мобильное приложение
 */
export const getApiInformationV1MobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationV1MobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Обновить существующее мобильное приложение
 */
export const patchApiInformationV1MobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationV1MobileAppsIdBody = zod.object({
  name: zod.string().nullish(),
  description: zod.string().nullish(),
  isDefault: zod.boolean().nullish(),
});

export const patchApiInformationV1MobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Удалить мобильное приложение
 */
export const deleteApiInformationV1MobileAppsIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationV1MobileAppsIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список мобильных приложений
 */
export const getApiInformationV1MobileAppsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        description: zod.string().nullish(),
        isDefault: zod.boolean(),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить мобильное приложение
 */
export const postApiInformationV1MobileAppsBody = zod.object({
  name: zod.string().min(1),
  description: zod.string().min(1),
  isDefault: zod.boolean(),
});

export const postApiInformationV1MobileAppsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      description: zod.string().nullish(),
      isDefault: zod.boolean(),
    })
    .optional(),
});

/**
 * @summary Получить саппорт
 */
export const getApiInformationV1SupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const getApiInformationV1SupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().nullish(),
      countryId: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Обновить саппорта
 */
export const patchApiInformationV1SupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const patchApiInformationV1SupportIdBody = zod.object({
  userAgreementUrl: zod.string().nullish(),
  urlFaq: zod.string().nullish(),
  supportPhone: zod.string().nullish(),
  telegrammBot: zod.string().nullish(),
});

export const patchApiInformationV1SupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().min(1),
      countryId: zod.string().min(1),
    })
    .optional(),
});

/**
 * @summary Удалить саппорт
 */
export const deleteApiInformationV1SupportIdParams = zod.object({
  id: zod.string().uuid(),
});

export const deleteApiInformationV1SupportIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Получить список саппорт
 */
export const getApiInformationV1SupportResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        userAgreementUrl: zod.string().min(1),
        urlFaq: zod.string().min(1),
        supportPhone: zod.string().min(1),
        telegrammBot: zod.string().nullish(),
        countryId: zod.string().min(1),
      })
    )
    .nullish(),
});

/**
 * @summary Добавить саппорта
 */
export const postApiInformationV1SupportBody = zod.object({
  userAgreementUrl: zod.string().min(1),
  urlFaq: zod.string().min(1),
  supportPhone: zod.string().min(1),
  telegrammBot: zod.string().nullish(),
  countryId: zod.string().uuid(),
});

export const postApiInformationV1SupportResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
  data: zod
    .object({
      id: zod.string().uuid(),
      userAgreementUrl: zod.string().min(1),
      urlFaq: zod.string().min(1),
      supportPhone: zod.string().min(1),
      telegrammBot: zod.string().nullish(),
      countryId: zod.string().min(1),
    })
    .optional(),
});
