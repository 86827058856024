import useWindowFocus from 'use-window-focus';

import { useGetChargingSessionsMetrics } from 'shared/api/services/chargepoint/rtk/enhanced/metrics';
import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { GetApiChargePointV1MetricsSessionChargingApiArg } from 'shared/api/services/chargepoint/rtk/generated/metrics';

import { METRICS_POLLING_INTERVAL } from 'entities/charge-point/consts';

import { MetricsCard } from './card';

type Props = GetApiChargePointV1MetricsSessionChargingApiArg;

export function MetricsContainer(props: Props) {
  const isWindowFocused = useWindowFocus();

  const { data, isFetching, error, currentData } =
    useGetChargingSessionsMetrics(
      {
        ...props,
      },
      {
        pollingInterval: isWindowFocused ? METRICS_POLLING_INTERVAL : undefined,
      }
    );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  return (
    <MetricsCard
      loading={isFetching && !currentData}
      metricsData={data?.data || { count: 0 }}
    />
  );
}
