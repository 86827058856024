import { enhancedApi as api } from '../generated/car-models';

const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiInformationV1CarModels: (endpoint) => {
      endpoint.providesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
    getApiInformationV1CarModelsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'CarModels', id: arg },
      ];
    },
    postApiInformationV1CarModels: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
    patchApiInformationV1CarModelsById: (endpoint) => {
      endpoint.invalidatesTags = (result, error, arg) => [
        { type: 'CarModels', id: 'LIST' },
        {
          type: 'CarModels',
          id: arg.id,
        },
      ];
    },
    deleteApiInformationV1CarModelsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'CarModels', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiInformationV1CarModelsQuery: useGetCarModels,
  useGetApiInformationV1CarModelsByIdQuery: useGetCarModel,
  usePostApiInformationV1CarModelsMutation: useAddCarModel,
  usePatchApiInformationV1CarModelsByIdMutation: useUpdateCarModel,
  useDeleteApiInformationV1CarModelsByIdMutation: useDeleteCarModel,
} = enhancedApi;
