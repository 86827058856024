import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';
import {
  useGetChargePointGroups,
  useGetHubs,
} from 'shared/api/services/chargepoint/rtk';
import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { useGetServicesLists } from 'shared/api/services/billing/rtk/enhanced';

import { Form } from './form';

export function FormContainer() {
  const companiesQuery = useGetCompanies(undefined);
  const hubsQuery = useGetHubs({});
  const chargePointGroupsQuery = useGetChargePointGroups({});
  const servicesListsQuery = useGetServicesLists({});

  const isLoading =
    companiesQuery.isLoading ||
    hubsQuery.isLoading ||
    chargePointGroupsQuery.isLoading ||
    servicesListsQuery.isLoading;

  const error = [
    companiesQuery.error,
    hubsQuery.error,
    chargePointGroupsQuery.error,
    servicesListsQuery.error,
  ].find((err) => err !== undefined);

  const apiResponseError = [
    companiesQuery.data,
    hubsQuery.data,
    chargePointGroupsQuery.data,
    servicesListsQuery.data,
  ].find((el) => el && el.status === 'ERROR');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusMessage} />;
  }

  if (
    !hubsQuery?.data?.data ||
    !companiesQuery?.data?.data ||
    !chargePointGroupsQuery?.data?.data ||
    !servicesListsQuery.data?.data
  ) {
    return <EmptyData />;
  }

  return (
    <Form
      groups={chargePointGroupsQuery.data.data}
      hubs={hubsQuery.data.data}
      companies={companiesQuery.data.data}
      servicesLists={servicesListsQuery.data.data}
    />
  );
}
