import { z } from 'zod';

import { CONTROLLERS, RIGHTS } from 'entities/authorization/consts';

export const FormSchema = z.record(
  z.enum(CONTROLLERS),
  z.record(z.enum(RIGHTS), z.boolean())
);

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.infer<typeof FormSchema>;
