/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * OCPI сервис
 * OpenAPI spec version: v1.0.0.0
 */
import type {
  CommonsContractsModelsResponseBase1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm,
  CommonsContractsModelsResponseBase1SystemCollectionsGenericIEnumerable1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm,
  CommonsContractsModelsResponseBase1SystemCollectionsGenericIEnumerable1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm,
  GetApiOcpiOcpi221CdrsParams,
  GetApiOcpiOcpi221CpoLocationsParams,
  GetApiOcpiOcpi221CredentialsTestGetVersionParams,
  GetApiOcpiOcpi221CredentialsTestGetVersionsParams,
  GetApiOcpiOcpi221TariffsParams,
  GetApiOcpiPlatformChargePointsParams,
  GetApiOcpiTestsHubLocationsParams,
  OcpiServiceApplicationOcpi221ModelsCdrGetCdrsResponse,
  OcpiServiceApplicationOcpi221ModelsLocationsGetConnectorResponse,
  OcpiServiceApplicationOcpi221ModelsLocationsGetEvseResponse,
  OcpiServiceApplicationOcpi221ModelsLocationsGetLocationResponse,
  OcpiServiceApplicationOcpi221ModelsLocationsGetLocationsResponse,
  OcpiServiceApplicationOcpi221ModelsOcpiResponseBase,
  OcpiServiceApplicationOcpi221ModelsOcpiResponseBase1SystemString,
  OcpiServiceApplicationOcpi221ModelsTariffsGetTariffsResponse,
  OcpiServiceApplicationOcpi221ModelsVersionsListVersionsResponse,
  OcpiServiceApplicationOcpi221ModelsVersionsVersionResponse,
  OcpiServiceApplicationOcpi221ModelsVersionsVersionsResponse,
  OcpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel,
  OcpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel,
  OcpiServiceApplicationOcpiContractsModelsLocationLocationContractModel,
  PostApiOcpiOcpi221CdrsParams,
  PostApiOcpiOcpi221CredentialsRunRegistrationCredentialsParams,
  PutApiOcpiOcpi221CredentialsUpdateCredentialsParams,
} from './schemas';
import { customInstance } from '../../../../../axios';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getOcpi = () => {
  /**
   * @summary Получить список CDR
   */
  const getApiOcpiOcpi221Cdrs = (
    params?: GetApiOcpiOcpi221CdrsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsCdrGetCdrsResponse>(
      { url: `/Api/Ocpi/ocpi/2.2.1/cdrs`, method: 'GET', params },
      options
    );
  };

  /**
   * @summary Отправка CDR внешнему сервису(хабу)
   */
  const postApiOcpiOcpi221Cdrs = (
    params?: PostApiOcpiOcpi221CdrsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsOcpiResponseBase1SystemString>(
      { url: `/Api/Ocpi/ocpi/2.2.1/cdrs`, method: 'POST', params },
      options
    );
  };

  /**
   * @summary Запускает процесс регистрации и обмена кредами
   */
  const postApiOcpiOcpi221CredentialsRunRegistrationCredentials = (
    params: PostApiOcpiOcpi221CredentialsRunRegistrationCredentialsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<void>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/credentials/RunRegistrationCredentials`,
        method: 'POST',
        params,
      },
      options
    );
  };

  /**
   * @summary Обновить токен доступа для ocpiServer Sitronics
   */
  const putApiOcpiOcpi221CredentialsUpdateCredentials = (
    params: PutApiOcpiOcpi221CredentialsUpdateCredentialsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<void>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/credentials/UpdateCredentials`,
        method: 'PUT',
        params,
      },
      options
    );
  };

  /**
   * @summary Провека установки кредов после регистрации (запросом версий в ocpihub)
   */
  const getApiOcpiOcpi221CredentialsTestGetVersions = (
    params: GetApiOcpiOcpi221CredentialsTestGetVersionsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsVersionsVersionsResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/credentials/TestGetVersions`,
        method: 'GET',
        params,
      },
      options
    );
  };

  /**
   * @summary Провека установки кредов после регистрации (запросом эндпонитов в ocpihub)
   */
  const getApiOcpiOcpi221CredentialsTestGetVersion = (
    params: GetApiOcpiOcpi221CredentialsTestGetVersionParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsVersionsVersionResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/credentials/TestGetVersion`,
        method: 'GET',
        params,
      },
      options
    );
  };

  /**
   * @summary Получить ЭЗС
   */
  const getApiOcpiOcpi221LocationsCountryCodePartyIdLocationId = (
    countryCode: string,
    partyId: string,
    locationId: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetLocationResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}`,
        method: 'GET',
      },
      options
    );
  };

  /**
   * @summary Добавить новую или обновить(целиком) старую ЭЗС
   */
  const putApiOcpiOcpi221LocationsCountryCodePartyIdLocationId = (
    countryCode: string,
    partyId: string,
    locationId: string,
    ocpiServiceApplicationOcpiContractsModelsLocationLocationContractModel: OcpiServiceApplicationOcpiContractsModelsLocationLocationContractModel,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsOcpiResponseBase>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: ocpiServiceApplicationOcpiContractsModelsLocationLocationContractModel,
      },
      options
    );
  };

  const patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationId = (
    countryCode: string,
    partyId: string,
    locationId: string,
    ocpiServiceApplicationOcpiContractsModelsLocationLocationContractModel: OcpiServiceApplicationOcpiContractsModelsLocationLocationContractModel,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsOcpiResponseBase>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: ocpiServiceApplicationOcpiContractsModelsLocationLocationContractModel,
      },
      options
    );
  };

  /**
   * @summary Получить зарядную станцию
   */
  const getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid = (
    countryCode: string,
    partyId: string,
    locationId: string,
    evseUid: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetEvseResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}`,
        method: 'GET',
      },
      options
    );
  };

  /**
   * @summary Добавить новую или обновить(целиком) старую зарядную станцию
   */
  const putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid = (
    countryCode: string,
    partyId: string,
    locationId: string,
    evseUid: string,
    ocpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel: OcpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<void>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: ocpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel,
      },
      options
    );
  };

  const patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid = (
    countryCode: string,
    partyId: string,
    locationId: string,
    evseUid: string,
    ocpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel: OcpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<void>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: ocpiServiceApplicationOcpiContractsModelsEVSEEvseContractModel,
      },
      options
    );
  };

  /**
   * @summary Получить коннектор
   */
  const getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConneTorId =
    (
      countryCode: string,
      partyId: string,
      locationId: string,
      evseUid: string,
      options?: SecondParameter<typeof customInstance>
    ) => {
      return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetConnectorResponse>(
        {
          url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}/conneсtorId}`,
          method: 'GET',
        },
        options
      );
    };

  /**
   * @summary Добавить новый или обновить(целиком) старый коннектор
   */
  const putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId =
    (
      countryCode: string,
      partyId: string,
      locationId: string,
      evseUid: string,
      connetcorId: string,
      ocpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel: OcpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel,
      options?: SecondParameter<typeof customInstance>
    ) => {
      return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetConnectorResponse>(
        {
          url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}/${connetcorId}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          data: ocpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel,
        },
        options
      );
    };

  const patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId =
    (
      countryCode: string,
      partyId: string,
      locationId: string,
      evseUid: string,
      connetcorId: string,
      ocpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel: OcpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel,
      options?: SecondParameter<typeof customInstance>
    ) => {
      return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetConnectorResponse>(
        {
          url: `/Api/Ocpi/ocpi/2.2.1/locations/${countryCode}/${partyId}/${locationId}/${evseUid}/${connetcorId}`,
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          data: ocpiServiceApplicationOcpiContractsModelsEVSEConnectorContractModel,
        },
        options
      );
    };

  /**
   * @summary Получить список всех ЭЗС
   */
  const getApiOcpiOcpi221CpoLocations = (
    params?: GetApiOcpiOcpi221CpoLocationsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetLocationsResponse>(
      { url: `/Api/Ocpi/ocpi/2.2.1/cpo/locations`, method: 'GET', params },
      options
    );
  };

  /**
   * @summary Получить ЭЗС
   */
  const getApiOcpiOcpi221CpoLocationsLocationId = (
    locationId: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetLocationResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/cpo/locations/${locationId}`,
        method: 'GET',
      },
      options
    );
  };

  /**
   * @summary Получить  станцию по UID
   */
  const getApiOcpiOcpi221CpoLocationsLocationIdEvseUid = (
    locationId: string,
    evseUid: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetEvseResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/cpo/locations/${locationId}/${evseUid}`,
        method: 'GET',
      },
      options
    );
  };

  /**
   * @summary Получить коннектор по ID
   */
  const getApiOcpiOcpi221CpoLocationsLocationIdEvseUidConneTorId = (
    locationId: string,
    evseUid: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetConnectorResponse>(
      {
        url: `/Api/Ocpi/ocpi/2.2.1/cpo/locations/${locationId}/${evseUid}/conneсtorId}`,
        method: 'GET',
      },
      options
    );
  };

  const getApiOcpiOcpi221Tariffs = (
    params?: GetApiOcpiOcpi221TariffsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsTariffsGetTariffsResponse>(
      { url: `/Api/Ocpi/ocpi/2.2.1/tariffs`, method: 'GET', params },
      options
    );
  };

  /**
   * @summary Получить список поддерживаемых версий
   */
  const getApiOcpiOcpiVersions = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsVersionsListVersionsResponse>(
      { url: `/Api/Ocpi/ocpi/versions`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Получить список конечных точек для конкретной версии
   */
  const getApiOcpiOcpiVersion = (
    version: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<void>(
      { url: `/Api/Ocpi/ocpi/${version}`, method: 'GET' },
      options
    );
  };

  const getApiOcpiPlatformChargePointsId = (
    id: string,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonsContractsModelsResponseBase1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm>(
      { url: `/Api/Ocpi/Platform/ChargePoints/${id}`, method: 'GET' },
      options
    );
  };

  /**
   * @summary получить зарядные станции OCPI хаба в формате платформы
   */
  const getApiOcpiPlatformChargePoints = (
    params?: GetApiOcpiPlatformChargePointsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonsContractsModelsResponseBase1SystemCollectionsGenericIEnumerable1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm>(
      { url: `/Api/Ocpi/Platform/ChargePoints`, method: 'GET', params },
      options
    );
  };

  /**
   * @summary Получить статусы зарядных станций в формате платформы
   */
  const getApiOcpiPlatformChargePointsCpStatuses = (
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<CommonsContractsModelsResponseBase1SystemCollectionsGenericIEnumerable1ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm>(
      { url: `/Api/Ocpi/Platform/ChargePoints/_cpStatuses`, method: 'GET' },
      options
    );
  };

  /**
   * @summary Выдает зарядные станциииз полученные от хаба в формате OCPI
   */
  const getApiOcpiTestsHubLocations = (
    params?: GetApiOcpiTestsHubLocationsParams,
    options?: SecondParameter<typeof customInstance>
  ) => {
    return customInstance<OcpiServiceApplicationOcpi221ModelsLocationsGetLocationsResponse>(
      { url: `/Api/Ocpi/Tests/HubLocations`, method: 'GET', params },
      options
    );
  };

  return {
    getApiOcpiOcpi221Cdrs,
    postApiOcpiOcpi221Cdrs,
    postApiOcpiOcpi221CredentialsRunRegistrationCredentials,
    putApiOcpiOcpi221CredentialsUpdateCredentials,
    getApiOcpiOcpi221CredentialsTestGetVersions,
    getApiOcpiOcpi221CredentialsTestGetVersion,
    getApiOcpiOcpi221LocationsCountryCodePartyIdLocationId,
    putApiOcpiOcpi221LocationsCountryCodePartyIdLocationId,
    patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationId,
    getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid,
    putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid,
    patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid,
    getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConneTorId,
    putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId,
    patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId,
    getApiOcpiOcpi221CpoLocations,
    getApiOcpiOcpi221CpoLocationsLocationId,
    getApiOcpiOcpi221CpoLocationsLocationIdEvseUid,
    getApiOcpiOcpi221CpoLocationsLocationIdEvseUidConneTorId,
    getApiOcpiOcpi221Tariffs,
    getApiOcpiOcpiVersions,
    getApiOcpiOcpiVersion,
    getApiOcpiPlatformChargePointsId,
    getApiOcpiPlatformChargePoints,
    getApiOcpiPlatformChargePointsCpStatuses,
    getApiOcpiTestsHubLocations,
  };
};
export type GetApiOcpiOcpi221CdrsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['getApiOcpiOcpi221Cdrs']>>
>;
export type PostApiOcpiOcpi221CdrsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['postApiOcpiOcpi221Cdrs']>>
>;
export type PostApiOcpiOcpi221CredentialsRunRegistrationCredentialsResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['postApiOcpiOcpi221CredentialsRunRegistrationCredentials']
      >
    >
  >;
export type PutApiOcpiOcpi221CredentialsUpdateCredentialsResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getOcpi
      >['putApiOcpiOcpi221CredentialsUpdateCredentials']
    >
  >
>;
export type GetApiOcpiOcpi221CredentialsTestGetVersionsResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getOcpi>['getApiOcpiOcpi221CredentialsTestGetVersions']
    >
  >
>;
export type GetApiOcpiOcpi221CredentialsTestGetVersionResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getOcpi>['getApiOcpiOcpi221CredentialsTestGetVersion']
    >
  >
>;
export type GetApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['getApiOcpiOcpi221LocationsCountryCodePartyIdLocationId']
      >
    >
  >;
export type PutApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['putApiOcpiOcpi221LocationsCountryCodePartyIdLocationId']
      >
    >
  >;
export type PatchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationId']
      >
    >
  >;
export type GetApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid']
      >
    >
  >;
export type PutApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid']
      >
    >
  >;
export type PatchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUid']
      >
    >
  >;
export type GetApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConneTorIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['getApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConneTorId']
      >
    >
  >;
export type PutApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['putApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId']
      >
    >
  >;
export type PatchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['patchApiOcpiOcpi221LocationsCountryCodePartyIdLocationIdEvseUidConnetcorId']
      >
    >
  >;
export type GetApiOcpiOcpi221CpoLocationsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getOcpi>['getApiOcpiOcpi221CpoLocations']>
  >
>;
export type GetApiOcpiOcpi221CpoLocationsLocationIdResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getOcpi>['getApiOcpiOcpi221CpoLocationsLocationId']
    >
  >
>;
export type GetApiOcpiOcpi221CpoLocationsLocationIdEvseUidResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getOcpi
      >['getApiOcpiOcpi221CpoLocationsLocationIdEvseUid']
    >
  >
>;
export type GetApiOcpiOcpi221CpoLocationsLocationIdEvseUidConneTorIdResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof getOcpi
        >['getApiOcpiOcpi221CpoLocationsLocationIdEvseUidConneTorId']
      >
    >
  >;
export type GetApiOcpiOcpi221TariffsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['getApiOcpiOcpi221Tariffs']>>
>;
export type GetApiOcpiOcpiVersionsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['getApiOcpiOcpiVersions']>>
>;
export type GetApiOcpiOcpiVersionResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['getApiOcpiOcpiVersion']>>
>;
export type GetApiOcpiPlatformChargePointsIdResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getOcpi>['getApiOcpiPlatformChargePointsId']>
  >
>;
export type GetApiOcpiPlatformChargePointsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getOcpi>['getApiOcpiPlatformChargePoints']>
  >
>;
export type GetApiOcpiPlatformChargePointsCpStatusesResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getOcpi>['getApiOcpiPlatformChargePointsCpStatuses']
    >
  >
>;
export type GetApiOcpiTestsHubLocationsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getOcpi>['getApiOcpiTestsHubLocations']>>
>;
