/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UserService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * @summary Get assembly version.
 */
export const getApiUserAppInfoResponse = zod.string();

/**
 * @summary Get car connector by Id.
 */
export const getApiUserCarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const getApiUserCarConnectorsCarConnectorIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update car connector.
 */
export const patchApiUserCarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const patchApiUserCarConnectorsCarConnectorIdBody = zod.object({
  carId: zod.string().uuid().nullish(),
  type: zod
    .enum([
      'SCHUKO',
      'TYPE2',
      'CCS2',
      'CHADEMO',
      'GBTDC',
      'GBTAC',
      'CCS1',
      'TYPE1',
      'TESLA',
    ])
    .optional(),
  isAdapterNeeded: zod.boolean().nullish(),
});

export const patchApiUserCarConnectorsCarConnectorIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Remove car connector.
 */
export const deleteApiUserCarConnectorsCarConnectorIdParams = zod.object({
  carConnectorId: zod.string().uuid(),
});

export const deleteApiUserCarConnectorsCarConnectorIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get car connectors by filter.
 */
export const getApiUserCarConnectorsQueryParams = zod.object({
  CarId: zod.string().uuid(),
});

export const getApiUserCarConnectorsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        carId: zod.string().uuid(),
        type: zod.enum([
          'SCHUKO',
          'TYPE2',
          'CCS2',
          'CHADEMO',
          'GBTDC',
          'GBTAC',
          'CCS1',
          'TYPE1',
          'TESLA',
        ]),
        isAdapterNeeded: zod.boolean(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Add car connector.
 */
export const postApiUserCarConnectorsBody = zod.object({
  carId: zod.string().uuid(),
  type: zod.enum([
    'SCHUKO',
    'TYPE2',
    'CCS2',
    'CHADEMO',
    'GBTDC',
    'GBTAC',
    'CCS1',
    'TYPE1',
    'TESLA',
  ]),
  isAdapterNeeded: zod.boolean(),
});

export const postApiUserCarConnectorsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      carId: zod.string().uuid(),
      type: zod.enum([
        'SCHUKO',
        'TYPE2',
        'CCS2',
        'CHADEMO',
        'GBTDC',
        'GBTAC',
        'CCS1',
        'TYPE1',
        'TESLA',
      ]),
      isAdapterNeeded: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get car by Id.
 */
export const getApiUserCarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const getApiUserCarsCarIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update car.
 */
export const patchApiUserCarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const patchApiUserCarsCarIdBody = zod.object({
  name: zod.string().nullish(),
  userId: zod.string().uuid().nullish(),
  carModelId: zod.string().uuid().nullish(),
});

export const patchApiUserCarsCarIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete car.
 */
export const deleteApiUserCarsCarIdParams = zod.object({
  carId: zod.string().uuid(),
});

export const deleteApiUserCarsCarIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get cars by filter.
 */
export const getApiUserCarsQueryParams = zod.object({
  UserId: zod.string().uuid(),
});

export const getApiUserCarsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        userId: zod.string().uuid(),
        carModelId: zod.string().uuid(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create car.
 */
export const postApiUserCarsBody = zod.object({
  name: zod.string().min(1),
  userId: zod.string().uuid(),
  carModelId: zod.string().uuid(),
});

export const postApiUserCarsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      userId: zod.string().uuid(),
      carModelId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get favorite charge points ids by filter.
 */
export const getApiUserFavoriteChargePointsQueryParams = zod.object({
  UserId: zod.string().uuid(),
});

export const getApiUserFavoriteChargePointsResponse = zod.object({
  data: zod.array(zod.string().uuid()).nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Add favorite charge point.
 */
export const postApiUserFavoriteChargePointsBody = zod.object({
  chargePointId: zod.string().uuid(),
  userId: zod.string().uuid(),
});

export const postApiUserFavoriteChargePointsResponse = zod.object({
  data: zod
    .object({
      chargePointId: zod.string().uuid(),
      userId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Remove favorite charge point.
 */
export const deleteApiUserFavoriteChargePointsQueryParams = zod.object({
  ChargePointId: zod.string().uuid(),
  UserId: zod.string().uuid(),
});

export const deleteApiUserFavoriteChargePointsResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get group by Id.
 */
export const getApiUserGroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const getApiUserGroupsGroupIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update group.
 */
export const patchApiUserGroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const patchApiUserGroupsGroupIdBody = zod.object({
  name: zod.string().nullish(),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean().nullish(),
});

export const patchApiUserGroupsGroupIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete group.
 */
export const deleteApiUserGroupsGroupIdParams = zod.object({
  groupId: zod.string().uuid(),
});

export const deleteApiUserGroupsGroupIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get groups by filter.
 */
export const getApiUserGroupsQueryParams = zod.object({
  UserId: zod.string().uuid().optional(),
});

export const getApiUserGroupsResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        createdDate: zod.string().datetime(),
        lastUpdate: zod.string().datetime(),
        comment: zod.string().nullish(),
        parentId: zod.string().uuid().nullish(),
        isDefault: zod.boolean(),
        children: zod.array(zod.any()),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create group.
 */
export const postApiUserGroupsBody = zod.object({
  name: zod.string().min(1),
  comment: zod.string().nullish(),
  parentId: zod.string().uuid().nullish(),
  isDefault: zod.boolean(),
});

export const postApiUserGroupsResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      createdDate: zod.string().datetime(),
      lastUpdate: zod.string().datetime(),
      comment: zod.string().nullish(),
      parentId: zod.string().uuid().nullish(),
      isDefault: zod.boolean(),
      children: zod.array(zod.any()),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Send verification code to user.
 */
export const postApiUserLoginCodeBody = zod.object({
  login: zod.string().min(1),
});

export const postApiUserLoginCodeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get authorization tokens.
 */
export const postApiUserLoginBody = zod.object({
  login: zod.string().min(1),
  countryId: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
  code: zod.string().min(1),
});

export const postApiUserLoginResponse = zod.object({
  data: zod
    .object({
      accessToken: zod.string().min(1),
      refreshToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Refresh authorization tokens.
 */
export const postApiUserLoginRefreshTokensResponse = zod.object({
  data: zod
    .object({
      accessToken: zod.string().min(1),
      refreshToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Lock or unlock user.
If need to unlock - set LockDateTo to now.
 */
export const postApiUserLoginLockBody = zod.object({
  userId: zod.string().uuid(),
  lockDateTo: zod.string().datetime(),
});

export const postApiUserLoginLockResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Send verification code to user.
 */
export const postApiUserOldActionLoginCodeBody = zod.object({
  login: zod.string().min(1),
});

export const postApiUserOldActionLoginCodeResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Get session token.
 */
export const postApiUserOldActionLoginBody = zod.object({
  login: zod.string().min(1),
  countryId: zod.string().min(1),
  mobileAppId: zod.string().uuid(),
  code: zod.string().min(1),
});

export const postApiUserOldActionLoginResponse = zod.object({
  data: zod
    .object({
      sessionToken: zod.string().min(1),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @deprecated
 * @summary Get session user by token.
 */
export const postApiUserOldActionGetsessionQueryParams = zod.object({
  sessionToken: zod.string().optional(),
});

export const postApiUserOldActionGetsessionResponse = zod.object({
  data: zod
    .object({
      userId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get user by Id.
 */
export const getApiUserUsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const getApiUserUsersUserIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update user.
 */
export const patchApiUserUsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const patchApiUserUsersUserIdBody = zod.object({
  login: zod.string().nullish(),
  lockoutEndDate: zod.string().datetime().nullish(),
  email: zod.string().nullish(),
  firstName: zod.string().nullish(),
  lastName: zod.string().nullish(),
  middleName: zod.string().nullish(),
  birthDate: zod.string().datetime().nullish(),
  sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
  countryId: zod.string().nullish(),
  iconUrl: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  notesRelatedWithUser: zod.string().nullish(),
  userAgreementAccepted: zod.boolean().nullish(),
  interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  isDebug: zod.boolean().nullish(),
});

export const patchApiUserUsersUserIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete user.
 */
export const deleteApiUserUsersUserIdParams = zod.object({
  userId: zod.string().uuid(),
});

export const deleteApiUserUsersUserIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get users by filter.
 */
export const getApiUserUsersQueryParams = zod.object({
  Login: zod.string().optional(),
  RegistrationDateFrom: zod.string().datetime().optional(),
  RegistrationDateTo: zod.string().datetime().optional(),
  GroupId: zod.string().uuid().optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
  OrderingField: zod.string().optional(),
  OrderingType: zod.enum(['ASC', 'DESC']).optional(),
});

export const getApiUserUsersResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        login: zod.string().min(1),
        lockoutEndDate: zod.string().datetime(),
        email: zod.string().nullish(),
        firstName: zod.string().nullish(),
        lastName: zod.string().nullish(),
        middleName: zod.string().nullish(),
        birthDate: zod.string().datetime().nullish(),
        sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
        registrationDate: zod.string().datetime(),
        countryId: zod.string().min(1),
        iconUrl: zod.string().nullish(),
        groupId: zod.string().uuid().nullish(),
        notesRelatedWithUser: zod.string().nullish(),
        userAgreementAccepted: zod.boolean(),
        interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
        isDebug: zod.boolean(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create user.
 */
export const postApiUserUsersBody = zod.object({
  login: zod.string().min(1),
  lockoutEndDate: zod.string().datetime().nullish(),
  email: zod.string().nullish(),
  firstName: zod.string().nullish(),
  lastName: zod.string().nullish(),
  middleName: zod.string().nullish(),
  birthDate: zod.string().datetime().nullish(),
  sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
  countryId: zod.string().min(1),
  iconUrl: zod.string().nullish(),
  groupId: zod.string().uuid().nullish(),
  notesRelatedWithUser: zod.string().nullish(),
  userAgreementAccepted: zod.boolean(),
  interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']).optional(),
  isDebug: zod.boolean().nullish(),
});

export const postApiUserUsersResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      login: zod.string().min(1),
      lockoutEndDate: zod.string().datetime(),
      email: zod.string().nullish(),
      firstName: zod.string().nullish(),
      lastName: zod.string().nullish(),
      middleName: zod.string().nullish(),
      birthDate: zod.string().datetime().nullish(),
      sex: zod.enum(['UNKNOWN', 'MALE', 'FEMALE']).optional(),
      registrationDate: zod.string().datetime(),
      countryId: zod.string().min(1),
      iconUrl: zod.string().nullish(),
      groupId: zod.string().uuid().nullish(),
      notesRelatedWithUser: zod.string().nullish(),
      userAgreementAccepted: zod.boolean(),
      interfaceLanguage: zod.enum(['RU', 'BY', 'AM', 'KZ', 'KG']),
      isDebug: zod.boolean(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get users count by filter.
 */
export const getApiUserUsersCountQueryParams = zod.object({
  Login: zod.string().optional(),
  RegistrationDateFrom: zod.string().datetime().optional(),
  RegistrationDateTo: zod.string().datetime().optional(),
  GroupId: zod.string().uuid().optional(),
});

export const getApiUserUsersCountResponse = zod.object({
  data: zod.number().optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
