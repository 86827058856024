import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import { TemplateDto } from 'shared/api/services/pushnotification/rtk/generated';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';
import { UiButton } from 'shared/ui/buttons/ui-button';
import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';

import { FormSchema, FormInput, FormOutput } from '../consts/schema';

type Props = {
  template: TemplateDto;
};

export function Form({ template }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...template },
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  return (
    <UiCard>
      <UiCard.Header>{template.name}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <DevTool control={form.control} />
          <Spin spinning={false}>
            <form>
              <Form.Fields isDisabled={isDisabled} />
              <Form.Buttons templateId={template.id} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  isDisabled: boolean;
};

Form.Fields = function Fields({ isDisabled }: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="name"
            label="Название"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomInput<FormInput>
              name="title"
              label="Заголовок"
              required
              disabled={isDisabled}
            />
          </Col>
          <Col span={6}>
            <CustomInput<FormInput>
              name="browserUrl"
              label="URL для браузера"
              required
              disabled={isDisabled}
            />
          </Col>
          <Col span={6}>
            <CustomInput<FormInput>
              name="deepLink"
              label="Deeplink для приложения"
              disabled={isDisabled}
            />
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={6}>
            <CustomTextarea<FormInput>
              name="text"
              label="Текст"
              required
              disabled={isDisabled}
            />
          </Col>
        </FormRow>
      </>
    </>
  );
};

type ButtonsProps = {
  // enableForm: () => void;
  // isDisabled: boolean;
  templateId: string;
};

Form.Buttons = function Buttons({ templateId }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <Link
          to={{
            pathname: PUSH_NOTIFICATION_ROUTES.ADD,
            search: `?templateId=${templateId}`,
          }}
        >
          <UiButton type="primary">Создать копию</UiButton>
        </Link>

        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
