import { BILLINGS, RESERVES, ROOT } from 'shared/consts/names';
import { useAbility } from 'shared/lib/ability/context';
import { CustomLink } from 'shared/ui';
import { TabMenuLayout } from 'shared/ui/tab-menu';
import { MenuItemWithPermissions } from 'shared/ui/tab-menu/types';

const ITEMS: MenuItemWithPermissions[] = [
  {
    key: ROOT,
    label: <CustomLink to="">Журнал сессий</CustomLink>,
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
  },
  {
    key: RESERVES,
    label: <CustomLink to={RESERVES}>Журнал брони</CustomLink>,
    permissions: [{ controller: 'PrivateMethods', right: 'Read' }],
    disabled: true,
  },
  {
    key: BILLINGS,
    label: <CustomLink to={BILLINGS}>Журнал оплат</CustomLink>,
    permissions: [
      {
        controller: 'PrivateMethods',
        right: 'Read',
      },
    ],
  },
  {
    key: 'bindings',
    label: <CustomLink to="bindings">Статус привязки БК</CustomLink>,
  },
  {
    key: 'profile',
    label: <CustomLink to="profile">Профиль пользователя</CustomLink>,
  },
  {
    key: 'transactions',
    label: <CustomLink to="transactions">Бонусные баллы</CustomLink>,
    disabled: true,
  },
];

export function TabMenu() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter(({ permissions }) => {
    if (!permissions) {
      return true;
    }

    return permissions.every((el) => {
      const { controller, right } = el;

      return ability.can(right, controller);
    });
  });

  return <TabMenuLayout items={allowedItems} />;
}
