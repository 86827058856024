import { useAbility } from 'shared/lib/ability/context';

import { SectionNavMenuItem } from 'features/sidebar/types';
import { UsersIcon } from 'features/sidebar/icons/users';
import { OwnersIcon } from 'features/sidebar/icons/owners';
import { TariffsIcon } from 'features/sidebar/icons/tariffs';
import { DiscountsIcon } from 'features/sidebar/icons/discounts';
import { ChargePointsIcon } from 'features/sidebar/icons/charge-points';
import { HubsIcon } from 'features/sidebar/icons/hubs';
import { ReportsIcon } from 'features/sidebar/icons/reports';
import { PushNotificationsIcon } from 'features/sidebar/icons/push-notifications';
import { BonusesIcon } from 'features/sidebar/icons/bonuses';

import { SidebarLayout } from '../layout';
import {
  BILLINGS,
  CHARGE_POINTS,
  DISCOUNTS,
  HUBS,
  OWNERS,
  PUSH_NOTIFICATIONS,
  USERS,
} from 'shared/consts/names';

const ITEMS: SectionNavMenuItem[] = [
  {
    label: 'Биллинг',
    path: BILLINGS,
    icon: <TariffsIcon />,
    permissions: [
      {
        controller: 'ChargeTariff',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Скидки',
    path: DISCOUNTS,
    icon: <DiscountsIcon />,
    permissions: [
      {
        controller: 'Discount',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Список ЭЗС',
    path: CHARGE_POINTS,
    icon: <ChargePointsIcon />,
    permissions: [
      {
        controller: 'ChargePoint',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Зарядные хабы',
    path: HUBS,
    icon: <HubsIcon />,
    permissions: [
      {
        controller: 'Hub',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Клиенты',
    path: USERS,
    icon: <UsersIcon />,
    permissions: [
      {
        controller: 'Person', // TODO
        right: 'Read',
      },
    ],
  },
  {
    label: 'Отчеты',
    path: 'reports',
    icon: <ReportsIcon />,
    permissions: [
      {
        controller: 'Report',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Пуш уведомления',
    path: PUSH_NOTIFICATIONS,
    icon: <PushNotificationsIcon />,
    permissions: [
      {
        controller: 'PushNotification',
        right: 'Read',
      },
    ],
  },
  {
    label: 'Кешбэк и награды',
    path: 'bonuses',
    icon: <BonusesIcon />,
    permissions: [
      {
        controller: 'BonusService',
        right: 'Read',
      },
    ],
  },
];

export function MonitoringSidebar() {
  const ability = useAbility();

  const allowedItems = ITEMS.filter((item) =>
    item.permissions.every((item) => {
      const { controller, right } = item;

      return ability.can(right, controller);
    })
  );

  return <SidebarLayout items={allowedItems} />;
}
