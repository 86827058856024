import { Spin } from 'antd';

import {
  Gradient,
  StyledCard,
  MetricsTitle,
  MetricsValue,
  MetricsSuffix,
} from './styles';

type Props = {
  title: React.ReactNode;
  value: React.ReactNode;
  suffix: string;
  gradient: string;
  loading: boolean;
};

export function MetricsCardLayout({
  loading,
  title,
  value,
  suffix,
  gradient,
}: Props) {
  return (
    <StyledCard>
      <Gradient src={gradient} />
      <MetricsTitle>{title}</MetricsTitle>
      <Spin spinning={loading}>
        <MetricsValue>{value}</MetricsValue>
        <MetricsSuffix>{suffix}</MetricsSuffix>
      </Spin>
    </StyledCard>
  );
}
