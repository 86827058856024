import { useMemo } from 'react';

import { useGetChargePointsStatuses } from 'shared/api/services/chargepoint/rtk/enhanced/charge-points';
import { GetStatusesVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { useGetOcpiCpStatuses } from 'shared/api/services/ocpi/rtk/enhanced';
import { useGetReserveLocks } from 'shared/api/services/reserve/rtk/enhanced';
import { getServerErrorText } from 'shared/lib';

export type ChargePointMapData = GetStatusesVm & { isOcpi?: boolean };

type ChargePointsMapping = {
  [key: string]: ChargePointMapData;
};

export function useChargePointsMapData() {
  const locksQuery = useGetReserveLocks({ isActive: true });
  const cpsQuery = useGetChargePointsStatuses({});
  const ocpiQuery = useGetOcpiCpStatuses();

  const errors = useMemo(() => {
    const ERRORS: string[] = [];

    [cpsQuery.error, locksQuery.error, ocpiQuery.error].forEach((el) => {
      if (el) {
        ERRORS.push(getServerErrorText(el));
      }
    });

    [cpsQuery.data, locksQuery.data, ocpiQuery.data].forEach((el) => {
      if (el?.status === 'ERROR') {
        ERRORS.push(el.statusMessage ?? 'Неизвестная ошибка');
      }
    });

    return ERRORS;
  }, [
    cpsQuery.error,
    cpsQuery.data,
    locksQuery.error,
    locksQuery.data,
    ocpiQuery.error,
    ocpiQuery.data,
  ]);

  // const unavailables = useMemo(() => {}, [
  //   cpsQuery.data?.unavailables,
  //   locksQuery.data?.unavailables,
  //   ocpiQuery.data?.unavailables,
  // ]);

  const data = useMemo(() => {
    const CPS_MAPPING: ChargePointsMapping = {};
    const CONNECTOR_CP_MAPPING: { [key: string]: string } = {};

    if (cpsQuery.data) cpsQuery.data?.unavailables;

    if (cpsQuery.data?.data) {
      cpsQuery.data.data.map((cp) => {
        const { connectors, chargePointId } = cp;

        connectors?.map((connector) => {
          const { id } = connector;

          CONNECTOR_CP_MAPPING[id] = chargePointId;
        });

        CPS_MAPPING[chargePointId] = {
          ...cp,
        };
      });
    }

    if (locksQuery.data?.data) {
      locksQuery.data.data.map((lock) => {
        const { reserveId, reserveName } = lock;

        if (reserveName === 'CHARGE_POINT') {
          CPS_MAPPING[reserveId] = {
            ...CPS_MAPPING[reserveId],
            connectors: CPS_MAPPING[reserveId]?.connectors?.map((el) => ({
              ...el,
              status: 'RESERVED',
            })),
          };
        } else if (reserveName === 'CONNECTOR') {
          const chargePointId = CONNECTOR_CP_MAPPING[reserveId];

          if (!chargePointId) {
            return;
          }

          CPS_MAPPING[chargePointId] = {
            ...CPS_MAPPING[chargePointId],
            connectors: CPS_MAPPING[chargePointId]?.connectors?.map((el) => {
              if (el.id === reserveId) {
                return { ...el, status: 'RESERVED' };
              }

              return el;
            }),
          };
        }
      });
    }

    if (ocpiQuery.data?.data) {
      ocpiQuery.data.data.map((cp) => {
        const { connectors, chargePointId } = cp;

        connectors?.map((connector) => {
          const { id } = connector;

          CONNECTOR_CP_MAPPING[id] = chargePointId;
        });

        CPS_MAPPING[chargePointId] = {
          ...cp,
          isOcpi: true,
        };
      });
    }

    return Object.values(CPS_MAPPING);
  }, [cpsQuery.data?.data, ocpiQuery.data?.data, locksQuery.data?.data]);

  return { chargePoints: data, errors };
}
