import { useParams } from 'react-router-dom';

export function ChargePointCommandsPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  return null;

  // return <ConnectedCommandsTable chargePointName={chargePointName} />;
}
