import dayjs from 'dayjs';
import { useState } from 'react';

import { useGetGraphSuccessfulSessions } from 'shared/api/services/chargepoint/rtk/enhanced/metrics';
import { GetApiChargePointV1MetricsGraphSuccessfulSessionsApiArg } from 'shared/api/services/chargepoint/rtk/generated/metrics';
import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, RangePickerProps } from 'shared/ui';

import { MetricsGraph } from './graph';

type Props = GetApiChargePointV1MetricsGraphSuccessfulSessionsApiArg;

export function MetricsContainer({ companyIds, notСompanyIds, isTest }: Props) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(
    dayjs().subtract(3, 'months')
  );
  const [dateTo, setDateTo] = useState<dayjs.Dayjs>(dayjs());

  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const onChange = (values: RangePickerProps['value']) => {
    if (values) {
      setDateFrom(dayjs(values[0]));
      setDateTo(dayjs(values[1]));
    }
  };

  const { isFetching, data, error } = useGetGraphSuccessfulSessions(
    {
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      groupByPeriod: 'DAY',
      companyIds,
      notСompanyIds,
      isTest,
    },
    { skip: isOpen }
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.status === 'ERROR') {
    return <ErrorMessage text={data.statusMessage} />;
  }

  return (
    <MetricsGraph
      dateFrom={dateFrom}
      dateTo={dateTo}
      loading={isFetching}
      onChange={onChange}
      onOpenChange={onOpenChange}
      data={data?.data || []}
    />
  );
}
