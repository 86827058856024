import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { TEMPLATE } from 'shared/consts/routes/push-notification';
import { ADD_PREFIX, PUSH_NOTIFICATIONS } from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';

import { TemplatesMenu } from 'features/push-notification/menu';
import { BatchListPage } from 'pages/push-notification/batch-list';
import { BatchesLayout } from 'entities/push-notification/ui/batches-layout';
import { AddBatchForm } from 'features/push-notification/add-batch';
import { EditTemplatePage } from 'pages/push-notification/edit-template';

export const PushNotificationRoutes = (
  <Route
    element={<RequireAuth controllerName="PushNotification" rightType="Read" />}
  >
    <Route
      path={PUSH_NOTIFICATIONS}
      element={<SectionLayout menu={<TemplatesMenu />} />}
    >
      <Route element={<BatchesLayout />}>
        <Route index element={<BatchListPage />} />
      </Route>
      <Route
        element={
          <RequireAuth controllerName="PushNotification" rightType="Write" />
        }
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddBatchForm />} />}
        />
      </Route>
      <Route
        path={TEMPLATE}
        element={<MainContentLayout element={<EditTemplatePage />} />}
      />
    </Route>
  </Route>
);
