import { z } from 'zod';

import { postApiIdentityV1UsersLoginBody } from 'shared/api/services/identity/orval/zod/schemas';

export const FormSchema = postApiIdentityV1UsersLoginBody.extend({
  rememberMe: z.boolean(),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  login: '',
  password: '',
  rememberMe: false,
};
