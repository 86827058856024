import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 8px;
  background: #fff;

  box-shadow: 0px 0px 14px 0px rgba(24, 30, 28, 0.15);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  grid-area: summary;
`;

export const Section = styled.div``;

export const SectionTitle = styled.h3`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 16px;
`;

export const SectionContent = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > *:not(:last-child) {
    border-bottom: 1px solid #c3c3c3;
  }

  & > * {
    padding-block: 8px;
  }

  & > *:first-child {
    padding-bottom: 12px;
  }

  & > *:last-child {
    padding-top: 12px;
  }
`;

export const SectionContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryFooter = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const SummaryPayment = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
