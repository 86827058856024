import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiChargePointV1SessionsById: build.query<
      GetApiChargePointV1SessionsByIdApiResponse,
      GetApiChargePointV1SessionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Sessions/${queryArg}`,
      }),
    }),
    getApiChargePointV1Sessions: build.query<
      GetApiChargePointV1SessionsApiResponse,
      GetApiChargePointV1SessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Sessions`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          CompanyId: queryArg.companyId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          ChargePointGroupId: queryArg.chargePointGroupId,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
          SearchField: queryArg.searchField,
          SearchText: queryArg.searchText,
          MeterStart: queryArg.meterStart,
          MeterStop: queryArg.meterStop,
          Energy: queryArg.energy,
          InstantPower: queryArg.instantPower,
          InstantCurrent: queryArg.instantCurrent,
          StartChargingPercentage: queryArg.startChargingPercentage,
          ChargingPercentage: queryArg.chargingPercentage,
          MaxPower: queryArg.maxPower,
          MaxCurrent: queryArg.maxCurrent,
          StopReason: queryArg.stopReason,
          CreatedDate: queryArg.createdDate,
          ClosedDate: queryArg.closedDate,
          LastUpdate: queryArg.lastUpdate,
        },
      }),
    }),
    getApiChargePointV1SessionsCount: build.query<
      GetApiChargePointV1SessionsCountApiResponse,
      GetApiChargePointV1SessionsCountApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Sessions/Count`,
        params: {
          ChargePointId: queryArg.chargePointId,
          ConnectorId: queryArg.connectorId,
          UserId: queryArg.userId,
          Status: queryArg.status,
          TransactionId: queryArg.transactionId,
          DateCreateFrom: queryArg.dateCreateFrom,
          DateCreateTo: queryArg.dateCreateTo,
          EndDateFrom: queryArg.endDateFrom,
          EndDateTo: queryArg.endDateTo,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
        },
      }),
    }),
    getApiChargePointV1SessionsByIdGraphMeterValues: build.query<
      GetApiChargePointV1SessionsByIdGraphMeterValuesApiResponse,
      GetApiChargePointV1SessionsByIdGraphMeterValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/ChargePoint/V1/Sessions/${queryArg.id}/_graphMeterValues`,
        params: {
          DateFrom: queryArg.dateFrom,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiChargePointV1SessionsByIdApiResponse =
  /** status 200 Success */ GetSessionVmBaseResponse;
export type GetApiChargePointV1SessionsByIdApiArg = /** Идентификатор */ string;
export type GetApiChargePointV1SessionsApiResponse =
  /** status 200 Success */ GetSessionVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1SessionsApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  companyId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
  chargePointGroupId?: string;
  orderingField?: string;
  orderingType?: OrderBy;
  offset?: number;
  limit?: number;
  searchField?: string;
  searchText?: string;
  meterStart?: number;
  meterStop?: number;
  energy?: number;
  instantPower?: number;
  instantCurrent?: number;
  startChargingPercentage?: number;
  chargingPercentage?: number;
  maxPower?: number;
  maxCurrent?: number;
  stopReason?: string;
  createdDate?: string;
  closedDate?: string;
  lastUpdate?: string;
};
export type GetApiChargePointV1SessionsCountApiResponse =
  /** status 200 Success */ Int64BaseResponse;
export type GetApiChargePointV1SessionsCountApiArg = {
  chargePointId?: string;
  connectorId?: string;
  userId?: string;
  status?: SessionStatus;
  transactionId?: string;
  dateCreateFrom?: string;
  dateCreateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  dateFrom?: string;
  dateTo?: string;
};
export type GetApiChargePointV1SessionsByIdGraphMeterValuesApiResponse =
  /** status 200 Success */ GetGraphMeterVmIReadOnlyCollectionBaseResponse;
export type GetApiChargePointV1SessionsByIdGraphMeterValuesApiArg = {
  id: string;
  /** Временной промежуток ОТ */
  dateFrom?: string;
};
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetSessionVmChargePointVm = {
  id: string;
  name: string;
  groupId?: string | null;
  companyId?: string | null;
};
export type ConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type GetSessionVmConnector = {
  id: string;
  liter: string;
  innerConnectorId: number;
  type: ConnectorType;
};
export type SessionStatus =
  | 'NEW'
  | 'PREPARING'
  | 'CHARGING'
  | 'COMPLETED_WITH_SUCCESS'
  | 'COMPLETED_WITH_ERROR';
export type GetSessionVm = {
  id: string;
  chargePoint: GetSessionVmChargePointVm;
  connector: GetSessionVmConnector;
  userId: string;
  status: SessionStatus;
  meterStart: number;
  meterStop?: number | null;
  energy: number;
  instantPower: number;
  instantCurrent: number;
  startChargingPercentage?: number | null;
  chargingPercentage?: number | null;
  maxPower: number;
  maxCurrent: number;
  stopReason?: string | null;
  transactionId: string;
  createdDate: string;
  closedDate: string;
  lastUpdate: string;
};
export type GetSessionVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm;
};
export type GetSessionVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetSessionVm[] | null;
};
export type OrderBy = 'Asc' | 'Desc';
export type Int64BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: number;
};
export type GetGraphMeterVm = {
  date: string;
  power: number;
  chargingPercentage: number;
  energy: number;
};
export type GetGraphMeterVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetGraphMeterVm[] | null;
};
export const {
  useGetApiChargePointV1SessionsByIdQuery,
  useGetApiChargePointV1SessionsQuery,
  useGetApiChargePointV1SessionsCountQuery,
  useGetApiChargePointV1SessionsByIdGraphMeterValuesQuery,
} = injectedRtkApi;
