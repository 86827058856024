import styled from 'styled-components';

export const GraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-row: 2;
  grid-column: 1/2;

  & > * {
    flex: 1;
  }
`;
