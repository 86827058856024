import { Row } from 'antd';
import styled from 'styled-components';

export const FormControlsContainer = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const RequiredLabel = styled.p`
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    // font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

//

export const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > input {
    width: 100%;
  }
`;

export const FormItemLabel = styled.label<{ required?: boolean }>`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;

  min-width: max-content;

  &::before {
    display: ${(props) => (props.required ? 'inline-block' : 'none')};
    margin-right: 4px;
    color: #ff4d4f;
    // font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  //gap: 32px;
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const FieldErrorContainer = styled.div`
  height: 18px;
  color: #fe4d4d;
`;

export const FormRow = styled(Row)`
  margin-bottom: 15px;
`;
