import { pickFromObject } from 'shared/lib';

import { COMMAND_TYPE } from 'entities/command';

export const AVAILABLE_COMMAND_TYPE = pickFromObject(
  COMMAND_TYPE,
  'DIAGNOSTICS',
  'CONFIGURE',
  'LOAD_FIRMWARE',
  'RESTART'
);
