import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const AdminBadge = styled.div`
  padding: 10px;
  background-color: ${COLOR.white};
  display: inline-block;
  margin-bottom: 15px;
`;

export const TableContainer = styled.div`
  border-radius: 8px;
  overflow: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  background-color: rgb(255, 255, 255);

  & thead {
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgb(217, 217, 217);

    & tr th {
      padding: 20px 10px;
      text-align: left;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }

  & tbody {
    & tr td {
      padding: 10px 10px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }

  & tbody tr td div {
    & label {
      background-color: transparent !important;
    }

    & div {
      display: none;
    }
  }

  & tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const CheckboxContainer = styled.span<{ isRoleRight: boolean }>`
  display: inline-block;
  padding: 4px;
  border: ${(props) =>
    props.isRoleRight ? `3px solid ${COLOR.greenMenu}` : 'none'};
  border-radius: 4px;
`;
