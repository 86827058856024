import { useState } from 'react';
import { HighlightedText } from 'shared/ui/styles';

import { ChargePointPopoverContent } from './content';
import { StyledPopover } from './styles';

type Props = {
  sessionId?: string;
};

export function ChargePointPopover({ sessionId }: Props) {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <StyledPopover
      content={
        <ChargePointPopoverContent visible={visible} sessionId={sessionId} />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayInnerStyle={{
        borderRadius: '6px',
        background: 'rgba(0, 0, 0, 0.70)',
        backdropFilter: 'blur(6px)',
      }}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <HighlightedText>Info</HighlightedText>
    </StyledPopover>
  );
}
