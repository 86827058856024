import {
  ADD_PREFIX,
  CHARGE_POINT_RIGHTS,
  CONTROLLER_CLAIMS,
  SECURITY,
  IDENTITY_USERS,
  IDENTITY_ROLES,
} from '../names';

export const ROLE = ':roleName';

const ROOT = `/${SECURITY}/${IDENTITY_ROLES}`;

export const ROLE_ROUTES = {
  ROLES: ROOT,
  ROLE: `${ROOT}/${ROLE}`,
  CONTROLLER_CLAIMS: `${ROOT}/${ROLE}/${CONTROLLER_CLAIMS}`,
  CHARGE_POINTS_RIGHTS: `${ROOT}/${ROLE}/${CHARGE_POINT_RIGHTS}`,
  USERS_LIST: `${ROOT}/${ROLE}/${IDENTITY_USERS}`,
  ADD_ROLE: `${ROOT}/${ADD_PREFIX}`,
};
