import { useMemo } from 'react';

import { useGetCompanies } from 'shared/api/services/company/rtk/enhanced';
import { CompanyDto } from 'shared/api/services/company/rtk/generated';
import { getServerErrorText } from 'shared/lib';

export function useCompaniesList() {
  const { data, isLoading, error } = useGetCompanies(undefined);

  let errorMessage: string | null = null;

  const emptyData: {
    ownCompanies: CompanyDto[];
    agentCompanies: CompanyDto[];
  } = {
    ownCompanies: [],
    agentCompanies: [],
  };

  const { agentCompanies, ownCompanies } = useMemo(() => {
    if (!data?.data) {
      return emptyData;
    }

    const res = data.data.reduce((acc, el) => {
      if (el.isDefault) {
        acc.ownCompanies = [...acc.ownCompanies, el];
      } else {
        acc.agentCompanies = [...acc.agentCompanies, el];
      }

      return acc;
    }, emptyData);

    return res;
  }, [data]);

  if (error) {
    errorMessage = getServerErrorText(error);
  } else if (data?.status === 'ERROR') {
    errorMessage = data.statusMessage ?? '';
  }

  return {
    isLoading,
    error: errorMessage,
    ownCompanies,
    agentCompanies,
  };
}
