import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';
import { YMaps } from '@pbe/react-yandex-maps';
import NiceModal from '@ebay/nice-modal-react';

import 'antd/dist/antd.css';
import { store, persistor } from './shared/redux/store';
import { GlobalStyles } from './styles';

import { AppRouter } from 'app/router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalStyles />
          <ConfigProvider locale={ru_RU}>
            <YMaps>
              <NiceModal.Provider>
                <AppRouter />
              </NiceModal.Provider>
            </YMaps>
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
