import { CarDto } from 'shared/api/services/user/rtk/generated';
import { UiDivider } from 'shared/ui/ui-kit';
import { GetCarModelVm } from 'shared/api/services/information/rtk/generated/car-models';

import { AddCarButton } from 'features/car/add';

import { CarCard } from './card';

import {
  StyledCardsContainer,
  NoCarsText,
  StyledContainer,
  StyledHeader,
  StyledTitle,
} from './styles';

type Props = {
  cars: CarDto[];
  userId: string;
  carModels: GetCarModelVm[];
};

export function CarList({ cars, userId, carModels }: Props) {
  let content: JSX.Element;

  if (!cars.length) {
    content = <NoCarsText>Нет электромобилей</NoCarsText>;
  } else {
    content = (
      <StyledCardsContainer>
        {cars.map((car) => (
          <CarCard car={car} key={car.id} carModels={carModels} />
        ))}
      </StyledCardsContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>{`Электромобиль(-и)`}</StyledTitle>
        <AddCarButton userId={userId} />
      </StyledHeader>
      {content}
      <UiDivider />
    </StyledContainer>
  );
}
