import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

export const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: `${__API_BASE__}`,
    // credentials: 'include', // если есть кука - цепляем её
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.accessToken ||
        (getState() as RootState).authSession.accessToken;

      if (token && !headers.has('Authorization')) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    paramsSerializer: (params: Record<string, unknown>) =>
      queryString.stringify(params, { skipEmptyString: true, skipNull: true }),
  }),
  { maxRetries: 0 } //
);
