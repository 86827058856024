/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PushNotificationService.WebApi
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

export const getApiPushNotificationAppInfoResponse = zod.string();

/**
 * @summary Get batch by its Id.
 */
export const getApiPushNotificationBatchesBatchIdParams = zod.object({
  batchId: zod.string().uuid(),
});

export const getApiPushNotificationBatchesBatchIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      status: zod.enum(['NEW', 'SENT', 'SUCCESS', 'ERROR']),
      createdDate: zod.string().datetime(),
      sentDate: zod.string().datetime().nullish(),
      totalCount: zod.number(),
      sentCount: zod.number(),
      deliveredCount: zod.number(),
      templateId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete batch.
 */
export const deleteApiPushNotificationBatchesBatchIdParams = zod.object({
  batchId: zod.string().uuid(),
});

export const deleteApiPushNotificationBatchesBatchIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get all batches.
 */
export const getApiPushNotificationBatchesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        status: zod.enum(['NEW', 'SENT', 'SUCCESS', 'ERROR']),
        createdDate: zod.string().datetime(),
        sentDate: zod.string().datetime().nullish(),
        totalCount: zod.number(),
        sentCount: zod.number(),
        deliveredCount: zod.number(),
        templateId: zod.string().uuid(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create batch.
 */
export const postApiPushNotificationBatchesBody = zod.object({
  templateId: zod.string().uuid().optional(),
});

export const postApiPushNotificationBatchesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      status: zod.enum(['NEW', 'SENT', 'SUCCESS', 'ERROR']),
      createdDate: zod.string().datetime(),
      sentDate: zod.string().datetime().nullish(),
      totalCount: zod.number(),
      sentCount: zod.number(),
      deliveredCount: zod.number(),
      templateId: zod.string().uuid(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Send batch's messages.
 */
export const postApiPushNotificationBatchesSendQueryParams = zod.object({
  batchId: zod.string().uuid().optional(),
});

export const postApiPushNotificationBatchesSendResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get messages statuses by filter.
 */
export const getApiPushNotificationMessagesStatusesQueryParams = zod.object({
  BatchId: zod.string().uuid().optional(),
  Status: zod.enum(['LOADED', 'SENT', 'DELIVERED', 'ERROR']).optional(),
  Limit: zod.number().optional(),
  Offset: zod.number().optional(),
});

export const getApiPushNotificationMessagesStatusesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        templateId: zod.string().uuid().optional(),
        status: zod.enum(['LOADED', 'SENT', 'DELIVERED', 'ERROR']).optional(),
        deviceId: zod.string().uuid().optional(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Load messages for sending from CSV-file with user devices ids.
 */
export const postApiPushNotificationMessagesFromCsvWithDevicesIdsQueryParams =
  zod.object({
    batchId: zod.string().uuid().optional(),
  });

export const postApiPushNotificationMessagesFromCsvWithDevicesIdsBody =
  zod.object({
    CsvFile: zod.string(),
  });

export const postApiPushNotificationMessagesFromCsvWithDevicesIdsResponse =
  zod.object({
    data: zod
      .object({
        receivedDevicesIdsCount: zod.number(),
        successfullyCreatedCount: zod.number(),
      })
      .optional(),
    status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
    statusMessage: zod.string().nullish(),
    unavailables: zod
      .array(
        zod.enum([
          'CHARGE_POINT_SERVICE',
          'CP_WS_CONTROLLER',
          'CP_SOAP_CONTROLLER',
          'BILLING_SERVICE',
          'PAYMENT_SERVICE',
          'FISCAL_SERVICE',
          'SBER_PAY',
          'ARMENIA_PAY',
          'E_PAY',
          'FISCAL_RUSSIA',
          'FISCAL_ARMENIA',
          'BONUS_SERVICE',
          'DISCOUNT_SERVICE',
          'MOBILE_API_BFF',
          'USER_SERVICE',
          'RESERVE_SERVICE',
          'OCPI_SERVICE',
          'SMS_SERVICE',
          'PUSH_NOTIFICATION_SERVICE',
          'SYSTEM_INFROMATION_SERVICE',
          'IDENTITY_SERVICE',
          'ANALYTICS_SERVICE',
        ])
      )
      .nullish(),
  });

/**
 * @summary Get template by its Id.
 */
export const getApiPushNotificationTemplatesTemplateIdParams = zod.object({
  templateId: zod.string().uuid(),
});

export const getApiPushNotificationTemplatesTemplateIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      title: zod.string().min(1),
      text: zod.string().min(1),
      imgUrl: zod.string().nullish(),
      deepLink: zod.string().nullish(),
      browserUrl: zod.string().min(1),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Update template.
 */
export const patchApiPushNotificationTemplatesTemplateIdParams = zod.object({
  templateId: zod.string().uuid(),
});

export const patchApiPushNotificationTemplatesTemplateIdBody = zod.object({
  name: zod.string().nullish(),
  title: zod.string().nullish(),
  text: zod.string().nullish(),
  imgUrl: zod.string().nullish(),
  deepLink: zod.string().nullish(),
  browserUrl: zod.string().nullish(),
});

export const patchApiPushNotificationTemplatesTemplateIdResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      title: zod.string().min(1),
      text: zod.string().min(1),
      imgUrl: zod.string().nullish(),
      deepLink: zod.string().nullish(),
      browserUrl: zod.string().min(1),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Delete template.
 */
export const deleteApiPushNotificationTemplatesTemplateIdParams = zod.object({
  templateId: zod.string().uuid(),
});

export const deleteApiPushNotificationTemplatesTemplateIdResponse = zod.object({
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Get all templates.
 */
export const getApiPushNotificationTemplatesResponse = zod.object({
  data: zod
    .array(
      zod.object({
        id: zod.string().uuid(),
        name: zod.string().min(1),
        title: zod.string().min(1),
        text: zod.string().min(1),
        imgUrl: zod.string().nullish(),
        deepLink: zod.string().nullish(),
        browserUrl: zod.string().min(1),
        createdDate: zod.string().datetime(),
      })
    )
    .nullish(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Create template.
 */
export const postApiPushNotificationTemplatesBody = zod.object({
  name: zod.string().min(1),
  title: zod.string().min(1),
  text: zod.string().min(1),
  imgUrl: zod.string().nullish(),
  deepLink: zod.string().nullish(),
  browserUrl: zod.string().min(1),
});

export const postApiPushNotificationTemplatesResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      name: zod.string().min(1),
      title: zod.string().min(1),
      text: zod.string().min(1),
      imgUrl: zod.string().nullish(),
      deepLink: zod.string().nullish(),
      browserUrl: zod.string().min(1),
      createdDate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});

/**
 * @summary Method for testing firebase work.
 */
export const postApiPushNotificationTestingQueryParams = zod.object({
  deviceId: zod.string().uuid().optional(),
});

/**
 * @summary Create or update (if exists) device token.
 */
export const putApiPushNotificationTokensBody = zod.object({
  deviceId: zod.string().uuid(),
  value: zod.string().min(1),
});

export const putApiPushNotificationTokensResponse = zod.object({
  data: zod
    .object({
      id: zod.string().uuid(),
      deviceId: zod.string().uuid(),
      value: zod.string().min(1),
      lastUpdate: zod.string().datetime(),
    })
    .optional(),
  status: zod.enum(['SUCCESS', 'ERROR', 'PART_UNAVAILABLE']).optional(),
  statusMessage: zod.string().nullish(),
  unavailables: zod
    .array(
      zod.enum([
        'CHARGE_POINT_SERVICE',
        'CP_WS_CONTROLLER',
        'CP_SOAP_CONTROLLER',
        'BILLING_SERVICE',
        'PAYMENT_SERVICE',
        'FISCAL_SERVICE',
        'SBER_PAY',
        'ARMENIA_PAY',
        'E_PAY',
        'FISCAL_RUSSIA',
        'FISCAL_ARMENIA',
        'BONUS_SERVICE',
        'DISCOUNT_SERVICE',
        'MOBILE_API_BFF',
        'USER_SERVICE',
        'RESERVE_SERVICE',
        'OCPI_SERVICE',
        'SMS_SERVICE',
        'PUSH_NOTIFICATION_SERVICE',
        'SYSTEM_INFROMATION_SERVICE',
        'IDENTITY_SERVICE',
        'ANALYTICS_SERVICE',
      ])
    )
    .nullish(),
});
