import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ApplicationDtoUserDto } from 'shared/api/services/identity/rtk/generated';
import { UiModal } from 'shared/ui';

import { Form } from '../form';

type Props = {
  user: ApplicationDtoUserDto;
};

export const Modal = NiceModal.create(({ user }: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Редактирование профиля"
    >
      <Form closeModal={modal.hide} user={user} />
    </UiModal>
  );
});
