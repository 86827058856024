import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInformationV1CarModelsById: build.query<
      GetApiInformationV1CarModelsByIdApiResponse,
      GetApiInformationV1CarModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/CarModels/${queryArg}`,
      }),
    }),
    patchApiInformationV1CarModelsById: build.mutation<
      PatchApiInformationV1CarModelsByIdApiResponse,
      PatchApiInformationV1CarModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/CarModels/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCarModelRequest,
      }),
    }),
    deleteApiInformationV1CarModelsById: build.mutation<
      DeleteApiInformationV1CarModelsByIdApiResponse,
      DeleteApiInformationV1CarModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/CarModels/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getApiInformationV1CarModels: build.query<
      GetApiInformationV1CarModelsApiResponse,
      GetApiInformationV1CarModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/CarModels`,
        params: { carModels: queryArg },
      }),
    }),
    postApiInformationV1CarModels: build.mutation<
      PostApiInformationV1CarModelsApiResponse,
      PostApiInformationV1CarModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Information/v1/CarModels`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiInformationV1CarModelsByIdApiResponse =
  /** status 200 Success */ GetCarModelVmBaseResponse;
export type GetApiInformationV1CarModelsByIdApiArg =
  /** Идентификатор */ string;
export type PatchApiInformationV1CarModelsByIdApiResponse =
  /** status 200 Success */ UpdateCarModelVmBaseResponse;
export type PatchApiInformationV1CarModelsByIdApiArg = {
  /** Идентификатор модели */
  id: string;
  /** Параметры */
  updateCarModelRequest: UpdateCarModelRequest;
};
export type DeleteApiInformationV1CarModelsByIdApiResponse =
  /** status 200 Success */ BaseResponse;
export type DeleteApiInformationV1CarModelsByIdApiArg =
  /** Идентификатор модели */ string;
export type GetApiInformationV1CarModelsApiResponse =
  /** status 200 Success */ GetCarModelVmIReadOnlyCollectionBaseResponse;
export type GetApiInformationV1CarModelsApiArg =
  /** Идентификаторы моделей */ string[];
export type PostApiInformationV1CarModelsApiResponse =
  /** status 200 Success */ AddCarModelVmBaseResponse;
export type PostApiInformationV1CarModelsApiArg =
  /** Параметры */ AddCarModelRequest;
export type Status = 'SUCCESS' | 'ERROR' | 'PART_UNAVAILABLE';
export type UnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type GetCarModelCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type GetCarModelVm = {
  id: string;
  name: string;
  carBrand: GetCarModelCarBrandVm;
};
export type GetCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarModelVm;
};
export type UpdateCarModelCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type UpdateCarModelVm = {
  id: string;
  name: string;
  carBrand: UpdateCarModelCarBrandVm;
};
export type UpdateCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: UpdateCarModelVm;
};
export type UpdateCarModelRequest = {
  name?: string | null;
  carBrandId?: string | null;
};
export type BaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
};
export type GetCarModelVmIReadOnlyCollectionBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: GetCarModelVm[] | null;
};
export type AddCarModelCarBrandVm = {
  id: string;
  name: string;
  logoUrl?: string | null;
  objectStorageName?: string | null;
};
export type AddCarModelVm = {
  id: string;
  name: string;
  carBrand: AddCarModelCarBrandVm;
};
export type AddCarModelVmBaseResponse = {
  status?: Status;
  statusMessage?: string | null;
  unavailables?: UnavailableServices[] | null;
  data?: AddCarModelVm;
};
export type AddCarModelRequest = {
  carBrandId: string;
  name: string;
};
export const {
  useGetApiInformationV1CarModelsByIdQuery,
  usePatchApiInformationV1CarModelsByIdMutation,
  useDeleteApiInformationV1CarModelsByIdMutation,
  useGetApiInformationV1CarModelsQuery,
  usePostApiInformationV1CarModelsMutation,
} = injectedRtkApi;
