import NiceModal from '@ebay/nice-modal-react';
import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';
import { PopoverContentLayout } from 'shared/ui/ui-popover/content-layout';
import { CarDto } from 'shared/api/services/user/rtk/generated';

import { DeleteCarModal } from 'features/car/delete';

type Props = {
  car: CarDto;
};

export function Actions({ car }: Props) {
  const ability = useAbility();

  const { userId, id } = car;

  const showDeleteModal = () => {
    NiceModal.show(DeleteCarModal, { carId: id, userId });
  };

  const showEditModal = () => {
    // NiceModal.show(EditCarModal, props);
  };

  const actions = (
    <>
      <button onClick={showEditModal}>Редактировать</button>
      {ability.can('Write', 'Car') && (
        <button onClick={showDeleteModal}>Удалить</button>
      )}
    </>
  );

  const content = <PopoverContentLayout contentGroups={[actions]} />;

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
