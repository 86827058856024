import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // Тариф (стоимость)
    getApiBillingV1Tariffs: (endpoint) => {
      endpoint.providesTags = [{ type: 'Tariffs', id: 'LIST' }];
    },
    postApiBillingV1Tariffs: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
    },
    deleteApiBillingV1TariffsById: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'Tariffs', id: 'LIST' }];
    },
    // Списки услуг (биллинг)
    getApiBillingV1ServicesLists: (endpoint) => {
      endpoint.providesTags = [{ type: 'ServicesLists', id: 'LIST' }];
    },
    getApiBillingV1ServicesListsById: (endpoint) => {
      endpoint.providesTags = (result, error, arg) => [
        { type: 'ServicesLists', id: arg },
      ];
    },
    postApiBillingV1ServicesLists: (endpoint) => {
      endpoint.invalidatesTags = [{ type: 'ServicesLists', id: 'LIST' }];
    },
    // Стадии
    deleteApiBillingV1StagesById: (endpoint) => {
      // Принимает только id. Но инвалидировать надо еще ServiceList
      // TODO:
      // endpoint.invalidatesTags = [{ type: 'ServicesLists', id: 'LIST' }];
    },
    // Биллинг (оплаты)
    getApiBillingV1Billings: (endpoint) => {
      endpoint.providesTags = [{ type: 'Billings', id: 'LIST' }];
    },
    // Привязанные списки услуг для сущностей
    // getApiBillingV1TargetsServicesLists: (endpoint) => {
    //   endpoint.providesTags = (result, error, arg) => [
    //     { type: 'TargetServicesLists', id: JSON.stringify(arg) },
    //   ];
    // },
  },
});
// .injectEndpoints({
//   endpoints: (builder) => ({
//     addServices: builder.mutation<
//       void,
//       { stages: AddServicesFormOutput; id: string }
//     >({
//       queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
//         const {
//           id,
//           stages: { chargingStages, reservingStages },
//         } = args;

//         const {
//           postApiBillingServices,
//           postApiBillingStages,
//           patchApiBillingServicesListsId,
//         } = getBillingServiceWebApi();

//         try {
//           if (chargingStages.length) {
//             const chargingService = await postApiBillingServices({
//               name: 'Услуга Заряд',
//               type: 'CHARGING',
//             });

//             // if (chargingService.status === 'ERROR') {
//             //   return {
//             //     error: {
//             //       error: chargingService.statusMessage,
//             //       status: 'CUSTOM_ERROR',
//             //     },
//             //   };
//             // }

//             const patchServicesListRes = await patchApiBillingServicesListsId(
//               id,
//               { servicesIds: [chargingService.data?.id] }
//             );

//             const chargingStagesRes = await Promise.all(
//               chargingStages.map((stage) =>
//                 postApiBillingStages({
//                   ...stage,
//                   serviceId: chargingService.data?.id,
//                 })
//               )
//             );
//           }

//           if (reservingStages.length) {
//             const reservingService = await postApiBillingServices({
//               name: 'Услуга Бронирование',
//               type: 'RESERVING',
//             });

//             const patchServicesListRes = await patchApiBillingServicesListsId(
//               id,
//               { servicesIds: [reservingService.data?.id] }
//             );

//             const reservingStagesRes = await Promise.all(
//               reservingStages.map((stage) =>
//                 postApiBillingStages({
//                   ...stage,
//                   serviceId: reservingService.data?.id,
//                 })
//               )
//             );
//           }

//           return { data: { status: 'SUCCESS', statusMessage: '' } };
//         } catch (err) {}
//       },
//       invalidatesTags: ['ServicesLists'],
//     }),
//     updateServices: builder.mutation<
//       void,
//       { data: EditServicesListFormOutput; servicesList: ServicesListDto }
//     >({
//       queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
//         const {
//           servicesList,
//           data: { chargingStages, reservingStages, name },
//         } = args;

//         const { id, services } = servicesList;

//         const {
//           postApiBillingServices,
//           postApiBillingStages,
//           patchApiBillingServicesListsId,
//         } = getBillingServiceWebApi();

//         try {
//           if (name !== servicesList.name) {
//             const patchServicesListRes = await patchApiBillingServicesListsId(
//               id,
//               {
//                 name,
//               }
//             );
//           }

//           //
//           // Сервис "Заряд"
//           //
//           if (chargingStages.length) {
//             //
//             // Если сервиса не было - сначала создаем его
//             //
//             let chargingServiceId: string;

//             const chargingService = services?.find(
//               (el) => el.type === 'CHARGING'
//             );

//             if (chargingService) {
//               chargingServiceId = chargingService.id;
//             } else {
//               const chargingServiceRes = await postApiBillingServices({
//                 name: 'Услуга Заряд',
//                 type: 'CHARGING',
//               });

//               chargingServiceId = chargingServiceRes.data?.id;

//               //
//               // Обновляем список услуг, добавляя созданный сервис
//               //
//               const servicesIds = services?.map((el) => el.id) ?? [];

//               const patchServicesListRes =
//                 await patchApiBillingServicesListsId(id, {
//                   servicesIds: [...servicesIds, chargingServiceId],
//                 });
//             }

//             //
//             // Добавляем созданные стадии
//             //
//             const chargingStagesRes = await Promise.all(
//               chargingStages.map((stage) =>
//                 postApiBillingStages({
//                   ...stage,
//                   serviceId: chargingServiceId,
//                 })
//               )
//             );
//           }

//           //
//           // Сервис "Бронирование"
//           //
//           if (reservingStages.length) {
//             //
//             // Если сервиса не было - сначала создаем его
//             //
//             let reservingServiceId: string;

//             const reservingService = services?.find(
//               (el) => el.type === 'RESERVING'
//             );

//             if (reservingService) {
//               reservingServiceId = reservingService.id;
//             } else {
//               const reservingServiceRes = await postApiBillingServices({
//                 name: 'Услуга Бронирование',
//                 type: 'RESERVING',
//               });

//               reservingServiceId = reservingServiceRes.data?.id;

//               //
//               // Обновляем список услуг, добавляя созданный сервис
//               //
//               const servicesIds = services?.map((el) => el.id) ?? [];

//               const patchServicesListRes =
//                 await patchApiBillingServicesListsId(id, {
//                   servicesIds: [...servicesIds, reservingServiceId],
//                 });
//             }

//             //
//             // Добавляем созданные стадии
//             //
//             const reservingStagesRes = await Promise.all(
//               reservingStages.map((stage) =>
//                 postApiBillingStages({
//                   ...stage,
//                   serviceId: reservingServiceId,
//                 })
//               )
//             );
//           }

//           return { data: { status: 'SUCCESS', statusMessage: '' } };
//         } catch (err) {
//           // TODO
//         }
//       },
//       invalidatesTags: (result, error, arg) => [
//         { type: 'ServicesLists', id: 'LIST' },
//         {
//           type: 'ServicesLists',
//           id: arg.servicesList.id,
//         },
//       ],
//     }),
//   }),
// });

export const {
  useGetApiBillingV1ServicesListsQuery: useGetServicesLists,
  useGetApiBillingV1ServicesListsByIdQuery: useGetServiceList,
  usePostApiBillingV1ServicesListsMutation: useAddServiceList,
  // Тарифы
  useGetApiBillingV1TariffsQuery: useGetTariffs,
  usePostApiBillingV1TariffsMutation: useAddTariff,
  useDeleteApiBillingV1TariffsByIdMutation:
    useDeleteApiBillingTariffsByIdMutation,
  // Стадии
  useDeleteApiBillingV1StagesByIdMutation: useDeleteStage,
  // Кастомные
  // useAddServicesMutation: useAddServices,
  // useUpdateServicesMutation: useUpdateServicesList,
  //
  // Биллинги
  useGetApiBillingV1BillingsQuery: useGetBillings,
  useGetApiBillingV1BillingsCountQuery: useGetBillingsCount,
  // Привязанные списки услуг для сущности
  useGetApiBillingV1TargetsServicesListsQuery: useGetTargetsServicesLists,
} = enhancedApi;
