import { BILLING_ROUTES } from 'shared/consts/routes/billing';
import { SectionMenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <SectionMenuAddButton
      path={BILLING_ROUTES.ADD}
      label="Добавить биллинг"
      // permissions={[
      //   {
      //     controller: 'ChargeTariff', // TODO: выяснить
      //     right: 'Write',
      //   },
      // ]}
    />
  );
}
