import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useDeleteCar } from 'shared/api/services/user/rtk/enhanced';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  userId: string;
  carId: string;
};

export const Modal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteCar();

  const handleDelete = async () => {
    try {
      const res = await trigger(props).unwrap();

      openSuccessNotification(DELETE_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить автомобиль?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
