import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useAddMobileApp } from 'shared/api/services/information/rtk';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { DEFAULT_VALUES, FormSchema, FormSchemaType } from '../consts/schema';
import { FORM_TITLE, ADD_ERROR, ADD_SUCCESS } from '../consts';

export function Form() {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const [trigger, { isLoading }] = useAddMobileApp();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger(data).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{FORM_TITLE}</UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields />
              <Form.Buttons />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

Form.Fields = function Fields() {
  const {
    formState: { errors },
  } = useFormContext<FormSchemaType>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormSchemaType> name="name" label="Название" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomInput<FormSchemaType>
            name="description"
            label="Описание"
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomCheckbox<FormSchemaType> name="isDefault" label="isDefault" />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
