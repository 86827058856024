import { z } from 'zod';

import { postApiChargePointV1ChargePointsChargePointsIdConnectorBody } from 'shared/api/services/chargepoint/orval/zod/schemas';
import { requiredNumberInString } from 'shared/lib/schema';

const bodySchema = postApiChargePointV1ChargePointsChargePointsIdConnectorBody;

const type = bodySchema.shape.type.nullish().transform((val, ctx) => {
  if (val === undefined || val === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Required field',
    });

    return z.NEVER;
  }

  return val;
});

const format = bodySchema.shape.format.nullish().transform((val, ctx) => {
  if (val === undefined || val === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Required field',
    });

    return z.NEVER;
  }

  return val;
});

const status = bodySchema.shape.status.nullish().transform((val, ctx) => {
  if (val === undefined || val === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Required field',
    });

    return z.NEVER;
  }

  return val;
});

export const FormSchema = bodySchema
  .omit({
    type: true,
    format: true,
    status: true,
    maxPower: true,
    maxAmperage: true,
    maxVoltage: true,
    innerConnectorId: true,
  })
  .extend({
    type: type,
    format: format,
    status: status,
    maxPower: requiredNumberInString.pipe(z.number().min(0)),
    maxAmperage: requiredNumberInString.pipe(z.number().min(0)),
    maxVoltage: requiredNumberInString.pipe(z.number().min(0)),
    innerConnectorId: requiredNumberInString.pipe(z.number()),
  });

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  liter: '',
};
