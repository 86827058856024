import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { COUNTRY } from 'shared/consts/routes/country';

import { RequireAuth } from 'entities/authorization';

import { AddCountryPage, EditCountryPage } from 'pages/country';
import { ConnectedCountriesMenu } from 'features/country/menu';
import { ADD_PREFIX, COUNTRIES } from 'shared/consts/names';

export const CountryRoutes = (
  <Route element={<RequireAuth controllerName="Country" rightType="Read" />}>
    <Route
      path={COUNTRIES}
      element={<SectionLayout menu={<ConnectedCountriesMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Country" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddCountryPage />} />
      </Route>
      <Route path={COUNTRY} element={<EditCountryPage />} />
    </Route>
  </Route>
);
