import { ADD_PREFIX, BANKS, SETTINGS } from '../names';

export const BANK = ':bankId';

const ROOT = `/${SETTINGS}/${BANKS}`;

export const BANK_ROUTES = {
  BANKS: ROOT,
  BANK: `${ROOT}/${BANK}`,
  ADD_BANK: `${ROOT}/${ADD_PREFIX}`,
};
