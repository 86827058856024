import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomTextarea } from 'shared/ui/form/custom-textarea';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useUpdateUserGroup } from 'shared/api/services/user/rtk/enhanced';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useAbility } from 'shared/lib/ability/context';
import { GroupDto } from 'shared/api/services/user/rtk/generated';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { FormInput, FormOutput, FormSchema } from '../consts/schema';
import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  userGroups: GroupDto[];
  userGroup: GroupDto;
};

export function Form({ userGroups, userGroup }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...userGroup,
    },
  });

  const [trigger, { isLoading }] = useUpdateUserGroup();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const handleSubmit = form.handleSubmit(async (data) => {
    // Когда будут скидки - переделать на санк

    try {
      const res = await trigger({
        groupId: userGroup.id,
        updateGroupRequestDto: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>{userGroup.name}</UiCard.Header>
      <UiCard.Body>
        {' '}
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields isDisabled={isDisabled} userGroups={userGroups} />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  userGroups: GroupDto[];
  isDisabled: boolean;
};

Form.Fields = function Fields({ userGroups, isDisabled }: FieldsProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="parentId"
            options={userGroups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Родительский узел"
            allowClear
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput>
            name="name"
            label="Наименование"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <CustomTextarea<FormInput>
            name="comment"
            label="Описание"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <CustomCheckbox<FormInput>
            name="isDefault"
            label="isDefault"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  const ability = useAbility();

  if (!ability.can('Write', 'Group')) {
    return null;
  }

  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
