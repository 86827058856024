import { PageSettingsButton } from '../../settings/button';
import { ChargePointsMap } from '../map';
import {
  Mapcontainer,
  MapContainerHeader,
  MapTitle,
  MapWrapper,
  MonitroingMapContainer,
} from './styles';

type Props = {
  isFullWidth: boolean;
};

export function MapLayout({ isFullWidth }: Props) {
  return (
    <MonitroingMapContainer isFullWidth={isFullWidth}>
      <MapContainerHeader>
        <MapTitle>Карта ЭЗС</MapTitle>
        <PageSettingsButton />
      </MapContainerHeader>
      <Mapcontainer>
        <MapWrapper>
          <ChargePointsMap />
        </MapWrapper>
      </Mapcontainer>
    </MonitroingMapContainer>
  );
}
