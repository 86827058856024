import { combineReducers } from 'redux';

import manualCommandsReducer from 'features/manual-command/table/store';

export const tablesReducer = combineReducers({
  manualCommands: manualCommandsReducer,
});

export const selectManualCommandsTable = (state: RootState) =>
  state.tables.manualCommands;
