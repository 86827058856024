import { COLOR } from 'shared/consts';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  min-width: 700px;
`;

export const StyledHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${COLOR.black};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
