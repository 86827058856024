import { z } from 'zod';

import { postApiBillingStagesBody } from 'shared/api/services/billing/orval/zod/schemas';
import { requiredNumberInString } from 'shared/lib/schema';

const endType = postApiBillingStagesBody.shape.endType
  .nullish()
  .transform((val, ctx) => {
    if (val === undefined || val === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required field',
      });

      return z.NEVER;
    }

    return val;
  });

const billingStage = postApiBillingStagesBody
  .omit({
    endType: true,
    endConditionValue: true,
    serviceId: true,
  })
  .extend({
    endType: endType,
    endConditionValue: requiredNumberInString.pipe(z.number().gte(0)),
  });

export const FormSchema = z.object({
  reservingStages: z.array(billingStage),
  chargingStages: z.array(billingStage),
});

export type FormInput = z.input<typeof FormSchema>;
export type FormOutput = z.output<typeof FormSchema>;

export const DEFAULT_VALUES: FormInput = {
  chargingStages: [],
  reservingStages: [],
};
