import { Checkbox, MenuProps } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/redux/types';
import { UiDefaultButton, UiPrimaryButton } from 'shared/ui/buttons';
import { ErrorMessage } from 'shared/ui';

import { CHARGE_POINT_STATUS } from 'entities/charge-point';

import {
  selectCompaniesId,
  selectNotCompaniesId,
  selectOwnerTypes,
  selectStatuses,
  selectWithTestCp,
  setCompaniesId,
  setNotCompaniesId,
  setOwnerTypes,
  setStatuses,
  setWithTestCp,
} from '../../store/slice';

import {
  CardHeader,
  Cardtitle,
  CheckboxCover,
  CheckboxContainer,
  FiltersCard,
  StyledFiltersMenu,
  FiltersGrid,
  FiltersGridColumn,
  SelectAllButton,
  StyledButtonsContainer,
} from './styles';
import { OWNER_TYPE } from '../../model';
import { useCompaniesList } from '../../hooks/use-companies-list';
import { AGENT_KEY, OWN_KEY } from '../../consts';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type Props = {
  closeModal: () => void;
};

export function FullFilters({ closeModal }: Props) {
  const { ownCompanies, agentCompanies, error, isLoading } = useCompaniesList();

  const ownCompaniesIds = useMemo(() => {
    return ownCompanies.map((el) => el.id);
  }, [ownCompanies]);

  const agentCompaniesIds = useMemo(() => {
    return agentCompanies.map((el) => el.id);
  }, [agentCompanies]);

  const companies = useMemo(() => {
    if (ownCompanies && agentCompanies) {
      return [...ownCompanies, ...agentCompanies];
    }

    return [];
  }, [ownCompanies, agentCompanies]);

  const ownerTypes = useAppSelector(selectOwnerTypes);
  const statuses = useAppSelector(selectStatuses);
  const withTestCp = useAppSelector(selectWithTestCp);
  const companiesId = useAppSelector(selectCompaniesId);
  const notCompaniesId = useAppSelector(selectNotCompaniesId);

  const [draftOwnerTypes, setDraftOwnerTypes] = useState(ownerTypes);
  const [draftStatuses, setDraftStatuses] = useState(statuses);
  const [draftCompaniesId, setDraftCompaniesId] = useState(companiesId);
  const [draftNotCompaniesId, setDraftNotCompaniesId] =
    useState(notCompaniesId);
  const [draftWithTestCp, setDraftWithTestCp] = useState(withTestCp);

  const dispatch = useAppDispatch();

  const ownerTypeItems: MenuProps['items'] = [
    getItem(
      'Тип владения станции',
      'sub1',
      undefined,
      Object.entries(OWNER_TYPE).map(([key, label]) => ({
        key,
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox
              checked={draftOwnerTypes.includes(key)}
              disabled={key === 'partner'}
            >
              {label}
            </Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const statusTypeItems: MenuProps['items'] = [
    getItem(
      'Активность',
      'sub1',
      undefined,
      Object.entries(CHARGE_POINT_STATUS).map(([key, label]) => ({
        key,
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={draftStatuses.includes(key)}>{label}</Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const testCpItems: MenuProps['items'] = [
    getItem('Тестовые', 'sub1', undefined, [
      {
        key: 'testCp',
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={Boolean(draftWithTestCp.length)}>
              Тестовые
            </Checkbox>
          </CheckboxContainer>
        ),
      },
    ]),
  ];

  const ownerIdsItems: MenuProps['items'] = [
    getItem(
      'Владелец',
      'sub1',
      undefined,
      companies.map(({ id, name }) => ({
        key: String(id),
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={draftCompaniesId.includes(String(id))}>
              {name}
            </Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const checkAll =
    draftOwnerTypes.length === Object.keys(OWNER_TYPE).length &&
    draftStatuses.length === Object.keys(CHARGE_POINT_STATUS).length &&
    draftWithTestCp.length === 1 &&
    draftCompaniesId.length == companiesId.length;

  const handleOwnerTypesChange = (args: SelectInfo) => {
    const selected = args.selectedKeys;

    setDraftOwnerTypes(selected);

    if (selected.includes(OWN_KEY)) {
      setDraftCompaniesId((curr) => [...curr, ...ownCompaniesIds]);
    } else {
      setDraftCompaniesId((curr) =>
        curr.filter((el) => !ownCompaniesIds.includes(el))
      );
    }

    if (selected.includes(AGENT_KEY)) {
      setDraftCompaniesId((curr) => [...curr, ...agentCompaniesIds]);
    } else {
      setDraftCompaniesId((curr) =>
        curr.filter((el) => !agentCompaniesIds.includes(el))
      );
    }
  };

  const handleStatusesChange = (args: SelectInfo) => {
    setDraftStatuses(args.selectedKeys);
  };

  const handleCompaniesIdChange = (args: SelectInfo) => {
    const selected = args.selectedKeys;

    setDraftCompaniesId(selected);

    //
    // TODO
    //
    //
  };

  const handleWithoutTestCpChange = (args: SelectInfo) => {
    setDraftWithTestCp(args.selectedKeys);
  };

  const handleSubmitFilters = () => {
    dispatch(setOwnerTypes(draftOwnerTypes));
    dispatch(setStatuses(draftStatuses));
    dispatch(setCompaniesId(draftCompaniesId)); // TODO
    dispatch(setWithTestCp(draftWithTestCp));

    closeModal();
  };

  const handleSelectAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (checkAll) {
      setDraftOwnerTypes([]);
      setDraftStatuses([]);
      setDraftWithTestCp([]);
      setDraftCompaniesId([]);
    } else {
      setDraftOwnerTypes(Object.keys(OWNER_TYPE));
      setDraftStatuses(Object.keys(CHARGE_POINT_STATUS));
      setDraftWithTestCp(['testCp']);
      setDraftCompaniesId(companies.map((el) => el.id));
    }
  };

  if (isLoading) {
    return <div style={{ padding: '10px' }}>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={error} />;
  }

  return (
    <FiltersCard>
      <CardHeader>
        <Cardtitle>Фильтры</Cardtitle>
        <SelectAllButton checkAll={checkAll} onClick={handleSelectAllClick}>
          {checkAll ? 'Очистить всё' : 'Выбрать всё'}
        </SelectAllButton>
      </CardHeader>
      <FiltersGrid>
        <FiltersGridColumn>
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={ownerTypeItems}
            selectedKeys={draftOwnerTypes}
            onSelect={handleOwnerTypesChange}
            onDeselect={handleOwnerTypesChange}
            defaultOpenKeys={['sub1']}
          />
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={statusTypeItems}
            selectedKeys={draftStatuses}
            onSelect={handleStatusesChange}
            onDeselect={handleStatusesChange}
            defaultOpenKeys={['sub1']}
          />
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={testCpItems}
            selectedKeys={draftWithTestCp}
            onSelect={handleWithoutTestCpChange}
            onDeselect={handleWithoutTestCpChange}
            defaultOpenKeys={['sub1']}
          />
        </FiltersGridColumn>
        <FiltersGridColumn>
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={ownerIdsItems}
            selectedKeys={draftCompaniesId}
            onSelect={handleCompaniesIdChange}
            onDeselect={handleCompaniesIdChange}
            defaultOpenKeys={['sub1']}
          />
        </FiltersGridColumn>
      </FiltersGrid>
      <StyledButtonsContainer>
        <UiPrimaryButton onClick={closeModal}>Отмена</UiPrimaryButton>
        <UiDefaultButton onClick={handleSubmitFilters}>
          Сохранить
        </UiDefaultButton>
      </StyledButtonsContainer>
    </FiltersCard>
  );
}
