import { baseApi as api } from '../../../../base/base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiOcpiV1PlatformChargePointsById: build.query<
      GetApiOcpiV1PlatformChargePointsByIdApiResponse,
      GetApiOcpiV1PlatformChargePointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Ocpi/V1/Platform/ChargePoints/${queryArg}`,
      }),
    }),
    getApiOcpiV1PlatformChargePoints: build.query<
      GetApiOcpiV1PlatformChargePointsApiResponse,
      GetApiOcpiV1PlatformChargePointsApiArg
    >({
      query: (queryArg) => ({
        url: `/Api/Ocpi/V1/Platform/ChargePoints`,
        params: {
          Name: queryArg.name,
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          OrderingField: queryArg.orderingField,
          OrderingType: queryArg.orderingType,
          Offset: queryArg.offset,
          Limit: queryArg.limit,
        },
      }),
    }),
    getApiOcpiV1PlatformChargePointsCpStatuses: build.query<
      GetApiOcpiV1PlatformChargePointsCpStatusesApiResponse,
      GetApiOcpiV1PlatformChargePointsCpStatusesApiArg
    >({
      query: () => ({ url: `/Api/Ocpi/V1/Platform/ChargePoints/_cpStatuses` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiOcpiV1PlatformChargePointsByIdApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm;
export type GetApiOcpiV1PlatformChargePointsByIdApiArg = string;
export type GetApiOcpiV1PlatformChargePointsApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase601SystemCollectionsGenericIEnumerable601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm;
export type GetApiOcpiV1PlatformChargePointsApiArg = {
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  orderingField?: string;
  orderingType?: CommonsApplicationModelsOrderingType;
  offset?: number;
  limit?: number;
};
export type GetApiOcpiV1PlatformChargePointsCpStatusesApiResponse =
  /** status 200 OK */ CommonsContractsModelsResponseBase601SystemCollectionsGenericIEnumerable601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm;
export type GetApiOcpiV1PlatformChargePointsCpStatusesApiArg = void;
export type ChargePointServiceContractsModelsEnumsSpeedType =
  | 'UNDEFINED'
  | 'LOW_SPEED_AC'
  | 'HIGH_SPEED_DC';
export type ChargePointServiceContractsModelsEnumsChargePointProtocol =
  | 'OCPP16'
  | 'OCPP16J'
  | 'OCPP20';
export type ChargePointServiceContractsModelsEnumsChargePointStatus =
  | 'NO_CONNECT'
  | 'AVAILABLE'
  | 'FAULTED'
  | 'UNAVAILABLE';
export type ChargePointServiceContractsModelsEnumsCountryCode =
  | 'RU'
  | 'BY'
  | 'AM'
  | 'KZ'
  | 'KG';
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmGroupVm =
  {
    id: string;
    name: string;
  };
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmHubVm =
  {
    id: string;
    name: string;
    description?: string | null;
    address: string;
    latitude: number;
    longitude: number;
  };
export type ChargePointServiceContractsModelsEnumsConnectorType =
  | 'SCHUKO'
  | 'TYPE2'
  | 'CCS2'
  | 'CHADEMO'
  | 'GBTDC'
  | 'GBTAC'
  | 'CCS1'
  | 'TYPE1'
  | 'TESLA';
export type ChargePointServiceContractsModelsEnumsConnectorFormat =
  | 'SOCKET'
  | 'CABLE';
export type ChargePointServiceContractsModelsEnumsConnectorStatus =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EVSE'
  | 'SUSPENDED_EV'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type ChargePointServiceContractsModelsEnumsStatusForUser =
  | 'UNDEFINED'
  | 'AVAILABLE'
  | 'CHARGING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmConnectorVm =
  {
    id: string;
    type: ChargePointServiceContractsModelsEnumsConnectorType;
    format: ChargePointServiceContractsModelsEnumsConnectorFormat;
    status: ChargePointServiceContractsModelsEnumsConnectorStatus;
    statusForUser: ChargePointServiceContractsModelsEnumsStatusForUser;
    innerConnectorId: number;
    maxPower: number;
    maxAmperage: number;
    maxVoltage?: number | null;
    manualBlock: boolean;
    lastUpdate: string;
    liter?: string | null;
  };
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmPhotoVm =
  {
    url?: string | null;
    priority: number;
  };
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm =
  {
    id: string;
    number?: number | null;
    name: string;
    speedType: ChargePointServiceContractsModelsEnumsSpeedType;
    multiconnectorSupport: boolean;
    chargePointProtocol: ChargePointServiceContractsModelsEnumsChargePointProtocol;
    status: ChargePointServiceContractsModelsEnumsChargePointStatus;
    isVisible: boolean;
    maxPower: number;
    commentary?: string | null;
    publicDescription?: string | null;
    userLocationCheckDistance?: number | null;
    warningText?: string | null;
    workingTime?: string | null;
    countryCode: ChargePointServiceContractsModelsEnumsCountryCode;
    city: string;
    address: string;
    latitude: number;
    longitude: number;
    model?: string | null;
    dipLinkCharby?: string | null;
    manufacturer?: string | null;
    softwareRevision?: string | null;
    ocppConfig?: string | null;
    ipAddress?: string | null;
    simNumber?: string | null;
    chargeBoxIdentity: string;
    supportContact?: string | null;
    companyId?: string | null;
    createdDate: string;
    lastUpdate: string;
    isTestCp: boolean;
    isBlocked: boolean;
    ocppConnectionBlockDate: string;
    infrastructureInfo?: string | null;
    group?: ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmGroupVm;
    hub?: ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmHubVm;
    connectors?:
      | ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmConnectorVm[]
      | null;
    photos?:
      | ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVmPhotoVm[]
      | null;
  };
export type CommonsContractsModelsEnumsStatus =
  | 'SUCCESS'
  | 'ERROR'
  | 'PART_UNAVAILABLE';
export type CommonsContractsModelsEnumsUnavailableServices =
  | 'CHARGE_POINT_SERVICE'
  | 'CP_WS_CONTROLLER'
  | 'CP_SOAP_CONTROLLER'
  | 'BILLING_SERVICE'
  | 'PAYMENT_SERVICE'
  | 'FISCAL_SERVICE'
  | 'SBER_PAY'
  | 'ARMENIA_PAY'
  | 'E_PAY'
  | 'FISCAL_RUSSIA'
  | 'FISCAL_ARMENIA'
  | 'BONUS_SERVICE'
  | 'DISCOUNT_SERVICE'
  | 'MOBILE_API_BFF'
  | 'USER_SERVICE'
  | 'RESERVE_SERVICE'
  | 'OCPI_SERVICE'
  | 'SMS_SERVICE'
  | 'PUSH_NOTIFICATION_SERVICE'
  | 'SYSTEM_INFROMATION_SERVICE'
  | 'IDENTITY_SERVICE'
  | 'ANALYTICS_SERVICE';
export type CommonsContractsModelsResponseBase601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm =
  {
    data?: ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm;
    status?: CommonsContractsModelsEnumsStatus;
    statusMessage?: string | null;
    unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  };
export type CommonsContractsModelsResponseBase601SystemCollectionsGenericIEnumerable601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm =
  {
    data?:
      | ChargePointServiceContractsModelsVMsChargePointServiceVMsGetChargePointVm[]
      | null;
    status?: CommonsContractsModelsEnumsStatus;
    statusMessage?: string | null;
    unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  };
export type CommonsApplicationModelsOrderingType = 'Asc' | 'Desc';
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesConnectorVm =
  {
    id: string;
    status: ChargePointServiceContractsModelsEnumsConnectorStatus;
    type: ChargePointServiceContractsModelsEnumsConnectorType;
    innerConnectorId: number;
    statusForUser: ChargePointServiceContractsModelsEnumsStatusForUser;
    maxPower: number;
  };
export type ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm =
  {
    chargePointId: string;
    status: ChargePointServiceContractsModelsEnumsChargePointStatus;
    multiconnectorSupport: boolean;
    name: string;
    maxPower: number;
    city: string;
    address: string;
    number?: number | null;
    warningText?: string | null;
    dipLinkCharby?: string | null;
    speedType: ChargePointServiceContractsModelsEnumsSpeedType;
    groupId?: string | null;
    hubId?: string | null;
    latitude: number;
    longitude: number;
    lastUpdate: string;
    connectors?:
      | ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesConnectorVm[]
      | null;
  };
export type CommonsContractsModelsResponseBase601SystemCollectionsGenericIEnumerable601ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm =
  {
    data?:
      | ChargePointServiceContractsModelsVMsChargePointServiceVMsGetStatusesVm[]
      | null;
    status?: CommonsContractsModelsEnumsStatus;
    statusMessage?: string | null;
    unavailables?: CommonsContractsModelsEnumsUnavailableServices[] | null;
  };
export const {
  useGetApiOcpiV1PlatformChargePointsByIdQuery,
  useGetApiOcpiV1PlatformChargePointsQuery,
  useGetApiOcpiV1PlatformChargePointsCpStatusesQuery,
} = injectedRtkApi;
