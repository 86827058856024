import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.white};
  padding: 15px;
  position: relative;
`;

export const Gradient = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
`;

export const MetricsTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
  margin-bottom: 18px;
`;

export const MetricsValue = styled.div``;

export const MetricsSuffix = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const DashboardContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

// export const ErrorWrapper = styled.div`
//   & .ant-result.ant-result-error {
//     padding: 5px;

//     & .anticon.anticon-close-circle {
//       font-size: 48px;
//     }

//     & .ant-result-icon {
//       margin-bottom: 12px;
//     }
//   }
// `;
