import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { useAddConnector } from 'shared/api/services/chargepoint/rtk';

import {
  CONNECTOR_FORMAT,
  CONNECTOR_STATUS,
  CONNECTOR_TYPE,
  CONNECTOR_TYPE_ICON,
} from 'entities/connector';

import {
  DEFAULT_VALUES,
  FormSchema,
  FormInput,
  FormOutput,
} from '../../consts/schema';
import { ADD_ERROR, ADD_SUCCESS } from '../../consts';

import { ImageContainer, ImageText, Image, FormLayout } from './styles';
import { ConnectorType } from 'shared/api/services/chargepoint/rtk/generated/charge-points';
import { FormRow } from 'shared/ui/form';

type Props = {
  chargePointId: string;
  closeModal: () => void;
};

export function Form({ chargePointId, closeModal }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const type = form.watch('type');

  const [trigger, { isLoading }] = useAddConnector();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        chargePointsId: chargePointId,
        addConnectorRequest: data,
      }).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : ADD_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <FormProvider {...form}>
      <Spin spinning={isLoading}>
        <FormLayout>
          <Form.ConnectorImage type={type} />
          <form onSubmit={handleSubmit}>
            <Form.Fields />
            <Form.Buttons />
          </form>
        </FormLayout>
      </Spin>
    </FormProvider>
  );
}

type ImageProps = {
  type?: ConnectorType | null;
};

Form.ConnectorImage = function ConnectorImage({ type }: ImageProps) {
  const content = !type ? (
    <ImageText>Выбрать тип коннектора</ImageText>
  ) : (
    <Image src={CONNECTOR_TYPE_ICON[type]} />
  );

  return <ImageContainer>{content}</ImageContainer>;
};

Form.Fields = function Fields() {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput> name="maxPower" label="Мощность" required />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="type"
            label="Тип"
            options={Object.entries(CONNECTOR_TYPE).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="status"
            label="Статус"
            options={Object.entries(CONNECTOR_STATUS).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput> name="innerConnectorId" label="ID" required />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput> name="liter" label="Литер" />
        </Col>
        <Col span={12}>
          <CustomSelect<FormInput>
            name="format"
            label="Формат"
            options={Object.entries(CONNECTOR_FORMAT).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            required
          />
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <CustomInput<FormInput> name="maxVoltage" label="Max V" required />
        </Col>
        <Col span={12}>
          <CustomInput<FormInput> name="maxAmperage" label="Max A" required />
        </Col>
      </FormRow>
    </>
  );
};

Form.Buttons = function Buttons() {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        <UiSubmitButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
