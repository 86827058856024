export function Divider() {
  return (
    <svg
      width="297"
      height="1"
      viewBox="0 0 297 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="-4.37114e-08"
        y1="0.5"
        x2="297"
        y2="0.499974"
        stroke="#C3C3C3"
        stroke-dasharray="8 8"
      />
    </svg>
  );
}
