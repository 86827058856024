import { useState } from 'react';

import { RequiredLabel } from 'shared/ui';
import { UiSelect } from 'shared/ui/ui-kit';

import { CommandType, COMMAND_TYPE } from 'entities/command';

import { ConfigureForm } from '../forms/configure';
import { DiagnosticForm } from '../forms/diagnostic';
import { LoadFirmwareForm } from '../forms/load-firmware';
import { RestartForm } from '../forms/restart';
import { FormWrapper, InputContainer, InputLabel } from './styles';

type Props = {
  closeModal: () => void;
  commandType?: CommandType;
  chargePointId?: string;
};

export function CommandForm({ commandType, closeModal, chargePointId }: Props) {
  const [type, setType] = useState(commandType);

  const handleChange = (value: CommandType) => setType(value);

  const COMMAND_MAPPING: Record<CommandType, JSX.Element> = {
    CONFIGURE: (
      <ConfigureForm closeModal={closeModal} chargePointId={chargePointId} />
    ),
    DIAGNOSTICS: (
      <DiagnosticForm closeModal={closeModal} chargePointId={chargePointId} />
    ),
    LOAD_FIRMWARE: (
      <LoadFirmwareForm closeModal={closeModal} chargePointId={chargePointId} />
    ),
    RESTART: (
      <RestartForm closeModal={closeModal} chargePointId={chargePointId} />
    ),
  };

  return (
    <FormWrapper>
      <InputContainer>
        <InputLabel>
          <RequiredLabel>Команда</RequiredLabel>
        </InputLabel>
        <UiSelect
          value={type}
          onChange={handleChange} // TODO
          style={{ width: '100%' }}
          options={Object.entries(COMMAND_TYPE).map(([value, label]) => ({
            value,
            label,
          }))}
        />
      </InputContainer>
      {type && COMMAND_MAPPING[type]}
    </FormWrapper>
  );
}
