import styled from 'styled-components';
import { Typography, Input, Checkbox, Button } from 'antd';

import { COLOR } from 'shared/consts';

const { Text } = Typography;

export const StyledHeader = styled.header`
  background-color: ${COLOR.white};
  border-bottom: 1px solid ${COLOR.layoutBorder};
  height: 70px;
  display: flex;
  /* position: fixed;
  z-index: 1000;
  top: 0;
  width: calc(100% - 93px); */
  min-width: max-content;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 15px 20px;
  flex-grow: 1;
`;

export const HeaderControls = styled.div`
  padding: 4px 0 4px 0;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderControlsInner = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid ${COLOR.headerBorder};
`;

export const HeaderTitle = styled(Text)`
  color: ${COLOR.title};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-right: 15px;
`;

export const HeaderSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const HeaderInput = styled(Input)`
  width: auto;
  padding: 7px;
  background-color: ${COLOR.whiteSmoke};
  border-color: transparent;
  height: 29px;

  & input {
    background-color: ${COLOR.whiteSmoke};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLOR.black};
  }

  & input::placeholder {
    color: ${COLOR.placeholder};
  }
`;

export const LanguageRadioGroupWrapper = styled.div`
  padding: 4px 0 4px 0;
`;

export const StyledButton = styled(Button)`
  border-radius: 2px;
  background-color: ${COLOR.greenMenu};
  color: ${COLOR.white};
  padding: 6px 30px;
  margin-left: 7px;
  height: 29px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  &:hover,
  &:focus {
    color: ${COLOR.greenMenu};
    border-color: ${COLOR.greenMenu};
  }
`;

export const CheckboxWrapper = styled.div`
  margin-left: 10px;
  height: 15px;
`;

export const StyledCheckbox = styled(Checkbox)`
  height: 15px;
  display: flex;
  align-items: center;

  & .ant-checkbox {
    width: 15px;
    height: 15px;
    top: 0;
  }

  & > span:not(.ant-checkbox) {
    padding: 0;
    margin-left: 5px;
  }

  & .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    border: 2px solid ${COLOR.white};
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }
`;

export const CheckboxLabel = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR.black};
  height: 100%;
`;
