import { ADD_PREFIX, CAR_BRANDS, SETTINGS } from '../names';

export const CAR_BRAND = ':carBrandId';
export const CAR_MODEL = ':carModelId';

export const CAR_BRAND_ROUTES = {
  CAR_BRANDS: `/${SETTINGS}/${CAR_BRANDS}`,
  CAR_BRAND: `/${SETTINGS}/${CAR_BRANDS}/${CAR_BRAND}`,
  CAR_MODEL: `/${SETTINGS}/${CAR_BRANDS}/${CAR_BRAND}/${CAR_MODEL}`,
  ADD_CAR_BRAND: `/${SETTINGS}/${CAR_BRANDS}/${ADD_PREFIX}`,
  ADD_CAR_MODEL: `/${SETTINGS}/${CAR_BRANDS}/${ADD_PREFIX}-model`,
};
