import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { USER } from 'shared/consts/routes/identity-user';
import {
  ADD_PREFIX,
  CONTROLLER_CLAIMS,
  IDENTITY_ROLES,
  IDENTITY_USERS,
} from 'shared/consts/names';

import { RequireAuth } from 'entities/authorization';
import { IdentityUserLayout } from 'entities/identity-user/ui/user-layout';
import { IdentityUsersLayout } from 'entities/identity-user/ui/users-layout';

import { IdentityUsersMenu } from 'features/identity-user/menu';

// TODO
// import { ConnectedUserChargePointRights } from '../../../components/Security/User/pages/ConnectedUserChargePointRights';

import { AddIdentityUserPage } from 'pages/identity-user/add';
import { EditIdentityUserPage } from 'pages/identity-user/edit';
import { IdentityUserListPage } from 'pages/identity-user/list';
import { IdentityUserRolesPage } from 'pages/identity-user/roles';
import { IdentityUserControllerClaimsForm } from 'features/identity-user/controller-claims';

export const IdentityUserRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={IDENTITY_USERS}
      element={<SectionLayout menu={<IdentityUsersMenu />} />}
    >
      <Route element={<IdentityUsersLayout />}>
        <Route index element={<IdentityUserListPage />} />
      </Route>
      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddIdentityUserPage />} />}
        />
      </Route>

      <Route path={USER} element={<IdentityUserLayout />}>
        <Route index element={<EditIdentityUserPage />} />
        <Route
          path={CONTROLLER_CLAIMS}
          element={<IdentityUserControllerClaimsForm />}
        />
        {/* <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedUserChargePointRights />}
        />*/}
        <Route path={IDENTITY_ROLES} element={<IdentityUserRolesPage />} />
      </Route>
    </Route>
  </Route>
);
