import { Menu, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomLink, EmptyData, ErrorMessage, NavMenuError } from 'shared/ui';
import { useGetUserGroups } from 'shared/api/services/user/rtk/enhanced';
import { GroupDto } from 'shared/api/services/user/generated';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { SectionMenuItem } from 'shared/ui/section-menu/menu-item';
import { getOpenKeys } from 'shared/ui/ui-menu/lib/get-open-keys-array';
import { getServerErrorText } from 'shared/lib';

import { AddUserLink } from './add-user-link';
import { AddUserGroupLink } from './add-user-group-link';

import { StyledBasicMenu } from '../../../common-styles/basic-menu';
import { USERS } from 'shared/consts/names';

export function UserGroupsMenu() {
  // @ts-expect-error
  const { data, error, isFetching } = useGetUserGroups();

  if (data?.status === 'ERROR') {
    return <NavMenuError text={getServerErrorText(data.statusMessage)} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.data || [];

  return <UserGroupsMenu.Layout loading={isFetching} items={items} />;
}

const fullPath = USER_ROUTES.PERSONS_LIST;

function buildMenuItems(group: GroupDto, groups: GroupDto[]) {
  const { id, name, children } = group;

  if (children?.length === 0) {
    return <SectionMenuItem id={id} name={name} />;
  }

  return (
    <CustomLink to={`${id}`}>
      <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
        {group.children?.map((group) => buildMenuItems(group, groups))}
      </Menu.SubMenu>
    </CustomLink>
  );
}

type Props = {
  loading: boolean;
  items: GroupDto[];
};

UserGroupsMenu.Layout = function ({ loading, items }: Props) {
  const { groupId } = useParams() as {
    groupId: string;
  };

  return (
    <>
      <AddUserLink />
      <AddUserGroupLink />
      <StyledBasicMenu
        openKeys={
          Number(groupId)
            ? ['root', ...getOpenKeys(items, Number(groupId))]
            : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={loading}>
          <CustomLink
            to={fullPath}
            checkIsActive={(path) =>
              path.includes(`${USERS}?`) || path === fullPath
            }
          >
            <Menu.SubMenu key="root" title="Физ. лица" eventKey="root">
              {items
                .filter((k) => !k.parentId)
                .map((el) => buildMenuItems(el, items))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );
};
