import {
  ServiceDto,
  TargetsServicesListsDto,
} from 'shared/api/services/billing/rtk/generated';

import { Service } from './service';
import { StyledContainer, StyledContent, StyledHeader } from './styles';

type Props = {
  servicesList: TargetsServicesListsDto;
};

export function ServicesListInfo({ servicesList }: Props) {
  const { name, services } = servicesList;

  return (
    <StyledContainer>
      <StyledHeader>{name}</StyledHeader>
      <StyledContent>
        {services.map((el) => (
          <Service key={el.id} {...el} />
        ))}
      </StyledContent>
    </StyledContainer>
  );
}
