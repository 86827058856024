import { useNavigate } from 'react-router-dom';

import { useGetLazyFiscalChecks } from 'shared/api/services/fiscal/rtk/enhanced';
import { isErrorWithMessage, openErrorNotification } from 'shared/lib';

import { StyledText, TextContainer } from './styles';

type Props = {
  billingId: string;
};

export function FiscalCheckLink({ billingId }: Props) {
  const [trigger, { isLoading }] = useGetLazyFiscalChecks();

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const res = await trigger({
        billingId,
      });

      if (res.data?.data?.length) {
        const { receiptUrl } = res.data.data[0];

        if (receiptUrl) {
          const link = document.createElement('a');

          link.setAttribute('href', receiptUrl);
          link.setAttribute('target', '_blank');

          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusMessage
        : 'Не удалось получить чек';

      openErrorNotification(errorText);
    }
  };

  return (
    <TextContainer>
      {isLoading ? (
        'Loading...'
      ) : (
        <StyledText onClick={handleClick}>Чек</StyledText>
      )}
    </TextContainer>
  );
}
