import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';

import { UiPopover } from 'shared/ui';
import { useAbility } from 'shared/lib/ability/context';

import {
  ChargePointManualCommandType,
  CHARGE_POINT_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';

import { PopoverButtonsContainer } from '../../../../common-styles';
import { CommandModal } from 'features/command';

type Props = {
  chargePointName: string;
};

export function ChargePointTableActions({ chargePointName }: Props) {
  const ability = useAbility();

  // if (!can('Execute', 'PrivateMethods')) {
  //   return null;
  // }

  const showManualCommandModal = (type: ChargePointManualCommandType) => {
    NiceModal.show(CommandModal, {
      commandType: type,
      chargePointName,
    });
  };

  const manualCommands = (
    Object.keys(CHARGE_POINT_MANUAL_COMMAND_TYPE) as unknown as Array<
      keyof typeof CHARGE_POINT_MANUAL_COMMAND_TYPE
    >
  ).map((type) => {
    return (
      <button
        key={CHARGE_POINT_MANUAL_COMMAND_TYPE[type]}
        onClick={() => {
          showManualCommandModal(type);
        }}
      >
        {CHARGE_POINT_MANUAL_COMMAND_TYPE[type]}
      </button>
    );
  });

  const content = (
    <>
      <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
    </>
  );

  return (
    <UiPopover content={content} children={<MoreOutlined rotate={90} />} />
  );
}
