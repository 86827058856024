import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { useDeleteStage } from 'shared/api/services/billing/rtk/enhanced';
import { useAppDispatch } from 'shared/redux/types';
import { baseApi } from 'shared/api/base';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  stageId: string;
  servicesListId: string;
};

export const Modal = NiceModal.create(({ stageId, servicesListId }: Props) => {
  const modal = useModal();

  const dispatch = useAppDispatch();

  const [trigger, { isLoading }] = useDeleteStage();

  const handleDelete = async () => {
    try {
      const res = await trigger(stageId).unwrap();

      openSuccessNotification(DELETE_SUCCESS);

      dispatch(
        baseApi.util.invalidateTags([
          { type: 'ServicesLists', id: servicesListId },
        ])
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить стадию услуги?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
