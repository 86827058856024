import { enhancedApi as api } from '../generated';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getApiReserveV1Sessions: (endpoint) => {
      endpoint.providesTags = [{ type: 'ReserveSessions', id: 'LIST' }];
    },
  },
});

export const {
  useGetApiReserveV1SessionsQuery: useGetReserveSessions,
  useGetApiReserveV1SessionsCountQuery: useGetReserveSessionsCount,
  useGetApiReserveV1SessionsByIdQuery: useGetReserveSession,
  useGetApiReserveV1LocksQuery: useGetReserveLocks,
} = enhancedApi;
