import { useParams } from 'react-router-dom';

import { ChargePointInfoContainer } from './container';

export function ChargePointInfoPage() {
  const { chargePointId } = useParams() as {
    chargePointId: string;
  };

  return <ChargePointInfoContainer chargePointId={chargePointId} />;
}
