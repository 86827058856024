import { z } from 'zod';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import {
  useGetTemplate,
  useGetTemplates,
} from 'shared/api/services/pushnotification/rtk/enhanced';
import { useTypedParams } from 'shared/lib/router/use-typed-params';

import { Form } from './form';

const pageParamsSchema = z.object({
  templateId: z.string(),
});

export function FormContainer() {
  const { templateId } = useTypedParams(pageParamsSchema);

  const templateQuery = useGetTemplate(templateId);

  if (templateQuery.isFetching) {
    return <div>Loading...</div>;
  }

  if (templateQuery.error) {
    return <ErrorMessage text={getServerErrorText(templateQuery.error)} />;
  }

  if (!templateQuery.data?.data) {
    return <EmptyData />;
  }

  return <Form template={templateQuery.data.data} />;
}
