import { GetSuccessfulSessionMetricsVm } from 'shared/api/services/chargepoint/rtk/generated/metrics';
import { addSpacesToNumber } from 'shared/lib';

import { getMetricsLineChartData } from 'entities/metrics';

import { colorParams } from '../../consts';
import { MetricsLayout } from '../graph-layout';
import { Summary } from './styles';

type Props = Omit<
  React.ComponentProps<typeof MetricsLayout>,
  'summary' | 'chartData'
> & {
  data: GetSuccessfulSessionMetricsVm[];
};

export function MetricsGraph(props: Props) {
  const { data } = props;

  const total = data.reduce((acc, el) => acc + el.value, 0);
  const chartData = getMetricsLineChartData(data, colorParams);

  const summary = (
    <Summary>{`${addSpacesToNumber(total)} успешных сессий`}</Summary>
  );

  return <MetricsLayout {...props} summary={summary} chartData={chartData} />;
}
