import { ADD_PREFIX, HUBS } from '../names';

export const HUB = ':hubId';

const ROOT = `/${HUBS}`;

export const HUB_ROUTES = {
  HUBS: ROOT,
  HUB: `${ROOT}/${HUB}`,
  ADD_HUB: `${ROOT}/${ADD_PREFIX}`,
};
