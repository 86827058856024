import { Col, Spin } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState } from 'react';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiEditFormButton,
} from 'shared/ui';
import { CustomInput } from 'shared/ui/form/custom-input';
import {
  FORMAT_TO_DAYS,
  FORMAT_TO_SECONDS,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  showTimeString,
} from 'shared/lib';
import { CustomCheckbox } from 'shared/ui/form/custom-checkbox';
import { useUpdateUser } from 'shared/api/services/user/rtk/enhanced';
import { UiDivider } from 'shared/ui/ui-kit';
import { CustomSelect } from 'shared/ui/form/custom-select';
import { USER_ROUTES } from 'shared/consts/routes/user';
import { GetCountryVm } from 'shared/api/services/information/rtk/generated/countries';
import { GroupDto, UserDto } from 'shared/api/services/user/rtk/generated';
import { FormRow } from 'shared/ui/form';
import { UiCard } from 'shared/ui/ui-card';

import { SEX } from 'entities/user';

import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

import { FormInput, FormOutput, FormSchema } from '../../consts/schema';
import { Title, UserLogin } from './styles';
import { UserCarList } from 'features/car/user-cars';

type Props = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
  user: UserDto;
};

export function Form({ countries, user, userGroups }: Props) {
  const form = useForm<FormInput, void, FormOutput>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      ...user,
      birthDate: user.birthDate
        ? showTimeString(user.birthDate, FORMAT_TO_DAYS)
        : '',
    },
  });

  const [trigger, { isLoading }] = useUpdateUser();

  const [isDisabled, setIsDisabled] = useState(true);

  const enableForm = () => setIsDisabled(false);

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      const res = await trigger({
        userId: user.id,
        updateUserRequestDto: data,
      }).unwrap();

      openSuccessNotification(EDIT_SUCCESS);

      // navigate(`${USER_ROUTES.PERSONS_LIST}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.statusMessage : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  });

  return (
    <UiCard>
      <UiCard.Header>
        <Title>
          Пользователь <UserLogin>{` ${user.login}`}</UserLogin>
        </Title>
      </UiCard.Header>
      <UiCard.Body>
        <FormProvider {...form}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Form.Fields
                countries={countries}
                userGroups={userGroups}
                isDisabled={isDisabled}
                userId={user.id}
              />
              <Form.Buttons isDisabled={isDisabled} enableForm={enableForm} />
            </form>
          </Spin>
        </FormProvider>
      </UiCard.Body>
    </UiCard>
  );
}

type FieldsProps = {
  countries: GetCountryVm[];
  userGroups: GroupDto[];
  isDisabled: boolean;
  userId: string;
};

Form.Fields = function Fields({
  countries,
  userGroups,
  isDisabled,
  userId,
}: FieldsProps) {
  const {
    formState: { errors },
  } = useFormContext<FormInput, void, FormOutput>();

  return (
    <>
      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="login"
            label="Номер телефона"
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="email"
            label="Email"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="lastName"
            label="Фамилия"
            disabled={isDisabled}
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="firstName"
            label="Имя"
            disabled={isDisabled}
          />
        </Col>
        <Col span={6}>
          <CustomInput<FormInput>
            name="middleName"
            label="Отчество"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomInput<FormInput>
            name="birthDate"
            label="Дата рождения"
            disabled={isDisabled}
          />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="sex"
            label="Пол"
            options={Object.entries(SEX).map((entry) => {
              const [key, label] = entry;

              return { value: key, label };
            })}
            disabled={isDisabled}
          />
        </Col>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="countryId"
            options={countries.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Страна"
            required
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <FormRow gutter={10}>
        <Col span={6}>
          <CustomSelect<FormInput>
            name="groupId"
            options={userGroups.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            label="Добавить в группу"
            allowClear
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <UiDivider />

      <FormRow gutter={10}>
        <Col span={12}>
          <CustomInput<FormInput>
            name="notesRelatedWithUser"
            label="Комментарий"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>

      <UiDivider />

      <UserCarList userId={userId} />

      <FormRow style={{ gap: '10px' }}>
        <Col>
          <CustomCheckbox<FormInput>
            name="userAgreementAccepted"
            label="Флаг принятия условий ПС"
            disabled={isDisabled}
          />
        </Col>
        <Col>
          <CustomCheckbox<FormInput>
            name="isDebug"
            label="isDebug"
            disabled={isDisabled}
          />
        </Col>
      </FormRow>
    </>
  );
};

type ButtonsProps = {
  enableForm: () => void;
  isDisabled: boolean;
};

Form.Buttons = function Buttons({ enableForm, isDisabled }: ButtonsProps) {
  return (
    <FormControlsContainer>
      <ButtonsContainer>
        {isDisabled ? (
          <UiEditFormButton onClick={enableForm} />
        ) : (
          <UiSubmitButton />
        )}
        <UiCancelFormButton />
      </ButtonsContainer>
    </FormControlsContainer>
  );
};
