import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/redux/types';
import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { FORMAT_TO_DAYS, showTimeString } from 'shared/lib';

import {
  selectEventsTableColumns,
  setTableColumns,
} from 'shared/redux/slices/events/slice';

import { EVENT_NAME, EVENT_TYPE } from 'entities/event/consts';
import { CONNECTOR_STATUS } from 'entities/connector';
import { GetEventVm } from 'shared/api/services/chargepoint/rtk/generated/events';
import { DATE_TIME_COLUMN_WIDTH } from 'shared/consts/table';

const columnHelper = createColumnHelper<GetEventVm>();

const DATA_COLUMNS = [
  columnHelper.accessor('createdDate', {
    id: 'createdDate',
    header: 'Время получения',
    size: DATE_TIME_COLUMN_WIDTH,
    minSize: DATE_TIME_COLUMN_WIDTH,
    maxSize: DATE_TIME_COLUMN_WIDTH,
    cell: (props) => {
      const date = props.getValue();

      return date ? showTimeString(date, FORMAT_TO_DAYS) : '';
    },
    meta: {
      filterElement: (
        <DateTimeRangePickerFilter
          dateFromName="dateFrom"
          dateToName="dateTo"
        />
      ),
    },
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: 'Тип',
    size: 250,
    minSize: 250,
    maxSize: 250,
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(EVENT_TYPE).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="type"
        />
      ),
    },
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Название',
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(EVENT_NAME).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="name"
        />
      ),
    },
  }),
  columnHelper.accessor('connectorStatus', {
    id: 'connectorStatus',
    header: 'Статус коннектора',
    meta: {
      filterElement: (
        <SelectFilter
          options={[
            {
              label: 'Все',
              value: '',
            },
            ...Object.entries(CONNECTOR_STATUS).map((entry) => {
              const [value, label] = entry;

              return { value, label };
            }),
          ]}
          paramName="connectorStatus"
          disabled
        />
      ),
    },
  }),
  columnHelper.accessor('connectorId', {
    id: 'connectorId',
    header: 'ID коннектора',
    size: 250,
    minSize: 250,
    maxSize: 250,
    meta: {
      filterElement: <InputFilter paramName="connectorId" />,
    },
  }),
  columnHelper.accessor('energy', {
    id: 'energy',
    header: 'кВт*ч',
    meta: {
      filterElement: <InputFilter paramName="energy" />,
    },
  }),
  columnHelper.accessor('instantPower', {
    id: 'instantPower',
    header: 'Мощность',
    meta: {
      filterElement: <InputFilter paramName="instantPower" />,
    },
  }),
  columnHelper.accessor('instantCurrent', {
    id: 'instantCurrent',
    header: 'Ток',
    meta: {
      filterElement: <InputFilter paramName="instantCurrent" />,
    },
  }),
];

export const useColumns = () => {
  const dispatch = useAppDispatch();

  const tableColumns = useSelector(selectEventsTableColumns);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: tableColumns[id],
      };
    }),
    settings: tableColumns,
    renderCell: (props) => {
      return '';
    },
    // setVisibleColumns: (cols: TableColumnsState) => {
    setVisibleColumns: (cols) => {
      dispatch(setTableColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = tableColumns
      ? DATA_COLUMNS.filter((el) => tableColumns[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [tableColumns]);

  return visibleColumns;
};
