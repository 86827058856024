import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { renderBooleanAsString } from 'shared/lib';
import { GetChargePointVm } from 'shared/api/services/chargepoint/rtk/generated/charge-points';

import { CHARGE_POINT_PROTOCOL } from 'entities/charge-point';

import {
  StyledCard,
  CardTitle,
  GridWrapper,
  GridRow,
  GridCell,
  ParamKey,
  ParamValue,
} from './styles';
import { getOcppConfigFromString } from 'entities/charge-point/lib/get-ocpp-config-string';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  chargePoint: GetChargePointVm;
};

export function TechInfoCard({ chargePoint }: Props) {
  const {
    warningText,
    manufacturer,
    softwareRevision,
    commentary,
    chargePointProtocol,
    isVisible,
    isTestCp,
    ipAddress,
    ocppConnectionBlockDate,
    isBlocked,
    simNumber,
    ocppConfig,
    model,
    chargeBoxIdentity,
  } = chargePoint;

  const PARAMS: Array<{
    label: React.ReactNode;
    value: React.ReactNode;
  }> = [
    {
      label: 'Аварийное сообщение в приложении',
      value: warningText,
    },
    {
      label: 'Производитель и серийный номер',
      value: `${manufacturer} ${model || ''}`,
    },
    { label: 'Внутреннее описание', value: commentary },
    { label: 'СhargeBoxIdentity', value: chargeBoxIdentity },
    { label: 'Номер SIM', value: simNumber },
    { label: 'IP адрес', value: ipAddress },
    // { label: 'Балансировка', value: '' },
    {
      label: 'Протокол ЭЗС',
      value: CHARGE_POINT_PROTOCOL[chargePointProtocol],
    },
    {
      label: 'OCPP конфиг',
      value: ocppConfig ? getOcppConfigFromString(ocppConfig) : '',
    },
    { label: 'Версия ПО', value: softwareRevision },
    {
      label: 'Показывать в приложении',
      value: renderBooleanAsString(isVisible),
    },
    // { label: 'Скоро открытие', value: '' },
    { label: 'Тестовая', value: renderBooleanAsString(isTestCp) },
    {
      label: 'Блокировано СПАМ фильтром',
      value: isBlocked
        ? dayjs(ocppConnectionBlockDate)
            .tz('Europe/Moscow')
            .format('YYYY-MM-DD HH:mm:ss')
        : null,
    },
  ];

  return (
    <StyledCard>
      <CardTitle>Техническая информация</CardTitle>
      <GridWrapper>
        {PARAMS.map(({ label, value }, idx) => {
          return (
            <GridRow key={idx}>
              <GridCell>
                <ParamKey>{label}</ParamKey>
              </GridCell>
              <GridCell>
                <ParamValue>{value}</ParamValue>
              </GridCell>
            </GridRow>
          );
        })}
      </GridWrapper>
    </StyledCard>
  );
}
